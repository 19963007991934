<template>
  <div class="ls-loop-sidecart">
    <GlobalEvents
      v-if="active"
      @keydown.escape="toggleCart"
    />
    <transition name="sidecart-backdrop">
      <div
        v-if="active"
        class="ls-loop-sidecart__backdrop"
        @click="toggleCart"
      >
      </div>
    </transition>
    <transition name="sidecart">
      <div
        v-if="active"
        class="ls-loop-sidecart__panel"
      >
        <cart-header
          :theme="theme"
          :text="text"
          :cart="products"
          @toggle="toggleCart"
        />
        <transition-group
          class="ls-loop-sidecart__items"
          name="product-list"
          tag="section"
        >
          <cart-product
            v-for="(product, index) in productsWithKeys"
            :key="product.key"
            class="ls-loop-sidecart__item"
            :products="products"
            :product="product"
            :theme="theme"
            :index="index"
            :loading="loading"
            :data-testid="`cart-product-${index}`"
            @update="updateProducts"
          />
        </transition-group>
        <cart-footer
          :theme="theme"
          :text="text"
          :cart="products"
          :total="total"
          :loading="loading"
          :view="view"
          @toggle="toggleCart"
          @next="fireNext"
          @back="fireBack"
          @to-shop="fireToShop"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import CartHeader from './CartHeader';
import CartProduct from './CartProduct';
import CartFooter from './CartFooter';

export default {
  name: 'LoopSidecart',
  components: {
    CartHeader,
    CartProduct,
    CartFooter,
  },
  props: {
    products: {
      type: Array,
      required: false,
      default: () => ([])
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    theme: {
      type: Object,
      required: false,
      default: () => ({})
    },
    text: {
      type: Object,
      required: false,
      default: () => ({})
    },
    total: {
      type: Number,
      required: false,
      default: 0
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    view: {
      type: String,
      required: true,
    }
  },
  emits: [
    'toggle',
    'update',
    'next',
    'back',
    'toShop'
  ],
  computed: {
    productsWithKeys() {
      return this.products.map((product) => {
        // We need a unique number for making keys that don't clash
        // We can't rely on any product data to be unique on a per item basis
        // because we don't have proper line item ids for new items
        const random = [...Array(16)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
        return {
          ...product,
          key: random
        };
      });
    }
  },
  methods: {
    toggleCart() {
      this.$emit('toggle');
    },
    updateProducts(removeIndex) {
      this.$emit('update', removeIndex);
    },
    fireNext() {
      this.$emit('next', this.products);
    },
    fireBack() {
      this.$emit('back');
    },
    fireToShop() {
      this.$emit('toShop');
    }
  }
};
</script>

<style lang="scss" scoped>
.product-list-enter-from,
.product-list-leave-to {
  opacity: 0;
  transform: translateY(-6px);
}

.product-list-leave-active {
  position: absolute;
}

.ls-loop-sidecart {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 234123423;
  pointer-events: none;

  &__backdrop {
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background: rgba(black, 0.6);
    transform: translateZ(0);
    backface-visibility: hidden;
    will-change: opacity;
    pointer-events: all;

    &.sidecart-backdrop-enter-active,
    &.sidecart-backdrop-leave-active {
      transition: opacity 0.12s ease;
    }

    &.sidecart-backdrop-enter-from,
    &.sidecart-backdrop-leave-to {
      opacity: 0;
    }
  }

  &__panel {
    background: $base-background;
    color: $base-text;
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-width: 420px;
    width: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: all;
    box-shadow: 0 0 20px rgba(black, 0.1);

    &.sidecart-enter-active,
    &.sidecart-leave-active {
      transition: transform 0.3s cubic-bezier(0.3, 0.5, 0.35, 1);
    }

    &.sidecart-enter-from,
    &.sidecart-leave-to {
      transform: translate3d(100%, 0, 0);
    }
  }

  &__items {
    position: relative;
    flex-grow: 1;
    width: 100%;
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__item {
    transition: all $easing-regular;
  }
}
</style>
