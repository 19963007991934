export const languages = {
  en: 'English',
  fr: 'Français',
  'fr-CA': 'Français (Canada)',
  de: 'Deutsch',
  ja: '日本語',
  it: 'Italian',
  es: 'Español (Spain)',
  'es-XL': 'Español (Latin America)',
  nl: 'Nederlands',
};
