<template>
  <div
    class="exchange-item-container"
  >
    <div
      v-if="!product || product.loading"
      class="exchange-placeholder card"
    >
      <div class="exchange-placeholder__container">
        <div class="exchange-placeholder__image"></div>
        <div class="exchange-placeholder__info">
          <div class="exchange-placeholder__title"></div>
          <div class="exchange-placeholder__copy"></div>
        </div>
      </div>
    </div>
    <div
      v-else-if="product.failed"
      class="loading-failed card"
    >
      <div
        class="failed-image"
        aria-hidden="true"
      ></div>
      <div
        class="failed-info"
        aria-hidden="true"
      >
        <div class="failed-title"></div>
        <div class="failed-copy"></div>
      </div>
      <div class="failed-overlay">
        <base-text>
          <render-content>
            {{ $content.moduleLineItem.loadingFailed }}
          </render-content>
        </base-text>
      </div>
    </div>
    <template v-else>
      <product-card
        :product="product"
        :disabled="disabled"
        :loading-item="loadingItem"
        :is-bundle="isBundle"
        @selectProduct="selectProduct"
        @discardTransaction="discardTransaction"
      >
        <template #product-additional="additionalProps">
          <slot
            name="product-additional"
            v-bind="additionalProps"
          />
        </template>
      </product-card>
      <transition-expand>
        <div
          v-if="isExpanded"
          class="exchange-item-container__bundle-expand"
        >
          <product-card
            v-for="item in bundleItems"
            :key="item.id"
            :product="item"
            :disabled="item.allowed.returned || !item.allowed.return"
            :loading-item="loadingItem"
            @selectProduct="selectProduct"
            @discardTransaction="discardTransaction"
          />

          <progress-button
            v-if="!hideReturnAll"
            class="exchange-item-container__return-btn"
            :hide-arrow="true"
            center-text
            @click="handleReturnAll"
          >
            {{ $content.moduleLineItem.bundleReturnAll }} ({{ bundleItems.length }})
          </progress-button>
        </div>
      </transition-expand>
      <progress-button
        v-if="isBundle && allowsPartialReturns"
        class="exchange-item-container__show-all-btn"
        :hide-arrow="true"
        :disabled="isBundleSelected"
        center-text
        @click="handleShowAll"
      >
        {{ showAllText }}
      </progress-button>
    </template>
  </div>
</template>

<script>
import { BaseText } from '@loophq/design-system';
import ProductCard from '@/components/product/ProductCard';
import TransitionExpand from '@/components/globals/TransitionExpand';
import ProgressButton from '@/components/ProgressButton';

export default {
  components: {
    TransitionExpand,
    ProductCard,
    ProgressButton,
    BaseText
  },
  props: {
    bundleItems: {
      type: Array,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    loadingItem: {
      type: [Number, String],
      required: true,
    },
    product: {
      type: Object,
      required: true,
    }
  },
  emits: [
    'selectProduct',
    'discardTransaction',
  ],
  data: () => {
    return {
      isExpanded: false,
    };
  },
  computed: {
    isBundle() {
      return this.product?.is_bundle === 1;
    },
    allowsPartialReturns() {
      return this.product?.bundle?.partialReturns;
    },
    hideReturnAll() {
      return this.bundleItems.some(item => item.allowed.returned) || this.product?.bundle.disallowBundleExchange;
    },
    showAllText() {
      const itemCount = (this.bundleItems && this.bundleItems.length) ?? 0;
      const message = this.isExpanded ? 'Show less' : 'Show all';
      return `${message} (${itemCount})`;
    },
    isBundleSelected() {
      return this.product.returnType !== undefined;
    }
  },
  methods: {
    selectProduct(item) {
      if (item.is_bundle === 1 && this.allowsPartialReturns) {
        this.handleShowAll();
      } else {
        this.$emit('selectProduct', item);
      }
    },
    discardTransaction(item) {
      this.$emit('discardTransaction', item.id);
    },
    selectBundleProduct($event) {
      $event.stopImmediatePropagation();
    },
    handleShowAll() {
      if (!this.isBundleSelected) {
        this.isExpanded = !this.isExpanded;
      }
    },
    handleReturnAll() {
      //Get all items in bundle and deselect them
      this.bundleItems.map((item) => this.discardTransaction(item));
      this.isExpanded = false;
      this.$emit('selectProduct', this.product);
    },
  },
};
</script>

<style lang="scss" scoped>
.exchange-item-container {
  margin-bottom: 1rem;

  &__bundle-expand {
    background: white;
    padding: var(--spacing-300);
  }

  &__return-btn {
    font-weight: bold;
    margin-top: var(--spacing-300);
  }

  &__show-all-btn {
    &:disabled {
      background: white !important;
      cursor: not-allowed !important;
      pointer-events: auto !important;

      .progress-button__text {
        color: var(--grey-300);
      }
    }
  }
}

.loading-failed {
  position: relative;
  width: 100%;
  display: flex;
  margin-bottom: var(--spacing-300);
  padding: var(--spacing-300);
  border-radius: var(--theme-corners);
  opacity: 0.5;
}

.failed-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.failed-image {
  width: 100px;
  height: 100px;
  background-color: var(--grey-100);
  margin-right: 0.5rem;
}

.failed-info {
  padding: var(--spacing-300);
  width: calc(100% - 125px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.failed-title {
  width: 50%;
  background-color: var(--grey-100);
  height: var(--spacing-300);
  margin-bottom: var(--spacing-300);
}

.failed-copy {
  width: 75%;
  background-color: var(--grey-100);
  height: var(--spacing-300);
}
</style>
