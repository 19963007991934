import axios from 'axios';
import Store from '@/store';
import useStorageMethods from '@/js/mixins/store';
import { storeCreditTypes } from '@/js/constants/returns';
import { isSplitReturn, returnMethod } from './helpers';

const {
  getStore,
  setStore,
  removeStore,
} = useStorageMethods();

/**
 * @typedef Variant
 * @type {object}
 * @property {number} productId
 * @property {number} variantId
 * @property {string=} sku
 * @property {string} title
 * @property {string} variantTitle
 * @property {string=} productType
 * @property {string=} image
 */

/**
 * @typedef LineItem
 * @type {object}
 * @property {number} id - The line item id
 * @property {'exchange'|'credit'} returnType - Used for return submission
 * @property {'exchange'|'return'} resolution - Used for totals
 * @property {object} reason - The reason data attached to this line item
 * @property {number} reason.id - The reason id
 * @property {string=} reason.comment - If applicable, the comment written by the user
 * @property {number} variantId - The variant of the line item
 * @property {number} productId - The product of the line item variant
 * @property {number} sku - The sku of the line item variant
 * @property {Variant=} exchangeVariant - The new variant to be exchanged for
 * @property {boolean=} isAdvancedExchange
 * @property {boolean=} allowExchangeStorefront - Allow for upsell/refund on variant or advanced exchange
 */

/**
 * @typedef CartItem
 * @type {object}
 * @property {number} variantId - The line item id
 */

/**
 * @typedef {'refund'|'gift'} CreditType
 */

/**
 * @typedef {'regular'|'instant'|null} ExchangeType
 */

/**
 * @typedef {'gift-card'|'shop-cash'} StoreCreditType
 */

/**
 * @typedef {'regular'|'instant'|null} ReturnType
 */

/**
 * @typedef Address
 * @type {object}
 * @property {string} address1
 * @property {string} address2
 * @property {string} city
 * @property {string} country
 * @property {string} countryCode
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} phone
 * @property {string=} state
 * @property {string} zip
 */

/**
 * @typedef Customer
 * @type {object}
 * @property {string} email
 */

const initialState = () => {
  return {
    /** @type {LineItem[]} */
    lineItems: [],
    /** @type {CartItem[]} */
    newItems: [],
    /** @type {number|null} - The on-store cart id */
    cartId: null,
    /** @type {number|null} - The on-store cart id */
    cartIdForDraft: null,
    /** @type {CreditType|null} */
    creditType: null,
    /** @type {ExchangeType|null} */
    exchangeType: null,
    /** @type {StoreCreditType} */
    storeCreditType: 'gift-card',
    /** @type {ReturnType|null} */
    returnType: 'regular',
    /** @type {boolean} */
    isGift: false,
    /** @type {object} */
    stripeToken: null,
    /** @type {object} */
    shopifyPaymentsToken: null,
    /** @type {object} */
    stripePaymentIntentId: null,
    /** @type {object} */
    shopifyToken: null,
    /** @type {Address|null} */
    address: null,
    /** @type {Customer|null} */
    customer: null,
    /** @type {boolean} */
    hasShopLaterOffer: false,
    /** @type {string|null} */
    key: null,
    /** @type {object|null} */
    shopLater: null,
    /** @type {boolean} */
    acceptsPolicyConditions: false,
    /** @type {boolean} */
    allowExchangeStorefront: false,
    /** @type {number|string} */
    workflowGroupId: null,
  };
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    /**
     * @param {object} state
     * @param {LineItem} item
     */
    addLineItem(state, item) {
      state.lineItems = [...state.lineItems, item];
      setStore('return', state);
    },
    /**
     * @param {object} state
     * @param {LineItem} item
     */
    removeLineItem(state, { id }) {
      // Mutate order object for now
      const order = Store.state.order;

      state.lineItems = state.lineItems.filter(item => item.id !== id);

      // no need to reset the workflowGroupId if the order has an active_workflow_group_id
      // workflowGroupId is needed to fetch updated warrantyAllowedOutcomes
      if (!state.lineItems.length && state.workflowGroupId && !order.active_workflow_group_id) {
        // reset workflowGroupId only if no remaining line items selected for warranty return
        state.workflowGroupId = null;
      }
      setStore('return', state);

      // Remove properties set by the app so we have a pristine line item
      // eslint-disable-next-line no-unused-vars
      const { returnType, reason, new_variant, advanced, resolution, product, ...lineItem } = order.line_items[id];
      Store.commit('updateOrder', {
        ...order,
        line_items: {
          ...order.line_items,
          [id]: lineItem
        }
      });
    },
    /**
     * @param {object} state
     * @param {CartItem} item
     */
    addNewItem(state, item) {
      state.newItems = [...state.newItems, item];
      setStore('return', state);
    },
    /**
     * @param {object} state
     * @param {CartItem[]} items
     */
    updateNewItems(state, items) {
      state.newItems = items;
      setStore('return', state);
    },
    updateLineItem(state, lineItem) {
      const updatedItem = {
        outcome: lineItem.outcome,
        outcomesSetByWorkflow: lineItem.outcomesSetByWorkflow
      };
      const itemIndex = state.lineItems.findIndex(item => item.id === lineItem.id);
      let item = state.lineItems[itemIndex];
      item = {
        ...item,
        ...updatedItem
      };
      state.lineItems[itemIndex] = item;
    },
    /**
     * @param {object} state
     * @param {CartItem} item
     */
    removeNewItem(state, { variantId }) {
      const indexToRemove = state.newItems.findIndex(item =>
        [item.variantId, item.id].includes(variantId)
      );
      state.newItems = state.newItems.filter(
        (item, index) => index !== indexToRemove
      );
      setStore('return', state);
    },
    /**
     * @param {object} state
     * @param {number} cartId
     */
    setCartId(state, cartId) {
      state.cartId = cartId;
      setStore('return', state);
    },
    /**
     * @param {object} state
     * @param {number} cartIdForDraft
     */
    setCartIdForDraft(state, cartIdForDraft) {
      state.cartIdForDraft = cartIdForDraft;
      setStore('return', state);
    },
    /**
     * @param {object} state
     * @param {CreditType} type
     */
    setCreditType(state, type) {
      state.creditType = type;
      setStore('return', state);
    },
    /**
     * @param {object} state
     * @param {ExchangeType} type
     */
    setExchangeType(state, type) {
      state.exchangeType = type;
      setStore('return', state);
    },
    /**
     * @param {object} state
     * @param {StoreCreditType} type
     */
    setStoreCreditType(state, type) {
      state.storeCreditType = type;
      setStore('return', state);
    },
    /**
     * @param {object} state
     * @param {ReturnType} type
     */
    setReturnType(state, type) {
      state.returnType = type;
      setStore('return', state);
    },
    /**
     * @param {object} state
     * @param {boolean} isGift
     */
    setIsGift(state, isGift) {
      state.isGift = isGift;
      setStore('return', state);
    },
    /**
     * @param {object} state
     * @param {object} stripeToken
     */
    setStripeToken(state, stripeToken) {
      state.stripeToken = stripeToken;
      setStore('return', state);
    },
    /**
     *
     * @param {object} state
     * @param {string} shopifyPaymentsToken
     */
    setShopifyPaymentsToken(state, shopifyPaymentsToken) {
      state.shopifyPaymentsToken = shopifyPaymentsToken;
      setStore('return', state);
    },
    /**
     *
     * @param {object} state
     * @param {string} shopifyToken
     */
    setShopifyToken(state, shopifyToken) {
      state.shopifyToken = shopifyToken;
      state.stripeToken = null;
      setStore('return', state);
    },
    /**
     *
     * @param {object} state
     * @param {string} stripePaymentIntentId
     */
    setStripePaymentIntentId(state, stripePaymentIntentId) {
      state.stripePaymentIntentId = stripePaymentIntentId;
      setStore('return', state);
    },
    /**
     * @param {object} state
     * @param {Address} address
     */
    setAddress(state, address) {
      state.address = address;
      setStore('return', state);
    },
    /**
     * @param {object} state
     * @param {Customer} customer
     */
    setCustomer(state, customer) {
      state.customer = customer;
      setStore('return', state);
    },
    /**
     * @param {object} state
     * @param {boolean} acceptsPolicyConditions
     */
    setAcceptsPolicyConditions(state, acceptsPolicyConditions) {
      state.acceptsPolicyConditions = acceptsPolicyConditions;
      setStore('return', state);
    },
    /**
     *
     * @param {number} workflowGroupId
     */
    setWorkflowGroupId(state, workflowGroupId) {
      state.workflowGroupId = workflowGroupId;
      setStore('return', state);
    },
    clear(state) {
      Object.assign(state, initialState());
      removeStore('return');
    },
    setState(state, data) {
      Object.entries(data).forEach(([key, value]) => {
        state[key] = value;
      });
    },
    setAllowExchangeStorefront(state, allowExchangeStorefront) {
      state.allowExchangeStorefront = allowExchangeStorefront;
      setStore('return', state);
    }
  },
  actions: {
    hydrate({ commit }) {
      const saved = getStore('return');

      if (saved) {
        commit('setState', saved);
      }
    },
    async generateShopifyPaymentToken({ commit }, { card, firstName, lastName }) {
      const { cvc, expiration, number } = card;

      const expiryArray = expiration.split('/').map(val => val.trim());
      const expiryMonth = expiryArray[0];
      const expiryYear = expiryArray[1];

      const numberFormatted = number.split(' ').join('');

      const creditCard = {
        first_name: firstName,
        last_name: lastName,
        month: expiryMonth,
        year: expiryYear,
        number: numberFormatted,
        verification_value: cvc
      };

      const shopifyTokenResponse = await axios.post(
        `https://elb.deposit.shopifycs.com/sessions`,
        { credit_card: creditCard }
      );

      if (shopifyTokenResponse && shopifyTokenResponse.data) {
        commit('setShopifyToken', shopifyTokenResponse.data);
      }
    }
  },
  getters: {
    hasItemsSelected(state) {
      return state.lineItems.length > 0;
    },
    hasCreditSelected(state) {
      return !!state.creditType;
    },
    isShopCash(state) {
      return state.storeCreditType === storeCreditTypes.SHOPCASH;
    },
    isSplitReturn(state, getters, rootState, rootGetters) {
      const lineItems = state.lineItems.map(li => rootState.order.line_items[li.id]);
      return isSplitReturn(rootGetters.settings.enableSplitRefunds, rootState.order, lineItems);
    },
    isGiftCardWithShopCash(state) {
      return state.storeCreditType === storeCreditTypes.GIFT_CARD_WITH_SHOP_CASH;
    },
    address(state) {
      return state.address;
    },
    customer(state) {
      return state.customer;
    },
    key(state) {
      return state.key;
    },
    selectedReturnMethod(state) {
      return {
        selectedOutcome: returnMethod(state),
        hasExchanges: state.newItems.length > 0,
      };
    }
  }
};
