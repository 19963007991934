<template>
  <header
    class="ls-cart-header"
    :style="{ 'font-family': theme.copyFont }"
  >
    <button
      class="ls-cart-header__close legacy-button"
      data-testid="close-cart"
      @click.prevent="toggleCart"
    >
      <span class="ls-cart-header__visually-hidden">Close exchange cart</span>
      <base-icon name="close" />
    </button>
    {{ $content.pageShopNow.cartHeading }}
  </header>
</template>

<script>
import {
  BaseIcon,
} from '@loophq/design-system';
export default {
  name: 'CartHeader',
  components: {
    BaseIcon,
  },
  props: {
    theme: {
      type: Object
    },
    text: {
      type: Object
    },
    cart: {
      type: Array
    }
  },
  emits: ['toggle'],
  methods: {
    toggleCart() {
      this.$trackEvent('cart closed');
      this.$emit('toggle');
    }
  }
};
</script>

<style lang="scss" scoped>
.ls-cart-header {
  $parent: &;

  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  padding: 1rem;
  font-family: $font-stack;
  font-size: 1rem;
  font-weight: 600;
  color: $base-text;
  text-align: center;
  border-bottom: 1px solid $light-grey;
  height: 4.5rem;

  &__close {
    position: absolute;
    right: 1rem;
    top: calc(50% - 10px);
    appearance: none;
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-neutral-500);
    cursor: pointer;
    transition: color $easing-regular;

    &:hover,
    &:focus {
      box-shadow: none;
      outline: none;
      color: black;

      #{$parent}__icon {
        transform: translate3d(-3px, 0, 0);
      }
    }
  }

  &__icon {
    width: 22px;
    height: 22px;
    transition: transform $easing-regular;
  }

  &__visually-hidden {
    @include visually-hidden;
  }
}
</style>
