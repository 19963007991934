<template>
  <div>
    <base-text type="body">
      <render-content :data="{ deadline: expiresAt }">
        {{ $content.moduleHappyReturnsDropoff.happyReturnsInstructions }}
      </render-content>
    </base-text>

    <div class="dropoff-content">
      <div>
        <div class="qr-code-container">
          <img
            class="happy-returns-logo"
            src="/img/happy-returns-logo.png"
          />
          <img
            class="qr-code"
            :src="returnMethod.qrCodeUrl"
          />
          <base-text class="qr-rmacode">
            {{ returnMethod.rmaCode }}
          </base-text>
        </div>
      </div>
      <div class="dropoff-location-container">
        <happy-returns-locations
          :locations="returnMethod.closest"
        />
        <div class="dropoff-button-container">
          <base-button
            v-track="'drop off view all locations clicked'"
            size="small"
            type="secondary"
            class="dropoff-button"
            target="_blank"
            :to="locationsLink"
          >
            <span class="dropoff-button-label">
              {{ $content.moduleHappyReturnsDropoff.viewAllLocationsButton }}
              <base-icon
                class="icon"
                name="external-link"
              />
            </span>
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseText,
  BaseButton,
  BaseIcon,
} from '@loophq/design-system';
import HappyReturnsLocations from '../ReviewPage/ReturnMethods/HappyReturnsLocations';

export default {
  components: {
    BaseText,
    BaseButton,
    BaseIcon,
    HappyReturnsLocations,
  },
  props: {
    returnMethod: {
      type: Object,
      required: true,
    },
    address: {
      type: Object,
      required: true,
    },
    expiresAt: {
      type: String,
      required: true,
    }
  },
  computed: {
    locationsLink() {
      return `https://locations.happyreturns.com/?has_qr_code=true&address=${this.address?.zip}`;
    },
  }
};
</script>

<style lang="scss" scoped>
.qr-code-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: var(--spacing-500);
}

.qr-code {
  max-width: 180px;
  padding: var(--spacing-200);
  border-radius: 7px;
}

.qr-rmacode {
  padding: var(--spacing-200);
  font-weight: bold;
}

.dropoff-content {
  margin-top: var(--spacing-500);
  display: flex;
  align-items: flex-start;
}

.dropoff-location-container {
  flex: 1;
}

.dropoff-button {
  padding: var(--spacing-200);
  margin-right: var(--spacing-200);
  color: var(--grey-800) !important;
}

.dropoff-button-label {
  font-weight: bold !important;
  display: flex;
  align-items: center;
}

.dropoff-button-container {
  display: flex;
  margin-top: var(--spacing-200);
}

.icon {
  height: 16px;
  margin-left: var(--spacing-100);
}

.happy-returns-logo {
  width: 160px;
}

@media screen and (max-width: $break-medium) {
  .dropoff-content {
    flex-direction: column;
    align-items: center;
    margin-top: var(--spacing-400);
  }

  .qr-code-container {
    max-width: 180px;
    margin-bottom: var(--spacing-400);
  }
}
</style>
