<template>
  <div
    ref="container"
    class="return-method__radio-container"
    :class="{ 'selected': selected === option.name, 'single': isSingle }"
    @click="$emit('change', option)"
  >
    <radio-input
      ref="radio"
      v-model="selected"
      class="return-method__radio-input"
      :class="{ 'selected': selected === option.name, 'single': isSingle }"
      :value="option.name"
      :name="name"
      :disabled="disabled"
      @focus="updateFocus"
      @focusout="removeFocus"
      @blur="removeFocus"
      @change="$emit('change', option)"
      @keyup.space="$emit('change', option)"
    >
      <div class="return-method__content-row">
        <div class="return-method__icon">
          <img
            class="return-method__icon-img"
            :src="option.logo"
            :class="{ 'return-method__icon-img--disabled': disabled }"
          />
        </div>
        <span class="return-method__radio-content">
          <div class="return-method__heading">
            <base-text
              class="return-method__heading-text"
              type="body"
            >
              <render-content>
                {{ $content[`moduleReturnMethodSelector${customizationName}`].heading }}
              </render-content>
            </base-text>
            <base-tooltip
              v-if="showLeafIcon"
              class="summary-section__description"
              placement="bottom"
            >
              <img
                class="return-method__leaf"
                src="/img/leaf.svg"
              />
              <template #content>
                <div class="sustainable-tooltip">
                  <render-content>
                    {{ sustainableDescription }}
                  </render-content>
                </div>
              </template>
            </base-tooltip>
          </div>
          <base-text
            type="body-2"
            class="return-method__subheading"
          >
            <render-content>
              {{ $content[`moduleReturnMethodSelector${customizationName}`].description }}
            </render-content>
          </base-text>
        </span>
        <base-text
          v-if="handlingFee && !hasReturnCoverage"
          type="body-2"
          theme="subdued"
          class="return-method__fee"
          :class="{ 'single': isSingle }"
        >
          {{ handlingFee }}
        </base-text>
        <base-text
          v-if="handlingFee && hasReturnCoverage"
          type="body-2"
          theme="subdued"
          class="return-method__fee-free"
          :class="{ 'single': isSingle }"
        >
          Free
        </base-text>
      </div>
    </radio-input>
    <transition-expand>
      <div v-if="selected === option.name">
        <div
          class="return-method__option"
          :class="{ 'return-method__option--disabled': disabled }"
        >
          <component
            :is="component"
            v-if="option.name !== firstMile.PRE_PAID"
            :return-method="option"
            @validated="handleValidated"
          />
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import {
  BaseText,
  RadioInput,
  BaseTooltip,
} from '@loophq/design-system';
import HappyReturns from './HappyReturns';
import ShopifyPos from './ShopifyPos';
import Veho from './Veho';
import LabelChoice from './LabelChoice';
import { formatCurrency } from '@/js/helpers/formatCurrency';
import firstMile from '@/js/constants/firstMile';

export default {
  components: {
    BaseText,
    RadioInput,
    BaseTooltip,
    HappyReturns,
    ShopifyPos,
    Veho,
    LabelChoice,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true,
    },
    isSingle: {
      type: Boolean,
      default: false,
    }
  },
  emits: [
    'change',
  ],
  data() {
    return {
      firstMile,
    };
  },
  computed: {
    customizationName() {
      const customizationName = this.option.name.split('-')
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        .join('');
      return customizationName;
    },
    displayCurrency() {
      return this.$store.getters['currencies/displayCurrency'];
    },
    exchangeRate() {
      return this.$store.getters['currencies/exchangeRate'];
    },
    hasRefundAmount() {
      return this.$store.state.totals.receipt.total > 0;
    },
    outcome() {
      if (!this.hasRefundAmount) {
        return 'exchange';
      }

      const creditType = this.$store.getters.order?.creditType;
      return creditType === 'gift' ? 'credit' : creditType;
    },
    handlingFee() {
      const handlingFees = this.option.fees?.fees;

      if (!handlingFees) {
        return null;
      }
      if (this.option.name === firstMile.LOOP_POS) {
        return null;
      }

      if (!handlingFees[this.outcome]?.amount || handlingFees[this.outcome].amount === 0) {
        return null;
      }

      const amount = handlingFees[this.outcome].amount * this.exchangeRate;

      return formatCurrency(amount, this.displayCurrency);
    },
    hasReturnCoverage() {
      return this.$store.getters.hasReturnCoverage;
    },
    component() {
      // Some return methods don't have an extra component to display.
      if (firstMile.RETURN_METHODS_THAT_REPLACE_BOX_AND_SHIP.includes(this.option.name)) {
        return firstMile.LABEL_CHOICE;
      }
      return this.option.name;
    },
    showLeafIcon() {
      return this.$settings.sustainabilityBannerEnabled && Object.keys(firstMile.SUSTAINABLE_RETURN_METHODS).includes(this.option.name);
    },
    sustainableDescription() {
      return this.$content.moduleSustainableBanner.sustainableDescription;
    }
  },
  created() {
    this.$trackEvent(`${this.option.name} return method shown`);
    if (this.selected === this.option.name) {
      this.$emit('change', this.option);
    }
  },
  methods: {
    updateFocus() {
      const container = this.$refs.container;
      container.classList.add('focused');
    },
    removeFocus() {
      const container = this.$refs.container;
      container.classList.remove('focused');
    },
    handleValidated(isValid) {
      this.$store.commit('firstMile/setReturnMethodIsValid', isValid);
    }
  },
};
</script>

<style lang="scss" scoped>
// duplicated value in children
$icon-size: 48px;

.return-method {
  &__radio-input {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: var(--spacing-400);

    &.selected {
      position: relative;
      border-bottom: 1px solid var(--grey-200);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 10%);
    }

    &.single {
      border: 0;
      box-shadow: none;
      background: white;
      padding: 0;
    }
  }

  &__radio-container {
    transition: 0.4s ease border-color, 0.4s ease box-shadow;

    &:deep(.radio-input__label) {
      flex: 1;

      &::before {
        display: none !important;
      }
    }

    &.single {
      &:deep(.radio-input__label) {
        cursor: default;
      }
    }

    &:deep(.radio-input__input.focus-visible+.radio-input__label) {
      &::after {
        display: none !important;
      }
    }

    &:deep(.radio-input__input) {
      position: relative !important;
      height: var(--spacing-400);
      min-width: var(--spacing-400);
      border: 2px solid var(--grey-600);
      margin: 0;

      &.single {
        display: none;
      }
    }

    &:deep(.base-tooltip__content) {
      max-width: none;
    }

    &.focused,
    &:focus-visible {
      outline: 4px solid var(--theme-outline-color);
      outline-offset: 1px;
    }

    &:hover {
      background: #f7f8f9;
    }

    &:active {
      border: 1px solid #c9cbcf;
    }

    &.selected {
      background: #f7f8f9;

      &.return-method__radio-input {
        box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
      }
    }
  }

  &:hover {
    border: 1px solid var(--grey-900);
    box-shadow: 0 0 0 1px var(--grey-900);
  }

  &__icon-img {
    height: $icon-size;
    width: $icon-size;
    margin-right: var(--spacing-300);
    background: white;
    border-radius: var(--spacing-300);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  &__radio-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
  }

  &__option {
    opacity: 1;
    transition: opacity var(--easing-quick);
    background: white;

    &--disabled {
      opacity: 0.3;
    }
  }

  &__content-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }

  &__fee {
    margin: 0 20px 0 var(--spacing-300);
    color: var(--grey-600);
    text-align: right;

    &.single {
      display: none;
    }
  }

  &__fee-free {
    margin: 0 8px 0 var(--spacing-300);
    color: var(--grey-600);
    text-align: right;

    &.single {
      display: none;
    }
  }

  &__content-row {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  &__heading {
    display: flex;

    &-text {
      color: var(--heading-color);
      font-weight: bold;
    }
  }

  &__leaf {
    height: 15px;
    margin-left: var(--spacing-200);
  }

  &__subheading {
    color: var(--heading-color);
  }

  &__subheading {
    margin-top: var(--spacing-100);
  }
}

@media screen and (max-width: $break-small) {
  .return-method__fee {
    display: none;
  }

  .return-method__fee-small {
    display: block;
  }
}
</style>
