import { Factory, Model } from 'miragejs';
import { returns } from '../mocks/returns';
import { locations } from '../mocks/locations';

const models = () => {
  return {
    location: Model,
  };
};

const factories = () => {
  return {
    location: Factory.extend({
      return_key: '',
      name: 'Loop HQ',
      distance: 2.34333,
      address: {
        company: null,
        address1: '797 N Wall St',
        address2: 'Suite 201',
        city: 'Columbus',
        state: 'Ohio',
        zip: '43215',
        country: 'United States',
        country_code: 'US',
        phone: '(555) 555-5555',
        latitude: '40.75816881729377',
        longitude: '-73.98479290229305'
      }
    })
  };
};

const seed = (context) => {
  locations.forEach((location) => {
    context.create('location', location);
  });
};

const routes = (context) => {
  context.get('/:shopId/return/:returnKey/returnlocations', function (schema, request) {
    const returnKey = request.params.returnKey;
    const returnData = returns.find((item) => item.return_key === returnKey);
    const locationIds = returnData.locations.map((location) => +location.id);
    const items = schema.locations.where((location) => {
      return locationIds.includes(+location.id);
    });
    if (items) {
      const serialized = this.serialize(items);
      return serialized.locations;
    }

    return new Response(
      404,
      { 'Content-Type': 'application/json' },
      {
        errors: [{
          title: 'return not found',
          description: 'return could not be found'
        }]
      }
    );
  });
};

export default {
  factories,
  routes,
  models,
  seed
};
