import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { logError } from '@/js/helpers/errors';
import Labels from '@/js/controllers/labels';
import QrCode from '@/js/controllers/qrCode';

export default function useLabels(item, loading) {
  const store = useStore();

  const labelError = ref();
  const labelAddressErrors = ref(null);
  const labelIsProcessing = ref(false);

  const qrCodeUrl = ref(null);
  const showQrCodeAlert = ref(false);

  const contentModuleUspsQrSelectorAlertHeading = computed(() => {
    return store.getters['content'].moduleUspsQrSelector.alertHeading;
  });

  const contentModuleUspsQrSelectorAlertDescription = computed(() => {
    return store.getters['content'].moduleUspsQrSelector.alertDescription;
  });

  const label = computed(() => {
    return {
      generate: item.value?.needsLabel && !item.value?.returnMethod,
      error: labelError.value,
      ...(item.value?.prepaidLabels?.length ? { prepaid: item.value?.prepaidLabels } : {}),
      ...(item.value?.returnCode ? { code: item.value?.returnCode } : {}),
      ...(item.value?.label ? { label: item.value?.label } : {}),
    };
  });

  const labelData = computed(() => {
    return store?.getters['firstMile/label'];
  });

  const labelsEnabled = computed(() => {
    return item.value?.policy?.labelsEnabled ?? false;
  });

  watch(labelData, async (newLabel) => {
    if (newLabel?.labelError) {
      labelError.value = newLabel.labelError;
      labelAddressErrors.value = newLabel.labelAddressErrors;
    } else {
      labelError.value = false;
      labelAddressErrors.value = null;
      item.value.label = newLabel;
      item.value.needsLabel = false;
    }
    labelIsProcessing.value = false;
  });

  const setLabel = (label) => {
    item.value.label = label;
  };

  const generateLabel = async () => {
    labelIsProcessing.value = true;
    item.value.needsLabel = true;
    Labels.generate({ id: item.value.id })
      .then(({ status, data }) => {
        // A 202 status code indicates that the label is generated as a background job, which triggers label refetch.
        if (status === 202) {
          store.dispatch(
            'firstMile/refetchShippingLabel',
            { returnId: item.value.id },
          );
        } else {
          store.commit('firstMile/setShippingLabel', {
            labelError: false,
            labelAddressErrors: null,
            ...data,
          });
        }
      })
      .catch((err) => {
        logError(err);
        store.commit('firstMile/setShippingLabel', {
          labelError: true,
          labelAddressErrors: err.response?.data?.addressErrors ?? null
        });
      });
  };

  const createLabelQrCode = async () => {
    const res = await QrCode.getLabelQrCode(item.value.shopId, item.value.id, label.value.label.id);
    // Show loading page and hide alert banner
    loading.value = 'page';
    showQrCodeAlert.value = false;

    try {
      if (res.data.qr_code === null) {
        throw new Error('QR code url is null');
      }
    } catch (error) {
      // Show alert banner and log error
      showQrCodeAlert.value = true;
      logError(error);
    }
    qrCodeUrl.value = res.data.qr_code;
    loading.value = null;
  };

  const toggleQrAlert = (toggle) => {
    showQrCodeAlert.value = toggle;
  };

  return {
    contentModuleUspsQrSelectorAlertDescription,
    contentModuleUspsQrSelectorAlertHeading,
    label,
    labelAddressErrors,
    labelError,
    labelsEnabled,
    labelIsProcessing,
    qrCodeUrl,
    showQrCodeAlert,
    createLabelQrCode,
    generateLabel,
    setLabel,
    toggleQrAlert,
  };
}
