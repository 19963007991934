import {
  deliveredStatuses,
  expectedShippingStatuses,
  labelStatuses,
  outForDeliveryStatuses,
  shippedStatuses,
  shippingWarningStatuses
} from '@/js/constants/labels';
import { trackingStatusHealth } from '@/js/constants/tracking';

export default {
  namespaced: true,
  state: {
    tracking: {
      orderName: null,
      shippingAddressZip: null,
      tracker: null,
      fulfillment: {
        trackingUrl: null,
        isReturnable: 'false',
        lineItems: []
      },
      trackerHashData: {
        shopProviderDomain: null,
      },
      recommendationsEnabled: false,
      trackingHash: '',
      recommendationsList: null,
      providerCustomerId: null,
      session: null,
      orderProviderId: null
    },
    loading: false,
    productRecommendationsCount: null,
  },
  mutations: {
    setTrackingData(state, payload) {
      state.tracking = payload;
    },
    setRecommendationsCount(state, limit) {
      state.productRecommendationsCount = limit;
    }
  },
  getters: {
    tracking: state => state.tracking,
    productIds: state => state.tracking.fulfillment?.lineItems?.map(item => item?.productId),
    recommendationsProductsCount: state => state.productRecommendationsCount,
    trackingStatusHistory(state) {
      return state.tracking?.tracker?.tracking_details?.map(item => item.status);
    },
    latestShippingStatus(state, getters) {
      switch (true) {
        case getters.trackingStatusHistory?.some(status => deliveredStatuses.includes(status)):
          return {
            step: 4,
            status: labelStatuses.DELIVERED
          };
        case getters.trackingStatusHistory?.some(status => outForDeliveryStatuses.includes(status)):
          return {
            step: 3,
            status: labelStatuses.OUT_FOR_DELIVERY
          };
        case getters.trackingStatusHistory?.some(status => shippedStatuses.includes(status)):
          return {
            step: 2,
            status: labelStatuses.IN_TRANSIT
          };
        default:
          return {
            step: 1,
            status: labelStatuses.PRE_TRANSIT
          };
      }
    },
    currentShippingStatusHealth(state) {
      switch (true) {
        case expectedShippingStatuses.includes(state.tracking.tracker?.status):
          return trackingStatusHealth.OK;
        case shippingWarningStatuses.includes(state.tracking.tracker?.status):
          return trackingStatusHealth.WARNING;
        default:
          return trackingStatusHealth.ERROR;
      }
    }
  }
};
