const getImageBackup = (type) => {
  if (type) {
    return `./img/icons/${type}`;
  }

  return '/img/icons/no-image.svg';
};

export { getImageBackup };

export default function() {
  function parseEmail(value) {
    var expression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var output = expression.exec(value);
    if (output) {
      return true;
    }
    return false;
  }

  function sluggify(text) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w-]+/g, '')       // Remove all non-word chars
      .replace(/--+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  }

  //Hhange normal or shorthand hex to RGB format
  function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  function diffArray(arr1, arr2) {
    return arr1.concat(arr2).filter(function (val) {
      if (!(arr1.includes(val) && arr2.includes(val)))
        return val;
    });
  }

  function imageBackup(e, type) {
    if (type) {
      e.target.src = './img/icons/' + type;
    } else {
      e.target.src = '/img/icons/no-image.svg';
    }
  }

  function miniImage(link, size) {
    var spot,
      newLink;
    if (link == undefined || link == '') {
      return link;
    }

    if (!link.includes('cdn.shopify.com')) {
      return link;
    }

    if (size === undefined) {
      size = 300;
    }
    spot = link.split('.');

    //construct new link
    newLink = `${spot.slice(0, -1).join('.')}_${size}x.${spot[spot.length - 1]}`;

    return newLink;
  }

  function loadImage(image) {
    if (!image?.src) {
      return;
    }

    return new Promise((resolve, reject) => {
      const loader = document.createElement('img');

      loader.addEventListener('load', function () {
        this.removeEventListener('load', this);
        resolve(this);
      });

      loader.addEventListener('error', function () {
        this.removeEventListener('load', this);
        reject(this);
      });

      if (image.srcset) {
        loader.setAttribute('srcset', image.srcset);
      }

      if (image.sizes) {
        loader.setAttribute('sizes', image.sizes);
      }

      loader.setAttribute('src', image.src);
    });
  }
  return {
    parseEmail,
    sluggify,
    hexToRgb,
    diffArray,
    imageBackup,
    miniImage,
    loadImage
  };
}
