<template>
  <div
    key="product-description"
    class="select-variant__description"
  >
    <base-text
      tag="h2"
      type="heading"
      class="select-variant__description-heading"
    >
      <render-content :data="{ title: product.title }">
        {{ content.productDescriptionHeader }}
      </render-content>
    </base-text>
    <base-text
      type="body"
      v-html="product.description"
    />
  </div>
</template>

<script>
import {
  BaseText,
} from '@loophq/design-system';

export default {
  components: {
    BaseText
  },
  props: {
    product: {
      type: Object,
      required: true
    },
  },
  computed: {
    content() {
      return this.$content.moduleSelectVariant;
    }
  }
};
</script>

<style lang="scss" scoped>
.select-variant__description {
  background: var(--grey-100);
  padding: var(--spacing-400);

  &-heading {
    margin-bottom: var(--spacing-200);
  }
}
</style>
