import { trackEvent } from '@/js/helpers/trackEvent';

export default {
  computed: {
    '$content'() {
      return this.$store.getters['content'];
    },
    '$theme'() {
      return this.$store.getters['theme'];
    },
    '$shop'() {
      return this.$store.getters['shop'];
    },
    '$settings'() {
      return this.$store.getters['settings'];
    },
    '$screen'() {
      const screen = this.$store.state.screen;
      return {
        ...screen,
        isMobile: screen.width <= 680
      };
    }
  },
  methods: {
    '$toast'(message) {
      this.$store.dispatch('toast', { message, type: 'success' });
    },
    '$warn'(message) {
      this.$store.dispatch('toast', { message, type: 'warning' });
    },
    '$error'(message) {
      this.$store.dispatch('toast', { message, type: 'error' });
    },
    '$trackEvent'(message, fields = {}) {
      trackEvent(message, fields);
    },
  }
};
