<template>
  <base-text class="tracking-eyebrow">
    {{ label }} {{ normalizedReturn?.orderName }}
  </base-text>
</template>

<script setup>
import { inject } from 'vue';
import { useStore } from 'vuex';
import { BaseText } from '@loophq/design-system';

const store = useStore();

const label = store.getters['content'].moduleReturnTracking.eyebrow;

const normalizedReturn = inject('normalizedReturn');
</script>

<style lang="scss" scoped>
$block: '.tracking-eyebrow';

#{$block} {
  width: 100%;
  color: var(--color-grey-500);
  margin-bottom: 0;
  font-size: 14px;
}
</style>
