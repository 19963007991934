<template>
  <div class="tracking-refund-outcome">
    <base-text class="tracking-refund-outcome__eyebrow">
      {{ $content?.moduleReturnTracking.eyebrow }}{{ normalizedReturn.orderName }}
    </base-text>
    <error
      v-if="normalizedReturn.tracker?.terminalError"
    />
    <base-text
      class="tracking-refund-outcome__heading"
      type="display-medium"
    >
      {{ message.heading }}
    </base-text>
    <base-text
      class="tracking-refund-outcome__copy"
    >
      {{ message.copy }}
    </base-text>

    <recent-events
      v-if="showEventTimeline"
    />
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import { BaseText } from '@loophq/design-system';
import Error from '@/views/StatusPage/Tracking/Error';
import RecentEvents from '@/views/StatusPage/Tracking/RecentEvents';

const store = useStore();

const normalizedReturn = inject('normalizedReturn');

const message = computed(() => {
  const gcEvent = normalizedReturn.value.policy.giftCardEvent;
  const refundEvent = normalizedReturn.value.policy.refundEvent;

  if (refundEvent === 'disabled' && !normalizedReturn.value.hasGiftCard) {
    return {
      heading: store.getters['content']?.moduleReturnTracking.refundManualReviewHeading,
      copy: store.getters['content']?.moduleReturnTracking.refundManualReviewCopy
    };
  } else if (gcEvent === 'disabled' && normalizedReturn.value.hasGiftCard) {
    return {
      heading: store.getters['content']?.moduleReturnTracking.storeCreditManualReviewHeading,
      copy: store.getters['content']?.moduleReturnTracking.storeCreditManualReviewCopy
    };
  } else if (gcEvent !== 'disabled' && normalizedReturn.value.hasGiftCard) {
    return {
      heading: store.getters['content']?.moduleReturnTracking.storeCreditDeliveredHeading,
      copy: store.getters['content']?.moduleReturnTracking.storeCreditDeliveredCopy
    };
  } else {
    return {
      heading: store.getters['content']?.moduleReturnTracking.refundDeliveredHeading,
      copy: store.getters['content']?.moduleReturnTracking.refundDeliveredCopy
    };
  }
});

const showEventTimeline = computed(() => {
  const gcEvent = normalizedReturn.value?.policy.giftCardEvent;
  const refundEvent = normalizedReturn.value?.policy.refundEvent;

  if (gcEvent === 'delivered' && normalizedReturn.value?.hasGiftCard) {
    return true;
  }

  return refundEvent === 'delivered' && !normalizedReturn.value?.hasGiftCard;
});
</script>

<style lang="scss" scoped>
$block: '.tracking-refund-outcome';

#{$block} {
  background: var(--grey-100);
  padding: var(--spacing-400) var(--spacing-500);

  .base-text {
    color: black;
  }

  &__eyebrow.base-text {
    color: var(--color-grey-500);
    margin-bottom: 8px;
    font-size: 14px;
  }

  &__heading {
    margin-bottom: var(--spacing-200);
  }
}
</style>
