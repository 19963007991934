<template>
  <alert-banner
    class="status-page__shop-later-banner"
    :description="$content.pageReturnStatus.shopLaterExpiredDescription"
    :heading="$content.pageReturnStatus.shopLaterExpiredHeading"
    inline
    :is-closable="false"
    type="info"
  />
</template>

<script>
import { AlertBanner } from '@loophq/design-system';

export default {
  name: 'ShopLaterExpiredInline',
  components: { AlertBanner },
  mounted() {
    if (this.$screen.width <= 960) {
      this.$nextTick(() => this.$el.scrollIntoView({ behavior: 'smooth' }));
    }
  }
};
</script>
