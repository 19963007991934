import { getCountries as get } from '@loophq/country-state-list';

/**
 * @param {string[]} countryCodes
 */
export const getCountries = (countryCodes) => {
  const countries = countryCodes
    ? get(countryCodes)
    : get(null);

  return countries
    // Scotland doesn't have an ISO country code associated with it, which causes the map function to fail.
    // See: @loophq/country-state-list
    .filter(country => country && country.code)
    .map(country => {
      // We're ignoring most states for now
      if (['us', 'ca', 'au'].includes(country.code.toLowerCase())) {
        return country;
      }

      return {
        ...country,
        states: null,
      };
    });
};
