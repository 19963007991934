<template>
  <div
    class="line-item-selection"
  >
    <cobranded-header
      :order-number="orderNumber"
      :center="false"
    />
    <div class="line-item-selection__wrapper">
      <div class="line-item-selection__heading-wrapper">
        <base-text
          tag="h2"
          type="display-large"
          class="line-item-selection__heading"
        >
          <render-content :data="{ name: recipientName }">
            {{ $content.moduleGiftReceiptLookup.lineItemHeading }}
          </render-content>
        </base-text>
        <base-text
          tag="p"
          type="display-small"
          class="line-item-selection__copy"
        >
          {{ $content.moduleGiftReceiptLookup.lineItemCopy }}
        </base-text>
      </div>
      <div
        class="line-item-selection__line-items-wrapper"
        data-testid="line-items-wrapper"
      >
        <gift-product-card-wrapper
          v-for="(lineItem, i) in lineItems"
          :key="`${i}-${lineItem.id}`"
          :line-item-id="lineItem.id"
          :product="lineItem"
          @reset-error="resetError"
        />
      </div>
      <div
        class="line-item-selection__controls"
      >
        <base-button
          class="line-item-selection__back-button"
          type="secondary"
          @click="handleBackClick"
        >
          {{ $content.moduleGiftReceiptLookup.lineItemBackButtonLabel }}
        </base-button>
        <base-button
          class="line-item-selection__next-button"
          :activated="loading"
          @click="handleCreateClick"
        >
          {{ $content.moduleGiftReceiptLookup.lineItemCreateButtonLabel }}
        </base-button>
      </div>
      <div
        v-if="error"
        class="line-item-selection__errors"
      >
        <base-text>
          {{ error }}
        </base-text>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseText,
} from '@loophq/design-system';
import Order from '@/js/controllers/order';
import CobrandedHeader from './CobrandedHeader';
import GiftProductCardWrapper from './GiftProductCardWrapper.vue';

export default {
  name: 'LineItemSelection',
  components: {
    BaseButton,
    BaseText,
    CobrandedHeader,
    GiftProductCardWrapper,
  },
  data() {
    return {
      error: null,
      loading: false,
    };
  },
  computed: {
    orderNumber: {
      get() {
        return this.$store.state.giftReceipt.orderNumber;
      },
      set(orderNum) {
        this.$store.commit('giftReceipt/setOrderNumber', orderNum);
      }
    },
    recipientName: {
      get() {
        return this.$store.state.giftReceipt.recipientName;
      },
      set(recipientName) {
        this.$store.commit('giftReceipt/setRecipientName', recipientName);
      }
    },
    selectedItems: {
      get() {
        return this.$store.state.giftReceipt.selectedItems;
      },
      set(selectedItems) {
        this.$store.commit('giftReceipt/setSelectedItems', selectedItems);
      },
    },
    order() {
      return this.$store.state.giftReceipt.order;
    },
    lineItems() {
      return this.$store.getters['giftReceipt/lineItems'];
    },
    lineItemIds() {
      return this.order.line_item_ids;
    }
  },
  methods: {
    resetError() {
      this.error = null;
    },
    handleBackClick() {
      this.$store.commit('giftReceipt/setSelectedItems', []);
      this.$store.commit('giftReceipt/setStep', 'NameInput');
    },
    async handleCreateClick() {
      this.loading = true;

      if (this.selectedItems.length === 0) {
        this.error = this.$content.moduleGiftReceiptLookup.lineItemErrorText;
        this.loading = false;
        return;
      }

      const response = await Order.generateGiftQRCode({
        name: this.orderNumber,
        zip: this.$store.state.giftReceipt.zip,
        type: 'png',
        size: '500',
        line_items_id: this.$store.state.giftReceipt.selectedItems,
        recipient_name: this.recipientName,
      });

      if (!response?.data) {
        this.error = 'There was an error creating your gift receipt, please try again later.';
        this.loading = false;
        return;
      }

      this.$store.commit('giftReceipt/addGiftReceipt', response.data);
      this.$store.commit('giftReceipt/setActiveReceipt', response.data);
      this.$store.commit('giftReceipt/setSelectedItems', []);
      this.$store.commit('giftReceipt/setRecipientName', '');

      this.loading = false;
      this.$store.commit('giftReceipt/setStep', 'SharePage');
    },
  },
};
</script>

<style lang="scss" scoped>
$block: '.line-item-selection';

#{$block} {
  height: 100%;
  overflow: hidden;

  &__wrapper {
    margin: auto;
    margin-top: var(--spacing-400);
    padding: var(--spacing-200) 0;

    @media screen and (min-width: $break-medium) and (max-width: $break-large) {
      min-width: 60%;
    }
  }

  &__heading-wrapper {
    margin-bottom: var(--spacing-400);
  }

  &__heading {
    margin-bottom: var(--spacing-300);
  }

  &__line-items-wrapper {
    --gap: var(--spacing-300);

    display: flex;
    overflow-y: scroll;
    flex-wrap: wrap;
    top: 0;
    width: 100%;
    justify-content: flex-start;
    height: 225px;

    /**
     * For an inner overflow-y scroll on the line item selection grid
     * we need to explicitly set the container height. Different viewport
     * heights will cause the modal to overflow the viewport, necessitating
     * a number of min-height media queries
     *
     * We don't currently have any vertical breakpoints in the app, so until
     * we have design set those, going to use px values here
     *
     * TODO: coordinate with design to implement vertical breakpoint levels
     */
    @media (height >= 725px) {
      height: 350px;
    }

    @media (height >= 800px) {
      height: 475px;
    }

    @media (min-width: $break-small) {
      width: calc(100% + var(--gap));
      margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    }

    :deep(button.product-card) {
      width: 100%;
      height: fit-content;
    }

    :deep(button.base-card.selected) {
      border-width: 2px;
      box-shadow: none;
    }

    :deep(.product-card__title) {
      margin-top: 2px;
    }

    :deep(.product-card::before) {
      display: none;
    }
  }

  &__controls {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: var(--spacing-400);
  }

  &__next-button,
  &__back-button {
    width: 100%;
    text-transform: uppercase;
  }

  &__next-button {
    margin-left: var(--spacing-200);
  }

  &__back-button {
    margin-right: var(--spacing-200);
  }

  &__errors {
    text-align: center;
    margin: auto;
    margin-top: var(--spacing-400);
  }
}
</style>
