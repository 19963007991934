<script>
import { h } from 'vue';

/**
 * Returns the text content of the slot
 * @param {string} children - The vnodes of the default slot
 * @returns {string}
 */
const getSlotChildrenText = children =>
  children
    .map(node => {
      if (!node.children || typeof node.children === 'string')
        return node.children || '';
      else if (Array.isArray(node.children))
        return getSlotChildrenText(node.children);
      else if (node.children.default)
        return getSlotChildrenText(node.children.default());
    })
    .join('');

/**
 * Checks if the copy is a hyperlink
 * @param {string} customizationData - The customization data passed in from RenderContent
 * @returns {boolean}
 */
const isHyperLink = customizationData => {
  return (
    customizationData &&
    typeof customizationData === 'object' &&
    customizationData?.type === 'link'
  );
};

/**
 * Returns a hyperlink element using the customization data
 * @param {string} customizationData - The customization data passed in from RenderContent
 * @returns {VNode}
 */
const generateHyperLink = customizationData => {
  const { attrs, events, text } = customizationData;

  return h(
    'a',
    {
      ...(attrs ?? {}),
      ...(events ?? {})
    },
    [text]
  );
};

/**
 * Replaces variables ex. {{ variable }} within a customization string with data
 * @param {array} content - A customization split on the variables curly braces
 * @param {object} data - An object containing shop data provided by RenderContent
 * @returns {array}
 */
const replaceVariablesWithCustomizationData = (content, data) => {
  const variableBrackets = '{{';
  return content.map(customization => {
    if (customization.includes(variableBrackets)) {
      const variable = customization.replace(/({{|}})/g, '').trim();
      const variableValue = data && data[variable];
      return isHyperLink(variableValue)
        ? generateHyperLink(variableValue)
        : variableValue;
    }
    return customization;
  });
};

/**
 * Splits variables ex. {{ variable }} out of a customization
 * @param {string} copy - Customization
 * @returns {array}
 */
const splitOnVariables = copy => {
  return copy.split(/({{(?:\w|-|\s)+}})/g);
};

/**
 * Checks if a variable is present within the string
 * @param {string} copy - Customization
 * @returns {boolean}
 */
const hasVariables = copy => {
  return splitOnVariables(copy).length > 1;
};

/**
 * Checks if a customization has new lines
 * @param {string} copy - Customization
 * @returns {boolean}
 */
const isMultiLine = copy => {
  return copy.includes('\n');
};

/**
 * Replaces a variable with customization data and returns a single or list of VNodes
 * @param {string} copy - Customization
 * @returns {(VNode||VNode[])}
 */
const renderCustomization = (copy, data) => {
  const splitText = splitOnVariables(copy);
  const content = replaceVariablesWithCustomizationData(
    splitText,
    data
  );
  return content.length > 1 ? h('span', content) : content;
};

export default {
  name: 'CopyRenderer',
  inheritAttrs: false,
  props: {
    data: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  render(props) {
    const copy = getSlotChildrenText(props.$slots.default()).trim();
    const data = props.$attrs.renderData;
    if (!hasVariables(copy)) {
      return copy;
    }

    if (isMultiLine(copy)) {
      return copy.split('\n').map(item => renderCustomization(item, data));
    }

    return renderCustomization(copy, data);
  }
};
</script>
