import store from '@/store';

// Certain browsers have issues with any checks for localstorage when configured a certain way
// We're doing this check here so we can swallow the error and just react accordingly
const getLocalStorageSupport = () => {
  try {
    const testKey = 'loop.storageTest';
    localStorage.setItem(testKey, 'foo');
    localStorage.removeItem(testKey);
    return 'localStorage' in window && window.localStorage !== null;
  } catch (e) {
    return false;
  }
};

const hasLocalStorage = getLocalStorageSupport();

export const toAlphaNumeric = (str) => {
  return str.replace(/[^\w]/g, '');
};

export default function() {
  
  function getStore(storageKey, prefix) {
    const namespace = prefix !== undefined ? prefix : toAlphaNumeric(store.state.shopContents?.name ?? '');
    const key = `${namespace}${storageKey}`;
    if (hasLocalStorage && localStorage.getItem(key) !== null) {
      return JSON.parse(localStorage.getItem(key || null));
    }
  }
  function setStore(storageKey, storeData, prefix) {
    if (hasLocalStorage && storeData !== undefined) {
      const namespace = prefix !== undefined ? prefix : toAlphaNumeric(store.state.shopContents?.name ?? '');
      localStorage.setItem(`${namespace}${storageKey}`, JSON.stringify(storeData));
    }
  }
  function removeStore(storageKey) {
    if (hasLocalStorage) {
      localStorage.removeItem(toAlphaNumeric(store.state.shopContents?.name  ?? '') + storageKey);
    }
  }
  return {
    getStore,
    setStore,
    removeStore
  };

}
