<template>
  <transition
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <slot />
  </transition>
</template>

<script setup>
const afterEnter = (element) => {
  element.style.height = 'auto';
};

const enter = (element) => {
  const { width, height } = getComputedStyle(element);

  element.style.width = width;
  element.style.position = 'absolute';
  element.style.visibility = 'hidden';
  element.style.height = 'auto';
  element.style.width = null;
  element.style.position = null;
  element.style.visibility = null;
  element.style.height = 0;

  requestAnimationFrame(() => {
    element.style.height = height;
  });

};

const leave = (element) => {
  const height = getComputedStyle(element).height;

  element.style.height = height;

  requestAnimationFrame(() => {
    element.style.height = 0;
  });

};

</script>

<style lang="scss">
.expand-enter-active,
.expand-leave-active {
  transition: height 0.24s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>
