<template>
  <div class="return-selector">
    <header class="selector-header">
      <base-text
        class="selector-heading"
        tag="h2"
      >
        <render-content>
          {{ $content.moduleReturnSelector.heading }}
        </render-content>
      </base-text>
      <base-button
        class="more-button"
        type="link"
        @click.prevent="toggleMore"
      >
        <render-content>
          {{ $content.moduleReturnSelector.explanationButton }}
        </render-content>
      </base-button>
    </header>
    <base-card class="return-card">
      <radio-input
        v-model="selected"
        class="return-input"
        value="instant"
        data-value="instant"
      >
        <span class="return-label">
          <base-text class="return-heading">
            <render-content>
              {{ $content.moduleReturnSelector.quickHeading }}
            </render-content>
          </base-text>
          <base-text class="return-body">
            <render-content :data="{ instantReturnWindow }">
              {{ $content.moduleReturnSelector.quickCopy }}
            </render-content>
          </base-text>
        </span>
      </radio-input>
      <radio-input
        v-model="selected"
        class="return-input"
        value="regular"
        data-value="regular"
      >
        <span class="return-label">
          <base-text class="return-heading">
            <render-content>
              {{ $content.moduleReturnSelector.regularHeading }}
            </render-content>
          </base-text>
          <base-text class="return-body">
            <render-content :data="{ instantReturnWindow }">
              {{ $content.moduleReturnSelector.regularCopy }}
            </render-content>
          </base-text>
        </span>
      </radio-input>
    </base-card>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseCard,
  BaseText,
  RadioInput
} from '@loophq/design-system';

export default {
  name: 'ReturnSelector',
  components: {
    RadioInput,
    BaseCard,
    BaseText,
    BaseButton,
  },
  props: {
    initialValue: {
      type: String,
      required: false
    },
    order: {
      type: Object,
      required: true
    }
  },
  emits: [
    'input',
    'show-instant-return-explanation',

  ],
  data() {
    return {
      selected: '',
    };
  },
  computed: {
    instantReturnWindow() {
      return this.order.return_policy.instant_return_window;
    },
  },
  watch: {
    selected(val) {
      this.$trackEvent(`${val} return clicked`);

      this.$emit('input', {
        returnType: val
      });
    }
  },
  created() {
    if (this.initialValue) {
      this.selected = this.initialValue;
    }

    this.$trackEvent('user shown return options');
  },
  methods: {
    toggleMore() {
      this.$emit('show-instant-return-explanation');

      this.$trackEvent('more ir information button clicked');
    }
  }
};
</script>

<style lang="scss" scoped>
.return-selector {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-300);
}

.selector-header {
  display: flex;
}

.selector-heading {
  font-weight: 700;
}

.more-button {
  margin-left: var(--spacing-200);
}

.return-input {
  & + & {
    margin-top: var(--spacing-300);
    padding-top: var(--spacing-300);
    border-top: 1px solid var(--grey-200);
  }
}

.return-card {
  border-radius: var(--corners);
  box-shadow: none;
  padding: var(--spacing-300) var(--spacing-300);
}

.return-label {
  display: flex;
  flex-direction: column;
}

.return-heading {
  font-weight: 700;
}

.return-body {
  margin-top: var(--spacing-100);
}

.explanation-copy {
  margin: -1rem 0;
}
</style>
