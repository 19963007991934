<template>
  <transition
    name="module-out"
    appear
  >
    <div class="skeleton-slide">
      <skeleton-text
        :lines="1"
        type="heading"
      />
      <skeleton-text
        :lines="1"
        width="20%"
      />
      <div
        class="skeleton-btn-container"
      >
        <div
          v-for="reason in reasons"
          :key="reason.id"
          class="skeleton-btn"
        >
          <skeleton-text
            :lines="1"
            type="reason"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SkeletonText from '@/components/feedback/SkeletonText';

export default {
  components: {
    SkeletonText
  },
  props: {
    reasons: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.skeleton-slide {
  margin: var(--spacing-200) var(--spacing-300);

  .skeleton-btn {
    border: 1px solid var(--grey-200);
    padding: var(--spacing-300) calc(var(--spacing-200) + var(--corners));
    border-radius: var(--corners);
    margin-top: var(--spacing-300);
  }
}
</style>
