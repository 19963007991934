<template>
  <div
    class="recent-updates"
  >
    <div class="recent-updates__messaging">
      <base-text class="recent-updates__tracking-eyebrow">
        {{ orderEyebrow }} {{ orderName }}
      </base-text>

      <base-text
        class="recent-updates__messaging-heading"
        type="display-medium"
        data-testid="recent-updates_heading"
      >
        {{ thanksForYourOrder }}
      </base-text>
      <base-text
        class="recent-updates__message"
      >
        {{ yourOrderHasBeenShipped }}
      </base-text>
      <div class="recent-updates__cta">
        <base-button
          v-if="portalReturnUrl"
          v-track="'start a return clicked'"
          class="recent-updates__button-link"
          data-testid="recent-updates_btn"
          icon="external-link"
          size="small"
          type="secondary"
          target="_blank"
          :to="portalReturnUrl"
          @click="customerStartedAReturn"
        >
          {{ startAReturn }}
        </base-button>
      </div>
    </div>

    <div class="recent-updates__updates">
      <base-text
        class="recent-updates__updates-heading"
        type="display-medium"
        data-testid="recent_updates_updates_heading"
      >
        {{ mostRecentUpdates }}
      </base-text>
      <div class="recent-updates__tracking-detail-container">
        <div
          v-for="(trackingDetail, index ) in formattedTrackingDetails"
          :key="index"
          :class="errorOrWarningStatus(index)"
          class="recent-updates__tracking-detail"
          data-testid="recent_updates_tracking_detail"
        >
          <div class="recent-updates__timestamp-icon-container">
            <div
              v-if="shippingStatusHealth !== 'ok' && index === 0"
              class="recent-updates__error-warning-icon"
            >
              <base-icon
                class="recent-updates__error-icon"
                :name="shippingStatusHealth === 'warning' ? 'warning' : 'close-outline'"
                size="small"
                data-testid="error_icon"
              />
            </div>
          </div>
          <div class="recent-updates__status-text">
            <base-text
              class="recent-updates__tracking-date-time"
              type="subheading"
              :class="shippingStatusHealth"
              data-testid="tracking_date_time"
            >
              {{ (shippingStatusHealth === 'error' && index === 0) ? topLevelStatusDetail : trackingDetail.dateTime }}
            </base-text>
            <base-text
              class="recent-updates__tracking_message"
              :class="shippingStatusHealth"
              type="body"
              data-testid="tracking_message"
            >
              {{ trackingDetail.message }}
            </base-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { BaseText, BaseButton , BaseIcon } from '@loophq/design-system';
import { toSentenceCase } from '@/js/helpers/formatTextCase';
import { formatDateToLocalWithTime } from '@/js/helpers/formatDate';
import { trackingStatusHealth } from '@/js/constants/tracking';

const store = useStore();

const props = defineProps({
  orderName: {
    type: String,
    required: true
  },
  trackingDetails: {
    type: Array,
    required: true,
    default: () => []
  },
  shippingStatusHealth: {
    type: String,
    required: true
  },
  topLevelStatusDetail: {
    type: String,
    required: false
  },
  trackingUrl: {
    type: String,
    required: false
  },
  portalReturnUrl: {
    type: String,
    required: false,
  }
});

const emit = defineEmits(['customerStartedReturn']);

const errorOrWarningStatus = ((index) => {
  if (props.shippingStatusHealth === trackingStatusHealth.ERROR && index === 0) {
    return  'error-background';
  }

  if (props.shippingStatusHealth === trackingStatusHealth.WARNING && index === 0) {
    return  'warning-background';
  }
  return '';
});

const orderEyebrow = store.getters['content']?.moduleForwardTracking.eyebrow;
const thanksForYourOrder = store.getters['content']?.moduleForwardTracking.recentUpdatesThanksForYourOrder;
const yourOrderHasBeenShipped = store.getters['content']?.moduleForwardTracking.recentUpdatesYourOrderHasBeenShipped;
const startAReturn = store.getters['content']?.moduleForwardTracking.recentUpdatesStartAReturn;
const mostRecentUpdates = store.getters['content']?.moduleForwardTracking.recentUpdatesMostRecentUpdates;

const formattedTrackingDetails = computed(() => {
  return props.trackingDetails.map(({ message, datetime }) => ({
    dateTime: formatDateToLocalWithTime(datetime, 'numeric'),
    message: toSentenceCase(message)
  }));
});

const customerStartedAReturn = () => {
  emit('customerStartedReturn');
};

</script>

<style lang="scss" scoped>
$block: '.recent-updates';

#{$block} {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;

  &__messaging {
    padding-right: 1.25rem;
    max-width: 50%;
  }

  &__tracking-eyebrow {
    margin-bottom: var(--spacing-200);
  }

  &__messaging-heading {
    margin-bottom: var(--spacing-300);
    font-family: var(--heading-font);
  }

  &__updates-heading {
    font-family: var(--heading-font);
  }

  &__cta {
    display: flex;
    justify-content: flex-end;
  }

  &__button-link {
    flex-direction: row-reverse;
    background-color: var(--button-background-color) !important;
    margin-right: auto;

    :deep(.base-button__text) {
      margin-left: 0;
      margin-right: var(--spacing-200);
      color: var(--button-text-color);
    }

    :deep(.base-button__icon) {
      color: var(--button-text-color);
    }
  }

  &__updates {
    padding-left: 1.25rem;
    flex-grow: 1;
    max-width: 50%;
  }

  &__message {
    margin-bottom: var(--spacing-400);
  }

  &__tracking-detail-container {
    max-height: 360px;
    overflow-y: auto;
  }

  &__tracking-detail {
    border-top: 1px solid var(--grey-200);
    padding: var(--spacing-300) 0;
    margin: var(--spacing-400) 0;
    height: auto;
    display: flex;
    column-gap: var(--spacing-100);
  }

  &__tracking-date-time {
    font-size: 1rem;
    margin-bottom: var(--spacing-200);
    padding-right: var(--spacing-100);
    text-transform: none;
    font-family: var(--heading-font);

    &.warning,
    &.error {
      margin-top: var(--spacing-100);
    }
  }

  &__tracking-message {
    font-size: 1rem;
  }

  .error-background {
    background-color: #fbeae8;
    padding-left: 1rem;
    border-radius: 0.5rem;
  }

  .warning-background {
    background-color: #f1ebd0;
    padding-left: 1rem;
    border-radius: 0.5rem;
  }

  &__error-icon {
    padding-right: 0.3rem;
  }
}

@media screen and (width <= $break-small) {
  #{$block} {
    &__messaging {
      max-width: 100%;
      margin-bottom: var(--spacing-400);
    }

    &__updates {
      max-width: 100%;
      padding-left: 0;
    }
  }
}
</style>
