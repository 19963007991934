<template>
  <order-lookup
    class="gift-lookup"
    content-module="moduleGiftReturns"
    :criteria="criteria"
    @success="handleSuccess"
    @handle-missing-address="handleMissingAddress"
  >
    <template #subheading>
      <base-text class="gift-subheading">
        <render-content>
          {{ $content.moduleGiftReturns.subheading }}
        </render-content>
      </base-text>
    </template>
    <template #footer>
      <div
        v-if="$settings.giftFlowEnabled"
        class="footer-wrap"
      >
        <base-button
          class="no-info"
          type="link"
          @click.prevent="openForm"
        >
          <render-content>
            {{ $content.moduleMissingOrder.cta }}
          </render-content>
        </base-button>
        <base-text
          class="footer-heading"
          tag="h3"
          type="display-small"
        >
          <render-content>
            {{ $content.moduleGiftReturns.footerCopy }}
          </render-content>
        </base-text>
        <base-button
          class="footer-button"
          type="secondary"
          size="small"
          :to="{ name: 'lookup' }"
        >
          <render-content>
            {{ $content.moduleGiftReturns.footerButton }}
          </render-content>
        </base-button>
      </div>
    </template>
  </order-lookup>
</template>

<script>
import {
  BaseButton,
  BaseText,
} from '@loophq/design-system';
import OrderLookup from '@/modules/OrderLookup';

export default {
  name: 'GiftLookup',
  components: {
    BaseButton,
    BaseText,
    OrderLookup,
  },
  emits: ['success', 'open', 'handleMissingAddress'],
  data: function() {
    return {
      criteria: null
    };
  },
  created() {
    if (this.$route.params.zip && this.$route.params.order) {
      this.criteria = this.$route.params;
    }
  },
  methods: {
    handleSuccess(data) {
      this.$emit('success', data);
    },
    openForm() {
      this.$emit('open');
      this.$trackEvent('Gift order info form opened');
    },
    handleMissingAddress(payload) {
      this.$emit('handleMissingAddress', payload);
    }
  }
};
</script>

<style lang="scss" scoped>
.gift-subheading {
  color: var(--grey-700);
  text-align: center;
  padding-top: var(--spacing-200);
}

.footer-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--spacing-400);
}

.no-info {
  margin-bottom: var(--spacing-300);
}

.footer-heading {
  font-size: 1.0625rem;
  margin-bottom: 1rem;
}
</style>
