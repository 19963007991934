/**
 * @module imageSlugs
 */

/**
 * A list of variant options that should show images as previews where possible
 */
export const slugs = [
  'color',
  'colors',
  'colours',
  'colour',
  'shade',
  'shade-1',
  'shades',
  'shade-2',
  'shade-3',
  'select-a-style',
  'material'
];
