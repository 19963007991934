const defs = {
  'dolly': `
        <path d="M20 23.245l11.857 3.952c4.348 1.186 7.51 4.348 9.486 8.695l13.833 33.2M68.219 89.25L96.28 77.391M47.271 101.897c7.859 0 14.229-6.37 14.229-14.229 0-7.858-6.37-14.228-14.229-14.228-7.858 0-14.228 6.37-14.228 14.228s6.37 14.229 14.228 14.229z" stroke="var(--secondary-color, #C9CBCF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M75.682 19L49.025 30.042l6.768 16.337 26.656-11.042L75.682 19z" stroke="var(--primary-color, #467AFF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M89.203 39.228L58.164 52.085 69.206 78.74l31.039-12.856-11.042-26.657zM77.31 54.468l-3.557-9.09" stroke="var(--secondary-color, #C9CBCF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M65.847 33.125L62.29 24.43" stroke="var(--primary-color, #467AFF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    `,
  'giftcard': `
        <path d="M29 68h11m-11 6.5h16.5M101 41.905C101 37.557 97.443 34 93.095 34h-67.19C21.557 34 18 37.557 18 41.905v35.571c0 4.348 3.557 7.905 7.905 7.905h67.19c4.348 0 7.905-3.557 7.905-7.905V66.583" stroke="var(--secondary-color, #C9CBCF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M87.348 72c0-7.492-3.037-13.565-6.783-13.565-3.746 0-6.782 6.073-6.782 13.565M59.092 50.846c2.776-4.443 9.737-2.815 14.505-.646 2.36 1.073 4.36 2.574 4.36 4.583 0 2.008-2 3.51-4.36 4.582-4.768 2.169-11.729 3.797-14.505-.645-.628-1.005-.962-2.304-.962-3.937 0-1.634.334-2.933.962-3.938zM102.038 50.846c-2.776-4.443-9.736-2.815-14.505-.646-2.36 1.073-4.36 2.574-4.36 4.583 0 2.008 2 3.51 4.36 4.582 4.769 2.169 11.729 3.797 14.505-.645.628-1.005.962-2.304.962-3.937 0-1.634-.334-2.933-.962-3.938z" stroke="var(--primary-color, #467AFF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <circle cx="80.565" cy="54.783" r="4.696" fill="transparent" stroke="var(--primary-color, #467AFF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    `,
  'laptop': `
        <path d="M25.905 71.5V37.905c0-4.348 3.557-7.905 7.904-7.905h51.382c4.347 0 7.904 3.557 7.904 7.905V71.5" stroke="var(--secondary-color, #C9CBCF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path clip-rule="evenodd" d="M101 77.428c0 4.348-3.952 11.858-16.6 11.858H34.6c-12.648 0-16.6-7.51-16.6-11.858h83z" stroke="var(--secondary-color, #C9CBCF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M43.69 41.857h3.953l3.952 15.81h15.81L69.38 45.81H49.619" stroke="var(--primary-color, #467AFF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M53.781 66.385a2 2 0 100-4 2 2 0 000 4zM65.638 66.385a2 2 0 100-4 2 2 0 000 4z" fill="var(--primary-color, #467AFF)"/>
    `,
  'mobile': `
        <path d="M77.476 19c4.348 0 7.905 3.557 7.905 7.905v67.19c0 4.348-3.557 7.905-7.905 7.905H41.905C37.557 102 34 98.443 34 94.095v-67.19C34 22.557 37.557 19 41.905 19h35.571z" stroke="var(--secondary-color, #C9CBCF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M47.834 19v4.348a3.542 3.542 0 003.557 3.557h16.6a3.542 3.542 0 003.557-3.557V19" stroke="var(--secondary-color, #C9CBCF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M43.88 54.572h3.953l3.953 15.81h15.81l1.975-11.858H49.81" stroke="var(--primary-color, #467AFF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M53.972 79.1a2 2 0 100-4 2 2 0 000 4zM65.829 79.1a2 2 0 100-4 2 2 0 000 4z" fill="var(--primary-color, #467AFF)"/>
    `,
  'package': `
        <path d="M67.452 41.81H96.7M29.905 41.81h29.643M59.547 27.976V41.81M79.705 26H39.39c-1.186 0-1.977.395-2.767 1.186L23.186 40.624c-.79.79-1.186 1.58-1.186 2.767v45.847c0 2.371 1.581 3.953 3.952 3.953h67.19c2.372 0 3.953-1.582 3.953-3.953V43.391c0-1.186-.395-1.977-1.185-2.767L82.47 27.186c-.79-.79-1.58-1.186-2.766-1.186z" stroke="var(--secondary-color, #C9CBCF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M45.714 67.5h18.972c4.743 0 8.695 3.952 8.695 8.695M54.014 75.8l-8.3-8.3M54.014 58.805l-8.3 8.695" stroke="var(--primary-color, #467AFF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    `,
  'shop': `
        <path d="M99.517 50.062c.395-1.581.395-3.162-.395-4.743l-7.51-18.18C90.426 23.975 87.264 22 83.707 22H37.07c-3.557 0-6.324 1.976-7.905 5.138l-7.51 18.181c-.79 1.581-.79 3.162-.394 4.743 1.185 3.952 5.138 6.719 9.485 6.719 5.534 0 9.881-4.348 9.881-9.486 0 5.138 4.348 9.486 9.881 9.486 5.534 0 9.881-4.348 9.881-9.486 0 5.138 4.348 9.486 9.881 9.486s9.881-4.348 9.881-9.486c0 5.138 4.348 9.486 9.881 9.486 4.743 0 8.3-2.767 9.486-6.72zM90.03 63.895v33.596H30.746V63.895" stroke="var(--secondary-color, #C9CBCF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M82.126 61.92v15.809c0 2.371-1.581 3.952-3.953 3.952H42.602c-2.372 0-3.953-1.58-3.953-3.952v-15.81" stroke="var(--primary-color, #467AFF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    `,
  'truck': `
        <path d="M30.857 37.857h11.857M19 37.857h3.952" stroke="var(--primary-color, #467AFF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M41.924 73.428H34.81" stroke="var(--secondary-color, #C9CBCF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.952 49.714H34.81M34.81 49.714h11.857M26.905 61.572h23.714" stroke="var(--primary-color, #467AFF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M34.81 26h39.523c2.372 0 3.953 1.581 3.953 3.952v35.572M77.495 73.428H66.43M78.286 37.857C91.329 37.857 102 48.53 102 61.572v11.857M50.619 93.19c6.548 0 11.857-5.308 11.857-11.857 0-6.548-5.309-11.857-11.857-11.857-6.549 0-11.857 5.309-11.857 11.857 0 6.549 5.308 11.857 11.857 11.857zM86.19 93.19c6.55 0 11.858-5.308 11.858-11.857 0-6.548-5.309-11.857-11.857-11.857-6.549 0-11.858 5.309-11.858 11.857 0 6.549 5.31 11.857 11.858 11.857zM86.19 57.62h15.415" stroke="var(--secondary-color, #C9CBCF)" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    `,
};

export const names = Object.keys(defs);

export default defs;
