import { Factory, Model } from 'miragejs';
import { returns } from '../mocks/returns';

const models = () => {
  return {
    receipt: Model,
  };
};

const factories = () => {
  return {
    receipt: Factory.extend({
      'returned_items': {
        '129': {
          'item_net': 2400,
          'item_discount': 0,
          'item_tax': 180,
          'item_total': 2580
        },
        '136': {
          'item_net': 2400,
          'item_discount': 0,
          'item_tax': 180,
          'item_total': 2580
        },
        '154': {
          'item_net': 500,
          'item_discount': 0,
          'item_tax': 38,
          'item_total': 538
        }
      },
      'new_items': {
        '21': {
          'item_net': 500,
          'item_discount': 0,
          'item_tax': 38,
          'item_total': 538
        }
      },
      'handling_fee': 0,
      'bonus_used': 0,
      'gift_card_used': 0,
      'returned_item_tax': -750,
      'returned_item_total_discount': 0,
      'returned_item_subtotal': -10000,
      'returned_item_credit': 10750,
      'new_item_tax': 0,
      'new_item_subtotal': 0,
      'new_item_total': 0,
      'total': 10750,
      'exchange_order_shipping_total': 527
    })
  };
};

const seed = (context) => {
  returns.forEach((item) => {
    context.create('receipt', {
      return_key: item.return_key,
      ...item.totals
    });
  });
};

const routes = (context) => {
  context.get('/:shopId/return/:returnKey/receipt', (schema, request) => {
    const return_key = request.params.returnKey;
    return findOrFail(schema, return_key);
  });
};

const findOrFail = (schema, returnKey) => {
  const item = schema.receipts.findBy({ return_key: returnKey });
  if (item) {
    return item.attrs;
  }

  return new Response(
    404,
    { 'Content-Type': 'application/json' },
    {
      errors: [{
        title: 'return not found',
        description: 'return could not be found'
      }]
    }
  );
};

export default {
  factories,
  routes,
  models,
  seed
};
