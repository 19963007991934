<template>
  <div
    class="skeleton-text"
    :class="[type]"
    aria-hidden="true"
  >
    <span
      v-for="n in lines"
      :key="n"
      class="skeleton-text__line"
      :class="[type]"
      :style="styles"
    ></span>
  </div>
</template>

<script>
export default {
  props: {
    lines: {
      type: Number,
      required: false,
      default: 3
    },
    type: {
      type: String,
      required: false,
      default: 'body',
      validator: value => ['body', 'heading', 'reason'].includes(value)
    },
    width: {
      type: String,
      required: false
    }
  },
  computed: {
    styles() {
      if (this.width) {
        return {
          width: this.width
        };
      }

      return {};
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.skeleton-text';

#{$block} {
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 1rem;
  }

  &__line {
    @include skeleton;

    width: 100%;
    height: 0.5rem;

    &.heading {
      width: 50%;
      height: 1.75rem;
    }

    &.reason {
      width: 60%;
      height: 0.75rem;
    }

    & + & {
      margin-top: 1rem;
    }

    &:last-of-type:not(:first-of-type) {
      width: calc(100% - 6rem);
    }
  }
}
</style>
