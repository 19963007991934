import { products } from './products';

const cartItem = {
  'price': 2000,
  'product_id': 0,
  'variant_id': 0,
  'image': '',
  'variant_title': '',
  'product_type': '',
  'sku': '',
  'title': '',
  'cart_id': null
};

const createCart = (name, items = []) => {
  return {
    name,
    items: items.map((item) => {
      const productId = item.product_id ?? 1;
      const product = products.find((product) => product.product_id === productId);
      const variant = product.variants[0];

      return {
        ...cartItem,
        cart_id: name,
        price: variant.price,
        product_id: productId,
        variant_id: variant.id,
        image: product.image.src,
        variant_title: variant.variant_title,
        product_type: product.product_type,
        sku: variant.sku,
        title: product.title,
        ...item
      };
    })
  };
};

export const carts = [
  createCart('normal cart', [1]),
  createCart('multiple items cart', [
    { product_id: 1 },
    { product_id: 2 },
    { product_id: 3 }
  ]),
];
