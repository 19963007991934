import { products } from './products';

/**
 * Creates a line item object
 * @param {string|number} id - The id to assign to this line item
 * @returns {object} A line item object that matches the BE endpoint
 */
export const createLineItem = (id = 1, keys = {}) => {
  let product, variant;
  if (keys.product_id) {
    product = products.find((product) => product.product_id === keys.product_id);
    variant = product.variants[0];
  } else {
    product = getRandomItem(products);
    variant = getRandomItem(product.variants);
  }

  const discount = keys.discount ?? 0;

  return {
    line_id: id,
    id,
    created_at: '2020-06-16 20:55:57',
    updated_at: '2020-06-17 18:48:38',
    fulfilled_at: '2020-06-16 20:55:35',
    description: product.body_html.replace(/(<([^>]+)>)/ig, ''),
    order_id: null,
    provider_line_item_id: null,
    product_id: product.product_id,
    variant_id: variant.id,
    sku: variant.sku,
    currency: 'USD',
    price: variant.price,
    current_variant_price: variant.price,
    title: product.title,
    variant_title: variant.variant_title,
    returned_at: null,
    image: product.image?.src ?? '',
    images: product.images ?? [],
    product_type: product.product_type,
    return_id: null,
    exchange_id: null,
    refunded_at: null,
    weight: variant.weight,
    grams: variant.grams,
    returned: 0,
    restocked: 0,
    vendor: product.vendor,
    fulfillment_service: variant.fulfillment_service,
    location_id: 0,
    product_exists: true,
    barcode: variant.barcode,
    inventory_item_id: null,
    taxable: variant.taxable,
    tax_code: null,
    image_id: null,
    visible_properties: [],
    tags: product.tags ? product.tags.split(/,\s*/) : [],
    outcomesSetByWorkflow: [],
    'allowed': {
      'return': true,
      'refund': true,
      'exchange': true,
      'gift': true,
      'returned': false,
      'reason': ''
    },
    'excluded': {
      'advancedExchange': false,
      'inlineExchange': false,
      'shopNow': false,
      'instantExchange': false,
    },
    'rules': {
      'matched': [],
      'applied': []
    },
    'discount': discount,
    'discounted_price': (variant.price - discount),
    'tax': variant.price * .07,
    'advanced_exchange': null,
    'option1': variant.option1,
    'option2': variant.option2,
    'option3': variant.option3,
    'options': product.options,
    'reason_group': 1,
    'status': null,
    'order': {
      'id': 2692,
      'created_at': '2020-06-16 20:55:54',
      'updated_at': '2020-07-08 17:16:03',
      'provider_order_id': 2366968234053,
      'customer_id': 81,
      'name': '#1114',
      'fulfillment_status': 'partial',
      'fulfilled_at': '2020-06-16 20:55:35',
      'currency': 'USD',
      'total': 63708,
      'total_shop': 63708,
      'provider_customer_id': 873012723781,
      'subtotal': 59261,
      'subtotal_shop': 59261,
      'discounts_total': 0,
      'discounts_total_shop': 0,
      'shipping_total': 0,
      'shipping_total_shop': 0,
      'tax_total': 4447,
      'tax_total_shop': 4447,
      'refund_total': 0,
      'cancelled_at': null,
      'shop_id': 260,
      'email': 'jesse@loopreturns.com',
      'ex_order_count': 0,
      'gc_order_count': 0,
      'number': 114,
      'taxes_included': false,
      'provider_created_at': '2020-06-16 20:55:22',
      'provider_updated_at': '2020-07-08 17:04:42'
    },
    destinations: [],
    ...keys
  };
};

/**
 * Get a random item from an array
 * @param {array} items
 * @returns {*} A single item from the array
 */
const getRandomItem = (items) => {
  return items[Math.floor(Math.random() * items.length)];
};

export const lineItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => createLineItem(item));
