import { useStore } from 'vuex';
import { computed } from 'vue';

export default function useCustomers() {
  const store = useStore();

  const contentModuleCustomerInfoHeading = computed(() => {
    return store.getters['content'].moduleCustomerInfo.heading;
  });

  return {
    contentModuleCustomerInfoHeading,
  };
}
