<template>
  <div
    class="product-preview"
    aria-hidden="true"
  >
    <template v-if="shownItems.length > 0">
      <span
        v-for="item in shownItems"
        :key="item.id"
        class="preview-image"
      >
        <img
          class="preview-img"
          alt=""
          data-testid="product-image-preview"
          :src="item.image"
        />
        <base-text
          v-if="item.text"
          class="preview-more"
          type="caption"
        >
          <render-content>
            {{ item.text }}
          </render-content>
        </base-text>
      </span>
    </template>
    <template v-else>
      <skeleton-product
        v-for="i in amount"
        :key="i"
        :show-info="false"
      />
    </template>
  </div>
</template>

<script>
/**
 *  Shows a preview of a list of products or variants in the form of a group of product images
 */
import {
  BaseText,
} from '@loophq/design-system';
import SkeletonProduct from '@/components/feedback/SkeletonProduct';
import { formatCustomizationString } from '@/js/helpers/formatCustomizationString';

export default {
  name: 'ProductImagesPreview',
  components: {
    BaseText,
    SkeletonProduct,
  },
  props: {
    /**
   * A list of products or variants to show
   */
    products: {
      type: Array,
      required: true,
      validator(val) {
        return val.every((item) => {
          return ['title', 'id', 'image'].every((key) => {
            return key in item;
          });
        });
      }
    },
    /**
   * The amount of images to show
   */
    amount: {
      type: Number,
      required: false,
      default: 4
    },
    /**
   * By default, the "+<amount> items" will be based on the amount of products you pass i
   * This lets you override that
   */
    additionalText: {
      type: String,
      required: false
    }
  },
  computed: {
    shownItems() {
      const extraItems = this.products.length - this.amount;
      const truncated = this.products.slice(0, this.amount);

      if (extraItems > 0) {
        const amount = this.additionalText ?? `+${extraItems}`;
        return truncated.map((item, index, arr) => {
          // Add additional text to the last element
          if (index === arr.length - 1) {
            return {
              ...item,
              text: formatCustomizationString(
                this.$content.moduleProductPreview.additional,
                { amount }
              )
            };
          }
          return item;
        });
      }

      return truncated;
    }
  },
};
</script>

<style lang="scss" scoped>
.product-preview {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: var(--spacing-300);
  margin-bottom: var(--spacing-600);
  width: 100%;
}

.preview-image {
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: var(--product-background-color);
  border-radius: var(--corners);
  padding-bottom: 100%;
}

.preview-img {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: var(--product-image-fit);
  object-position: var(--product-image-position);
}

.preview-more {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(black, 0.6);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
