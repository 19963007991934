<template>
  <label-modal-layout
    :labels="labels"
    :order-name="orderName"
    :return-by="returnBy"
    :content="content"
    :carrier="carrier"
  >
    <template
      v-if="carrier"
      #label-card-header
    >
      <render-content :data="{ carrier }">
        {{ content.qrLabelCardHeader }}
      </render-content>
    </template>
    <template
      v-if="carrier"
      #description
    >
      <render-content :data="{ formattedReturnByDate, carrier, locationLink }">
        {{ content.qrLabelDropOffInstruction }}
      </render-content>
    </template>

    <template #label-card="{ count, ...currentLabel }">
      <label-card
        :label="currentLabel"
        :count="count"
        :return-id="returnId"
        :shop-id="shopId"
        :content="content"
        :is-qr-code="true"
        :packing-slips-enabled="packingSlipsEnabled"
        :return-key="returnKey"
      >
        <img
          v-if="currentLabel.qrCodeUrl"
          class="label-modal-layout__code-image"
          :src="currentLabel.qrCodeUrl"
        />

        <alert-banner
          v-if="qrFetchErrored"
          type="error"
          class="label-modal-layout__code-error"
          :heading="content.qrFetchFailureHeading"
          :description="content.qrFetchFailureDescription"
          :is-closable="false"
        />
      </label-card>
    </template>
  </label-modal-layout>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { AlertBanner } from '@loophq/design-system';
import LabelModalLayout from './LabelModalLayout.vue';
import LabelCard from './LabelCard.vue';
import QrCode from '@/js/controllers/qrCode';
import { logError } from '@/js/helpers/errors';
import formatDate from '@/js/helpers/formatDate';
import { useStore } from 'vuex';
import { useLocationLinkMapper } from '@/js/composables/views/StatusPage/locationLinkMapper';

const props = defineProps({
  labels: Array,
  returnId: Number,
  shopId: Number,
  returnKey: String,
  returnBy: String,
  orderName: String,
  content: Object,
  packingSlipsEnabled: Boolean,
});

const store = useStore();
const qrFetchErrored = ref(false);

onMounted(() => {
  props.labels.forEach(async (label) => {
    if (label && !label?.qrCodeUrl) {
      const res = await QrCode.getLabelQrCode(props.shopId, props.returnId, label?.id);
      try {
        if (res.data.qr_code === null || res.data.errors) {
          qrFetchErrored.value = true;
          throw new Error('QR code url is null');
        }
      } catch (error) {
        logError(error);
      }
      await store.dispatch('firstMile/addQrCode', { labelId: label?.id, qrCodeUrl: res.data.qr_code });
      label.value = res.data.qr_code;
    }
  });
});

const formattedReturnByDate = computed(() => {
  return formatDate(props.returnBy); 
});

const carrier = computed(() => {
  if (props.labels.length > 0) {
    return props.labels[0]?.carrier;
  }

  return null;
});

const locationLink = computed(() => {
  return useLocationLinkMapper(carrier);
});
</script>

<style lang="scss" scoped>
.label-modal-layout {
  &__code {
    &-image {
      border-radius: var(--theme-corners);
      display: block;
      margin: 0 auto var(--spacing-400);
      max-width: 400px;
      width: 90%;
    }

    &-error {
      margin: 0 auto var(--spacing-400);
    }
  }
}
</style>
