import { carriers } from '@/js/constants/carriers';

export const useLocationLinkLookup = {
  [carriers.USPS]: 'https://tools.usps.com/find-location.htm?locationType=po&serviceType=lbroretail',
  [carriers.DHLSEKO]: 'https://tools.usps.com/find-location.htm?locationType=po&serviceType=lbroretail',
  [carriers.NEWGISTICS]: 'https://tools.usps.com/find-location.htm?locationType=po&serviceType=lbroretail',
  [carriers.INPOST]: 'https://inpost.co.uk/lockers',
  [carriers.ASDA]: 'https://storelocator.asda.com',
  [carriers.EVRI]: 'https://www.evri.com/find-a-parcelshop',
  [carriers.CANADA_POST]: 'https://www.canadapost.ca/cpotools/apps/fpo/personal/findPostOffice',
  [carriers.ROYAL_MAIL]: 'https://www.royalmail.com/services-near-you#/',
};

export function useLocationLinkMapper(carrier) {
  return {
    type: 'link',
    text: `any ${carrier.value} carrier location`,
    attrs: {
      href: useLocationLinkLookup[carrier.value] || 'http://fedex.com/easyreturns',
      target: '_blank',
    }
  };
}
