import store from '@/store';
import axios from 'axios';
import { outcomes, ruleTypes } from '@/js/constants/workflows';
import { toMinorCurrencyUnit } from '@/js/helpers/formatCurrency';

export const applyOutcome = async (result, lineItem, order, shopId) => {
  const { outcome } = result;
  const outcomeTypes = [outcomes.KEEP_ITEM, outcomes.REJECT, outcomes.REVIEW, outcomes.DONATE];
  if (outcomeTypes.includes(outcome)) {
    await updateOutcomeOnLineItem(order, lineItem, outcome, shopId);
  }
};

export const excludeOutcome = async (result, lineItem) => {
  const allowed = {
    ...lineItem.allowed,
    refund: result?.value.refund ? false : lineItem.allowed.refund,
    gift: result?.value.storeCredit ? false : lineItem.allowed.gift,
    exchange: result?.value.exchange ? false : lineItem.allowed.exchange,
  };

  const excluded = {
    ...lineItem.excluded,
    advancedExchange: result?.value?.advancedExchange ?? lineItem.excluded.advancedExchange,
    shopNow: result?.value?.shopNow ?? lineItem.excluded.shopNow,
    inlineExchange: result?.value?.inlineExchange ?? lineItem.excluded.inlineExchange,
    instantExchange: result?.value?.instantExchange ?? lineItem.excluded.instantExchange,
    refund: result?.value?.refund,
    storeCredit: result?.value?.storeCredit,
  };

  await store.dispatch('updateLineItem', {
    id: lineItem.id,
    allowed,
    excluded,
    outcomesSetByWorkflow: lineItem.outcomesSetByWorkflow,
  }, { root: true });

  return {
    id: lineItem.id,
    allowed,
    excluded
  };
};

export const applyPreCalculatedLineItemFees = (lineItem, applicableLineItemFees, lineItemFees) => {
  store.dispatch('updateLineItem', {
    id: lineItem.id,
    ...(applicableLineItemFees?.length ? { applicableLineItemFees } : {}),
    ...(lineItemFees ? { lineItemFees, fallbackLineItemFees: lineItemFees } : {}),
  }, { root: true });
};

export const revertExcludeOutcomes = (lineItem, originalLineItem) => {
  store.dispatch('updateLineItem', {
    id: lineItem.id,
    excluded: {
      advancedExchange: false,
      shopNow: false,
      inlineExchange: false,
      instantExchange: false,
    },
    allowed: {
      ...lineItem.allowed,
      exchange: originalLineItem.allowed.exchange,
      refund: originalLineItem.allowed.refund,
      gift: originalLineItem.allowed.gift,
    }
  }, { root: true });
};

export const revertReturnOutcomes = async (lineItem) => {
  // preserve user input outcomes
  const userInputOutcomesSetByWorkflow = lineItem.outcomesSetByWorkflow.filter(item => item.name === ruleTypes.USER_INPUT);

  const updatedLineItem = await store.dispatch('updateLineItem', {
    id: lineItem.id,
    outcome: null,
    outcomesSetByWorkflow: userInputOutcomesSetByWorkflow,
  }, { root: true });

  if (store.state.return.lineItems?.find((item) => item.id === updatedLineItem.id)) {
    store.commit('return/updateLineItem', updatedLineItem);
  }
};

export const revertBonusCredit = (order) => {
  if (!order.return_policy?.credit_bonuses?.length) {
    return;
  }
  let creditBonus = order.return_policy?.credit_bonuses.find(bonus => bonus.outcome === 'upsell');
  store.commit('updateData', {
    name: 'shopNowBonus',
    data: creditBonus,
    save: true
  });
};

export const setOutcomeSetByWorkflow = (lineItem, rule, order) => {
  if (rule.type !== ruleTypes.USER_INPUT && lineItem.outcomesSetByWorkflow.find(item => item.name === rule.type)) {
    return;
  }
  lineItem.outcomesSetByWorkflow.push({
    name: rule.type,
    workflowId: rule.workflowId,
    ruleId: rule.ruleId,
    questionId: rule?.questionId,
    type: rule.value?.type ?? 'default',
  });
  order.line_items[lineItem.id].outcomesSetByWorkflow = lineItem.outcomesSetByWorkflow;
  store.dispatch('updateLineItem', {
    id: lineItem.id,
    outcomesSetByWorkflow: lineItem.outcomesSetByWorkflow
  }, { root: true });
};

export const updateOutcomeList = (lineItemOutcome, outcome) => {
  if (!Array.isArray(lineItemOutcome)) {
    return [outcome];
  }
  if (outcome === outcomes.KEEP_ITEM || outcome === outcomes.DONATE) {
    lineItemOutcome = lineItemOutcome.filter(value => (value !== outcomes.KEEP_ITEM && value !== outcomes.DONATE));
  } else if (outcome === outcomes.REVIEW || outcome === outcomes.REJECT) {
    lineItemOutcome = lineItemOutcome.filter(value => (value !== outcomes.REVIEW && value !== outcomes.REJECT));
  } else {
    lineItemOutcome = lineItemOutcome.filter(value => (value !== outcome));
  }
  lineItemOutcome.push(outcome);
  return lineItemOutcome;
};

export const applyPhotoUploadRequirements = async (lineItem, workflow) => {
  const isMandatory = workflow.value?.mandatory ?? true;

  await store.dispatch('updateLineItem', {
    id: lineItem.id,
    photoUploadRequired: isMandatory,
  }, { root: true });
};

const updateOutcomeOnLineItem = async (order, lineItem, outcome, shopId) => {
  if (outcome === outcomes.REJECT && order.allowlisted) {
    return;
  }

  let outcomeList = updateOutcomeList(lineItem.outcome, outcome);

  order.line_items[lineItem.id].outcome = outcomeList;
  if (outcome === outcomes.REJECT) {
    await rejectLineItem(order, lineItem, shopId);
  } else {
    store.dispatch('updateLineItem', {
      id: lineItem.id,
      outcome: outcomeList,
    }, { root: true });
  }

  if (store.state.return.lineItems?.length) {
    lineItem.outcome = outcomeList;
    store.commit('return/updateLineItem', lineItem);
  }
};

const rejectLineItem = async (order, lineItem, shopId) => {
  lineItem.allowed = {
    ...order.line_items[lineItem.id].allowed,
    returned: true,
    reason: 'ineligibleCode2',
  };
  store.dispatch('updateLineItem', {
    id: lineItem.id,
    allowed: lineItem.allowed,
    outcome: ['reject'],
  }, { root: true });

  await axios.post(`api/v1/${shopId}/line_item/${lineItem.id}/disqualify`, {});
};

export const applyBonusCredit = (workflowResult, order) => {
  const displayCurrency = store.getters['currencies/displayCurrency'];
  let creditBonus = order.return_policy.credit_bonuses.find(bonus => bonus.outcome === 'upsell');
  creditBonus.amount = workflowResult.value.type === 'flat_fee' ? toMinorCurrencyUnit(workflowResult.value.amount, displayCurrency) : workflowResult.value.amount;
  creditBonus.type = workflowResult.value.type;
  store.commit('updateData', {
    name: 'shopNowBonus',
    data: creditBonus,
    save: true
  });
};
