<template>
  <div
    class="pos-return-method"
  >
    <base-text
      class="pos-return-method__heading"
    >
      <render-content
        :data="{
          distance: returnMethod.distance.value.toFixed(2),
          unit: returnMethod.distance.unit === 'm' ? 'miles' : returnMethod.distance.unit
        }"
      >
        {{ $content.moduleReturnMethodSelector.posStoreDistance }}
      </render-content>
    </base-text>

    <base-button
      v-track="'pos get directions clicked'"
      type="link"
      class="pos-return-method__directions"
      :to="googleMapsLink"
      target="_blank"
    >
      <span class="pos-return-method__directions-label">
        <address-display
          :address="address"
          leading="none"
          align="left"
          class="pos-return-method__address"
        />
        <base-icon
          class="pos-return-method__external-icon"
          name="external-link"
        />
      </span>
    </base-button>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseText,
  BaseIcon,
} from '@loophq/design-system';
import AddressDisplay from '@/components/AddressDisplay';

export default {
  components: {
    BaseButton,
    BaseText,
    BaseIcon,
    AddressDisplay,
  },
  props: {
    returnMethod: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    googleMapsLink() {
      return `https://www.google.com/maps/dir/?api=1&destination=${this.returnMethod.address.latitude},${this.returnMethod.address.longitude}`;
    },
    address() {
      // eslint-disable-next-line
			const { name, ...rest } = this.returnMethod.address;
      return { ...rest };
    },
  }
};
</script>

<style lang="scss" scoped>
// duplicated value from parent
$icon-size: 48px;
$external-icon-size: 12px;
$block: '.pos-return-method';
$address-font-size: 0.875rem;

#{$block} {
  padding: var(--spacing-400);

  &__heading {
    font-weight: 600;
    display: block;
  }

  &__distance {
    font-weight: 600;
  }

  &__directions {
    color: inherit !important;
    font-weight: normal;
    display: block;
    margin-bottom: var(--spacing-800);
  }

  &__directions-label {
    display: flex;
    align-items: flex-end;
  }

  &__address {
    text-decoration: underline;
    text-align: left;
    font-size: $address-font-size;
  }

  &__external-icon {
    height: $external-icon-size;
    margin-left: var(--spacing-200);
    box-sizing: content-box;
    // 1.6 is line-height plus a little extra to put above underline
    padding-bottom: calc(calc(#{$address-font-size * 1.6} - #{$external-icon-size}) / 2);
  }
}
</style>
