import env from '@/env';

export const initSegment = () => {
  if (!env('VITE_SEGMENT_WRITE_KEY_PORTAL')) return;

  !function () {
    var analytics = window.analytics = window.analytics || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.'); else {
      analytics.invoked = !0; analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'screen', 'once', 'off', 'on', 'addSourceMiddleware', 'addIntegrationMiddleware', 'setAnonymousId', 'addDestinationMiddleware', 'register']; analytics.factory = function (e) { return function () { if (window.analytics.initialized) return window.analytics[e].apply(window.analytics, arguments); var i = Array.prototype.slice.call(arguments); if (['track', 'screen', 'alias', 'group', 'page', 'identify'].indexOf(e) > -1) { var c = document.querySelector('link[rel=\'canonical\']'); i.push({ __t: 'bpc', c: c && c.getAttribute('href') || void 0, p: location.pathname, u: location.href, s: location.search, t: document.title, r: document.referrer }); } i.unshift(e); analytics.push(i); return analytics; }; }; for (var i = 0; i < analytics.methods.length; i++) { var key = analytics.methods[i]; analytics[key] = analytics.factory(key); } analytics.load = function (key, i) { var t = document.createElement('script'); t.type = 'text/javascript'; t.async = !0; t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'; var n = document.getElementsByTagName('script')[0]; n.parentNode.insertBefore(t, n); analytics._loadOptions = i; }; analytics._writeKey = `${env('VITE_SEGMENT_WRITE_KEY_PORTAL')}`; analytics.SNIPPET_VERSION = '5.2.0';
      analytics.load(env('VITE_SEGMENT_WRITE_KEY_PORTAL'));
    }
  }();
};

/**
 * Link users and their actions
 * @param {string} [userId] optional, omit for anonymous user
 * @param {object} [traits] traits about the user, such as name or email
 */
export const identify = (userId, traits = {}) => {
  if (!env('VITE_SEGMENT_WRITE_KEY_PORTAL')) return;
  window.analytics.identify(userId, { ...traits });
};

/**
 * Record actions a user performs
 * @param {string} event
 * @param {object} [properties] extra info about the event
 */
export const track = (event, properties = {}) => {
  if (!env('VITE_SEGMENT_WRITE_KEY_PORTAL')) return;

  window.analytics.track(event, {
    ...properties
  });
};
