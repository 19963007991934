/**
 * Get an environment variable. Refer to .env.example for what variables are available
 * @param {string} name
 */
export default (name) => {
  const value = import.meta.env[name] ?? null;
  return parse(value);
};

/**
 * Converts strings to the proper type, if possible
 * @param {string} str - The string to parse
 */
const parse = (str) => {
  try {
    const parsed = JSON.parse(str);
    return parsed;
  } catch (error) {
    return str;
  }
};
