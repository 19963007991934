<template>
  <div class="gift-lookup-page__header">
    <div
      class="gift-lookup-page__header-wrapper"
      :class="{'center': center}"
    >
      <div class="gift-lookup-page__header-logos">
        <img 
          class="loop-logo"
          src="/img/loop-logo-dark.svg" 
          alt="Loop logo" 
        />
        <base-icon
          class="close"
          name="close"
          aria-hidden="true"
        />
        <img
          v-if="logo"
          class="loop-logo"
          :src="logo"
          alt="logo"
        />
        <base-text
          v-else
          aria-hidden="true"
          tag="span"
          type="display-large"
        >
          {{ $shop.name }}
        </base-text>
      </div>
      <div 
        v-if="orderNumber"
        class="gift-lookup-page__header-order-number"
      >
        <base-text
          tag="span"
          class="gift-lookup-page__header-order-number"
        >
          ORDER {{ orderNumber }}
        </base-text>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseIcon,
  BaseText,
} from '@loophq/design-system';

export default {
  name: 'CobrandedHeader',
  components: {
    BaseIcon,
    BaseText
  },
  props: {
    center: {
      type: Boolean,
      required: false,
      default: true,
    },
    orderNumber: {
      required: false,
      type: String
    }
  },
  computed: {
    logo() {
      const { branding } = this.$theme;

      if (branding.navLogo) return branding.navLogo;
      if (branding.logo) return branding.logo;

      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.gift-lookup-page {
  &__header-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &.center {
      @media (min-width: $break-small) {
        justify-content: center;
      }

      .gift-lookup-page__header-logos {
        @media (min-width: $break-small) {
          justify-content: center;
          align-items: center;
        }
      }
    }

    @media screen and (max-width: $break-small) {
      align-items: flex-start;
      flex-direction: column;
      gap: var(--spacing-200);
    }
  }

  &__header-logos {
    display: flex;
    width: fit-content;

    .loop-logo {
      width: auto;
      max-width: 100%;
      max-height: 32px;
      overflow: hidden;
    }

    .close {
      margin: 0 var(--spacing-300);
      max-height: 18px;
      align-self: center;
      color: var(--grey-900);
    }
  }

  &__header-order-number {
    text-decoration: underline;
  }
}
</style>
