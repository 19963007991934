<template>
  <div
    ref="shopLater"
    class="shop-later-inline"
  >
    <div class="shop-later-inline__content-wrapper">
      <base-text
        class="shop-later-inline__heading"
        type="display-medium"
        tag="h2"
      >
        {{ headingCopy }}
      </base-text>
      <markup-renderer
        class="shop-later-inline__copy"
        :content="shopLaterTermsCopy"
        :customization-data="{ formattedTotalCreditOffer, formattedBonus }"
      />
      <img
        class="shop-later-inline__image"
        src="/img/shop-later-inline-img.svg"
        alt="Stack of gifts"
      />
      <div
        class="shop-later-inline__actions"
      >
        <base-button
          v-if="giftCardEnabled"
          class="shop-later-inline__button"
          :type="giftCardButtonType"
          @click="$emit('open-shop-later-modal')"
        >
          <div
            :class="{'shop-later-inline__button-icon': !exchangesEnabled }"
          >
            {{ $content.pageReturnStatus.shopLaterActiveStoreCreditButtonText }}
            <base-icon
              v-if="!exchangesEnabled"
              class="icon"
              name="gift"
            />
          </div>
        </base-button>
        <base-button
          v-if="exchangesEnabled"
          class="shop-later-inline__button"
          :activated="shopNowLoading"
          @click="navigateToShopNow"
        >
          <div class="shop-later-inline__button-icon">
            {{ $content.pageReturnStatus.shopLaterActiveShopNowButtonText }}
            <base-icon
              class="icon"
              name="shopping-cart"
            />
          </div>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseIcon,
  BaseText,
} from '@loophq/design-system';
import { getOrderByReturnKey } from '@/js/controllers/shopnow';
import { formatCurrency } from '@/js/helpers/formatCurrency';
import MarkupRenderer from '@/components/renderers/MarkupRenderer';
import { generateOnstoreUrl } from '@/js/onstore';

export default {
  name: 'ShopLaterOfferInline',
  components: {
    BaseButton,
    BaseIcon,
    BaseText,
    MarkupRenderer,
  },
  props: {
    shopLaterRecord: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    return: {
      type: Object,
      required: true
    }
  },
  emits: [
    'open-shop-later-modal',
  ],
  data() {
    return {
      shopNowLoading: false,
    };
  },
  computed: {
    order() {
      return this.$store.getters.order;
    },
    formattedTotalCreditOffer() {
      return formatCurrency(this.shopLaterRecord.totalCreditOffer, this.currency);
    },
    formattedBonus() {
      return formatCurrency(this.shopLaterRecord.bonus, this.currency);
    },
    exchangesEnabled() {
      return this.shopLaterRecord.exchangesEnabled;
    },
    giftCardEnabled() {
      return this.shopLaterRecord.giftCardEnabled;
    },
    primaryButtonBackgroundColor() {
      return this.$store.state.customizations.theme?.button?.background ?? 'black';
    },
    giftCardButtonType() {
      return this.exchangesEnabled ? 'secondary' : 'primary';
    },
    shopLaterTermsCopy() {
      if (this.shopLaterRecord.bonus === 0.00) {
        if (this.giftCardEnabled && !this.exchangesEnabled) {
          return this.$content.pageReturnStatus.shopLaterZeroBonusGiftCardEnabledExchangesDisabled;
        } else if (this.exchangesEnabled && !this.giftCardEnabled) {
          return this.$content.pageReturnStatus.shopLaterZeroBonusGiftCardDisabledExchangesEnabled;
        } else {
          return this.$content.pageReturnStatus.shopLaterZeroBonusGiftCardEnabledExchangesEnabled;
        }
      } else {
        if (!this.giftCardEnabled && this.exchangesEnabled) {
          return this.$content.pageReturnStatus.shopLaterBonusGiftCardDisabledExchangesEnabled;
        } else if (this.giftCardEnabled && !this.exchangesEnabled) {
          return this.$content.pageReturnStatus.shopLaterBonusGiftCardEnabledExchangesDisabled;
        } else {
          return this.$content.pageReturnStatus.shopLaterBonusGiftCardEnabledExchangesEnabled;
        }
      }
    },
    headingCopy() {
      if (this.formattedBonus === 0.00) {
        return this.$content.pageReturnStatus.shopLaterActiveSecondaryHeadingCopy;
      }

      return this.$content.pageReturnStatus.shopLaterActiveHeadingCopy;
    }
  },
  mounted() {
    if (window.innerWidth <= 960) {
      // scroll into view when the viewport is mobile
      this.$nextTick(() => this.$refs.shopLater.scrollIntoView());
    }
  },
  methods: {
    async navigateToShopNow() {
      this.shopNowLoading = true;
      const response = await getOrderByReturnKey(this.$route.params.hash);
      this.$store.commit('updateOrder', response.data.order);

      this.$store.commit('return/setState', {
        lineItems: this.return.lineItems.map(item => ({
          ...item,
          returnType: item.isExchange ? 'exchange' : 'credit',
          resolution: item.isExchange ? 'exchange' : 'return',
        })),
        newItems: this.return.newItems,
        cartId: null,
        cartIdForDraft: null,
        creditType: null,
        exchangeType: null,
        returnType: 'credit',
        isGift: false,
        stripeToken: null,
        shopifyToken: null,
        address: this.return.address,
        customer: this.return.customer,
        hasShopLaterOffer: true,
        key: this.return.returnKey,
        shopLater: this.return.shopLater
      });

      await this.$store.dispatch('totals/update');

      this.shopNowLoading = false;
      if (this.order.enabled.on_store_api === 'yes') {
        window.location.href = generateOnstoreUrl(`return/${this.return.returnKey}`);
      } else {
        this.$router.push(`/shop?return_key=${this.return.returnKey}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.shop-later-inline';

#{$block} {
  display: block;
  background: var(--grey-100);
  border: 1px solid var(--grey-800);
  border-radius: var(--spacing-300);

  @media screen and (max-width: $break-tiny) {
    border-radius: var(--spacing-300) !important;
    border: 1px solid var(--grey-800) !important;
    padding: var(--spacing-200) !important;
    margin-left: var(--spacing-300);
    margin-right: var(--spacing-300);
  }

  &__content-wrapper {
    padding: var(--spacing-400);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__heading {
    order: 0;
    flex-grow: 0;
    margin-bottom: var(--spacing-300);
    color: black !important;
  }

  &__copy {
    :deep(p) {
      color: var(--grey-800);
    }
  }

  &__image {
    margin: var(--spacing-300) 0;
    max-width: 100%;
  }

  &__actions {
    margin-top: var(--spacing-200);
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (width >= 1300px) {
      flex-wrap: nowrap;
    }
  }

  &__button {
    padding: var(--spacing-300) calc(2px + var(--spacing-300)) !important;
    text-align: center;
    white-space: nowrap;
    width: 100%;

    &.primary {
      background-color: v-bind(primaryButtonBackgroundColor);
    }

    &-icon {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      text-align: center;

      .icon {
        margin-left: var(--spacing-200);
        margin-right: calc(-1 * var(--spacing-200)); // center the text & icon in the button
        padding-left: 0;
        margin-bottom: -3.5px;
        height: 24px;
        width: 24px;
      }
    }

    & + #{$block}__button {
      margin-top: var(--spacing-300);

      @media screen and (width >= 1300px) {
        margin-top: 0;
        margin-left: var(--spacing-300);
      }
    }
  }
}
</style>
