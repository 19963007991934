import store from '@/store';
import router from '@/router';
import env from '@/env';
import bootTheme from '@/js/mixins/theme';
import changeCase from 'change-object-case';
changeCase.options = { recursive: true, arrayRecursive: true };

const origins = env('VITE_ADMIN_ORIGINS')?.split(',') ?? [];

export const actions = {
  RETURN_COMPLETE: 'return:complete',
  CLEAR_DATA: 'data:clear',
  SET_DATA: 'data:set',
  SET_CUSTOMIZATION: 'customization:set',
  NAVIGATE: 'navigate',
  SET_LANGUAGE: 'language:set',
  LOADED: 'loaded',
  SET_TOTALS: 'totals:set',
  SET_ELEMENT_STYLE: 'style'
};

const actionResolvers = {
  [actions.NAVIGATE]: (data) => {
    router.push({ path: data.url });
  },
  [actions.SET_CUSTOMIZATION]: (data) => {
    store.commit('setEdits', data);

    if (data.type === 'theme') {
      store.commit('tracking/setRecommendationsCount', data.data?.trackingRecommendations.limit);
      bootTheme(store.getters.theme);
    }
  },
  [actions.SET_DATA]: (data) => {
    store.commit('setEdits', data);
  },
  [actions.CLEAR_DATA]: () => {
    store.commit('updateOrder', null);
    store.commit('return/clear');
  },
  [actions.SET_LANGUAGE]: (data) => {
    store.dispatch('setLanguage', data.language);
  },
  [actions.SET_TOTALS]: (totals) => {
    const dataBundle = changeCase.toCamel(totals.data);
    store.commit('totals/setTotals', dataBundle);
  },
  [actions.SET_ELEMENT_STYLE]: (data) => {
    const element = document.querySelector(data.class);
    if (element) {
      element.style[data.styleName] = data.style;
    }
  }
};

export const watchMessages = (cb = Function.prototype) => {
  window.addEventListener('message', (event) => {
    if (origins.includes(event.origin)) {
      if (actionResolvers[event.data.action]) {
        actionResolvers[event.data.action](event.data.data);
      }
      cb(event.data);
    }
  }, false);
};

/**
 * Send a message to the parent window
 * @param {*} message
 * @returns
 */
export const sendMessage = (message) => {
  if (!origins) {
    console.error('Unable to send message to admin without VITE_ADMIN_ORIGINS being set.');
    return;
  }

  window.parent.postMessage(message, origins[0]);
};

/**
 * Check to see if the current user is seeing the portal in an iframe
 * @returns {boolean}
 */
export const inIframe = () => {
  return window.location !== window.parent.location;
};
