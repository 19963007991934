<template>
  <div class="return-details">
    <div
      class="return-details__row"
    >
      <base-text
        class="return-details__text"
      >
        <b>Return method</b>
      </base-text>
      <base-text
        class="return-details__text"
      >
        Veho home pickup
      </base-text>
    </div>
    <div
      class="return-details__row"
    >
      <base-text
        class="return-details__text"
      >
        <b>Pickup date</b>
      </base-text>
      <span
        v-if="isScheduling"
        class="return-details__pickup-scheduling"
      >
        <transition name="fadein">
          <base-loader
            v-if="isScheduling"
            size="small"
            class="return-details__pickup-scheduling__loader"
          />
        </transition>
        <base-text>
          {{ $content.moduleVehoHomePickup.pickupScheduling }}
        </base-text>
      </span>
      <base-text
        v-if="!isScheduling"
        class="return-details__text"
      >
        {{ formattedDate }}
      </base-text>
    </div>
    <div
      class="return-details__row"
    >
      <base-text
        class="return-details__text"
      >
        <b>Pickup instructions</b>
      </base-text>
      <base-text
        class="return-details__text"
      >
        Pickup option: {{ pickupOption }}
      </base-text>
      <base-text
        class="return-details__text"
      >
        Special instructions: {{ specialInstructions }}
      </base-text>
    </div>
  </div>
</template>

<script>
import { BaseText } from '@loophq/design-system';
import BaseLoader from '@/components/feedback/BaseLoader';
import firstMile from '@/js/constants/firstMile';

export default {
  components: {
    BaseText,
    BaseLoader,
  },
  props: {
    vehoData: {
      type: Object,
      required: true
    },
    isSchedulingVehoPickup: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formattedDate: '',
      pickupOption: '',
      specialInstructions: '',
    };
  },
  computed: {
    isScheduling() {
      return this.isSchedulingVehoPickup || this.$store.state.firstMile.returnMethodState === firstMile.VEHO_STATE_TIMED_OUT;
    },
  },
  created() {
    // Format the date to be displayed
    const dateArr = this.vehoData.serviceDate.split('-');
    const date = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
    this.formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

    // Pickup options and special instructions are divided by the colon before being stored
    const instructionsArr = this.vehoData.instructions.split(': ');
    this.pickupOption = instructionsArr[0];
    this.specialInstructions = instructionsArr[1];
  }
};
</script>

<style lang="scss" scoped>
$block: '.return-details';

#{$block} {
  &__row {
    margin-bottom: var(--spacing-300);
  }

  &__text {
    margin-bottom: var(--spacing-200);
  }

  &__pickup-scheduling {
    display: flex;
    align-items: center;

    &__loader {
      margin-right: var(--spacing-200);
      color: var(--primary-color);
    }
  }
}
</style>
