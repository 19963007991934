<template>
  <div class="gift-receipt-list">
    <cobranded-header
      :order-number="orderNumber"
      :center="false"
    />
    <div class="gift-receipt-list__wrapper">
      <div class="gift-receipt-list__header">
        <base-text
          tag="h2"
          type="display-large"
          class="gift-receipt-list__heading"
        >
          {{ $content.moduleGiftReceiptLookup.giftReceiptListHeading }}
        </base-text>
      </div>
      <div class="gift-receipt-list__list">
        <div v-if="giftReceipts.length !== 0">
          <gift-receipt-list-item
            v-for="(receipt, i) in giftReceipts"
            :key="i"
            :receipt="receipt"
            @set-active-receipt="setActiveReceipt"
            @set-modal-open="openModal"
          />
        </div>
        <div v-else>
          <base-text
            tag="span"
            type="display-small"
            class="gift-receipt-list__no-receipts-text"
          >
            {{ $content.moduleGiftReceiptLookup.giftReceiptListNoReceiptsText }}
          </base-text>
        </div>
      </div>
      <div class="gift-receipt-list__controls">
        <base-button
          class="gift-receipt-list__new-receipt-button"
          @click="createNewReceipt"
        >
          {{ $content.moduleGiftReceiptLookup.giftReceiptListNewReceiptButtonText }}
        </base-button>
      </div>
    </div>
    <base-modal
      :show="confirmModalOpen"
      :heading="confirmModalHeading"
      @close="closeModal"
    >
      {{ confirmModalBodyText }}
      <template #footer>
        <div class="gift-receipt-list__delete-modal-buttons">
          <base-button
            v-if="activeReceiptHasReturnedItems"
            class="modal-button okay"
            size="small"
            type="secondary"
            @click="closeModal"
          >
            Okay
          </base-button>
          <div v-else>
            <base-button
              class="modal-button cancel"
              size="small"
              type="secondary"
              @click="closeModal"
            >
              Cancel
            </base-button>
            <base-button
              class="modal-button delete"
              size="small"
              :activated="deleteLoading"
              @click="deleteReceipt"
            >
              Delete Gift Receipt
            </base-button>
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseModal,
  BaseText,
} from '@loophq/design-system';
import CobrandedHeader from './CobrandedHeader';
import GiftReceiptListItem from './GiftReceiptListItem';

import Order from '@/js/controllers/order';

export default {
  name: 'GiftReceiptList',
  components: {
    BaseButton,
    BaseModal,
    BaseText,
    CobrandedHeader,
    GiftReceiptListItem,
  },
  data() {
    return {
      deleteLoading: false,
      confirmModalOpen: false,
      activeReceipt: {},
    };
  },
  computed: {
    orderNumber() {
      return this.$store.state.giftReceipt.orderNumber;
    },
    giftReceipts() {
      return this.$store.state.giftReceipt.giftReceipts;
    },
    confirmModalHeading() {
      return `Delete ${this.activeReceipt.recipient_name}'s gift receipt`;
    },
    confirmModalBodyText() {
      return this.activeReceiptHasReturnedItems
        ? 'You can not delete this receipt because it contains items that have already been returned.'
        : `Are you sure you want to delete ${this.activeReceipt.recipient_name}'s gift receipt?`;
    },
    order() {
      return this.$store.state.giftReceipt.order;
    },
    lineItems() {
      return this.$store.state.giftReceipt.lineItems;
    },
    activeReceiptLineItems() {
      return Object.values(this.lineItems)
        .filter(item => item.gift_receipt_id === this.activeReceipt.id);
    },
    activeReceiptHasReturnedItems() {
      return this.activeReceiptLineItems.some(item => item.returned_at !== null);
    }
  },
  methods: {
    setActiveReceipt(receipt) {
      this.activeReceipt = receipt;
    },
    closeModal() {
      this.setModalOpen(false);
    },
    openModal() {
      this.setModalOpen(true);
    },
    setModalOpen(value = false) {
      this.confirmModalOpen = value;
    },
    createNewReceipt() {
      this.$store.commit('giftReceipt/setStep', 'NameInput');
    },
    async deleteReceipt() {
      this.deleteLoading = true;

      try {
        const receiptDeleted = await Order.deleteGiftReceipt({ id: this.activeReceipt.id });

        // Receipt delete failed
        if (!receiptDeleted) {
          this.$toast('Unable to delete gift receipt.');
          this.deleteLoading = false;
          this.closeModal();
          return;
        }

        // Fetch a list of gift receipts that ARE NOT the one we just deleted and then set new receipts [] in vuex
        const receipts = this.giftReceipts.filter(receipt => receipt.id !== this.activeReceipt.id);
        this.$store.commit('giftReceipt/updateGiftReceipts', receipts);

        /**
         * Refetch the order's line items so that we ensure we have an up-to-date list if the user creates
         * another gift receipt before refreshing the page
         */
        this.$store.dispatch('giftReceipt/loadLineItems');

        this.$toast('Receipt deleted.');
        this.deleteLoading = false;
        this.closeModal();
      } catch (e) {
        // Receipt delete failed
        console.error(e);
        this.$toast('Unable to delete gift receipt.');
        this.deleteLoading = false;
        this.closeModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$block: '.gift-receipt-list';

#{$block} {
  height: 100%;
  overflow: visible;

  &__delete-modal-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .modal-button {
      & + .modal-button {
        margin-left: 10px;
      }

      &.delete {
        background: var(--color-red-600);
        color: white;
      }

      &.cancel {
        background: white;
        color: black;
      }

      &.okay {
        background: $bg-blue;
        color: white;
      }
    }
  }

  :deep(.base-dialog.modal) {
    background: transparent !important;
    box-shadow: none !important;
    max-width: 100% !important;
    width: 100vw !important;
    height: 100vh !important;
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
  }

  :deep(.base-dialog__heading) {
    color: var(--grey-900);

    &::selection {
      background: var(--grey-200);
    }
  }

  :deep(.base-dialog__content) {
    padding-bottom: var(--spacing-400) !important;
    padding-top: var(--spacing-200) !important;

    &::selection {
      background: var(--grey-200);
    }
  }

  :deep(.base-dialog__footer) {
    padding-bottom: var(--spacing-300) !important;
    padding-top: var(--spacing-300) !important;
  }

  &__wrapper {
    margin-top: var(--spacing-500);
  }

  &__header {
    padding-bottom: var(--spacing-400);
  }

  &__list {
    border-top: 1px solid rgba(0, 0, 0, 70%);
    border-bottom: 1px solid rgba(0, 0, 0, 70%);
    max-height: 50vh;
    overflow: auto;
  }

  &__controls {
    padding-top: var(--spacing-500);
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media screen and (min-width: $break-small) {
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  &__new-receipt-button {
    text-transform: uppercase;
    font-weight: 700;
    display: block;
  }

  &__new-receipt-button {
    margin-left: 0;
    order: 0;
  }

  &__no-receipts-text {
    padding: var(--spacing-400);
    width: fit-content;
    display: block;
    margin: auto;
  }
}
</style>
