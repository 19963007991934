export default (callback = () => {/**/ }) => {
  if (typeof window !== 'undefined') {
    let isOnline = navigator.onLine;

    const handler = () => {
      isOnline = navigator.onLine;
      callback(isOnline);
    };

    window.addEventListener('online', handler);
    window.addEventListener('offline', handler);
  }
};
