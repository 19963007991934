import { Model, Response } from 'miragejs';
import { returns, label } from './mocks/returns';

const error404 = new Response(
  404,
  { 'Content-Type': 'application/json' },
  {
    errors: [{
      title: 'return not found',
      description: 'return could not be found'
    }]
  }
);

const serializers = (Serializer) => {
  return {
    return: Serializer.extend({
      root: false,
      embed: true
    })
  };
};

const models = () => {
  return {
    return: Model
  };
};

const factories = () => {
  return {};
};

const seed = (context) => {
  returns.forEach(item => {
    context.create('return', item);
  });
  console.info(`%c Seeded ${returns.length} example completed returns: ${returns.map((item) => item.return_key).join(', ')}`, 'color: #0045FF');
  console.info(`%c You can use these to see different return states by visiting: /return/<return_key>`, 'color: #0045FF');
};

const routes = (context) => {
  context.get('/:shopId/returns');
  context.get('/:shopId/return/:return_key', (schema, request) => {
    const return_key = request.params.return_key;

    const item = schema.returns.findBy({ return_key });
    if (item) {
      return item;
    }

    return error404;
  });
  context.post('/:shopId/return', (schema, request) => {
    const body = JSON.parse(request.requestBody);

    if (body?.lineItems?.[0]?.error) {
      return new Response(
        500,
        { 'Content-Type': 'application/json' },
        {
          errors: ['cannot return this']
        }
      );
    }

    const requiredFields = ['lineItems', 'orderId'];
    const keys = Object.keys(body);
    const missingKeys = requiredFields.filter((field) => !keys.includes(field));
    const missingValues = requiredFields.filter((field) => {
      const value = body[field];

      // Empty arrays are considered a missing value
      if (Array.isArray(value)) {
        return !value.length;
      }

      // Catch only null or undefined values
      return value == null;
    });

    if (missingKeys.length || missingValues.length) {
      return new Response(
        422,
        { 'Content-Type': 'application/json' },
        {
          errors: [...missingKeys, ...missingValues].reduce((acc, cur) => {
            acc[cur] = 'We are missing some data.';
            return acc;
          }, {})
        }
      );
    }

    const item = schema.returns.findBy({ return_key: 'normalreturn' });
    if (item) {
      return {
        return_key: item.return_key
      };
    }

    return error404;
  });
  context.get('/:shopId/return/:id/label/set/:labelId', (schema, request) => {
    const { id, labelId } = request.params;

    const item = schema.returns.findBy({ id });
    const code = item.prepaid_labels?.find((item) => +item.id === +labelId)?.tracking_code;

    if (item) {
      schema.db.returns.update(id, {
        label: {
          ...label,
          tracking_number: code
        }
      });
      return {
        ...label,
        tracking_number: code
      };
    }

    return error404;
  });
  context.get('/:shopId/return/:id/label/', (schema, request) => {
    const id = request.params.id;
    const item = schema.returns.findBy({ id });

    // Simulate a label failure for this return
    if (item.return_key === 'returnwithlabelerror' || item.return_key === 'customerinfoerror') {
      return new Response(
        500,
        { 'Content-Type': 'application/json' },
        {
          errors: [{
            title: 'label exploded',
            description: 'yeah, it exploded'
          }]
        }
      );
    }

    if (item) {
      schema.db.returns.update(id, { label });
      return item.label ? item.label : label;
    }

    return error404;
  }, { timing: 2000 });

  context.put('/:shopId/return/:id/address', (schema, request) => {
    const id = request.params.id;

    const item = schema.returns.findBy({ id });
    if (item && item.return_key !== 'customerinfoerror') {
      return true;
    }

    // Current API returns a 500 on failure
    return new Response(
      500,
      { 'Content-Type': 'application/json' },
      {
        message: 'Server Error'
      }
    );
  });

  context.post('/:shopId/return/:returnKey/returninstore', (schema, request) => {
    const return_key = request.params.returnKey;

    const item = schema.returns.findBy({ return_key });
    if (item) {
      item.update({ is_in_store_return: true });
      return true;
    }

    return error404;
  });
};

export { returns };

export default {
  serializers,
  models,
  factories,
  seed,
  routes
};
