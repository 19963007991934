<template>
  <div class="shop-later-alert">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'ShopLaterAlert',
};
</script>

<style lang="scss" scoped>
$block: '.shop-later-alert';

#{$block} {
  border-radius: 16px;
  border: 1px solid var(--grey-900);
  background-color: var(--grey-100);
  color: var(--grey-900);
  padding: var(--spacing-400);
}
</style>
