export const mapNavigation = (items, active) => {
  const navigation = items
    .map(
      ({
        id,
        provider_id: collectionId,
        tab_name: title,
        tab_order: order,
        parent_id: parent,
        products
      }) => {
        return {
          id,
          collectionId,
          link: `/shop/collection/${id}`,
          title,
          order,
          parent,
          active: id === active.id,
          products
        };
      }
    )
    // Sort order so they're ascending
    .sort((a, b) => a.order - b.order)
    .reduce((acc, item, index, arr) => {
      // Filter out children
      if (item.parent > 0) {
        return acc;
      }

      // Look for children
      const children = arr.filter(child => child.parent === item.id);
      const activeChild = children.find(child => child.active);

      // Add children to parent object if found
      const newItem = children.length ? { ...item, children } : item;

      return [
        ...acc,
        {
          ...newItem,
          active: newItem.active || activeChild
        }
      ];
    }, []);

  return navigation;
};

// Takes a mapped navigation and active id and updates the active properties
// In parents and children
export const mapActive = (items, activeId) => {
  return items.map(item => {
    const children = item.children
      ? item.children.map(child => {
        return {
          ...child,
          active: child.id === activeId
        };
      })
      : null;
    const activeChild = children?.find(child => child.active);

    return {
      ...item,
      active: item.id === activeId || activeChild,
      children
    };
  });
};

/**
 * Gets the first valid collection for shop now
 * Will filter out collections that have children, they are invalid due to the
 * accordion nav we have set up
 * @param {Object[]} collections
 */
export const getFirstCollection = (collections) => {
  const navigation = mapNavigation(
    collections,
    { id: null },
  );

  const collection = navigation.find(item => {
    return !!item.collectionId || (item.children && item.children.length);
  });

  return collection.children && collection.children.length
    ? collection.children[0]
    : collection;
};
