/**
 * Replaces mustache variables in a string, with support for ternary expressions (nested ternaries are not supported).
 * @example
 * // Returns "Replace this text"
 * formatCustomizationString('Replace {{ variable }} text', { variable: 'this' })
 * @example
 * // Returns "one fish, two fish, red fish, blue fish" because fishA is set to 'red' and fishB is set to 'blue'
 * formatCustomizationString(
 *   '{{fishA ? "one fish" : ""}}, {{fishB ? "two fish" : ""}}, {{fishA ? `${fishA} fish` : ""}}, {{fishB ? `${fishB} fish` : ""}}',
 *   { fishA: 'red', fishB: 'blue' }
 * );
 * @example
 * // Returns "one fish, red fish" because fishA is set to 'red' and fishB is not set
 * formatCustomizationString(
 *   '{{fishA ? "one fish" : ""}}, {{fishB ? "two fish" : ""}}, {{fishA ? `${fishA} fish` : ""}}, {{fishB ? `${fishB} fish` : ""}}',
 *   { fishA: 'red' }
 * );
 * @param {string} text - The text with variables in it
 * @param {object} map - A map object where the key is the variable name and the value is the value to set
 * @returns {string}
 */
export const formatCustomizationString = (text, map) => {
  // Replace ternary expressions like {{variable ? "truthy text" : "falsy text"}}
  let evaluatedText = text.replace(/{{\s*(\w+)\s*\?\s*(.*?)\s*:\s*(.*?)\s*}}/g, (_, condition, trueBranch, falseBranch) => {
    // Remove surrounding quotes/backticks from the truthy or falsy values
    const trueBranchValue = trueBranch.replace(/^["'`]\s*|\s*["'`]$/g, '');
    const falseBranchValue = falseBranch.replace(/^["'`]\s*|\s*["'`]$/g, '');

    // Determine which branch to use
    let value = map[condition] ? trueBranchValue : falseBranchValue;

    // Perform variable interpolation within the final value
    value = value.replace(/\${\s*(\w+)\s*}/g, (_, variable) => {
      return enforceValueType(map[variable]);
    });

    return enforceValueType(value);
  });

  // Replace remaining simple variables like {{brand}}, {{title}}, etc.
  evaluatedText = evaluatedText.replace(/{{\s*(\w+)\s*}}/g, (_, variable) => {
    const value = map[variable];
    return enforceValueType(value);
  });

  return evaluatedText;
};

const enforceValueType = (value) => {
  return (typeof value === 'string' || typeof value === 'number') ? value : '';
};
