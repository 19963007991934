<template>
  <div
    class="gift-layout app-background"
    :class="appStyling"
  >
    <div class="app-underlay"></div>
    <div class="app-gradient"></div>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'GiftReceiptLayout',
  computed: {
    appStyling() {
      return [
        'route-' + this.$route.name
      ];
    },
  }
};
</script>

<style lang="scss" scoped>
.gift-layout {
  overflow: auto !important;

  &.app-background.route-gift-link {
    height: 100vh !important;
  }
}

:deep(.modal-dialog__close) {
  color: var(--grey-600);
}

@media screen and (width <= 680px) {
  .default-layout-badge {
    margin-bottom: var(--spacing-400);
  }
}

:deep(.base-modal__container) {
  width: calc(100% - var(--spacing-300));
  max-height: calc(100vh - var(--spacing-300));
}
</style>
