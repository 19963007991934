<template>
  <alert-banner
    class="return-tracking-error"
    type="error"
    :inline="true"
    :heading="message.heading"
    :description="message.body"
  />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { AlertBanner } from '@loophq/design-system';

const store = useStore();

const props = defineProps({
  isExchange: {
    type: Boolean,
    default: false,
  }
});

const message = computed(() => {
  if (props.isExchange) {
    return {
      heading: store.getters['content']?.moduleExchangeTracking.terminalErrorHeading,
      body: store.getters['content']?.moduleExchangeTracking.terminalErrorBody
    };
  }

  return {
    heading: store.getters['content']?.moduleReturnTracking.terminalErrorHeading,
    body: store.getters['content']?.moduleReturnTracking.terminalErrorBody
  };
});
</script>

<style lang="scss" scoped>
$block: '.return-tracking-error';

#{$block} {
  background-color: var(--color-red-200);
  padding: var(--spacing-200);
  margin: var(--spacing-100) 0 var(--spacing-200);

  .base-text {
    color: black;
  }
}
</style>
