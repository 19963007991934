/**
 * Replace {{ placeholders }} in a string with the value of the property
 * of the passed in object that matches the placeholder name. If the property
 * does not exist, it will use the placeholder name instead.
 * 
 * @example
 * const object = { title: 'Envy by Turk' }
 * replacePlaceholderUsingObject('{{ title }} is {{ awesome }}', object); // 'Envy by Turk is awesome'
 * 
 * @param templateString string with placeholders in curly braces e.g. {{ title }}
 * @param obj object with properties to use
 * @returns replaced string
 */
export const replacePlaceholderUsingObject = (templateString, obj) => {
  return templateString.replace(/{{\s*(\w+)\s*}}/g, (_, p1) => obj[p1] ?? p1);
};
