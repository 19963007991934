<template>
  <div class="product-recommendations">
    <div class="product-recommendations__title">
      <base-text
        class="product-recommendations__product-title"
        tag="h2"
        type="display-medium"
      >
        <render-content>
          {{ $content.moduleTrackingProductRecommendations.heading }}
        </render-content>
      </base-text>
    </div>
    <div class="product-recommendations__recommendations-section">
      <div
        v-for="(recommendation, idx) in props.recommendationsList"
        :key="'recommendation-' + idx"
        class="product-recommendations__recommendation-block"
        @click="recommendedProductClicked(recommendation.id)"
      >
        <div class="product-recommendations__images">
          <div>
            <a
              :href="(! recommendation.link) ? shopLink : recommendation.link"
              target="_blank"
            >
              <img
                :src="(! recommendation.image?.src) ? '/img/icons/no-image.svg' : recommendation.image?.src"
                :alt="recommendation.title"
                class="product-recommendations__image"
              />
            </a>
            <div class="product-recommendations__product-details">
              <base-text
                class="product-recommendations__product-title"
                type="body"
              >
                {{ recommendation.title }}
              </base-text>
              <base-text
                class="product-recommendations__product-variant-title"
                type="body"
              >
                {{ recommendation.variant }}
              </base-text>
              <base-text
                class="product-recommendations__product-variant-price"
                type="body"
              >
                <display-price :amount="recommendation.price" />
              </base-text>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-recommendations__cta">
      <base-button
        class="product-recommendations__button-link"
        data-testid="product-recommendations_btn"
        icon="external-link"
        size="small"
        type="primary"
        target="_blank"
        :to="shopLink"
        @click="moreProductsClicked"
      >
        {{ $content.moduleTrackingProductRecommendations.moreProducts }}
      </base-button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { BaseButton, BaseText } from '@loophq/design-system';
import DisplayPrice from '@/components/globals/DisplayPrice.vue';
import { track } from '@/js/segment';
import { orderTrackingEvents } from '@/js/constants/segment';

const props = defineProps({
  recommendationsList: {
    type: Array
  }
});

const store = useStore();

const analyticsData = computed(() => {
  return store.getters['analytics/getData'];
});

const shopLink = computed(() => {
  if (store.getters.content?.pageLookup.shopLink) {
    return store.getters.content.pageLookup.shopLink;
  }

  return `https://${store.getters.shop.shop_domain}`;
});

const recommendedProductClicked = (shopifyProductId) => {
  track(orderTrackingEvents.RECOMMENDED_PRODUCT_CLICKED, {
    ...analyticsData.value,
    shopifyProductId
  });
};

const moreProductsClicked = () => {
  track(orderTrackingEvents.MORE_PRODUCTS_CLICKED, {
    ...analyticsData.value,
  });
};

</script>

<style lang="scss" scoped>
$block: '.product-recommendations';
#{$block} {
  overflow-x: auto;
  width: 100%;
  max-width: 980px;

  &__title h2 {
    text-align: left;
  }

  &__product-title,
  &__product-variant-price {
    color: #000;
    font-weight: 500;
  }

  &__recommendations-section {
    padding: var(--spacing-500) 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: var(--spacing-200);
    border-bottom: 1px solid #d9d9d9;
  }

  &__recommendation-block {
    margin: auto;
  }

  &__image {
    width: 240px;
    border: 1px solid #d9d9d9;
    border-radius: var(--corners);
    overflow: hidden;
  }

  &__cta {
    text-align: center;
    margin-top: var(--spacing-500);
  }

  &__button-link {
    flex-direction: row-reverse;
    margin-right: auto;

    :deep(.base-button__text) {
      margin-left: 0;
      margin-right: var(--spacing-200);
      color: var(--button-text-color);
    }
  }
}

@media screen and (width <= 680px) {
  #{$block} {
    &__title h2 {
      text-align: center;
      margin: var(--spacing-400) 0;
    }

    &__recommendations-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__recommendation-block {
      margin-bottom: var(--spacing-400);
    }

    &__cta {
      margin: var(--spacing-400) 0;
    }
  }
}
</style>
