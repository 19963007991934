<template>
  <div class="gift-share-modal">
    <div class="gift-share-modal__link-copy">
      <base-input
        v-model="shareLink"
        label=""
        class="gift-share-modal__deeplink-input"
        readonly
      />
      <base-button
        type="icon"
        icon="chain-link"
        class="gift-share-modal__copy-button"
        @click.prevent="copyDeeplinkUrl"
      />
    </div>
    <div class="gift-share-modal__text-email">
      <div class="gift-share-modal__email">
        <base-input
          v-model="emailAddress"
          class="gift-share-modal__email-input"
          label="Email address"
        />
        <base-button
          type="primary"
          class="gift-share-modal__email-button"
          placeholder="Email address"
          :activated="emailLoading"
          @click.prevent="handleShareReceipt"
        >
          {{ $content.moduleGiftReceiptLookup.shareEmailButtonText }}
        </base-button>
      </div>
      <div
        v-if="emailError"
        class="gift-share-modal__errors"
      >
        <base-text type="body">
          {{ emailError }}
        </base-text>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseIcon,
  BaseInput,
  BaseText,
} from '@loophq/design-system';
import Order from '@/js/controllers/order';

export default {
  name: 'GiftShareModal',
  components: {
    BaseButton,
    BaseIcon,
    BaseInput,
    BaseText,
  },
  props: {
    recipientName: {
      type: String,
      required: true,
    },
    receiptSent: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['set-receipt-sent'],
  data() {
    return {
      emailAddress: '',
      emailError: null,
      emailLoading: false,
    };
  },
  computed: {
    store() {
      return this.$store.state.giftReceipt;
    },
    shareLink() {
      return this.store.activeReceipt?.deep_link_url;
    },
    giftReceiptId() {
      return this.store.activeReceipt?.id ?? '';
    }
  },
  methods: {
    validateEmail() {
      // TODO: Swap this to vee-validate once it gets merged from the Vue3 branch
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      // email input value is empty
      if (!this.emailAddress.length) {
        this.emailError = 'Please enter an email address to share this gift receipt.';
        return false;
      }

      // email doesn't match regex
      if (!this.emailAddress.toLowerCase().match(emailRegex)) {
        this.emailError = 'Please make sure the email address you entered is valid.';
        return false;
      }

      return true;
    },
    async handleShareReceipt() {
      try {
        this.emailError = null;
        this.emailLoading = true;

        const emailValid = this.validateEmail();

        if (!emailValid) {
          this.emailLoading = false;
          return;
        }

        const payload = {
          recipient_name: this.recipientName,
          giftReceiptId: this.giftReceiptId,
        };

        payload.email = this.emailAddress;

        const receiptSent = await Order.shareGiftReceipt(payload);

        if (receiptSent) {
          this.$emit('set-receipt-sent', true);
          this.emailLoading = false;
        }
      } catch (err) {
        console.error(err);
        this.emailLoading = false;
      }
    },
    copyDeeplinkUrl() {
      const urlInputRef = this.$refs.deeplinkRef;
      const copyValue = this.shareLink;

      if (navigator) {
        navigator.clipboard.writeText(copyValue);
      } else {
        urlInputRef.$el.focus();
        document.execCommand('copy');
      }

      this.$store.dispatch(
        'toast',
        { message: 'Link copied to clipboard', type: 'success' }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.gift-share-modal';

#{$block} {
  padding: 0 var(--spacing-300);
  padding-top: var(--spacing-400);
  margin-bottom: calc(-1 * var(--spacing-600));

  @media screen and (min-width: $break-small) {
    padding: 0;
  }

  &__link-copy {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__deeplink-input {
    width: 100%;

    :deep(.input-wrapper__label) {
      display: none !important;
    }
  }

  &__copy-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2%;
    padding: var(--spacing-200) !important;
    background-color: rgba(255, 255, 255, 85%) !important;

    :deep(.base-icon) {
      position: relative;
      top: 3px;
      left: 3px;
    }
  }

  &__text-email {
    margin-top: var(--spacing-600);
    padding-top: var(--spacing-600);
    border-top: 2px solid #f1f1f1;

    :deep(.input-wrapper__label::selection) {
      background: var(--grey-200) !important;
    }
  }

  &__text-button,
  &__email-button {
    text-transform: uppercase;
    white-space: nowrap;
  }

  &__text,
  &__email {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: var(--spacing-400);
    gap: var(--spacing-200);

    &-input {
      flex: 1;

      @media screen and (min-width: $break-large) {
        margin-right: var(--spacing-300);
      }
    }

    &-button {
      width: 100%;
      display: block;

      @media screen and (min-width: $break-large) {
        width: 140px;
      }
    }

    :deep(input.base-input__input::selection) {
      background: var(--grey-200);
    }
  }

  &__errors {
    margin-top: -0.5rem;
    margin-bottom: var(--spacing-400);
  }
}
</style>
