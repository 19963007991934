<template>
  <transition name="cancelled-banner">
    <base-card
      class="cancelled-banner"
    >
      <div class="cancelled-banner__icon">
        <base-icon
          name="warning"
        />
      </div>
      <div class="cancelled-banner__messages">
        <base-text
          class="cancelled-banner__heading"
          tag="h2"
          type="display-medium"
        >
          <render-content>
            {{ $content.moduleCancelBanner.heading }}
          </render-content>
        </base-text>
        <base-text
          class="cancelled-banner__message"
        >
          <render-content>
            {{ $content.moduleCancelBanner.message }}
          </render-content>
        </base-text>
      </div>
    </base-card>
  </transition>
</template>

<script>
import {
  BaseCard,
  BaseIcon,
  BaseText,
} from '@loophq/design-system';

export default {
  name: 'CancelledBanner',
  components: {
    BaseCard,
    BaseIcon,
    BaseText,
  },
};
</script>

<style lang="scss" scoped>
$block: '.cancelled-banner';

#{$block} {
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  border: 4px solid var(--yellow-500);

  &__icon {
    flex-shrink: 0;
    padding: 0.75rem;
    margin-right: var(--spacing-400);
    border: 2px solid var(--yellow-500);
    border-radius: 50%;
  }

  &__messages {
    flex-basis: 50%;
    flex-grow: 1;
    margin-right: var(--spacing-600);
  }

  &__message {
    margin-top: var(--spacing-200);
    color: var(--grey-700);
  }
}

@media screen and (max-width: $break-medium) {
  #{$block} {
    flex-direction: column;

    &__icon {
      margin-bottom: var(--spacing-400);
      margin-right: 0;
    }

    &__messages {
      margin-bottom: var(--spacing-400);
      margin-right: 0;
    }
  }
}

@media screen and (max-width: $break-tiny) {
  #{$block} {
    border-radius: 0;
    border-right: none;
    border-left: none;
    padding: var(--spacing-500) var(--spacing-400);
  }
}
</style>
