<template>
  <div class="gift-lookup-page">
    <div class="gift-lookup-page__content">
      <component
        :is="step"
        :key="step"
        class="gift-lookup-page__active-component"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GiftReceiptLookup from './GiftReceiptLookup';
import GiftReceiptList from './GiftReceiptList';
import LineItemSelection from './LineItemSelection';
import NameInput from './NameInput';
import SharePage from './SharePage';

export default {
  name: 'GiftReceiptLookupPage',
  components: {
    GiftReceiptList,
    GiftReceiptLookup,
    LineItemSelection,
    NameInput,
    SharePage
  },
  computed: {
    ...mapState('giftReceipt', {
      step: state => state.step
    }),
  },
  mounted() {
    const { query } = this.$route;

    if (query.order) {
      this.$store.commit('giftReceipt/setOrderNumber', query.order);
    }

    if (query.zip) {
      this.$store.commit('giftReceipt/setZip', query.zip);
    }
  },
};
</script>

<style lang="scss" scoped>
.gift-lookup-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-400);
  background-image: var(--theme-background-image);
  background-size: var(--theme-background-size);
  background-position: var(--theme-background-position);

  @media screen and (max-width: $break-small) {
    padding: var(--spacing-300);
  }

  &__content {
    max-width: 1250px;
    width: 100%;
    padding: calc(var(--spacing-500) - 2px) calc(var(--spacing-400) - 4px);
    border: 2px solid black;
    background: white;
    z-index: 10;
    box-shadow: 10px 10px 0 0 black;

    @media screen and (min-width: $break-small) {
      padding: var(--spacing-700);
    }
  }
}
</style>
