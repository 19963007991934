import axios from 'axios';
import store from '@/store';

export default {
  update(cart) {
    const shopId = store.state.shopContents.id;
    const token = store.state.cartToken;
    return axios.post(`api/v1/${shopId}/cart/${token}`, { cart });
  }
};
