import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default function useShopLater(item) {
  const route = useRoute();

  const store = useStore();

  const showShopLaterExpiredBanner = computed(() => {
    return route.query.shoplater === 'true' && item.value?.shopLater?.state === 'expired';
  });

  const hasShopLaterSetting = computed(() => {
    return store.getters.settings.shopLaterEnabled;
  });

  const inlineShopLaterOfferVisible = computed(() => {
    return hasShopLaterSetting.value && shopLaterActive.value;
  });

  const shopLaterActive = computed(() => {
    if (!shopLaterRecord.value) return false;

    const {
      state: shopLaterState,
      giftCardEnabled,
      exchangesEnabled,
    } = shopLaterRecord.value;

    return shopLaterState === 'active' && (giftCardEnabled || exchangesEnabled);
  });

  const shopLaterRecord = computed(() => {
    return item.value?.shopLater;
  });

  return {
    hasShopLaterSetting,
    inlineShopLaterOfferVisible,
    shopLaterActive,
    showShopLaterExpiredBanner,
    shopLaterRecord,
  };
}
