<template>
  <div class="accordion-card">
    <base-accordion
      class="accordion-card__accordion"
      :expanded="expanded"
      @toggle="handleToggle"
    >
      <template v-slot:toggle>
        <base-button
          type="text"
          :icon="icon"
        >
          {{ heading }}
        </base-button>
      </template>
      <div class="accordion-card__content">
        <slot></slot>
      </div>
    </base-accordion>
  </div>
</template>

<script>
import { BaseButton, BaseAccordion } from '@loophq/design-system';

export default {
  components: {
    BaseAccordion,
    BaseButton,
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    expanded: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      icon: 'down'
    };
  },
  created() {
    if (this.expanded) {
      this.icon = 'up';
    }
  },
  methods: {
    handleToggle(isActive) {
      if (isActive) {
        this.icon = 'up';
      } else {
        this.icon = 'down';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.accordion-card';

#{$block} {
  border: 1px solid var(--grey-200);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 10%);
  border-radius: var(--spacing-200);

  &__accordion {
    :deep(.base-accordion__toggle) {
      width: 100%;

      .base-button {
        width: 100%;
        padding: var(--spacing-400) var(--spacing-500);
        display: flex;
        flex-direction: row-reverse;
      }

      .base-button:hover {
        background: #fff;
      }

      .base-button__text {
        text-align: start;

        /* Display Medium */
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
      }

      .base-button__icon {
        --icon-color: var(--primary-color);
      }

      .base-button__icon + .base-button__text {
        margin-left: 0;
      }
    }
  }

  &__content {
    padding: var(--spacing-400) var(--spacing-500);
    border-top: 2px solid var(--grey-200);

    @media screen and (max-width: $break-small) {
      padding: 0;
    }
  }
}
</style>
