<template>
  <div class="return-code">
    <div
      v-if="!showQrButton"
      class="return-code__code"
      data-testid="qr-code-button"
    >
      <img
        class="return-code__code-image"
        :src="codeUrl"
        :alt="contentModule.qrAltText"
      />
    </div>
    <div class="return-code__actions">
      <div
        class="return-code__actions-buttons"
        :class="{ stacked: packingSlipsEnabled && !showQrButton }"
      >
        <base-button
          v-if="showQrButton"
          v-track="'show qr button clicked'"
          class="return-code__qr-button"
          icon="external-link"
          target="_blank"
          rel="noopener"
          :to="codeUrl"
        >
          {{ $content.moduleReturnCode.qrButton }}
        </base-button>
        <slot
          name="packingSlip"
          classes="return-code__packing-slip-button"
          :type="showQrButton ? 'secondary' : 'primary'"
        />
        <base-button
          v-if="!showQrButton || !packingSlipsEnabled"
          v-track="'find location clicked'"
          class="return-code__location-button"
          :type="(showQrButton || packingSlipsEnabled) ? 'secondary' : 'primary'"
          icon="location"
          target="_blank"
          rel="noopener"
          :to="locationUrl"
        >
          {{ contentModule.cta }}
        </base-button>
      </div>
      <div class="return-code__links">
        <base-button
          v-if="showQrButton && packingSlipsEnabled"
          v-track="'find location clicked'"
          class="return-code__links-location"
          type="link"
          target="_blank"
          rel="noopener"
          :to="locationUrl"
        >
          {{ contentModule.cta }}
        </base-button>
        <base-button
          v-if="showButtonToLabel"
          class="return-code__links-label"
          type="link"
          data-testid="generate-label-button"
          @click="generateLabel"
        >
          {{ contentModule.noLabel }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
} from '@loophq/design-system';
import { views } from './actionMaps';
import { carriers } from '@/js/constants/carriers';
import firstMile from '@/js/constants/firstMile';

export default {
  components: {
    BaseButton,
  },
  props: {
    label: {
      type: Object,
      required: true,
    },
    address: {
      type: Object,
      required: true,
    },
    labelsEnabled: {
      type: Boolean,
      required: false
    },
    qrCodeUrl: {
      type: String,
      required: false
    },
    returnKey: {
      type: String,
      required: true
    },
    trackingUrl: {
      type: String,
      required: false,
    },
    packingSlipsEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  emits: ['toggleView', 'showQr'],
  data() {
    return {
      locationUrl: '',
      contentModule: {},
    };
  },
  computed: {
    isUsps() {
      return this.label.carrier === carriers.USPS || this.label.carrier === carriers.USPSRETURNS;
    },
    isInpost() {
      return this.label.carrier === carriers.INPOST;
    },
    isAsda() {
      return this.label.carrier === carriers.ASDA;
    },
    isEvri() {
      return this.label.carrier === carriers.EVRI || (this.isSblww && this.label.carrier.includes('Evri'));
    },
    isDHLSeko() {
      return this.label.carrier === carriers.DHLSEKO;
    },
    isCanadaPost() {
      return this.label.carrier === carriers.CANADA_POST || (this.isSblww && this.label.carrier.includes('Canada Post'));
    },
    isRoyalMail() {
      return this.label.carrier === carriers.ROYAL_MAIL;
    },
    isNewgistics() {
      return this.label.carrier.toLowerCase() === carriers.NEWGISTICS;
    },
    isSblww() {
      return this.label.labelUrl?.includes('easyship');
    },
    codeUrl() {
      return this.qrCodeUrl || this.label.qrCodeUrl;
    },
    showQrButton() {
      return this.codeUrl.toLowerCase().endsWith('.pdf');
    },
    showButtonToLabel() {
      const singleChoiceReturnMethods = firstMile.SINGLE_CHOICE_RETURN_METHODS.map(element => element.toLowerCase());
      const labelCarrier = this.label.carrier.toLowerCase();
      return this.labelsEnabled && !singleChoiceReturnMethods.includes(labelCarrier);
    }
  },
  created() {
    if (this.isSblww) {
      this.$emit('showQr', true);
    }

    if (this.isUsps || this.isDHLSeko || this.isNewgistics) {
      this.contentModule = this.$content.moduleUspsReturnCode;
      this.locationUrl = 'https://tools.usps.com/find-location.htm?locationType=po&serviceType=lbroretail'; // Redirect to USPS LBRO
    } else if (this.isInpost) {
      this.contentModule = this.$content.moduleInpostReturnCode;
      this.locationUrl = 'https://inpost.co.uk/lockers';
    } else if (this.isAsda) {
      this.contentModule = this.$content.moduleAsdaReturnCode;
      this.locationUrl = 'https://storelocator.asda.com';
    } else if (this.isEvri) {
      this.contentModule = this.$content.moduleEvriReturnCode;
      this.locationUrl = 'https://www.evri.com/find-a-parcelshop';
    } else if (this.isCanadaPost) {
      this.contentModule = this.$content.moduleCanadaPostReturnCode;
      this.locationUrl = 'https://www.canadapost.ca/cpotools/apps/fpo/personal/findPostOffice';
    } else if (this.isRoyalMail) {
      this.contentModule = this.$content.moduleRoyalMailReturnCode;
      this.locationUrl = 'https://www.royalmail.com/services-near-you#/';
    } else {
      this.contentModule = this.$content.moduleReturnCode;
      this.locationUrl = 'http://fedex.com/easyreturns'; // this link will redirect to the local fedex map based on location
    }
  },
  methods: {
    generateLabel() {
      this.$emit('toggleView', views.QR_CODE);
      this.$trackEvent('requesting new label instead of return code');
    },
    formatForURL(str) {
      return encodeURI(str.replace(/\s+/g, '-').toLowerCase());
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.return-code';

#{$block} {
  display: flex;

  &__code {
    flex-shrink: 0;
    border-radius: calc(var(--corners) * 2);
    padding: var(--spacing-300);
    margin-right: var(--spacing-500);
    background-color: var(--grey-100);
    max-width: 200px;
  }

  &__code-image {
    width: 100%;
  }

  &__code-text {
    display: flex;
    justify-content: center;
    color: var(--heading-color);
  }

  &__actions {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    :deep(.base-button__text) {
      width: auto;
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      width: 100%;

      &.stacked {
        flex-direction: column;

        #{$block}__packing-slip-button, :slotted(.return-code__packing-slip-button) {
          margin-bottom: var(--spacing-300);
        }
      }
    }
  }

  &__packing-slip-button,
  :slotted(.return-code__packing-slip-button) {
    width: 100%;
  }

  &__location-button {
    width: 100%;
  }

  &__links {
    display: flex;
    margin-top: var(--spacing-300);

    &-location {
      align-self: flex-start;
      margin-right: var(--spacing-400);
    }

    &-label {
      align-self: flex-start;
      width: auto;
      margin-right: var(--spacing-400);
    }

    &-tracking {
      align-self: flex-start;
      width: auto;
    }
  }

  &__qr-button {
    display: flex;
    flex-direction: row-reverse;
    margin-right: var(--spacing-400);
    width: 100%;

    :deep(.base-button__icon) {
      width: 1rem;
      margin-left: var(--spacing-200);
    }
  }
}

@mixin return-code-mobile {
  flex-direction: column;
  align-items: center;

  &__actions {
    min-width: 100%;
    margin-left: 0;
    margin-top: var(--spacing-300);

    &-buttons {
      flex-direction: column;
    }
  }

  &__qr-button {
    margin-bottom: var(--spacing-300);
  }

  &__links {
    flex-direction: column;
    margin-top: var(--spacing-400);

    &-location,
    &-label {
      margin-bottom: var(--spacing-300);
    }
  }
}

@media screen and (width <= 770px) {
  .return-code {
    @include return-code-mobile;
  }
}

@media only screen and (width >= 960px) and (width <= 1200px) {
  .return-code {
    @include return-code-mobile;
  }
}
</style>
