<template>
  <div class="veho-date-picker">
    <div
      ref="container"
      class="veho-date-picker__date-container"
      @scroll="updateScrollBtns()"
    >
      <div
        v-for="(date, idx) in dates"
        :key="idx"
        class="veho-date-picker__date-card"
        :class="{ selected: dateSelected === date, disabled: dateDisabled[idx] }"
        @click="handleDateSelect(date.toString())"
      >
        <radio-input
          v-model="dateSelected"
          class="veho-date-picker__date-radio"
          :value="date.toString()"
        >
          <base-text
            class="veho-date-picker__date-month"
            :class="{ disabled: dateDisabled[idx] }"
            type="subheading"
          >
            {{ monthMap[date.getMonth()] }}
          </base-text>
          <base-text
            class="veho-date-picker__date-num"
            :class="setDayOfWeekClass(dateDisabled[idx], date)"
            type="display-medium"
          >
            {{ date.getDate() }}
          </base-text>
          <base-text
            class="veho-date-picker__date-day"
            :class="{ disabled: dateDisabled[idx] }"
            type="caption"
          >
            {{ dayMap[date.getDay()] }}
          </base-text>
        </radio-input>
      </div>
    </div>
    <div class="veho-date-picker__scroll-btn-container">
      <div
        class="veho-date-picker__scroll-btn"
        :class="{ active: scrollLeftBtnIsActive }"
        @click.stop.prevent="handleScrollLeft()"
      >
        <base-icon
          class="veho-date-picker__scroll-btn-icon"
          name="chevron-left"
        />
      </div>
      <div
        class="veho-date-picker__scroll-btn"
        :class="{ active: scrollRightBtnIsActive }"
        @click.stop.prevent="handleScrollRight()"
      >
        <base-icon
          class="veho-date-picker__scroll-btn-icon"
          name="chevron-right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseIcon,
  BaseText,
  RadioInput,
} from '@loophq/design-system';
import firstMile from '@/js/constants/firstMile';

export default {
  name: 'VehoDatePicker',
  components: {
    BaseIcon,
    BaseText,
    RadioInput,
  },
  emits: [
    'selected'
  ],
  data() {
    return {
      dates: [],
      dateSelected: '',
      dateDisabled: [],
      monthMap: [
        'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
      ],
      dayMap: [
        'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
      ],
      scrollLeftBtnIsActive: false,
      scrollRightBtnIsActive: true,
    };
  },
  created() {
    this.dateDisabled = new Array(firstMile.VEHO_SCHEDULABLE_DAYS).fill(false);

    // Veho halts scheduling next-day pickups at 6PM EST
    const now = new Date();
    const schedulableTimeLimit = new Date();
    schedulableTimeLimit.setUTCHours(22, 0, 0, 0);

    if (now.getDate() !== now.getUTCDate()) { // Sync dates
      schedulableTimeLimit.setDate(now.getDate());
    }

    const dateOffset = now < schedulableTimeLimit ? 0 : 1;

    // Fill in dates array (7 days starting tomorrow)
    const datesArr = [];
    for (let i = 1; i <= firstMile.VEHO_SCHEDULABLE_DAYS; i++) {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() + i + dateOffset);
      datesArr.push(date);

      // Determine non-working holiday
      const month = date.getMonth();
      const dateNum = date.getDate();
      const week = Math.floor((dateNum - 1) / 7) + 1;
      const day = date.getDay();

      const key = month + ',' + dateNum;
      const weekBasedKey = month + ',' + week + ',' + day;
      this.dateDisabled[i - 1] = firstMile.VEHO_HOLIDAYS[key] || firstMile.VEHO_HOLIDAYS[weekBasedKey] ||
        // prevent weekend scheduling
        day === 0 || day === 6;
    }
    this.dates = datesArr;
  },
  methods: {
    getMonth(idx) {
      return this.monthMap[idx];
    },
    getDay(idx) {
      return this.dayMap[idx];
    },
    handleDateSelect(date) {
      this.dateSelected = date;
      this.$emit('selected', new Date(date));
    },
    handleScrollRight() {
      const container = this.$refs.container;

      container.scrollTo({
        left: container.scrollWidth,
        behavior: 'smooth',
      });

      this.updateScrollBtns();
    },
    handleScrollLeft() {
      const container = this.$refs.container;

      this.$refs.container.scrollTo({
        left: -container.scrollWidth,
        behavior: 'smooth',
      });
    },
    updateScrollBtns() {
      const container = this.$refs.container;
      const maxScroll = container.scrollWidth - container.clientWidth - 1;

      if (container.scrollLeft === 0) {
        this.scrollLeftBtnIsActive = false;
        this.scrollRightBtnIsActive = true;
      } else if (container.scrollLeft >= maxScroll) {
        this.scrollLeftBtnIsActive = true;
        this.scrollRightBtnIsActive = false;
      } else {
        this.scrollLeftBtnIsActive = true;
        this.scrollRightBtnIsActive = true;
      }
    },
    setDayOfWeekClass(disabled, date) {
      const dayOfWeek = date.toString().split(' ')[0]?.toLowerCase();
      return disabled ? `disabled ${dayOfWeek}` : dayOfWeek;
    },
  },
};
</script>

<style lang="scss">
$block: '.veho-date-picker';

#{$block} {
  max-width: 568px;

  &__date {
    &-container {
      display: flex;
      justify-content: space-between;
      padding-bottom: var(--spacing-200);
      overflow-x: auto;
      scrollbar-width: none;
    }

    // Hide scrollbar
    &-container::-webkit-scrollbar {
      display: none;
    }

    &-card {
      position: relative;
      min-width: 7.5rem;
      height: var(--spacing-900);
      padding: var(--spacing-300) !important;
      margin-right: var(--spacing-200);
      box-shadow: none;
      background-color: white;
      border: 1px solid var(--grey-200);
      border-radius: 8px;

      &.selected {
        background: var(--grey-100);
        border: 1px solid var(--theme-primary-color);
      }

      &.disabled {
        background: var(--grey-200);
        pointer-events: none;
      }

      .radio-input__input {
        position: relative !important;
        float: right;
        height: var(--spacing-400);
        width: var(--spacing-400);
        clip: auto;
        margin: 0;
      }

      .radio-input__label {
        height: -webkit-fill-available;
        height: -moz-available;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &-card:hover {
      background-color: var(--grey-100);
    }

    &-card:active {
      background-color: var(--grey-200);
    }

    &-card:focus-within {
      border: 2px solid var(--grey-300);
    }

    &-month {
      &.disabled {
        color: var(--grey-600);
      }
    }

    &-num {
      &.disabled {
        text-decoration-line: line-through;
        color: var(--grey-600);
      }
    }

    &-day {
      &.disabled {
        color: var(--grey-700);
        pointer-events: none;
      }
    }
  }

  &__scroll-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--spacing-300);
  }

  &__scroll-btn {
    border: 1px solid var(--grey-200);
    border-radius: 8px;
    background: var(--grey-200);
    padding: var(--spacing-200);
    margin-left: var(--spacing-200);

    &.active {
      background: white;
    }

    &.active:hover {
      background: var(--grey-100);
    }
  }

  &__scroll-btn-icon {
    width: var(--spacing-300) !important;
    height: auto !important;
    color: black;

    &.disabled {
      color: var(--grey-600);
    }
  }
}

@media screen and (max-width: $break-small) {
  .veho-date-picker {
    width: auto;
  }
}
</style>
