<template>
  <li class="collection-item">
    <router-link
      v-track="'shop now product viewed'"
      class="collection-item__link"
      :to="link"
    >
      <span class="collection-item__image">
        <img
          :src="image"
          :alt="displayTitle"
          class="collection-item__img"
        >
      </span>
      <span class="collection-item__info">
        <span class="collection-item__title">
          {{ displayTitle }}
        </span>
        <span
          :class="isDiscounted ? 'original' : ''"
          class="collection-item__price"
        >
          <display-price :amount="product.price" />
        </span>
        <span
          v-if="isDiscounted"
          :class="isDiscounted ? 'discounted' : ''"
          class="collection-item__price"
        >
          <display-price :amount="product.discounted_price" />
        </span>
      </span>
    </router-link>
  </li>
</template>

<script>
import { replacePlaceholderUsingObject } from '@/js/utility/replacePlaceholderUsingObject';
import DisplayPrice from '@/components/globals/DisplayPrice.vue';

export default {
  components: {
    DisplayPrice
  },
  props: {
    collection: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    link() {
      return {
        path: `/shop/collection/${this.collection.id}/product/${this.product.id}/`,
        query: this.$route.query
      };
    },
    image() {
      if (this.product.image) {
        return this.product.image.src ? this.product.image.src : this.product.image;
      }

      return '/img/icons/no-image.svg';
    },
    displayTitle() {
      return replacePlaceholderUsingObject(this.$content.global.productTitle, this.product);
    },
    isDiscounted() {
      if (this.product.discounted_price) {
        return this.product.price > this.product.discounted_price;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.collection-item';

#{$block} {
  .original {
    text-decoration: line-through;
  }

  &__link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__image {
    background-color: var(--product-background-color);
    flex: auto;
  }

  &__img {
    width: 100%;
    object-fit: var(--product-image-fit, contain);
    object-position: var(--product-image-position);
    border-radius: calc(var(--theme-corners) / 2);
    height: 100%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
  }

  &__title {
    text-align: left;
    font-size: 0.9375rem;
    margin-bottom: 0.5rem;
    line-height: 1.25;
    font-weight: var(--theme-heading-weight, 500);
    color: var(--theme-heading-color);
  }

  &__price {
    color: var(--theme-body-color);
    font-size: 0.9375rem;
  }
}
</style>
