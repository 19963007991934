/*eslint-disable no-empty-function */
import { Response } from 'miragejs';
import {
  totals,
  totalsScenarios,
  buildReceipt
} from './mocks/totals';

const serializers = () => {
};

const models = () => {
};

const factories = () => {
  return {};
};

const seed = () => {
};

const routes = (context) => {
  context.post('/:shopId/return/totals', (schema, request) => {
    const body = JSON.parse(request.requestBody);

    if (!body.lineItems?.length) {
      return new Response(
        422,
        { 'Content-Type': 'application/json' },
        {
          'errors': [
            'There must be at least one item to return.'
          ]
        }
      );
    }

    const scenarioName = localStorage.getItem('loop-dev-totals-scenario');
    const scenario = totalsScenarios.find((total) => total.name === scenarioName);
    return {
      ...totals,
      ...(scenario ?? {}),
      receipt: buildReceipt(body, scenario?.receipt ?? totals.receipt, scenario?.useReceiptFromPayload)
    };
  });
};

export default {
  serializers,
  models,
  factories,
  seed,
  routes
};
