<template>
  <page-container>
    <base-card class="locator__card">
      <header class="locator__header">
        <base-text
          class="locator__heading"
          tag="h2"
          type="display-medium"
        >
          <render-content>
            {{ $content.moduleStoreLocator.heading }}
          </render-content>
        </base-text>
        <base-text
          class="locator__subheading"
        >
          <render-content>
            {{ $content.moduleStoreLocator.subheading }}
          </render-content>
        </base-text>
      </header>
      <section class="locator__body">
        <address class="locator__address">
          <base-text
            class="address-line"
            type="display-small"
          >
            {{ location.addressLine1 }}
          </base-text>
          <base-text
            class="address-line"
            type="display-small"
          >
            {{ location.addressLine2 }}
          </base-text>
        </address>
        <base-text class="locator__distance">
          <render-content :data="{ distance: location.distance }">
            {{ $content.moduleStoreLocator.distance }}
          </render-content>
        </base-text>
        <section class="locator__opt-in">
          <base-button
            v-track="'get directions clicked'"
            class="locator__directions"
            size="small"
            :to="location.mapLink"
            target="_blank"
            rel="noopener"
          >
            <render-content>
              {{ $content.moduleStoreLocator.directions }}
            </render-content>
          </base-button>
          <base-button
            class="opt-out"
            type="link"
            @click.prevent="next"
          >
            <span class="opt-out__label">
              <render-content>
                {{ $content.moduleStoreLocator.optOut }}
              </render-content>
              <base-icon
                class="opt-out__arrow"
                name="arrow-right"
                aria-hidden="true"
              />
            </span>
          </base-button>
        </section>
      </section>
    </base-card>
  </page-container>
</template>

<script>
import {
  BaseCard,
  BaseText,
  BaseButton,
  BaseIcon,
} from '@loophq/design-system';
import PageContainer from '@/components/layout/PageContainer';
import Order from '@/js/controllers/order';
import { formatToLength } from '@/js/helpers/formatNumber';
import { formatSplitAddress, createMapLink } from '@/js/helpers/formatAddress';

export default {
  components: {
    BaseCard,
    BaseText,
    BaseButton,
    BaseIcon,
    PageContainer,
  },
  props: {
    stores: {
      type: Array,
      required: true
    }
  },
  emits: [
    'success',
  ],
  computed: {
    orderId() {
      return this.$store.getters.order.id;
    },
    location() {
      const { name, address, distance } = this.stores[0];
      const [addressLine1, addressLine2] = formatSplitAddress(address);
      return {
        name,
        distance: formatToLength(distance, 2),
        addressLine1,
        addressLine2,
        mapLink: createMapLink(address)
      };
    }
  },
  created() {
    Order.markInStore(this.orderId, true);
    this.$trackEvent('store locator shown');
  },
  methods: {
    next() {
      Order.markInStore(this.orderId, false);
      this.$trackEvent('selected ship return instead of in-store return');
      this.$emit('success', {});
    }
  },
};
</script>

<style lang="scss" scoped>
.locator {
  &__card {
    display: flex;
    flex-direction: column;
  }

  &__heading {
    text-align: center;
    margin-bottom: var(--spacing-300);
  }

  &__subheading {
    text-align: center;
    margin-bottom: var(--spacing-300);
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__address {
    font-style: normal;
    margin-bottom: var(--spacing-200);
  }

  &__opt-in {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-300);
    text-align: center;

    p.base-text {
      text-align: center;
    }
  }

  &__present-qr-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-300);
    text-align: center;
  }

  &__present-qr-text {
    display: block;
    text-align: center;
  }

  &__qr-code-container {
    height: 180px;
    width: 180px;
    border-radius: calc(var(--spacing-200) - 1px);
    display: block;

    &--loading {
      animation: skeleton 3s infinite linear;
    }
  }

  &__qr-code-image {
    max-width: 180px;
    padding: var(--spacing-200);
    border-radius: calc(var(--spacing-200) - 1px);
  }

  &__directions {
    margin: var(--spacing-200) 0;
  }
}

.address-line {
  text-align: center;
}

.opt-out {
  display: inline-flex;
  margin-top: var(--spacing-600);

  &__label {
    display: flex;
    align-items: center;
  }

  &__arrow {
    --size: 1.25rem;

    width: var(--size);
    height: var(--size);
    margin-left: var(--spacing-200);
  }
}

@media screen and (width <= 680px) {
  .locator-card {
    padding: var(--spacing-400);
  }

  .opt-out-button {
    margin-top: var(--spacing-400);
  }
}

@keyframes skeleton {
  0%,
  100% {
    background-color: var(--grey-200);
  }

  50% {
    background-color: var(--grey-100);
  }
}
</style>
