<template>
  <alert-banner
    data-testid="information-banner"
    :data-testvalue="bannerMessage"
    :type="bannerType"
    :is-closable="false"
    heading=""
    :description="bannerMessage"
    :class="{ '--custom': bannerInfo.type === 'custom' }"
  />
</template>

<script setup>
import {
  AlertBanner,
} from '@loophq/design-system';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { featureFlags } from '@/js/constants/featureFlags';

const store = useStore();

const parseString = (input) => {
  const parts = input.split(':');

  const type = parts[0].trim();
  let slug = parts.length > 1 ? parts[1].trim() : null;
  let customType = null;
  if (type === 'loop') {
    slug = parts[2].trim();
    if (parts.length > 3) {
      const newSlug = parts.slice(2);
      slug = newSlug.join(':');
    }
    customType = parts[1].trim();
  }
  return { type,customType, slug };
};

const bannerType = computed(() => {
  if (bannerInfo.value.type === 'loop') {
    return bannerInfo.value.customType ? bannerInfo.value.customType : 'info';
  }
  if (bannerInfo.value.type === 'custom') {
    return 'info';
  }
  return bannerInfo.value.type;
});

const bannerInfo = computed(() =>{
  const data = store.getters.hasFeature(featureFlags.ENABLE_CUSTOMER_PORTAL_INFORMATION_BANNER);
  return parseString(data);
});

const bannerMessage = computed(() => {
  if (bannerInfo.value.type === 'loop') {
    return bannerInfo.value.slug;
  }
  return store.getters.content.downtimeOrOutageBanner[bannerInfo.value.type];
});

const useCustomBanner = computed(() => store.getters.theme.useCustomBannerColor);

const theme = computed(() => store.getters.theme);

const alertBannerBorderColor = computed(() => {
  if (useCustomBanner.value) {
    return theme.value.branding.primaryColor;

  }
  return 'var(--grey-300)';
});

const alertBannerShadowColor = computed(() => {
  if (useCustomBanner.value) {
    return theme.value.branding.primaryColor.includes('#') ? hexToRgba(theme.value.branding.primaryColor, .24) : adjustRgbaOpacity(theme.value.branding.primaryColor, .24);

  }
  return 'var(--grey-200)';
});

const alertBannerIconColor = computed(() => {
  if (useCustomBanner.value) {
    return theme.value.branding.primaryColor.includes('#') ? hexToRgba(theme.value.branding.primaryColor, .84) : adjustRgbaOpacity(theme.value.branding.primaryColor, .84);
  }
  return 'var(--grey-700)';
});

function hexToRgba(color) {
  let r = parseInt(color.slice(1, 3), 16), g = parseInt(color.slice(3, 5), 16), b = parseInt(color.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${arguments[1]})`;
}

function adjustRgbaOpacity(color, newOpacity) {
  const rgb = color.replace(/^(rgb|rgba)\(/, '').replace(/\)$/, '').replace(/\s/g, '').split(',');
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${newOpacity})`;
}
</script>

<style lang="scss" scoped>
//will allow for custom colors for future state
.alert-banner.--custom {
  background-color: #fff;
  border-color: v-bind(alertBannerBorderColor);
  box-shadow: 0 0 0 6px v-bind(alertBannerShadowColor);
}

.--custom {
  :deep(.alert-banner__icon) {
    color: v-bind(alertBannerIconColor);
  }
}

.--custom {
  :deep(.alert-banner__icon-wrap) {
    border: 5px solid v-bind(alertBannerShadowColor) !important;
  }
}

</style>
