import axios from 'axios';

export default {
  namespaced: true,
  state: {
    preloading: false,
    loading: false,
    navigation: [],
    collection: {},
    products: [],
    perPage: 18,
    page: 1,
    prevPage: null,
    pages: null,
    cartActive: false
  },
  mutations: {
    setProperty(state, { key, value, namespace }) {
      if (namespace) {
        state[namespace][key] = value;
      } else {
        state[key] = value;
      }
    },
    setCollection(state, { collection, products, page = 1, resetMax = false, prevPage = null }) {
      if (collection) {
        // Pluck the collection out of the navigation
        // Checks both parents and children
        state.collection = state.navigation
          .reduce((acc, cur) => {
            // Get children if they exist
            const children = cur.children || [];
            // Concat past items, the current item, and the current children in an array
            return [
              ...acc,
              cur,
              ...children
            ];
          }, [])
          // the component needs an id, so we provide fake one if we can't find collection
          .find(item => item.id === collection.id) || { id: 'this-needs-an-id' };
      }
      state.products = products;
      state.page = products.length > 0 ? page : state.page;
      state.prevPage = prevPage;
      if (resetMax) {
        state.pages = null;
      }

      // Set max pages
      if (products.length && products.length < state.perPage) {
        state.pages = page;
      }
    },
    updateNav(state, nav) {
      state.navigation = nav;
    }
  },
  actions: {
    getCollection({ commit, state, rootState }, { collection, payload, resetMax }) {
      const isViewAll = collection.title === 'View all';

      commit('setProperty', { key: 'loading', value: true });

      if (isViewAll) {
        let previewProducts = [];
        rootState.tabs.map(item => item.products).map(product => previewProducts = [...previewProducts, ...product]);
        commit('setProperty', { key: 'products', value: previewProducts });
        commit('setProperty', { key: 'loading', value: false });
        return;
      }

      const defaultPayload = {
        page: 1,
        per_page: state.perPage
      };

      return axios.post(`api/v1/${rootState.shopContents.id}/collections/${collection.id}/products`,
        {
          ...defaultPayload,
          ...payload
        })
        .then(res => {
          commit('setCollection', {
            collection,
            products: res.data.products,
            page: res.data.next_page,
            resetMax,
            prevPage: res.data.prev_page
          });

          commit('setProperty', { key: 'loading', value: false });
        }).catch(err => {
          console.error(err);
          commit('setCollection', {
            collection,
            products: [],
            page: 1,
            resetMax
          });

          commit('setProperty', { key: 'loading', value: false });
        });
    }
  }
};
