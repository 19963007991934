<template>
  <base-panel
    type="panel"
    :heading="$content.moduleCreditSelector.instantReturnExplanationHeading"
    :active="isActive"
    @close="close"
  >
    <markup-renderer
      class="explanation-copy"
      :content="$content.moduleCreditSelector.instantReturnExplanation"
      :customization-data="customizationData"
      :components="components"
    />

    <base-text class="instant-exchange-body">
      <render-content :data="{ instantReturnWindow, amountToAuthorize }">
        {{ $content.moduleCreditSelector.instantReturnTerms }}
      </render-content>
    </base-text>

    <base-text
      tag="p"
      class="explanation-subheading"
    >
      {{ $content.moduleCreditSelector.instantRefundExplanationSubheading }}
    </base-text>

    <markup-renderer
      class="explanation-copy"
      :content="$content.moduleCreditSelector.instantRefundExplanation"
      :customization-data="customizationData"
      :components="components"
    />

    <base-text
      tag="p"
      class="disclaimer-copy"
    >
      {{ $content.moduleCreditSelector.instantRefundDisclaimer }}
    </base-text>

    <base-text
      tag="p"
      class="explanation-subheading"
    >
      {{ $content.moduleCreditSelector.instantExchangeExplanationSubheading }}
    </base-text>

    <markup-renderer
      class="explanation-copy"
      :content="$content.moduleCreditSelector.instantExchangeExplanation"
      :customization-data="customizationData"
      :components="components"
    />
  </base-panel>
</template>

<script>
import {
  BasePanel,
  BaseText,
} from '@loophq/design-system';
import MarkupRenderer from '@/components/renderers/MarkupRenderer';

export default {
  name: 'InstantReturnDialog',
  components: {
    BasePanel,
    BaseText,
    MarkupRenderer,
  },
  props: {
    components: {
      type: Object,
      required: true,
    },
    customizationData: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    }
  },
  emits: [
    'close',
  ],
  computed: {
    returnPolicy() {
      return this.$store.getters.order.return_policy;
    },
    instantReturnWindow() {
      return this.returnPolicy.instant_return_window;
    },
    amountToAuthorize() {
      return this.customizationData.amountToAuthorize;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.explanation-subheading {
  font-weight: bold;
  margin: var(--spacing-300) 0;
}

.explanation-copy {
  margin-bottom: var(--spacing-300);
}

.disclaimer-copy {
  font-style: italic;
}
</style>
