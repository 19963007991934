<template>
  <transition name="lazyimg">
    <img
      v-if="loaded.length > 0"
      class="lazy-img"
      :src="loaded"
      :alt="alt"
    >
  </transition>
</template>

<script>
export default {

  props: {
    src: {
      type: String,
      required: false
    },
    srcset: {
      type: String,
      required: false
    },
    sizes: {
      type: String,
      required: false
    },
    alt: {
      type: String,
      required: true,
      default: ''
    }
  },
  emits: [
    'error'
  ],
  data() {
    return {
      loaded: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
    };
  },
  watch: {
    src() {
      this.load();
    },
    srcset() {
      this.load();
    },
    sizes() {
      this.load();
    }
  },
  async created() {
    await this.$nextTick();
    this.loaded = this.src;
  },
  methods: {
    load() {
      // Set image to transparent pixel while we load to prevent old images from showing
      this.loaded = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
      const vm = this;
      const loader = document.createElement('img');

      loader.addEventListener('load', function () {
        this.removeEventListener('load', this);
        if (this.currentSrc === this.src) {
          vm.loaded = this.currentSrc;
        }
      });

      loader.addEventListener('error', function (event) {
        this.removeEventListener('load', this);
        vm.$emit('error', event);
      });

      if (this.srcset) {
        loader.setAttribute('srcset', this.srcset);
      }

      if (this.sizes) {
        loader.setAttribute('sizes', this.sizes);
      }

      loader.setAttribute('src', this.src);
    }
  },
};
</script>
