/**
 * Converts an object to an array, discarding the object keys
 * @param {object} obj
 * @returns {array} The object as an array
 */
const objectToArray = (obj) => {
  if (!obj) {
    return [];
  }
  return Object.values(obj)
    .reduce((acc, cur) => {
      return [...acc, cur];
    }, []);
};

export default objectToArray;
