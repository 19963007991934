import Router from '@/router';
import Store from '@/store';
import { defineAsyncComponent } from 'vue';

export const importModule = name => {
  return defineAsyncComponent(() => import(/* @vite-ignore */ `../modules/${name}.vue`));
};

export const actions = {
  loadModule(module) {
    console.info('load module ' + module);
  },
  loadView(options) {
    Router.push(options);
  }
};

export function finished({ action, store }) {
  if (store) {
    Store.commit('updateData', store);
  }

  if (action) {
    actions[action.type](action.target);
  }
}

export function error(data) {
  Store.commit('setError', {
    copy: data
  });
}
