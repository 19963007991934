import { LineItem, KeyValue } from '@/js/propSchemas.js';

// A function to take a nested object and make it one level deep
// Nested keys are prefixed by their parent keys, like: "foo.bar.baz"
const flattenSchema = (schema, prefix = '') => {
  return Object.entries(schema)
    .reduce((accumulator, [key, value]) => {
      // If it's an object, we want to walk into that and run this function on it
      // Then we can expand those keys back out into the top level object
      if (typeof value === 'object' && value !== null) {
        return {
          ...accumulator,
          ...flattenSchema(value, `${prefix}${key}.`)
        };
      }

      // Return the list, appending the current key on to the end
      // Prefix is the accumulation of any parent keys this key has
      return {
        ...accumulator,
        [`${prefix}${key}`]: value
      };
    }, {});
};

// Generic schema validator
export const validateSchema = (obj, schema, meta) => {
  const keys = Object.keys(flattenSchema(schema))
    .filter(item => {
      return !obj[item];
    });

  // We've found some keys in the schema that are missing in the object
  if (keys.length) {
    console.error(`Error: ${meta && meta.name ? meta.name + ' ' : ''}prop is missing keys:`, keys);
  }

  // Return a true/false so we can use this as a validator function
  return !keys.length;
};

// Specific schema validators
export const validateLineItem = (value) => validateSchema(value, LineItem, { name: 'LineItem' });
export const validateKeyValue = (value) => validateSchema(value, KeyValue);
