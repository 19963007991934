<template>
  <page-container>
    <base-card class="capture-email">
      <base-text
        class="capture-heading"
        tag="h2"
        type="display-large"
      >
        <render-content>
          {{ $content.moduleMissingInfo.heading }}
        </render-content>
      </base-text>
      <base-text class="capture-copy">
        <render-content>
          {{ $content.moduleMissingInfo.subheading }}
        </render-content>
      </base-text>
      <Form
        v-slot="{ handleSubmit }"
        slim
      >
        <form
          class="capture-form"
          @submit.prevent="handleSubmit(submit)"
        >
          <Field
            v-slot="{ errors, field }"
            v-model="email"
            name="email"
            rules="required|email"
            slim
          >
            <base-input
              :model-value="email"
              v-bind="field"
              type="email"
              :label="$content.moduleMissingInfo.email"
              :error="errors[0]"
            />
          </Field>
          <base-button :disabled="!valid">
            <render-content>
              {{ $content.moduleMissingInfo.submit }}
            </render-content>
          </base-button>
        </form>
      </Form>
      <base-button
        type="link"
        @click.prevent="backToLookup"
      >
        <render-content>
          {{ $content.moduleMissingInfo.goBack }}
        </render-content>
      </base-button>
    </base-card>
  </page-container>
</template>

<script>
import {
  BaseCard,
  BaseButton,
  BaseInput,
  BaseText,
} from '@loophq/design-system';
import PageContainer from '@/components/layout/PageContainer';
export default {
  components: {
    BaseCard,
    BaseButton,
    BaseInput,
    BaseText,
    PageContainer
  },
  emits: [
    'navigate',
  ],
  data() {
    return {
      email: ''
    };
  },
  computed: {
    valid() {
      return this.email.length > 0;
    },
  },
  methods: {
    submit() {
      const order = this.$store.getters.order;
      this.$store.commit('updateOrder', {
        ...order,
        customer: {
          ...order.customer,
          email: this.email
        }
      });
      this.$store.commit('return/setCustomer', {
        ...order.customer,
        email: this.email
      });
      this.$router.push({ name: 'exchange' });
    },
    backToLookup() {
      this.$store.commit('updateOrder', 0);
      this.$emit('navigate', { name: 'OrderLookup' });
    },
  }
};
</script>

<style lang="scss" scoped>
.capture-email {
  * + * {
    margin-top: var(--spacing-400);
  }
}

.capture-heading {
  text-align: center;
}

.capture-copy {
  text-align: center;
  color: var(--grey-700);
}

.capture-form {
  display: flex;
  flex-direction: column;
}
</style>
