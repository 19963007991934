import { cloneDeep, merge } from 'lodash';

export const returnedItemTotals = {
  item_net: 2400,
  item_discount: 0,
  item_tax: 180,
  item_total: 2580,
};

export const newItemTotals = {
  item_net: 500,
  item_discount: 0,
  item_tax: 38,
  item_total: 538,
};

const instant_exchange_receipt = {
  status: 'authorized',
  amount: {
    auth: 4000,
    value: 4000,
    currency: 'USD',
  },
};

const receipt = {
  returned_items: {
    0: returnedItemTotals,
  },
  new_items: {
    0: newItemTotals,
  },
  handling_fee: 0,
  bonus_used: 0,
  returned_item_tax: -750,
  returned_item_total_discount: 0,
  returned_item_subtotal: -10000,
  returned_item_credit: 10750,
  returned_item_fee: 200,
  new_item_tax: 38,
  new_item_subtotal: 500,
  new_item_total: 538,
  amount_to_authorize: 0,
  total: 10750,
  exchange_order_shipping_total: 527,
  instant_exchange_receipt,
  gift_card_bonus: 0,
};

const customer = {
  id: 81,
  created_at: '2018-10-17 13:59:01',
  updated_at: '2020-04-13 21:16:02',
  first_name: 'John',
  middle_name: null,
  last_name: 'Doe',
  email: 'test@test.test',
  shop_id: 260,
};

const address = {
  id: 5306,
  hash: 'd538b43379e73626170a3b4c44dcf46d',
  created_at: '2020-06-16 20:55:54',
  updated_at: '2020-07-08 13:50:08',
  name: 'John Doe',
  company: null,
  address1: '12345 Test St.',
  address2: '',
  city: 'Columbus',
  state: 'Ohio',
  zip: '43201',
  country: 'United States',
  country_code: 'US',
  phone: null,
};

export const totals = {
  totals: {
    credit: {
      total: 2150,
      base: 2150,
      with_partial_return_credit: 2150,
      net: 2000,
      tax: -150,
      available: 2150,
      with_bonus: 2150,
      with_fees: 2150,
      inline_exchange_delta: 0,
      bonus: {
        applied: 0,
        total: 0,
        discount: {
          applied: 0,
          total: 'NA',
        },
        on_store_bonus: {
          applied: 0,
          total: 'NA',
        },
        platform_credit: {
          bonus_amount: 0,
          name: 'default'
        }
      },
      pre_calculated: {
        handling_fee: 2150,
        refund_credit: 2150,
        store_credit: 2150,
        platform_credit: {
          credit_amount: 0,
          name: 'default'
        }
      },
    },
    owed: {
      total: 0,
      tax: 0,
      fees: {
        handling_fee: {
          applied: 0,
          total: 0,
        },
      },
      cart: {
        total: 0,
      },
      exchanges: {
        total: 0,
        instant_exchange_enabled: false,
        instant_exchange_authorization_type: 'full_amount',
        instant_exchange_window: 14,
        amount_to_authorize: 0,
        amount_to_charge: 0,
      },
    },
    settings: {
      stripe_min: 50,
    },
    computed: {
      net: 2150,
      remaining_credit: 2150,
      owed: 0,
    },
  },
  receipt,
  customer,
  address,
};

/**
 * Creates a new items array for the totals receipt
 * @param {array} items
 * @param {array} cart
 * @returns {object}
 */
const buildNewItems = (items, cart) => {
  const exchanges = items.reduce((acc, cur) => {
    if (cur.resolution === 'exchange') {
      return {
        ...acc,
        [cur.id]: newItemTotals,
      };
    }

    return acc;
  }, {});

  return {
    ...exchanges,
    ...cart.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: newItemTotals,
      };
    }, {}),
  };
};

/**
 * Adds correct returned and new items to a receipt
 * @param {*} order
 * @param {*} receipt
 */
export const buildReceipt = (data, receipt, useReceiptFromPayload = false) => {
  if (!data || useReceiptFromPayload) {
    return receipt;
  }
  const lineItems = data.lineItems ?? [];

  return {
    ...receipt,
    returned_items: lineItems.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: returnedItemTotals,
      };
    }, {}),
    new_items: buildNewItems(lineItems, data.cart ?? []),
  };
};

export const totalsScenarios = [
  {
    name: 'refund',
    totals: cloneDeep(totals.totals),
    receipt: cloneDeep(totals.receipt),
  },
  {
    name: 'money owed',
    stripe: true,
    totals: merge(cloneDeep(totals.totals), {
      credit: {
        total: 10750,
        base: 10750,
        net: 10000,
        tax: -750,
        available: -5119,
        with_bonus: 10750,
        with_fees: 10750,
        pre_calculated: {
          handling_fee: 10750,
        },
      },
      owed: {
        total: 15869,
        tax: 1107,
        cart: {
          total: 14762,
        },
      },
      settings: {
        stripe_min: 50,
      },
      computed: {
        net: -5119,
        remaining_credit: 0,
        owed: -5119,
      },
    }),
  },
  {
    name: 'money owed, no upsell',
    stripe: false,
    totals: merge(cloneDeep(totals.totals), {
      credit: {
        total: 10750,
        base: 10750,
        net: 10000,
        tax: -750,
        available: -5119,
        with_bonus: 10750,
        with_fees: 10750,
        pre_calculated: {
          handling_fee: 10750,
        },
      },
      owed: {
        total: 15869,
        tax: 1107,
        cart: {
          total: 14762,
        },
      },
      settings: {
        stripe_min: 50,
      },
      computed: {
        net: -5119,
        remaining_credit: 0,
        owed: -5119,
      },
    }),
  },
  {
    name: 'money owed with instant exchange',
    stripe: true,
    totals: merge(cloneDeep(totals.totals), {
      credit: {
        total: 10750,
        base: 10750,
        net: 10000,
        tax: -750,
        available: -5119,
        with_bonus: 10750,
        with_fees: 10750,
        pre_calculated: {
          handling_fee: 10750,
        },
      },
      owed: {
        exchanges: {
          total: 1200,
          instant_exchange_enabled: true,
          instant_exchange_authorization_type: 'instant',
          instant_exchange_window: 30,
          amount_to_authorize: 2400,
          amount_to_charge: 2400,
        },
      },
      settings: {
        stripe_min: 50,
      },
      computed: {
        net: -5119,
        remaining_credit: 0,
        owed: -5119,
      },
    }),
  },
  {
    name: 'money owed, is covered',
    totals: merge(cloneDeep(totals.totals), {
      credit: {
        total: 10750,
        base: 10750,
        net: 10000,
        tax: -750,
        available: -5119,
        with_bonus: 10750,
        with_fees: 10750,
        pre_calculated: {
          handling_fee: 10750,
        },
      },
      owed: {
        total: 15869,
        tax: 1107,
        cart: {
          total: 14762,
        },
      },
      settings: {
        stripe_min: 5200,
      },
      computed: {
        net: -5119,
        remaining_credit: 0,
        owed: -5119,
      },
    }),
  },
  {
    name: 'with bonus credit',
    totals: merge(cloneDeep(totals.totals), {
      credit: {
        bonus: {
          applied: 0,
          total: 1200,
          discount: {
            applied: 0,
            total: 'NA',
          },
          on_store_bonus: {
            applied: 0,
            total: 'NA',
          },
        },
      },
    }),
  },
  {
    name: 'with shop cash',
    totals: merge(cloneDeep(totals.totals), {
      credit: {
        bonus: {
          applied: 0,
          total: 1200,
          discount: {
            applied: 0,
            total: 'NA',
          },
          on_store_bonus: {
            applied: 0,
            total: 'NA',
          },
          platform_credit: {
            add_on_bonus_amount: 50,
            name: 'shop_cash',
            refund_bonus_amount: 0,
          },
          store_credit_bonus: 100,
        },
        platform_credit_available: true,
      },
    }),
  },
  {
    name: 'zero refund value',
    totals: merge(cloneDeep(totals.totals), {
      credit: {
        base: 0,
        with_partial_return_credit: 0,
        pre_calculated: {
          refund_credit: 0,
          store_credit: 0
        }
      },
    }),
  },
  {
    name: 'zero refund value no credit',
    totals: merge(cloneDeep(totals.totals), {
      credit: {
        base: 0,
        with_partial_return_credit: 0,
        pre_calculated: {
          refund_credit: 0,
          store_credit: 0
        }
      },
      computed: {
        net: 0,
        remaining_credit: 0,
        owed: 0,
      },
    }),
  },
  {
    name: 'zero refund value with store credit',
    totals: merge(cloneDeep(totals.totals), {
      credit: {
        base: 0,
        with_partial_return_credit: 0,
        pre_calculated: {
          refund_credit: 0,
          store_credit: 350
        }
      },
    }),
  },
  {
    name: 'exchange only with instant return',
    totals: merge(cloneDeep(totals.totals), {
      credit: {
        base: 0,
      },
      owed: {
        amount_to_authorize: 100,
        amount_to_charge: 2400,
        instant_return_authorization_type: 'single_unit_amount',
        instant_return_enabled: true,
        instant_return_window: 14,
        total: 2400,
      },
      settings: {
        stripe_min: 50,
      },
    }),
    receipt: merge({}, totals.receipt, {
      new_items: {
        22092877398095: returnedItemTotals,
      },
      new_item_tax: 0,
      new_item_subtotal: 2400,
      new_item_total: 2400,
      returned_item_credit: 2400,
      returned_item_subtotal: -2400,
      returned_item_tax: 0,
      exchange_order_shipping_total: 0,
      total: 2400,
    }),
  },
  {
    name: 'with instant exchange',
    totals: merge(cloneDeep(totals.totals), {
      owed: {
        exchanges: {
          total: 0,
          instant_exchange_enabled: true,
          instant_exchange_authorization_type: 'full_amount',
          instant_exchange_window: 30,
          amount_to_authorize: 2400,
          amount_to_charge: 2400,
        },
      },
    }),
    receipt: merge({}, totals.receipt, {
      returned_items: {
        0: returnedItemTotals,
      },
      new_items: {
        0: returnedItemTotals,
      },
      returned_item_tax: 0,
      returned_item_subtotal: 2400,
      returned_item_credit: 2400,
      new_item_tax: 0,
      new_item_subtotal: 2400,
      new_item_total: 2400,
      total: -2400,
      amount_to_authorize: 2400,
      instant_exchange_enabled: true,
    }),
  },
  {
    name: 'with instant exchange and lower auth amount',
    totals: merge(cloneDeep(totals.totals), {
      owed: {
        exchanges: {
          total: 0,
          instant_exchange_enabled: true,
          instant_exchange_authorization_type: 'single_unit_amount',
          instant_exchange_window: 20,
          amount_to_authorize: 100,
          amount_to_charge: 10000,
        },
      },
    }),
    receipt: merge({}, totals.receipt, {
      returned_items: {
        0: returnedItemTotals,
      },
      new_items: {
        0: returnedItemTotals,
      },
      returned_item_tax: 0,
      returned_item_subtotal: 2400,
      returned_item_credit: 2400,
      new_item_tax: 0,
      new_item_subtotal: 2400,
      new_item_total: 2400,
      total: -100,
      amount_to_authorize: 100,
      instant_exchange_enabled: true,
    }),
  },
  {
    name: 'with shipping and duties',
    totals: merge(cloneDeep(totals.totals), {
      credit: {
        shipping_total: 500,
        duties_total: 1000,
      },
    }),
    receipt: merge({}, totals.receipt, {
      shipping_total: 500,
      duties_total: 1000,
    }),
  },
  {
    name: 'with cart items',
    totals: cloneDeep(totals.totals),
    receipt: merge({}, totals.receipt, {
      // First variant of all products
      new_items: {
        22092877398095: returnedItemTotals,
        22092883329103: returnedItemTotals,
        22092872810575: returnedItemTotals,
        22092881068111: returnedItemTotals,
        22092874383439: returnedItemTotals,
        22092868419663: returnedItemTotals,
        22092972884047: returnedItemTotals,
        22092972654671: returnedItemTotals,
      },
    }),
  },
  {
    name: 'with gift card bonus',
    totals: merge({}, totals.totals, {
      credit: {
        ...totals.totals.credit,
        bonus: {
          ...totals.totals.credit.bonus,
          store_credit_bonus: 1000,
        },
      },
    }),
    receipt: merge({}, totals.receipt, {
      gift_card_bonus: 2100,
    }),
  },
  {
    name: 'refund with partial return credit',
    useReceiptFromPayload: true,
    receipt: {
      returned_items: {
        16: {
          item_amount: -200,
          item_discount: 0,
          item_net: -200,
          item_tax: 0,
          item_total: -200,
          item_fee: 100,
          currency: 'USD',
          price: 16000,
          struck_price: 20000,
          use_struck_price: true,
          lineItemFees: {
            feeAmount: 100,
            creditAmount: 100,
            creditPercentage: 80,
          },
        },
      },
    },
  },
  {
    name: 'refund with shop now discount',
    receipt: merge({}, totals.receipt, {
      bonus_used: 1000,
      new_items: {
        item_discount: -1000,
      },
    }),
    totals: merge(cloneDeep(totals.totals), {
      credit: {
        base: 20000,
        bonus: {
          applied: 1000,
          on_store_bonus: {
            applied: 1000,
            total: 'NA',
          },
        },
      },
      legacy: {
        used_storefront_bonus: 1000,
      },
    }),
  },
];
