<template>
  <div class="address-form">
    <div
      v-if="hasFirstLast || !hasName"
      class="address-form__line"
    >
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="modelValue.firstName"
        name="firstName"
        rules="required"
        slim
      >
        <base-input
          :model-value="modelValue.firstName"
          v-bind="field"
          :label="$content.moduleAddressForm.firstName"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('firstName')"
        />
      </Field>
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="modelValue.lastName"
        name="lastName"
        rules="required"
        slim
      >
        <base-input
          :model-value="modelValue.lastName"
          v-bind="field"
          :label="$content.moduleAddressForm.lastName"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('lastName')"
        />
      </Field>
    </div>
    <div
      v-if="hasName"
      class="address-form__line"
    >
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="modelValue.name"
        name="name"
        rules="required"
        slim
      >
        <base-input
          :model-value="modelValue.name"
          v-bind="field"
          :label="$content.moduleAddressForm.name"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('name')"
        />
      </Field>
    </div>
    <div class="address-form__line">
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="modelValue.address1"
        name="address1"
        rules="required"
        slim
      >
        <base-input
          :model-value="modelValue.address1"
          v-bind="field"
          :label="$content.moduleAddressForm.address1"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('address1')"
          :maxlength="128"
        />
      </Field>
    </div>
    <div
      v-if="isNewZealand"
      class="address-form__line"
    >
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="suburb"
        name="suburb"
        rules="required"
        slim
      >
        <base-input
          :model-value="suburb"
          v-bind="field"
          :label="$content.moduleAddressForm.suburb"
          :disabled="disabledFields.includes('suburb')"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
        />
      </Field>
    </div>
    <div
      v-else-if="!isAustralia"
      class="address-form__line"
    >
      <Field
        v-slot="{ field }"
        v-model="modelValue.address2"
        name="address2"
        slim
      >
        <base-input
          :model-value="modelValue.address2"
          v-bind="field"
          :label="$content.moduleAddressForm.address2"
          :disabled="disabledFields.includes('address2')"
        />
      </Field>
    </div>
    <div
      v-if="isAustralia"
      class="address-form__line"
    >
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="modelValue.city"
        name="city"
        rules="required"
        slim
      >
        <base-input
          :model-value="modelValue.city"
          v-bind="field"
          label="Suburb"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('city')"
        />
      </Field>
    </div>
    <div
      v-else
      class="address-form__line"
    >
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="modelValue.city"
        name="city"
        rules="required"
        slim
      >
        <base-input
          :model-value="modelValue.city"
          v-bind="field"
          :label="$content.moduleAddressForm.city"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('city')"
        />
      </Field>
    </div>
    <div class="address-form__line">
      <Field
        v-if="regions"
        v-slot="{ errors: validationErrors, field }"
        v-model="modelValue.state"
        name="state"
        rules="required"
        slim
      >
        <base-input
          :model-value="modelValue.state"
          v-bind="field"
          :label="stateLabel"
          :values="regions"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('state')"
        />
      </Field>
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="modelValue.zip"
        name="zip"
        rules="required"
        slim
      >
        <base-input
          :model-value="modelValue.zip"
          v-bind="field"
          :label="zipLabel"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('zip')"
        />
      </Field>
    </div>
    <div class="address-form__line">
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="modelValue.country"
        name="country"
        rules="required"
        slim
      >
        <base-input
          :model-value="modelValue.country"
          v-bind="field"
          :label="$content.moduleAddressForm.country"
          :values="Object.keys(countries)"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('country')"
        />
      </Field>
    </div>
    <div
      v-if="modelValue.phone || modelValue.phone === ''"
      class="address-form__line"
    >
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="modelValue.phone"
        name="phone"
        :rules="rules.phone"
        mode="aggressive"
        slim
      >
        <base-input
          :model-value="modelValue.phone"
          v-bind="field"
          :label="$content.moduleAddressForm.phone"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('phone')"
        />
      </Field>
    </div>
  </div>
</template>

<script>
import {
  BaseInput
} from '@loophq/design-system';
import { getCountries } from '@/js/countries';

/**
 * @typedef Address
 * @type {object}
 * @property {string=} firstName - If this and lastName are set, shows separate fields for first and last name.
 * @property {string=} lastName - If this and firstName are set, shows separate fields for first and last name.
 * @property {string=} name - Shows a name field if set. If this is set to a falsy value, the field will not show. If firstName and lastName are set, the component will prefer them over this.
 * @property {string} address1 - Required to validate the form
 * @property {string=} address2 - Optional
 * @property {string=} suburb - Displayed and required to validate the form for Australia and New Zealand addresses only.
 * @property {string} city - Required to validate the form
 * @property {string=} state - Optional, required to validate the form if state is available for the country
 * @property {string} zip - Required to validate the form
 * @property {string} country - Required to validate the form
 * @property {string=} countryCode - Hidden property that stores the country code
 * @property {string=} phone - Optional. Field is hidden if not set
 */
export default {
  name: 'AddressFormWithOceania',
  components: {
    BaseInput
  },
  /**
	 * Some fields will conditionally show based on whether the prop is set.
	 * Refer to the typedef for more info
	 */
  props: {
    /** @type {Address} */
    modelValue: {
      type: Object,
      required: true
    },
    /**
		 * Pass in an array of strings that map to the props that map to the fields you want to disable.
		 * Will still show the fields, you just won't be able to edit them.
		 * Validation is skipped for disabled fields.
		 */
    disabledFields: {
      type: Array,
      required: false,
      default: () => ([])
    },
    /**
		 * Pass in an array of strings that map to the props that map to the fields you want to require.
		 * Currently only supports phone number, most address fields are required always
		 */
    required: {
      type: Array,
      required: false,
      default: () => ([])
    },
    /**
		 * Accepts an array of two- or three-letter country codes
		 */
    availableCountries: {
      type: Array,
      required: false,
      validator: (val) => {
        return Array.isArray(val) &&
          val.every((item) => {
            return typeof item === 'string' && item.length <= 3;
          });
      }
    },
    /** @type {Address} */
    errors: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      suburb: '',
      countryData: [],
      previousCountry: this.modelValue.country,
    };
  },
  computed: {
    countries() {
      return {
        '': '',
        ...this.countryData.reduce((acc, country) => {
          acc[country.name] = country.code;
          return acc;
        }, {})
      };
    },
    regions() {
      if (!this.modelValue.country) {
        return [];
      }
      if (this.isNewZealand) {
        return this.newZealandRegions;
      }
      const regions = this.countryData.find((country) => this.modelValue.country === country.name)?.states;
      return regions ? ['', ...regions] : null;
    },
    hasFirstLast() {
      return ['firstName', 'lastName'].every((key) => {
        return !!this.modelValue[key] || this.modelValue[key] === '';
      });
    },
    hasName() {
      return !(this.hasFirstLast) && (this.modelValue.name || this.modelValue.name === '');
    },
    rules() {
      return {
        phone: this.required.includes('phone') ? 'required' : null
      };
    },
    order() {
      return this.$store.getters.order;
    },
    stateLabel() {
      switch (this.modelValue.country) {
        case 'Canada':
          return 'Province';
        case 'Japan':
          return 'Prefecture';
        case 'New Zealand':
          return 'Region';
        case 'Australia':
          return 'State/territory';
        default:
          return 'State';
      }
    },
    zipLabel() {
      switch (this.modelValue.country) {
        case 'Canada':
        case 'New Zealand':
          return 'Postal code';
        case 'Australia':
        case 'Great Britain':
          return 'Postcode';
        default:
          return 'ZIP/Postal code';
      }
    },
    isNewZealand() {
      return (this.modelValue.country === 'New Zealand' || this.modelValue.countryCode === 'NZ');
    },
    isAustralia() {
      return (this.modelValue.country === 'Australia' || this.modelValue.countryCode === 'AU');
    },
    newZealandRegions() {
      return [
        'Auckland',
        'Bay of Plenty',
        'Canterbury',
        'Chatham Islands',
        'Gisborne',
        'Hawke\'s Bay',
        'Manawatu-Wanganui',
        'Marlborough',
        'Nelson',
        'Northland',
        'Otago',
        'Southland',
        'Taranaki',
        'Tasman',
        'Waikato',
        'Wellington',
        'West Coast'
      ];
    }
  },
  watch: {
    modelValue: {
      handler(newValue) {
        if (newValue.country !== this.previousCountry) {
          this.handleCountryChange(newValue.country);
          this.previousCountry = newValue.country;
        }
      },
      deep: true,
      immediate: true
    },
    suburb(newSuburb) {
      this.$emit('update:modelValue', {
        ...this.modelValue,
        address2: newSuburb
      });
    }
  },
  created() {
    this.initializeForm();
  },
  methods: {
    initializeForm() {
      this.suburb = this.isNewZealand ? this.modelValue.address2 || '' : '';
      const address1 = this.isAustralia && this.modelValue.address2 
        ? this.modelValue.address2 + '/' + this.modelValue.address1 
        : this.modelValue.address1;
      const address2 = this.isAustralia ? '' : this.modelValue.address2;
      this.countryData = getCountries(this.availableCountries);

      this.$emit('update:modelValue', {
        ...this.modelValue,
        address1,
        address2,
        country: (this.modelValue.country || this.order?.address?.country) ?? 'United States',
        countryCode: (this.modelValue.countryCode || this.countries[this.modelValue.country]) ?? 'US',
      });
    },
    handleCountryChange(newCountry) {
      this.modelValue.countryCode = this.countries[newCountry] || '';
      this.initializeForm();
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.address-form';

#{$block} {
  &__line {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
    grid-gap: var(--spacing-200);

    & + & {
      margin-top: var(--spacing-300);
    }
  }
}

@media screen and (max-width: $break-small) {
  #{$block} {
    &__line {
      flex-direction: column;
    }
  }
}
</style>
