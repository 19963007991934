<template>
  <component
    :is="component"
    v-if="items.length"
    class="lookup-graphics"
    v-bind="props"
  >
    <div
      v-for="item in items"
      :key="item.id"
      class="graphic-item"
    >
      <default-icon
        v-if="item.image !== 'custom'"
        class="graphic-icon"
        :name="item.image"
      />
      <img
        v-if="item.image === 'custom' && item.url"
        class="graphic-img"
        alt=""
        :src="item.url"
      />
      <base-text
        v-if="item.description"
        class="graphic-description"
        tag="span"
        type="caption"
      >
        <render-content>
          {{ item.description }}
        </render-content>
      </base-text>
    </div>
  </component>
</template>

<script>
import {
  BaseText,
  Carousel,
} from '@loophq/design-system';
import DefaultIcon from './DefaultIcon';
import { names } from './icons.js';

export default {
  name: 'LookupGraphics',
  components: {
    BaseText,
    Carousel,
    DefaultIcon,
  },
  props: {
    content: {
      type: Array,
      required: true
    }
  },
  computed: {
    isMobile() {
      return this.$screen.width <= 680;
    },
    component() {
      return this.isMobile ? 'carousel' : 'div';
    },
    props() {
      if (this.isMobile) {
        return {
          controlType: 'dot',
          showFullscreen: false,
          autoplay: true
        };
      }

      return {};
    },
    items() {
      return this.content
        .map((item) => {
          return {
            ...item,
            type: names.includes(item.image) ? 'icon' : 'custom'
          };
        })
        .filter((item) => item.enabled);
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (width >= 681px) {
  .lookup-graphics {
    display: flex;
    justify-content: center;
    width: calc(100% + var(--spacing-400));
  }

  .graphic-item {
    width: 10rem;
    margin: 0 calc(var(--spacing-400) / 2);

    &:first-of-type {
      margin-left: calc(var(--spacing-400) / -2);
    }

    &:last-of-type {
      margin-right: calc(var(--spacing-400) / -2);
    }
  }
}

.graphic-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.graphic-icon {
  width: 100px;
  height: 100px;
}

.graphic-img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.graphic-description {
  color: var(--grey-700);
  text-align: center;
  margin-top: var(--spacing-200);
}

@media screen and (width <= 680px) {
  .lookup-graphics {
    --dot-color: var(--grey-300);
    --active-dot-color: var(--grey-700);
  }

  .graphic-item {
    // Add bottom padding on mobile to work with the carousel
    padding-bottom: var(--spacing-800);
  }

  .graphic-description {
    max-width: 9rem;
    margin: 0 auto;
    margin-top: var(--spacing-200);
  }
}
</style>
