<template>
  <span
    class="expandable-list"
  >
    <button
      class="expandable-list__line expandable-list__toggle legacy-button"
      @click.prevent="active = !active"
    >
      <slot></slot>
      <span class="expandable-list__icon-wrap">
        <svg
          viewBox="0 0 10 5"
          class="expandable-list__icon"
          :class="{ active }"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <path
            fill="currentColor"
            transform="translate(-5 -8)"
            fill-rule="evenodd"
            d="M5 8l5 5 5-5z"
          />
        </svg>
      </span>
    </button>
    <transition-expand>
      <template
        #list
      >
        <ul
          v-show="active"
          class="expandable-list__list"
        >
          <li
            v-for="item in items"
            :key="item.key"
            class="expandable-list__item"
          >
            <span class="expandable-list__key">{{ item.key }}:</span>
            <span class="expandable-list__value">{{ item.value }}</span>
          </li>
        </ul>
      </template>
    </transition-expand>
  </span>
</template>

<script>
import TransitionExpand from '@/components/globals/TransitionExpand';
import { validateKeyValue } from '@/js/propValidation';
/*
    Expected item object schema:
    {
        key: String,
        value: String,
    }
*/

export default {
  components: {
    TransitionExpand,
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => ([]),
      validator: value => value.every(item => validateKeyValue(item))
    }
  },
  data() {
    return {
      active: false
    };
  }
};
</script>

<style lang="scss" scoped>
$block: '.expandable-list';

#{$block} {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__line {
    width: 100%;
    margin-bottom: 0.25rem;
  }

  &__toggle {
    cursor: pointer;
    appearance: none;
    border-radius: 0;
    border: 0;
    text-align: left;
    padding: 0;
    background: transparent;
    color: var(--grey-900);

    @include body;

    font-family: var(--theme-body-font, var(--system-font)) !important;

    &:hover:not(.activated) {
      box-shadow: none;
    }

    &[data-focus-visible-added] {
      background: var(--grey-300);
      outline: none;
    }
  }

  &__icon {
    width: 9px;
    height: 9px;
    margin-left: 0.25rem;
    transform: rotate3d(1, 0, 0, -180deg);
    transition: transform var(--easing-slow);

    &.active {
      transform: rotate3d(0, 0, 0, -180deg);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__key {
    margin-right: 0.25rem;
  }
}
</style>
