export const carrierImg = (carrier) => {
  if (!carrier) return null;

  switch (carrier) {
    case 'UPS':
    case 'UPSMailInnovations':
    case 'UPSDAP':
      return '/img/carriers/ups-logo.png';
    case 'USPS':
    case 'USPSReturns':
      return '/img/carriers/usps-logo.png';
    case 'FedEx':
    case 'FedExSmartPost':
      return '/img/carriers/fedex-logo.png';
    case 'Purolator':
      return '/img/carriers/purolator-logo.png';
    case 'DHLeCommerce':
    case 'DHLExpress':
    case 'DHLPaket':
    case 'DhlEcs':
      return '/img/carriers/dhl-logo.png';
    case 'CanadaPost':
      return '/img/carriers/canada-post-logo.png';
    default:
      return null;
  }
};
