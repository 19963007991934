const collection = {
  'id': 0,
  'created_at': '2020-02-24 20:21:13',
  'updated_at': '2020-02-24 20:21:13',
  'shop_id': 0,
  'provider_id': 0,
  'title': 'Collection 1',
  'published_at': null,
  'body_html': null,
  'sort_order': 'best-selling',
  'image': null,
  'tab_name': 'Collection 1',
  'tab_order': 0,
  'parent_id': 0,
  'products': [1, 2, 3, 4, 5]
};

const createCollection = (id, data = {}) => {
  return {
    ...collection,
    id,
    provider_id: id,
    title: `Collection ${id}`,
    tab_name: `Collection ${id}`,
    ...data
  };
};

export const collections = [
  createCollection(1),
  createCollection(
    2,
    {
      title: 'Parent collection',
      tab_name: 'Parent collection'
    }
  ),
  createCollection(
    3,
    {
      title: 'Child collection 1',
      tab_name: 'Child collection 1',
      parent_id: 2,
      tab_order: 0
    }
  ),
  createCollection(
    4,
    {
      title: 'Child collection 2',
      tab_name: 'Child collection 2',
      parent_id: 2,
      tab_order: 1
    }
  ),
  createCollection(
    5,
    {
      title: 'Child collection 3',
      tab_name: 'Child collection 3',
      parent_id: 2,
      tab_order: 2
    }
  ),
  createCollection(6),
  createCollection(7),
  createCollection(
    8,
    {
      title: 'White Hi Top Sneakers',
      tab_name: 'White Hi Top Sneakers',
      products: [8, 9, 10, 11]
    }
  ),
  createCollection(
    9,
    {
      title: 'Empty collection',
      tab_name: 'Empty collection',
      products: []
    }
  ),
  createCollection(
    10,
    {
      title: 'Large collection',
      tab_name: 'Large collection',
      products: [1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 6, 1, 7, 8, 9]
    }
  ),
];
