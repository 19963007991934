import axios from 'axios';

export const getExchangeOrder = async (shopId, returnKey) => {
  const response = await axios.get(`api/v1/${shopId}/return/${returnKey}/exchange-order`);

  if (response.data.errors) {
    throw response.data.errors;
  }

  return response.data;
};
