<template>
  <div
    v-if="normalizedReturn"
    class="tracking-overview"
  >
    <exchange v-if="showExchangeTracking" />
    <refund v-else />
  </div>
</template>

<script setup>
import { inject } from 'vue';
import Exchange from '@/views/StatusPage/Tracking/Outcomes/Exchange';
import Refund from '@/views/StatusPage/Tracking/Outcomes/Refund';

const normalizedReturn = inject('normalizedReturn');
const showExchangeTracking = inject('showExchangeTracking');
</script>
