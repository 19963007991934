<template>
  <div class="line-items__list-item list-item">
    <div class="list-item__image">
      <img
        :src="item.image"
        alt="Product image"
      >
    </div>

    <div class="list-item__details">
      <div>
        <base-text type="heading">
          {{ item.title }}
        </base-text>
      </div>
      <div>
        <base-text type="body-2">
          {{ item.variantTitle }}
        </base-text>
      </div>
    </div>
  </div>
  <div
    v-if="item.hasMultipleLabels"
    class="multi-label-item"
  >
    <base-icon
      name="info"
      class="info-icon"
    />
    <base-text
      color="color-neutral-600"
    >
      {{ multiPackageText }}
    </base-text>
  </div>
</template>

<script setup>
import { BaseText, BaseIcon } from '@loophq/design-system';
import { computed } from 'vue';
const props = defineProps({
  item: Object,
  isQrCode: {
    type: Boolean,
    default: false,
  },
});

const multiPackageText = computed(() => {
  return props.isQrCode ? 'This item has multiple QR codes' : 'This item has multiple labels';
});

</script>

<style lang="scss" scoped>
.list-item {
  margin: var(--spacing-300) auto;
  display: flex;

  &__image {
    max-width: 64px;

    img {
      border-radius: var(--theme-corners);
      width: 100%;
    }
  }

  &__details {
    padding: var(--spacing-200) var(--spacing-300);
  }
}

.multi-label-item {
  display: flex;
  color: var(--color-neutral-600);
}

.info-icon {
  margin-right: var(--spacing-100);

  --icon-color: var(--body-color);
}

</style>
