import store from '@/store';
import { formatCurrency } from '@/js/helpers/formatCurrency';

export const limit = (value, amount = 320) => {
  if (!value) return '';

  return value.length > amount ? `${value.slice(0, amount)}...` : value;
};

export const currency = (value, currency) => {
  return formatCurrency(value, currency ?? store.state.currency ?? 'USD');
};
