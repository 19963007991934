/**
 * @typedef Address
 * @type {object}
 * @property {string=} firstName - If this and lastName are set, shows separate fields for first and last name.
 * @property {string=} lastName - If this and firstName are set, shows separate fields for first and last name.
 * @property {string=} name - Shows a name field if set. If this is set to a falsy value, the field will not show. If firstName and lastName are set, the component will prefer them over this.
 * @property {string} address1 - Required to validate the form
 * @property {string=} address2 - Optional
 * @property {string} city - Required to validate the form
 * @property {string=} state - Optional, required to validate the form if state is available for the country
 * @property {string} zip - Required to validate the form
 * @property {string} country - Required to validate the form
 * @property {string=} phone - Optional. Field is hidden if not set
 */

const joinArray = (arr) => {
  return arr
    .map(item => item.trim())
    .join(', ');
};

/**
 * Formats the address into a single string
 * @param {Address} address
 * @returns {string}
 */
export const formatFullAddress = (address) => {
  const { address1, address2, city, state, country, zip } = address;
  const sections = [
    `${address1} ${address2 ?? ''}`,
    `${city} ${state ?? ''}`,
    country,
    zip ?? ''
  ];
  return joinArray(sections);
};

/**
 * Formats the address without a country, split into two lines
 * @param {Address} address
 * @returns {array}
 */
export const formatSplitAddress = (address) => {
  const { address1, address2, city, state, zip } = address;

  return [
    `${address1} ${address2 ?? ''}`,
    joinArray([
      city,
      `${state ?? ''} ${zip ?? ''}`
    ])
  ];
};

/**
 *
 * @param {Address} address
 * @returns {string}
 */
export const createMapLink = (address) => {
  const query = encodeURIComponent(formatFullAddress(address));
  return `https://www.google.com/maps/search/?api=1&query=${query}`;
};
