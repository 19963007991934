<template>
  <transition name="removed-items-banner">
    <base-card
      v-if="visible"
      class="removed-items-banner"  
    >
      <base-button
        class="removed-items-banner__close"
        type="icon"
        icon="close"
        @click="hidden = true"
      >
        Hide warning
      </base-button>
      <div class="removed-items-banner__icon">
        <base-icon name="warning" />
      </div>
      <div class="removed-items-banner__messages">
        <base-text
          class="removed-items-banner__heading"
          tag="h2"
          type="display-medium"
        >
          <render-content>
            {{ $content.moduleRemovedItemsBanner.heading }}
          </render-content>
        </base-text>
        <base-text class="removed-items-banner__message">
          <render-content>
            {{ $content.moduleRemovedItemsBanner.message }}
          </render-content>
        </base-text>
      </div>
      <div class="removed-items-banner__products">
        <product-card
          v-for="product in formattedRemovedItems"
          :key="product.id"
          size="small"
          :product="product"
        >
          <template #utility>
            <span class="removed-items-banner__removed">
              <render-content>
                {{ $content.global.removed }}
              </render-content>
            </span>
          </template>
        </product-card>
      </div>
    </base-card>
  </transition>
</template>

<script>
import {
  BaseCard,
  BaseIcon,
  BaseText,
  BaseButton,
  ProductCard,
} from '@loophq/design-system';
import formatProduct from '@/js/helpers/formatProduct';

export default {
  name: 'RemovedItemsBanner',
  components: {
    BaseCard,
    BaseIcon,
    BaseText,
    BaseButton,
    ProductCard,
  },
  props: {
    removedItems: {
      type: Array,
      required: false,
      default: () => [],
    }
  },
  data() {
    return {
      hidden: false
    };
  },
  computed: {
    visible() {
      return !this.hidden;
    },
    formattedRemovedItems() {
      // We want to show this price struck out, so we're assigning it over to that instead
      return this.removedItems.map((product) => {
        const { price, ...rest } = formatProduct(product);
        
        return {
          ...rest,
          struckPrice: price
        };
      });
    }
  },
  created() {
    this.hidden = this.removedItems.length === 0;
  }
};
</script>

<style lang="scss" scoped>
$block: '.removed-items-banner';

#{$block}-enter, #{$block}-leave-to {
  transform: translate3d(0, 0.5rem, 0);
  opacity: 0;
}

#{$block}-enter-active, #{$block}-leave-active {
  transition: transform var(--easing-regular), opacity var(--easing-regular);
}

#{$block} {
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  border: 4px solid var(--yellow-500);

  &__close {
    position: absolute;
    top: var(--spacing-400);
    right: var(--spacing-400);
  }

  &__icon {
    flex-shrink: 0;
    padding: 0.75rem;
    margin-right: var(--spacing-400);
    margin-bottom: var(--spacing-800);
    border: 2px solid var(--yellow-500);
    border-radius: 50%;
  }

  &__messages {
    flex-basis: 50%;
    flex-grow: 1;
    margin-right: var(--spacing-600);
  }

  &__message {
    margin-top: var(--spacing-200);
    color: var(--grey-700);
  }

  &__products {
    flex-basis: 40%;
    flex-grow: 1;
  }

  &__removed {
    text-align: right;
    color: var(--red-300);
  }
}

@media screen and (max-width: $break-medium) {
  #{$block} {
    flex-direction: column;

    &__icon {
      margin-bottom: var(--spacing-400);
      margin-right: 0;
    }

    &__messages {
      margin-bottom: var(--spacing-400);
      margin-right: 0;
    }

    &__products {
      width: 100%;
    }
  }
}

@media screen and (max-width: $break-tiny) {
  #{$block} {
    border-radius: 0;
    border-right: none;
    border-left: none;
    padding: var(--spacing-500) var(--spacing-400);
  }
}
</style>
