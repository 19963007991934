import Store from '@/store';

/**
 * Filter a list of locations so it only includes locations close to the user
 * @param {array} locations
 * @param {string?} moduleName
 * @returns {array}
 */
export const getCloseLocations = (locations, moduleName = 'moduleStoreLocator') => {
  if (!locations || !locations.length) {
    return [];
  }

  return locations.filter((location) => {
    const maxDistance = Store.getters.content[moduleName].maxDistance;
    return location.distance < +maxDistance;
  });
};

/**
 *
 * @param {array} locations
 * @returns {boolean}
 */
export const hasCloseLocations = (locations) => {
  if (!locations) {
    return false;
  }

  return !!getCloseLocations(locations).length;
};
