import { h } from 'vue';

export const render = (items, h, components = {}) => {
  // Bail out if no items
  if (!items) {
    return null;
  }

  // Loop through items and call their render functions, defined below
  return items.map(item => {
    // If we have a renderer for this type, call it and pass in the data
    // Renderers should attempt to call this render function again
    // and pass in their children
    if (item.type in renderers) {
      return renderers[item.type](item, h, components[item.type]);
    }

    // Ignore items we don't have types for
    return null;
  })
    // Filter out any ignored items
    .filter(item => item);
};

// Render functions
// Should return either a string (for a text node), or
// a render function
// All functions get passed 2 arguments: item, and createElement (aliased to h typically)
// You should return h(), passing in the tag you want as the first argument (html tag or vue component),
// an options object (for attributes), and then pass in render(item.content, h) as the
// third param. This ensures the nodes children will render.
const renderers = {
  example(item) {
    return h(`div`, {}, render(item.content, h));
  },
  text(item) {
    if (item.marks) {
      return item.marks.reduce((accumulator, { type, attrs }) => {
        const content = accumulator ? [accumulator] : [item.text];
        return render([{ type, attrs, content }], h);
      }, null);
    }
    return item.text;
  },
  heading(item) {
    return h(`h${item.attrs.level}`, {}, render(item.content, h));
  },
  paragraph(item, components = {}) {
    const { component, options } = components;
    return h(component ?? `p`, options ? { ...options } : {}, render(item.content, h, components));
  },
  code_block(item) {
    return h(`pre`, {}, render(item.content));
  },
  bullet_list(item) {
    return h(`ul`, {}, render(item.content));
  },
  list_item(item) {
    return h(`li`, {}, render(item.content));
  },
  blockquote(item) {
    return h(`blockquote`, {}, render(item.content));
  },
  /*eslint-disable-next-line*/
  hard_break(item) {
    return h(`br`, {});
  },
  image(item) {
    return h(`img`, {
      src: item.attrs.src,
      alt: item.attrs.alt,
    });
  },
  // Marks
  italic(item) {
    return h(`em`, {}, item.content);
  },
  bold(item) {
    return h(`strong`, { style: { color: item?.attrs?.color ?? 'inherit' } }, item.content);
  },
  strike(item) {
    return h(`s`, {}, item.content);
  },
  code(item) {
    return h(`code`, {}, item.content);
  },
  link(item) {
    return h(`a`, {
      href: item.attrs.href,
      rel: 'noopener noreferrer',
      target: '_blank'
    }, item.content);
  },
};
