<template>
  <div class="qr-code-card">
    <div class="qr-code-card__heading-wrapper">
      <img
        v-if="logo"
        :src="logo"
        alt="logo"
        class="qr-code-card__brand-logo"
      />
      <base-text
        v-else
        type="display-medium"
        tag="h3"
        class="qr-code-card__brand-name"
      >
        {{ brandName }}
      </base-text>
      <base-text
        type="body"
        tag="p"
        class="qr-code-card__heading-copy"
      >
        Keep what you love,
      </base-text>
      <base-text
        type="body"
        tag="p"
        class="qr-code-card__heading-copy"
      >
        exchange what you don't.
      </base-text>
    </div>
    <div class="qr-code-card__qr-code-wrapper">
      <img
        :src="qrCode"
      />
    </div>
    <qr-code-line-items
      class="qr-code-card__line-items"
      :line-items="displayLineItems"
      :extra-line-items="extraLineItems"
    />
    <a
      href="https://loopreturns.com"
      target="_blank"
      class="qr-code-card__powered-by-loop-badge"
    >
      <base-text
        tag="span"
        type="body"
      >
        Powered by
      </base-text>
      <div class="qr-code-card__loop-logo">
        <svg
          width="35"
          height="16"
          viewBox="0 0 35 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Loop Returns | The returns solution for Shopify's top brands</title>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 12.4444H2.92403V0H0V12.4444Z"
            fill="currentColor"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.90172 12.7289C12.5058 12.7289 13.7808 10.3822 13.7808 8C13.7808 5.61778 12.5058 3.27111 8.90172 3.27111C5.29768 3.27111 4.03967 5.61778 4.03967 8C4.03967 10.4 5.29768 12.7289 8.90172 12.7289ZM6.9637 8C6.9637 6.22222 7.50771 5.65333 8.90172 5.65333C10.2957 5.65333 10.8397 6.22222 10.8397 8C10.8397 9.77778 10.2957 10.3467 8.90172 10.3467C7.50771 10.3467 6.9637 9.77778 6.9637 8Z"
            fill="currentColor"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.3734 12.7289C22.9775 12.7289 24.2525 10.3822 24.2525 8C24.2525 5.61778 22.9775 3.27111 19.3734 3.27111C15.7694 3.27111 14.5114 5.61778 14.5114 8C14.5114 10.4 15.7694 12.7289 19.3734 12.7289ZM17.4354 8C17.4354 6.22222 17.9794 5.65333 19.3734 5.65333C20.7675 5.65333 21.3115 6.22222 21.3115 8C21.3115 9.77778 20.7675 10.3467 19.3734 10.3467C17.9794 10.3467 17.4354 9.77778 17.4354 8Z"
            fill="currentColor"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M31.107 3.27111C29.6619 3.27111 28.6929 3.82222 28.1149 4.76444V3.55556H25.1909V16H28.1149V11.2C28.6929 12.16 29.6619 12.7289 31.107 12.7289C33.895 12.7289 35 10.4889 35 8.01778C35 5.45778 33.895 3.27111 31.107 3.27111ZM30.1209 10.3467C28.6249 10.3467 28.1149 9.58222 28.1149 8C28.1149 6.41778 28.6249 5.65333 30.1209 5.65333C31.566 5.65333 32.076 6.34667 32.076 8C32.076 9.65333 31.566 10.3467 30.1209 10.3467Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </a>
  </div>
</template>

<script>
import {
  BaseText
} from '@loophq/design-system';
import QrCodeLineItems from './QrCodeLineItems.vue';

export default {
  name: 'QrCodeCard',
  components: {
    BaseText,
    QrCodeLineItems,
  },
  data() {
    return {
      extraLineItems: 0,
      displayLineItems: [],
    };
  },
  computed: {
    logo() {
      if (this.$theme) {
        const { navLogo, logo } = this.$theme.branding;
        return navLogo || logo || null;
      }

      return null;
    },
    qrCode() {
      return this.$store.state.giftReceipt.activeReceipt?.qr_code_url;
    },
    brandName() {
      return this.$shop.name;
    },
    lineItems() {
      return this.$store.state.giftReceipt.lineItems;
    },
    selectedItemIds() {
      return this.$store.state.giftReceipt.activeReceipt?.line_item_ids || [];
    }
  },
  mounted() {
    const items = this.lineItems;
    const selectedItems = this.selectedItemIds;
    const products = selectedItems.map(id => Object.values(items).find(product => product.id === id));

    // handle case of > 3 line items
    if (products.length > 3) {
      this.extraLineItems = products.length - 3;
      this.displayLineItems = products.slice(0, 3);
    } else {
      this.displayLineItems = products;
    }

  },
};
</script>

<style lang="scss" scoped>
$block: '.qr-code-card';

#{$block} {
  display: block;
  background: white;
  width: 100%;
  border: 2px solid var(--grey-100);
  border-radius: 16px;
  padding: var(--spacing-500) 0;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 20%);

  @media print {
    box-shadow: none;
    border: 2px solid #000;
    border-radius: var(--spacing-300);
  }

  &__heading-wrapper {
    display: block;
    width: 100%;
    padding: 0 var(--spacing-400);
  }

  &__brand-logo {
    max-width: 100%;
    max-height: 100px;
    display: block;
    margin: 0 auto var(--spacing-300) auto;
  }

  &__brand-name,
  &__heading-copy {
    text-align: center;
  }

  &__brand-name {
    font-weight: 700;
    margin-bottom: var(--spacing-300);
    color: var(--grey-800);
  }

  &__heading-copy {
    color: var(--grey-700);
  }

  &__qr-code-wrapper {
    background-color: #f7f8f9;
    border-radius: 16px;
    padding: var(--spacing-500);
    width: fit-content;
    max-width: 60%;
    margin: auto;
    margin-top: var(--spacing-500);
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    img {
      width: 100%;

      @media print {
        max-width: 175px;
      }
    }

    a {
      margin-top: var(--spacing-400);
      display: block;
      text-align: center;
      color: var(--grey-700);
      text-decoration: none;
    }
  }

  &__powered-by-loop-badge {
    padding: var(--spacing-100) var(--spacing-300);
    border: 2px solid black;
    border-radius: var(--corners);
    width: fit-content;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    text-decoration: none;

    .base-text {
      color: black !important;

      &::selection {
        background: var(--grey-200);
      }
    }
  }

  &__loop-logo {
    margin-left: var(--spacing-200);
    padding-top: 6px;
    max-width: 60px;
    height: auto;
  }
}
</style>
