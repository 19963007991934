import { Factory, Model } from 'miragejs';
import { returns } from '../mocks/returns';

const models = () => {
  return {
    customer: Model,
  };
};

const factories = () => {
  return {
    customer: Factory.extend({
      'customer': {
        'id': 2,
        'hasEmail': true,
        'hasAddress': true,
      },
      'address': {
        'id': 9,
      }
    })
  };
};

const seed = (context) => {
  returns.forEach((item) => {
    const { return_key, customer, address } = item;
    context.create('customer', {
      return_key,
      customer,
      address
    });
  });
};

const routes = (context) => {
  context.get('/:shopId/return/:returnKey/customer', (schema, request) => {
    const return_key = request.params.returnKey;
    return findOrFail(schema, return_key);
  });
};

const findOrFail = (schema, returnKey) => {
  const item = schema.customers.findBy({ return_key: returnKey });
  if (item) {
    return item.attrs;
  }

  return new Response(
    404,
    { 'Content-Type': 'application/json' },
    {
      errors: [{
        title: 'return not found',
        description: 'return could not be found'
      }]
    }
  );
};

export default {
  factories,
  routes,
  models,
  seed
};
