<template>
  <address
    v-if="isJapaneseAddress"
    class="address-display"
    :class="{ 'address-display--left': align === 'left', 'address-display--right': align === 'right' }"
  >
    <span
      class="address-display__line"
      :class="{ 'address-display__line--leading-none' : leading === 'none' }"
    >
      {{ japaneseZip }}
    </span>

    <span
      class="address-display__line"
      :class="{ 'address-display__line--leading-none' : leading === 'none' }"
    >
      {{ japanesePrefectureCitySubarea }}
    </span>

    <span
      class="address-display__line"
      :class="{ 'address-display__line--leading-none' : leading === 'none' }"
    >
      {{ address.name }}
    </span>

    <span
      v-if="hasPhone"
      class="address-display__line address-display__phone-line"
      :class="{ 'address-display__line--leading-none' : leading === 'none' }"
    >
      <a
        class="address-display__phone-link"
        :href="`tel:${address.phone}`"
      >
        {{ address.phone }}
      </a>
    </span>
  </address>

  <address
    v-else
    class="address-display"
    :class="{ 'address-display--left': align === 'left', 'address-display--right': align === 'right' }"
  >
    <span
      class="address-display__line"
      :class="{ 'address-display__line--leading-none' : leading === 'none' }"
    >
      {{ address.name }}
    </span>

    <span
      class="address-display__line"
      :class="{ 'address-display__line--leading-none' : leading === 'none' }"
    >
      {{ address.address1 }}
    </span>

    <span
      v-if="address.address2"
      class="address-display__line"
      :class="{ 'address-display__line--leading-none' : leading === 'none' }"
    >
      {{ address.address2 }}
    </span>

    <span
      class="address-display__line"
      :class="{ 'address-display__line--leading-none' : leading === 'none' }"
    >
      {{ cityStateZip }}
    </span>

    <span
      class="address-display__line"
      :class="{ 'address-display__line--leading-none' : leading === 'none' }"
    >
      {{ country }}
    </span>

    <span
      v-if="hasPhone"
      class="address-display__line address-display__phone-line"
      :class="{ 'address-display__line--leading-none' : leading === 'none' }"
    >
      <a
        class="address-display__phone-link"
        :href="`tel:${address.phone}`"
      >
        {{ address.phone }}
      </a>
    </span>
  </address>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      required: true,
      validator(value) {
        const keys = Object.keys(value);
        return ['address1', 'city', 'country', 'zip'].every((item) =>
          keys.includes(item),
        );
      },
    },
    leading: {
      type: String,
      default: 'normal',
      validator: val => ['normal', 'none'].includes(val)
    },
    align: {
      type: String,
      default: 'center',
      validator: val => ['left', 'center', 'right'].includes(val)
    }
  },
  computed: {
    hasPhone() {
      return !!(this.address.phone && this.address.phone !== '');
    },
    cityStateZip() {
      const state = this.address.state ? ` ${this.address.state}` : '';

      if (this.isSwissAddress) {
        return `${this.address.zip} ${this.address.city},${state}`;
      } else {
        return `${this.address.city},${state} ${this.address.zip}`;
      }
    },
    country() {
      return this.address.country;
    },
    japaneseZip() {
      return `〒${this.address.zip}`;
    },
    japanesePrefectureCitySubarea() {
      let addressLine = `${this.address.state} ${this.address.city} ${this.address.address1}`;

      if (this.address.address2)
        addressLine = `${addressLine} ${this.address.address2}`;

      return addressLine;
    },
    isJapaneseAddress() {
      return this.address.country === 'Japan' && this.$store.state.language === 'ja';
    },
    isSwissAddress() {
      return this.address.country === 'Switzerland';
    }
  },
};
</script>

<style lang="scss" scoped>
$block: '.address-display';

#{$block} {
  display: flex;
  flex-direction: column;
  font-style: normal;

  &__line {
    & + & {
      margin-top: var(--spacing-100);

      &--leading-none {
        margin-top: 0;
      }
    }
  }

  &__phone-line {
    padding-top: var(--spacing-200);

    a {
      color: var(--body-color);
    }
  }

  &--left {
    align-items: flex-start;
  }

  &--right {
    align-items: flex-end;
  }
}
</style>
