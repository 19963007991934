import { Model } from 'miragejs';
import { orders } from './mocks/orders';
import { createLineItem } from './mocks/lineItems';

const serializers = (Serializer) => {
  return {
    order: Serializer.extend({
      root: false,
      embed: true
    }),
  };
};

const models = () => {
  return {
    order: Model,
  };
};

const factories = () => {
  return {};
};

const seed = (context) => {
  orders.forEach((item) => {
    const line_item_ids = [];
    if (item.line_item_ids) {
      item.line_item_ids.forEach((line) => {
        let res;
        if (Array.isArray(line)) {
          res = context.create('lineItem', createLineItem(...line));
        } else {
          res = context.create('lineItem', createLineItem(line));
        }
        line_item_ids.push(+res.attrs.id);
      });
    }
    context.create('order', {
      ...item,
      line_item_ids
    });
  });
  console.info(`%c Seeded ${orders.length} example orders: ${orders.map((item) => item.name).join(', ')}.`, 'color: #0045FF');
  console.info(`%c You can use these to test different order scenarios by using the string above as the order name, combined with any zip.`, 'color: #0045FF');
};

export default {
  serializers,
  models,
  factories,
  seed,
};
