// Pull key from file name and uppercase it
function getKey(filePath) {
  return filePath.split('/').pop().split('.').shift();
}

// Automatically load all language base files
function loadLanguages() {
  const modules = import.meta.glob('./*.json', { as: 'raw', eager: true });
  return Object.entries(modules).reduce((acc, [key, mod]) => {
    return {
      ...acc,
      [getKey(key)]: JSON.parse(mod),
    };
  }, {});
}

export default loadLanguages();
