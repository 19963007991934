<template>
  <div class="survey-form">
    <base-text
      class="survey-form__heading"
      tag="h2"
      type="display-medium"
    >
      <render-content>
        {{ $content.moduleSurvey.question }}
      </render-content>
    </base-text>

    <div
      class="survey-form__faces"
    >
      <survey-face
        v-for="face in faces"
        :key="face"
        :score="face"
        :selected="selectedScore === face"
        @click-face="clickFace"
      />
    </div>

    <div
      v-if="showComment"
      class="survey-form__comment"
    >
      <base-input
        v-model="comment"
        type="textarea"
        :label="$content.moduleSurvey.commentHint"
      />
    </div>

    <div
      v-if="showComment"
      class="survey-form__submit"
    >
      <base-button
        type="secondary"
        :disabled="sending"
        @click="submitSurvey()"
      >
        {{ $content.moduleSurvey.submitLabel }}
      </base-button>
    </div>

    <div
      v-if="error"
      class="survey-form__error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseInput,
  BaseText
} from '@loophq/design-system';
import SurveyFace from '@/views/StatusPage/CustomerSurvey/SurveyFace';
import Survey from '@/js/controllers/survey';

export default {
  name: 'SurveyForm',
  components: {
    BaseButton,
    BaseInput,
    BaseText,
    SurveyFace,
  },
  props: {
    returnKey: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      required: true,
    }
  },
  emits: [
    'surveyReceived',
  ],
  data() {
    return {
      faces: [1, 2, 3, 4, 5],
      selectedScore: 0,
      comment: '',
      sending: false,
      error: null,
    };
  },
  computed: {
    showComment() {
      return this.selectedScore !== 0;
    }
  },
  created() {
    if (this.$route.query.score > 0) {
      this.selectedScore = parseInt(this.$route.query.score[0]);
    }
  },
  methods: {
    clickFace(newScore) {
      this.selectedScore = newScore;
    },
    async submitSurvey() {
      this.error = null;
      this.sending = true;
      try {
        await Survey.submit(
          this.$shop.id,
          this.returnKey,
          {
            source: this.source,
            score: this.selectedScore,
            comment: this.comment
          }
        );
        this.$emit('surveyReceived');
      } catch (e) {
        this.error = 'Unable to save your rating, please refresh the page and try again.';
      }
      this.sending = false;
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.survey-form';

#{$block} {
  &__heading {
    color: var(--grey-900);
    text-align: center;
    margin-bottom: 20px;
  }

  &__faces {
    text-align: center;
  }

  &__comment {
    margin: 40px auto 0;
    width: 60%;
  }

  &__submit {
    margin: 20px auto;
    width: 60%;

    button {
      width: 100%;
      background: var(--primary-text-color);
      border: 1px solid var(--grey-200);
    }
  }

  &__error {
    text-align: center;
    color: var(--red-300);
  }
}
</style>
