<template>
  <main class="app-container">
    <!-- Banner is used when we need to send messages to customers -->
    <information-banner
      v-if="hasInformationalFlag"
    />
    <back-to-shop
      :id="hasInformationalFlag ? 'downtime' : null"
      class="back-to-shop-large"
    />
    <div class="app-wrap">
      <!-- For accessibility reasons we need an h1 here, but the app-header already visually shows one -->
      <visually-hidden>
        <h1>
          {{ $shop.name }}
        </h1>
      </visually-hidden>

      <app-header class="lookup-banner" />

      <transition
        name="card"
        mode="out-in"
        appear
      >
        <component
          v-bind="props"
          :is="module"
          :key="module"
          class="main-content"
          v-on="actions"
          @handle-missing-address="handleMissingAddress"
        />
      </transition>

      <app-footer :page="lookupPage" />
    </div>
  </main>
</template>

<script>
import {
  VisuallyHidden,
} from '@loophq/design-system';
import BackToShop from '@/components/app/BackToShop.vue';
import PortalInactive from '@/views/LookupPage/PortalInactive.vue';
import CaptureEmail from '@/views/LookupPage/CaptureEmail.vue';
import CaptureAddress from '@/views/LookupPage/CaptureAddress.vue';
import RegularLookup from '@/views/LookupPage/RegularLookup.vue';
import GiftLookup from '@/views/LookupPage/GiftLookup.vue';
import InfoForm from '@/views/LookupPage/InfoForm.vue';
import StoreLocator from '@/views/LookupPage/StoreLocator.vue';
import InformationBanner from './LookupPage/InformationBanner.vue';
import { inIframe } from '@/js/messages';
import { hasCloseLocations, getCloseLocations } from '@/js/helpers/locations';
import { featureFlags } from '@/js/constants/featureFlags';
import { customerPortalPageNames } from '@/js/constants/segment';

export default {
  name: 'LookupPage',
  components: {
    InformationBanner,
    VisuallyHidden,
    RegularLookup,
    GiftLookup,
    BackToShop,
    PortalInactive,
    CaptureEmail,
    InfoForm,
    StoreLocator,
    CaptureAddress,
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (to.name === 'gift') {
        vm.module = 'GiftLookup';
      }
      if (from.name === 'gift') {
        vm.module = 'RegularLookup';
        vm.formData = null;
      }
    });
  },
  data() {
    return {
      formData: null,
      stores: [],
      module: 'RegularLookup',
      payload: null,
      lookupPage: customerPortalPageNames.ORDER_LOOKUP_PAGE
    };
  },
  computed: {
    props() {
      return {
        data: this.formData,
        stores: this.stores,
        criteria: this.$route.query,
        payload: this.payload,
      };
    },
    actions() {
      return {
        success: this.handleOrderSuccess,
        open: () => this.openForm({
          header: 'heading',
          subheader: 'subheading',
          forms: [
            {
              label: 'gifter',
              addendum: this.$content.moduleMissingOrder.gifterAddendum,
              key: 'gifter',
              type: 'text',
              value: '',
              required: true
            },
            {
              label: 'productReturn',
              key: 'product_return',
              type: 'text',
              value: '',
              required: true
            },
            {
              label: 'productRequest',
              addendum: this.$content.moduleMissingOrder.productRequestAddendum,
              key: 'product_request',
              type: 'text',
              value: '',
              required: true
            },
            {
              label: 'giftInfo',
              key: 'gift_info',
              type: 'textarea',
              value: ''
            }
          ]
        }),
        closeForm: this.closeForm,
        navigate: this.updateModule,
      };
    },
    hasInformationalFlag() {
      let type = this.$store.getters.hasFeature(featureFlags.ENABLE_CUSTOMER_PORTAL_INFORMATION_BANNER);

      if (typeof type !== 'string') {
        return false;
      }

      type = type.split(':')[0];
      return type === 'loop' || type === 'error';
    }
  },
  mounted() {

    if (this.$shop.state === 'uninstalled' || this.$shop.state === 'inactive' || !this.$shop.portal_active) {
      this.module = 'PortalInactive';
    }

    if (this.$route.name === 'gift') {
      this.module = 'GiftLookup';
    }

    if (this.$route.name === 'customerLookup') {
      this.module = 'CustomerLookup';
    }

    if (this.$route.name === 'customerHistory') {
      this.module = 'CustomerHistory';
    }
  },

  methods: {
    updateModule({ name }) {
      this.module = name;
    },
    openForm(opts) {
      this.formData = {
        header: 'formHeading',
        subheader: 'formSubheading',
        forms: [],
        addressInput: true,
        gift: true,
        back: 'goBack',
        ...opts
      };

      this.$nextTick(() => {
        this.module = 'InfoForm';
      });
    },
    closeForm() {
      this.formData = null;
      this.module = 'GiftLookup';
    },
    handleOrderSuccess({ action, data = {} }) {
      const { order } = data;

      if (order) {
        this.$store.dispatch('initOrder', order);
        this.$store.commit('return/clear');
      }

      if (this.$route.name === 'gift') {
        this.$store.commit('updateOrder', {
          ...order,
          enabled: {
            ...order.enabled,
            refund: 'no',
          },
          gift: true,
        });
        this.$store.commit('return/setIsGift', true);
        this.openForm();
        return false;
      }

      if (hasCloseLocations(order?.stores) && !inIframe()) {
        this.stores = getCloseLocations(order.stores);
        this.module = 'StoreLocator';
        this.$store.dispatch('getStoreLocatorOrderQrCode');
        return;
      }

      if (action && action.type === 'loadModule') {
        this.module = action.target;
        return false;
      }

      this.$trackEvent('successful lookup');

      if (order) {
        this.$store.commit('return/setWorkflowGroupId', order.active_workflow_group_id);
      }

      if (order) {
        const { currencies } = order;
        this.$store.dispatch('currencies/setData', { ...currencies });
      }

      // eslint-disable-next-line no-unused-vars
      this.$router.push({ name: 'exchange' });
    },
    handleMissingAddress(payload) {
      this.module = 'CaptureAddress';
      this.payload = payload;
    },
  }
};
</script>

<!-- TODO - Ensure that we update the CSS formatting once removing the component -->
<style lang="scss" scoped>
.app-container {
  margin-top: 0;
  overflow-x: hidden;
}

:deep(.alert-banner) {
  margin: 0 var(--spacing-300) var(--spacing-300);
  background-color: #fff;
  display: flex;
  align-items: center;

  .alert-banner__content {
    width: auto !important;

    .base-text {
      font-size: 16px;
    }
  }
}

#downtime.back-to-shop-large.back-to-shop-large {
  position: fixed;
  left: var(--spacing-500);
  top: var(--spacing-500);
  margin-top: 6rem;
}

.back-to-shop-large.back-to-shop-large {
  position: fixed;
  left: var(--spacing-500);
  top: var(--spacing-500);
}

.lookup-banner {
  margin-top: var(--spacing-600);
  margin-bottom: var(--spacing-600);
}

.main-content {
  margin: 0 auto;
}

@media screen and (max-width: $break-small) {
  .back-to-shop-large {
    display: none;
  }

  .lookup-banner {
    margin-top: 0;
    margin-bottom: var(--spacing-600);
  }
}
</style>
