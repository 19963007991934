<template>
  <loop-sidecart
    v-bind="props"
    :active="active"
    :products="cart"
    :total="totals.credit.available"
    :loading="loading"
    :view="shopNow"
    @toggle="toggle"
    @update="updateProducts"
    @next="toReview"
    @back="toReturnList"
  />
</template>

<script>
import LoopSidecart from './Sidecart/LoopSidecart';
import defaults from './Sidecart/defaults';
import { views } from '../../js/constants/views';

export default {
  name: 'ShopNowSidecart',
  components: {
    LoopSidecart,
  },
  beforeRouteLeave (to, from, next) {
    // Force it to wait for the animation to finish
    setTimeout(() => {
      next();
    }, 240);
  },
  data() {
    return {
      loading: false,
      shopNow: views.SHOP_NOW
    };
  },
  computed: {
    props() {
      const theme = this.$theme;

      return {
        theme: {
          ...defaults.theme,
          cornerRadius: theme.globals.sharpness === 'round' ? '3px' : '0',
          primaryColor: theme.branding.primaryColor || null,
          headerFont: theme.heading.font || null,
          copyFont: theme.body.font || null
        },
        text: defaults
      };
    },
    store() {
      return this.$store.state.shopnow;
    },
    active: {
      get() {
        return this.store.cartActive;
      },
      set(value) {
        this.$store.commit('shopnow/setProperty', { key: 'cartActive', value });
      }
    },
    order() {
      return this.$store.getters.order;
    },
    totals() {
      return this.$store.state.totals.totals;
    },
    productId() {
      return +this.$route.params.productId;
    },
    product() {
      return this.store.products.find(item => item.id === this.productId) || { id: this.productId };
    },
    variants() {
      return this.$store.state.products.items[this.productId]?.variants || null;
    },
    price() {
      return this.product.price;
    },
    hasVariant() {
      return this.product.options && this.product.options.length === this.selected.length;
    },
    cart() {
      return this.order.cart || [];
    },
    selectedVariant() {
      if (this.hasVariant && this.variants) {
        return this.variants.find(variant => {
          // Check that every option in the selected array matches the options in
          // this variant
          return this.selected.every(item => variant[item.option] === item.value);
        });
      }
      return null;
    }
  },
  mounted() {
    this.active = true;

  },
  methods: {
    toggle() {
      this.active = !this.active;

      if (!this.active) {
        this.$router.push('/shop');
      }
    },
    async updateProducts(removeIndex) {
      this.loading = true;
      let products = this.cart.filter((_, index) => index !== removeIndex);
      this.$store.commit('updateCart', products);
      this.$store.commit('return/updateNewItems', products);
      await this.$store.dispatch('totals/get');
      this.loading = false;
    },
    async toReview() {
      this.$store.commit('setShopNowNavigation', true);
      this.loading = true;
      await this.$store.dispatch('totals/get');
      this.loading = false;
      const toPage = this.order.cart && !this.order.cart.length ? 'credit' : 'review';
      this.$router.push({ name: toPage });
    },
    async toReturnList() {
      this.loading = true;
      await this.$store.dispatch('totals/get');
      this.loading = false;
      this.$router.push({ name: 'exchange' });
    }
  }
};
</script>
