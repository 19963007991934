<template>
  <base-card class="multicurrency-banner">
    <base-text
      class="banner-heading"
      tag="span"
      type="display-small"
    >
      <render-content>
        {{ $content.moduleMulticurrency.heading }}
      </render-content>
    </base-text>

    <base-text
      class="banner-toggle"
      type="body-2"
      tag="button"
      @click.prevent="active = !active"
    >
      <render-content>
        {{ buttonText }}
      </render-content>
    </base-text>

    <transition-expand>
      <div v-if="active">
        <base-text
          class="banner-text"
          type="body-2"
        >
          <render-content :data="{ orderCurrency }">
            {{ $content.moduleMulticurrency.details }}
          </render-content>
        </base-text>
      </div>
    </transition-expand>
  </base-card>
</template>

<script>
import {
  BaseCard,
  BaseText,
} from '@loophq/design-system';

export default {
  name: 'MulticurrencyBanner',
  components: {
    BaseCard,
    BaseText,
  },
  props: {
    orderCurrency: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      active: false
    };
  },
  computed: {
    buttonText() {
      const property = this.active ? 'hide' : 'show';
      return this.$content.moduleMulticurrency[property];
    }
  }
};
</script>

<style lang="scss" scoped>
.banner-heading {
  margin-bottom: var(--spacing-300);
}

.banner-toggle {
  background: none;
  appearance: none;
  border: none;
  align-self: flex-start;
  padding: 0;
  color: var(--grey-700);
  font-weight: 600;
}

.banner-text {
  padding-top: var(--spacing-300);
  color: var(--grey-700);
}

@media screen and (width <= 680px) {
  .multicurrency-banner {
    padding: var(--spacing-400);
  }
}
</style>
