/**
 * Builds an array of product option values from an array of variants
 * @param {number} position - Which option this is (1, 2, or 3)
 * @param {object[]} variants - An array of product variants
 */
const getOptionValues = (position, variants) => {
  // Pushing an array into a set and then casting back to an array is
  // gets us an array of unique strings, in this case an array of the values
  // we actually have for this product option
  return [
    ...new Set(
      // Map all the variants to their data for this option
      variants.map((variant) => {
        return variant[`option${position}`];
      })
    )
  ];
};

/**
 * Takes an array of product options and variants and gives you the correct set of option values for the options
 * @param {object[]} options - An array of product options
 * @param {object[]} options[].position - Which option this object is related to
 * @param {object[]} variants - An array of product variants
 */
export const formatOptions = (options, variants) => {
  return options.map((option) => {
    return {
      ...option,
      values: getOptionValues(option.position, variants)
    };
  });
};

/**
 * Takes an array of product options and gives you an object of { option1: 'option name' }
 * Useful if you're passing option data into a customization renderer
 * @param {object[]} options - An array of product options
 * @param {object[]} options[].position - Which option this object is related to
 */
export const formatOptionVariables = (options) => {
  return options
    .reduce((acc, cur) => {
      return {
        ...acc,
        [`option${cur.position}`]: cur.name.toLowerCase()
      };
    }, {});
};
