<template>
  <div class="tracking-event-timeline">
    <eyebrow />

    <base-text
      type="display-large"
      class="tracking-event-timeline__heading"
    >
      {{ headingCopy }}
    </base-text>
    <carrier-link
      class="tracking-event-timeline__carrier-link"
      :show-label-info="false"
    />

    <div class="steps">
      <div
        class="step-item"
        :class="{
          'is-active': preTransitActive,
          'is-complete': preTransitComplete,
        }"
        data-step-id="0"
      >
        <div class="step-marker">
          <div class="step-marker-indicator">
            <base-icon
              v-if="preTransitComplete"
              name="check"
              aria-hidden="true"
            />
          </div>
        </div>
        <div class="step-details">
          <p class="step-title">
            {{ preTransitCopy }}
          </p>
        </div>
      </div>
      <div
        class="step-item"
        :class="{
          'is-active': inTransitActive,
          'is-complete': inTransitComplete,
        }"
        data-step-id="1"
      >
        <div class="step-marker">
          <div class="step-marker-indicator">
            <base-icon
              v-if="inTransitComplete"
              name="check"
              aria-hidden="true"
            />
          </div>
        </div>
        <div class="step-details">
          <p class="step-title">
            {{ inTransitCopy }}
          </p>
        </div>
      </div>
      <div
        class="step-item"
        :class="{
          'is-active': deliveredActive,
          'is-complete': deliveredComplete,
        }"
        data-step-id="2"
      >
        <div class="step-marker">
          <div class="step-marker-indicator">
            <base-icon
              v-if="deliveredComplete"
              name="check"
              aria-hidden="true"
            />
          </div>
        </div>
        <div class="step-details">
          <p class="step-title">
            {{ deliveredCopy }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import { BaseIcon, BaseText } from '@loophq/design-system';
import { labelStatuses } from '@/js/constants/labels';
import CarrierLink from './CarrierLink';
import Eyebrow from './Eyebrow';

const normalizedReturn = inject('normalizedReturn');

const store = useStore();

const headingCopy = computed(() => {
  if (!normalizedReturn.value?.exchanges.length) {
    return store.getters['content']?.moduleReturnTracking.eventTimelineHeading;
  }

  return store.getters['content']?.moduleExchangeTracking.eventTimelineHeading;
});

const preTransitCopy = computed(() => {
  if (!normalizedReturn.value?.exchanges.length) {
    return store.getters['content']?.moduleReturnTracking.eventTimelineStatusPreTransit;
  }

  return store.getters['content']?.moduleExchangeTracking.eventTimelineStatusPreTransit;
});

const inTransitCopy = computed(() => {
  if (!normalizedReturn.value?.exchanges.length) {
    return store.getters['content']?.moduleReturnTracking.eventTimelineStatusInTransit;
  }

  return store.getters['content']?.moduleExchangeTracking.eventTimelineStatusInTransit;
});

const deliveredCopy = computed(() => {
  if (!normalizedReturn.value?.exchanges.length) {
    return store.getters['content']?.moduleReturnTracking.eventTimelineStatusDelivered;
  }

  return store.getters['content']?.moduleExchangeTracking.eventTimelineStatusDelivered;
});

const preTransitActive = computed(() => {
  return normalizedReturn.value.label?.status === labelStatuses.PRE_TRANSIT;
});

const preTransitComplete = computed(() => {
  return normalizedReturn.value.label?.status !== labelStatuses.PRE_TRANSIT;
});

const inTransitActive = computed(() => {
  return normalizedReturn.value.label?.status === labelStatuses.IN_TRANSIT;
});

const inTransitComplete = computed(() => {
  return normalizedReturn.value.label?.status === labelStatuses.OUT_FOR_DELIVERY || normalizedReturn.value.label?.status === labelStatuses.DELIVERED;
});

const deliveredActive = computed(() => {
  return normalizedReturn.value.label?.status === labelStatuses.OUT_FOR_DELIVERY;
});

const deliveredComplete = computed(() => {
  return normalizedReturn.value.label?.status === labelStatuses.DELIVERED;
});
</script>

<style lang="scss" scoped>
$block: '.tracking-event-timeline';

#{$block} {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  padding: var(--spacing-500) 0;

  .base-text {
    margin-top: var(--spacing-200);
  }

  &__heading {
    margin-bottom: var(--spacing-200);
  }

  &__carrier-link {
    margin-bottom: var(--spacing-500);
  }
}

.steps {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .step-item {
    margin-top: 0;
    position: relative;
    flex-grow: 1;
    flex-basis: 0;

    &:not(:first-child)::before {
      height: 0.2em;
      width: 100%;
      bottom: 0;
      left: -50%;
      top: 1rem;
    }

    &:not(:first-child) {
      flex: 1 1 1em;

      &::before {
        content: " ";
        display: block;
        position: absolute;
      }
    }

    &::before {
      background: linear-gradient(to left, #000 50%, #000 50%);
      background-size: 200% 100%;
      background-position: right bottom;

      .step-marker {
        color: white;
        width: 1.5rem;
        height: 1.5rem;
        background-color: #000;
      }
    }

    &.is-active {
      &::before {
        background-position: left bottom;
      }

      .step-marker {
        border-color: black;
      }

      .step-details {
        p {
          color: black;
        }
      }
    }

    &.is-complete {
      &::before {
        background-position: left bottom;
      }

      .step-marker {
        border-color: black;
      }

      .step-details {
        p {
          color: black;
          font-weight: 600;
        }
      }
    }

    .step-marker {
      align-items: center;
      display: flex;
      border-radius: 50%;
      font-weight: bold;
      justify-content: center;
      background: white;
      color: white;
      border: 2px solid white;
      z-index: 1;
      position: absolute;
      left: calc(50% - 16px);
      padding: 0.5rem;
    }

    .step-marker-indicator {
      width: 16px;
      height: 16px;
      background: black;
      border-radius: 50%;

      .base-icon {
        width: 0.85rem;
        height: 0.85rem;
        padding-left: 2px;
        padding-top: 2px;
      }
    }

    .step-details {
      margin-top: 2.5rem;
      margin-left: 0.5em;
      margin-right: 0.5em;
      padding-top: 0.2em;
      text-align: center;

      p {
        color: var(--body-color);
      }
    }
  }

  .steps-content {
    align-items: stretch;
    flex-basis: 100%;
    margin: 2rem 0;

    .step-content {
      display: none;

      &.is-active {
        display: block;
      }
    }
  }

  .steps-actions {
    display: flex;
    align-items: stretch;
    flex-basis: 100%;

    .steps-action {
      display: flex;
      flex-basis: 0;
      flex-grow: 1;
      margin: 0.5rem;
      justify-content: center;
      align-items: center;
    }
  }

  &.is-animated {
    .step-item {
      &::before {
        transition: all 2s ease;
      }

      .step-marker {
        transition: all 0s ease;
        transition-delay: 1.5s;
      }
    }
  }
}

@media screen and (width <= 1402px) {
  #{$block} {
    p.base-text {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
