<template>
  <transition
    name="offline"
    appear
  >
    <div class="offline-message">
      <base-card class="offline-message__card">
        <p>
          {{ $content.moduleOffline.copy }}
        </p>
      </base-card>
    </div>
  </transition>
</template>

<script>
import BaseCard from '@/components/layout/Card';

export default {
  components: {
    BaseCard
  }
};
</script>

<style lang="scss" scoped>
$block: '.offline-message';

#{$block} {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(black, 0.5);
  backdrop-filter: grayscale(100%);

  &.offline-enter-active,
  &.offline-leave-active {
    transition: opacity 0.06s;
  }

  &.offline-enter,
  &.offline-leave-to {
    opacity: 0;
  }

  &__card {
    max-width: 400px;
  }
}
</style>
