<template>
  <div class="tracking-exchange-outcome">
    <base-card>
      <base-text class="tracking-exchange-outcome__eyebrow">
        {{ $content?.moduleReturnTracking.eyebrow }}{{ normalizedReturn.orderName }}
      </base-text>

      <error
        v-if="hasFulfillmentTrackingError"
        :is-exchange="true"
      />

      <base-text
        class="tracking-exchange-outcome__heading"
        type="display-medium"
      >
        {{ header }}
      </base-text>
      <base-text
        class="tracking-exchange-outcome__copy"
      >
        {{ body }}
      </base-text>

      <a
        v-if="hasFulfillmentTrackingError"
        :href="normalizedReturn.fulfillments[0].tracker.carrierTrackingUrl"
        target="_blank"
      >{{ exchangeDelayedLink }}</a>

      <base-button
        icon="truck"
        :loading="loadingExchangeOrder"
        :disabled="trackExchangeButtonDisabled"
        @click.prevent="trackExchange"
      >
        {{ trackExchangeButton }}
      </base-button>
    </base-card>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { BaseButton, BaseCard, BaseText } from '@loophq/design-system';
import { getExchangeOrder } from '@/js/controllers/exchangeOrder';
import Error from '@/views/StatusPage/Tracking/Error';

const store = useStore();

const normalizedReturn = inject('normalizedReturn');

const header = store.getters['content']?.moduleExchangeTracking.header;
const body = store.getters['content']?.moduleExchangeTracking.body;
const exchangeDelayedLink = store.getters['content']?.moduleExchangeTracking.exchangeDelayedLink;
const trackExchangeButton = store.getters['content']?.moduleExchangeTracking.trackExchangeButton;

const loadingExchangeOrder = ref(true);
const exchangeOrder = ref(null);

const trackExchangeButtonDisabled = computed(() => {
  return !loadingExchangeOrder.value && (!exchangeOrder.value || normalizedReturn.value.trackingUrl);
});

const hasFulfillmentTrackingError = computed(() => {
  const fulfillmentsWithTerminalError = normalizedReturn.value.fulfillments.filter(fo => {
    return fo.tracker?.terminalError;
  });

  return !!fulfillmentsWithTerminalError.length;
});

const trackExchange = () => {
  if (normalizedReturn.value.trackingUrl) {
    window.open(normalizedReturn.value.trackingUrl, '_open');
    return;
  }

  window.open(exchangeOrder.value.orderStatusUrl, '_blank');
};

const fetchExchangeOrder = async () => {
  loadingExchangeOrder.value = true;

  const shopId = store.state.shopContents.id;

  await getExchangeOrder(shopId, normalizedReturn.value.returnKey).then((res) => {
    exchangeOrder.value = res;
  }).finally(() => {
    loadingExchangeOrder.value = false;
  });
};

onMounted(() => {
  if (!normalizedReturn.value.trackingUrl) {
    fetchExchangeOrder();
  }
});
</script>

<style lang="scss" scoped>
$block: '.tracking-exchange-outcome';

#{$block} {
  display: flex;
  flex-direction: column;

  &__eyebrow {
    margin-bottom: var(--spacing-200);
    font-size: 14px;
    color: var(--body-color);
  }

  &__heading {
    color: black;
    margin-bottom: var(--spacing-200);
  }

  a {
    margin-top: var(--spacing-200);
  }

  button {
    margin-top: var(--spacing-400);
    align-self: flex-start;
  }
}
</style>
