import axios from 'axios';

export const getWorkflowResults = async function (shopId, data) {
  const response = await axios.post(`api/v1/${shopId}/workflows/evaluate`, data);
  return response?.data;
};

export const mapWorkflowsPayload = function (lineItem, order, returnData) {
  // Yes or no responses sets unique keys on the lineItem object so we need to grab those
  const inputKeys = Object.keys(lineItem).filter(key => key.includes('user_input'));
  const yesNoInputs = inputKeys.reduce((obj, input) => {
    return {
      ...obj,
      [input]: lineItem[input],
    };
  }, {});

  const returningItems = Object.values(order.line_items).filter(item => !!item.returnType).map(item => {
    return {
      id: item.id,
      resolution: item.resolution,
      hasPartialReturnCreditOffer: item.lineItemFees?.creditPercentage,
    };
  });

  return {
    lineItem: {
      id: lineItem.id,
      reason: {
        id: lineItem?.reason?.id ? lineItem.reason.id : lineItem?.reason?.reason_id
      },
      userInputs: yesNoInputs,
    },
    returningItems,
    cart: order.cart ?? [],
    return: {
      item_count: returningItems?.length ?? null,
      workflow_group_id: returnData.workflowGroupId ?? null,
    }
  };
};
