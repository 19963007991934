<template>
  <span class="popper">
    <span
      ref="trigger"
      class="popper__trigger"
    >
      <slot></slot>
    </span>
    <transition name="fade">
      <span
        v-show="isActive"
        ref="popper"
        class="popper__content-wrapper"
        :class="[alignArrow, { fading }]"
      >
        <span
          class="popper__content"
          :aria-labelledby="name"
          role="region"
        >
          <slot name="content">{{ content }}</slot>
        </span>
      </span>
    </transition>
  </span>
</template>

<script>
import Popper from 'popper.js';

export default {
  props: {
    active: {
      type: Boolean,
      required: true,
      default: () => false
    },
    content: {
      type: String,
      required: false,
      default: () => ''
    },
    alignArrow: {
      type: String,
      required: false,
      validator: value => ['left', 'right'].includes(value)
    },
    placement: {
      type: String,
      required: false,
      default: () => 'top',
      validator: value => ['top', 'bottom'].includes(value)
    },
    name: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      instance: null,
      isActive: false,
      animationDelay: 0,
      fading: false
    };
  },
  computed: {
    popperPlacement() {
      let variation = '';

      if (this.alignArrow === 'left') {
        variation = '-start';
      }

      if (this.alignArrow === 'right') {
        variation = '-end';
      }

      return `${this.placement}${variation}`;
    }
  },
  watch: {
    active() {
      if (!this.active && this.instance) {
        this.fading = true;

        setTimeout(() => {
          this.isActive = this.active;
          //This was causing it to flash on the screen briefly when closing, not sure if needed
          // this.instance.destroy();
          this.fading = false;
        }, this.animationDelay);
      }

      if (this.$slots.default() && this.active) {
        this.isActive = this.active;
        this.instance = new Popper(this.$refs.trigger, this.$refs.popper, {
          placement: this.popperPlacement
        });
      }
    }
  },
  created() {
    this.isActive = this.active;
  },
  mounted() {
    if (this.$slots.default() && this.active) {
      this.instance = new Popper(this.$refs.trigger, this.$refs.popper, {
        placement: this.popperPlacement
      });
    }
  },
  updated() {
    if (this.$slots.default() && this.active && this.instance) {
      this.$nextTick(() => {
        this.instance.scheduleUpdate();
      });
    }
  },
  unmounted() {
    if (this.instance) {
      this.instance.destroy();
    }
  }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.24s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.popper {
  position: relative;
  width: 100%;

  &__content-wrapper {
    position: absolute;
    top: calc(100% + 1rem);
    z-index: 100000;
    display: flex;
    background-color: white;
    border: 1px solid rgba(6, 44, 82, 15%);
    filter: drop-shadow(0 2px 16px rgba(84, 102, 121, 15%));
    border-radius: 6px;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      width: 12px;
      height: 12px;
      border-top-left-radius: 2px;
      background-color: white;
      left: calc(50% - 6px);
    }

    &.left {
      &::before {
        left: 1rem;
      }
    }

    &.right {
      &::before {
        left: auto;
        right: 1rem;
      }
    }

    &.fading {
      opacity: 0;
      transition: opacity 0.24s ease;
    }

    &[x-placement^="top"] {
      margin-bottom: 1rem;

      &::before {
        transform: rotate(-135deg);
        bottom: -7px;
        border-top: 1px solid rgba(6, 44, 82, 15%);
        border-left: 1px solid rgba(6, 44, 82, 15%);
      }
    }

    &[x-placement^="bottom"] {
      margin-top: 1rem;

      &::before {
        transform: rotate(45deg);
        top: -7px;
        border-top: 1px solid rgba(6, 44, 82, 15%);
        border-left: 1px solid rgba(6, 44, 82, 15%);
      }
    }
  }

  &__content {
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 500px;
    // overflow: hidden;
    // overflow-y: auto;
  }
}

@media screen and (width <= 680px) {
  .popper {
    &__content-wrapper {
      left: 0 !important;
    }
  }
}
</style>
