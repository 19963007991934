<template>
  <div class="hover-nav">
    <base-button
      class="hover-nav__button"
      type="secondary"
      @click="toggle"
    >
      <span class="hover-nav__button-content">
        <render-content>
          {{ $content.pageShopNow.navigationPlaceholder }}
        </render-content>
        <base-icon
          class="hover-nav__icon"
          name="down"
        />
      </span>
    </base-button>
    <Teleport to="body">
      <div
        class="hover-nav__fixed"
      >
        <transition name="fade">
          <div
            v-if="active"
            class="hover-nav__backdrop"
            @click.prevent="toggle"
          ></div>
        </transition>
        <transition name="slideleft">
          <section
            v-if="active"
            class="hover-nav__panel"
          >
            <header class="hover-nav__header">
              <button
                class="hover-nav__close"
                @click.prevent="toggleOrBack"
              >
                <icon-swapper :active="!!activeParent" />
              </button>
              {{ heading }}
            </header>
            <main class="hover-nav__items">
              <slide-nav
                v-if="items.length"
                :items="items"
                @update="updateNav"
              />
            </main>
            <footer class="hover-nav__footer">
              <button
                class="hover-nav__float legacy-button"
                @click.prevent="toggleOrBack"
              >
                <icon-swapper :active="!!activeParent" />
              </button>
            </footer>
          </section>
        </transition>
      </div>
    </Teleport>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseIcon,
} from '@loophq/design-system';
import SlideNav from '@/components/navigation/SlideNav';
import IconSwapper from './ShopNowIconSwapper';

export default {
  components: {
    SlideNav,
    IconSwapper,
    BaseButton,
    BaseIcon,
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      active: false
    };
  },
  computed: {
    buttonText() {
      return this.$store.state.shopnow.collection.title || 'Shop now';
    },
    activeParent() {
      const parent = this.items.find(item => item.active && item.children);
      return parent || null;
    },
    activeCollection() {
      const merged = this.items.reduce((acc, cur) => {
        const children = cur.children ? cur.children : [];
        return [
          ...acc,
          cur,
          ...children
        ];
      }, []);
      const item = merged.find(item => item.active);
      return item;
    },
    heading() {
      if (this.activeParent) {
        return this.activeParent.title;
      }
      return this.$content.pageShopNow.navigationPlaceholder;
    }
  },
  watch: {
    '$route' () {
      // Route change when active means the use has chosen a link
      if (this.active) {
        this.active = false;
      }
    }
  },
  methods: {
    toggle() {
      this.active = !this.active;
    },
    toggleOrBack() {
      // This is a back to parent nav situation
      if (this.activeParent) {
        const activeParentRemoved = this.items.map(item => {
          // Set to false if this is the actuve parent
          // Otherwise pass through the active state
          const active = item.id === this.activeParent.id ? false : item.active;
          return {
            ...item,
            active
          };
        });
        this.$store.commit('shopnow/updateNav', activeParentRemoved);
      } else {
        // This is a normal panel toggle
        this.active = !this.active;
      }
    },
    updateNav(nav) {
      this.$store.commit('shopnow/updateNav', nav);
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.hover-nav';

#{$block} {
  position: relative;
  z-index: 0;

  &__button {
    width: 100%;
  }

  &__button-content {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    color: var(--grey-600);
  }

  &__icon {
    color: black;
  }

  &__fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &__backdrop {
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: rgba($text-color, 0.2);
    pointer-events: auto;
  }

  &__panel {
    position: absolute;
    z-index: 10;
    background-color: white;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
  }

  &__header {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.75rem;
    text-align: center;
    font-weight: 700;
    font-size: 1rem;
    box-shadow: inset 0 -1px 0 0 rgba(216, 218, 223, 75%), 0 2px 4px 0 rgba(23, 24, 26, 2%);
  }

  &__close {
    position: absolute;
    left: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: 0;
    color: $text-color;
    width: 2.75rem;
    height: 2.75rem;

    &:focus,
    &:hover {
      box-shadow: none;
    }
  }

  &__close-icon {
    width: 1rem;
    height: 1rem;
  }

  &__items {
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    overflow-y: auto;
    padding: 1.25rem;
  }

  &__footer {
    flex-shrink: 0;
    position: absolute;
    z-index: 1;
    bottom: 40px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  &__float {
    width: 2.75rem;
    height: 2.75rem;
    background-color: white;
    border: 1px solid $gray-medium;
    box-shadow: 0 4px 16px 0 rgba(23, 24, 26, 25%);
    border-radius: 50%;
    padding: 0;
    color: $text-color;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
