<template>
  <div class="summary-section">
    <div class="summary-section__line">
      <div>
        <base-text
          class="summary-section__label"
          :class="{ active }"
        >
          <render-content v-if="label">
            {{ label }}
          </render-content>
          <base-icon
            v-if="labelIcon"
            class="summary-section__icon"
            :name="labelIcon"
            @click="$emit('toggle-info')"
          />
          <span
            v-if="products"
            class="summary-section__amount"
          >
            ({{ products.length }})
          </span>
          <base-tooltip
            v-if="description"
            class="summary-section__description"
          >
            <template #content>
              <render-content>
                {{ description }}
              </render-content>
            </template>
            <base-icon
              class="summary-section__icon"
              name="info"
            />
          </base-tooltip>
        </base-text>
        <base-text
          v-if="hasHandlingAndCoverage"
          class="summary-section__additional-info"
        >
          {{ $content.global.returnCoverageSubLine }}
          <base-tooltip>
            <template #content>
              <render-content>
                {{ $content.global.returnCoverageTooltip }}
              </render-content>
            </template>
            <base-icon
              class="summary-section__additional-info__tooltip-icon"
              name="help"
            />
          </base-tooltip>
        </base-text>
      </div>
      <base-text
        v-if="!loading"
        class="summary-section__value"
      >
        <template v-if="hasHandlingAndCoverage">
          <s>({{ value }})</s> Free
        </template>
        <template v-else>
          <render-content>
            {{ value }}
          </render-content>
        </template>
      </base-text>
      <base-loader
        v-if="loading"
        size="small"
      />
    </div>
    <div
      v-if="hasPerProductHandlingFeesFF"
      class="summary-section__handling-fee-breakdown"
    >
      <base-text v-if="policyHandlingFee !== 0 && perProductHandlingFee !== 0">
        {{ formatAmount(policyHandlingFee) }} + an additional {{ formatAmount(perProductHandlingFee) }} per returned item
      </base-text>
      <base-text v-else-if="policyHandlingFee == 0 && perProductHandlingFee !== 0">
        {{ formatAmount(perProductHandlingFee) }} per returned item
      </base-text>
    </div>
    <transition-expand>
      <div
        v-if="active && hasExpanded"
        class="summary-section__expanded"
      >
        <div
          v-for="(product, index) in formattedProducts"
          :key="`product-${index}`"
          class="summary-section__product"
        >
          <product-card
            :class="{ disabled: product.oos || product.removed }"
            size="small"
            :product="product"
            :badge="badgeInfo(product)"
          >
            <template
              v-if="product.oos"
              #utility
            >
              <span class="summary-section__oos">
                <render-content>
                  {{ $content.global.noStock }}
                </render-content>
              </span>
            </template>
            <template
              v-else-if="product.removed"
              #utility
            >
              <span class="summary-section__removed">
                <render-content>
                  {{ $content.global.removed }}
                </render-content>
              </span>
            </template>
          </product-card>
          <store-credit-only-badge
            v-if="hasSplitRefundsEnabled"
            :product="product"
            class="mb-200"
          />
        </div>
        <div
          v-for="(line, index) in breakdown"
          :key="`line-${index}`"
          class="summary-section__line subdued"
        >
          <base-text class="summary-section__label">
            {{ line.label }}
          </base-text>
          <base-text class="summary-section__value">
            {{ line.value }}
          </base-text>
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import { BaseIcon, BaseLoader, BaseText, BaseTooltip, ProductCard, } from '@loophq/design-system';
import { replacePlaceholderUsingObject } from '@/js/utility/replacePlaceholderUsingObject';
import StoreCreditOnlyBadge from '@/components/badges/StoreCreditOnlyBadge';
import { formatCurrency } from '@/js/helpers/formatCurrency';

export default {
  components: {
    BaseText,
    ProductCard,
    BaseIcon,
    BaseTooltip,
    BaseLoader,
    StoreCreditOnlyBadge
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false
    },
    label: {
      type: String,
      required: false,
    },
    labelIcon: {
      type: String,
      required: false,
      default: null,
    },
    description: {
      type: String,
      required: false
    },
    value: {
      type: String,
      required: false
    },
    policyHandlingFee: {
      type: Number,
      required: false 
    },
    perProductHandlingFee: {
      type: Number,
      required: false
    },
    breakdown: {
      type: Array,
      required: false,
      validator: (val) => val.every((line) => {
        return Object.keys(line).every((key) => ['label', 'value'].includes(key));
      })
    },
    products: {
      type: Array,
      required: false,
    },
    removedProducts: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    purchasing: {
      type: Boolean,
      required: false,
      default: false,
    },
    receipt: {
      type: Object,
      required: false,
    },
    hasPerProductHandlingFeesFF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['toggle-info'],
  computed: {
    hasExpanded() {
      return this.breakdown?.length;
    },
    hasHandlingAndCoverage() {
      return this.label
        && this.label === this.$content.global.handlingFee
        && this.$store.getters.hasReturnCoverage;
    },
    formattedProducts() {
      return this.products.map((product) => {
        return {
          ...product,
          title: replacePlaceholderUsingObject(this.$content.global.productTitle, product),
          oos: ['refunded', 'credited'].includes(product.status?.status),
          removed: false,
        };
      }).concat(this.removedProducts.map(product => {
        const { price, ...rest } = product;
        return {
          ...rest,
          title: replacePlaceholderUsingObject(this.$content.global.productTitle, product),
          oos: false,
          removed: true,
          struckPrice: price
        };
      })).sort((productA, productB) => productA.title - productB.title);
    },
    hasSplitRefundsEnabled() {
      return this.$store.getters.settings.enableSplitRefunds;
    },
    displayCurrency() {
      return this.$store.getters['currencies/displayCurrency'];
    },
    exchangeRate() {
      return this.$store.getters['currencies/exchangeRate'];
    },
  },
  methods: {
    formatAmount(amount) {
      return formatCurrency(amount * this.exchangeRate, this.displayCurrency);
    },
    badgeInfo(product) {
      return this.purchasing ? this.shopNowDiscountPercentage() : this.lineItemFeePercentage(product);
    },
    shopNowDiscountPercentage() {
      const shouldDisplayShopNowDiscount = this.receipt.shopNowDiscountPercentage > 0;
      return shouldDisplayShopNowDiscount ? `${this.receipt.shopNowDiscountPercentage}%` : '';
    },
    lineItemFeePercentage(product) {
      const shouldDisplayFee = product.lineItemFees?.feeAmount && product.lineItemFees?.creditPercentage;
      return shouldDisplayFee ? `${product.lineItemFees.creditPercentage}%` : '';
    },
  },
};
</script>

<style lang="scss" scoped>
$block: '.summary-section';

#{$block} {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-300) 0 0 0;

  :deep(.base-tooltip__content) {
    font-size: 14px;
    max-width: 380px;
  }

  &__line {
    display: flex;
    justify-content: space-between;

    &.heading {
      margin-bottom: var(--spacing-300);
    }

    &.subdued {
      color: var(--grey-700);
    }

    & + & {
      margin-top: var(--spacing-200);
    }
  }

  &__label {
    flex-grow: 1;
    display: flex;
    align-items: center;

    &.active {
      font-weight: 500;
    }
  }

  &__amount {
    margin-left: var(--spacing-100);
  }

  &__description {
    margin-left: var(--spacing-100);
  }

  &__icon {
    --icon-color: var(--grey-600);

    width: 1.375rem;
    height: 1.375rem;
  }

  &__additional-info {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    font-style: italic;

    &__tooltip-icon {
      display: block;
      margin-left: 4px;
      width: 14px;
      height: 14px;
    }
  }

  &__value {
    flex-shrink: 0;
    font-weight: 500;
  }

  &__product {
    margin-bottom: var(--spacing-200);

    &:first-of-type {
      margin-top: var(--spacing-300);
    }

    &:last-of-type {
      margin-bottom: var(--spacing-300);
    }

    &.disabled {

      // This is a weird scenario where we want to lower the opacity on
      // most of the product card, but not on the utility slot
      :deep(.product-card__image, .product-card__title, .product-card__price, .product-card__variants) {
        opacity: 0.4;
      }
    }

    :deep(.product-card__info) {
      padding: 0 0 0 var(--spacing-200) !important;
    }
  }

  &__oos,
  &__removed {
    text-align: right;
    color: var(--red-300);
  }
}

@media screen and (max-width: $break-small) {
  .summary-section__additional-info {
    :deep(.base-tooltip__content.top) {
      max-width: 250px;
    }
  }
}
</style>
