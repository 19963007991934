<template>
  <main class="shop-collection">
    <transition
      name="fadein"
      appear
    >
      <span
        v-if="loading"
        class="shop-collection__loader"
      >
        <span
          class="shop-collection__skeleton-title"
          aria-hidden="true"
        ></span>
        <skeleton-product
          v-for="product in 9"
          :key="product"
        />
      </span>
    </transition>
    <div
      v-if="!loading"
      :key="collection.id"
      class="shop-collection__content"
    >
      <transition
        name="fadein"
        appear
      >
        <h2 class="shop-collection__heading">
          {{ collection.title }}
        </h2>
      </transition>
      <ul
        v-if="products.length"
        class="shop-collection__items"
      >
        <collection-item
          v-for="product in products"
          :key="product.id"
          class="shop-collection__item"
          :collection="collection"
          :product="product"
        />
      </ul>
      <div
        v-else
        class="shop-collection__empty"
      >
        <p class="shop-collection__empty-text">
          {{ $content.pageShopNow.emptyCollection }}
        </p>
      </div>
      <div
        v-if="back || forward"
        class="shop-collection__pagination"
      >
        <router-link
          v-if="back"
          class="shop-collection__pagination-button"
          :to="{ query: { page: back }}"
          title="Go back a page"
        >
          <svg
            viewBox="0 0 16 16"
            class="shop-collection__arrow back"
          >
            <path
              fill="currentColor"
              transform="translate(-1 -1)"
              fill-rule="evenodd"
              d="M15.936 8.364l-4.5-4.5a.899.899 0 1 0-1.272 1.272L13.127 8.1H2.7a.9.9 0 1 0 0 1.8h10.427l-2.963 2.964a.899.899 0 1 0 1.272 1.272l4.5-4.5a.899.899 0 0 0 0-1.272"
            />
          </svg>
          <span class="visually-hidden">Go back a page</span>
        </router-link>
        <span
          v-else
          class="shop-collection__pagination-button disabled"
        >
          <svg
            viewBox="0 0 16 16"
            class="shop-collection__arrow back"
          >
            <path
              fill="currentColor"
              transform="translate(-1 -1)"
              fill-rule="evenodd"
              d="M15.936 8.364l-4.5-4.5a.899.899 0 1 0-1.272 1.272L13.127 8.1H2.7a.9.9 0 1 0 0 1.8h10.427l-2.963 2.964a.899.899 0 1 0 1.272 1.272l4.5-4.5a.899.899 0 0 0 0-1.272"
            />
          </svg>
          <span class="visually-hidden">Go back a page</span>
        </span>
        <router-link
          v-if="forward"
          class="shop-collection__pagination-button"
          :to="{ query: { page: forward }}"
          title="Go forward a page"
        >
          <svg
            viewBox="0 0 16 16"
            class="shop-collection__arrow forward"
          >
            <path
              fill="currentColor"
              transform="translate(-1 -1)"
              fill-rule="evenodd"
              d="M15.936 8.364l-4.5-4.5a.899.899 0 1 0-1.272 1.272L13.127 8.1H2.7a.9.9 0 1 0 0 1.8h10.427l-2.963 2.964a.899.899 0 1 0 1.272 1.272l4.5-4.5a.899.899 0 0 0 0-1.272"
            />
          </svg>
          <span class="visually-hidden">Go forward a page</span>
        </router-link>
        <span
          v-else
          class="shop-collection__pagination-button disabled"
        >
          <svg
            viewBox="0 0 16 16"
            class="shop-collection__arrow forward"
          >
            <path
              fill="currentColor"
              transform="translate(-1 -1)"
              fill-rule="evenodd"
              d="M15.936 8.364l-4.5-4.5a.899.899 0 1 0-1.272 1.272L13.127 8.1H2.7a.9.9 0 1 0 0 1.8h10.427l-2.963 2.964a.899.899 0 1 0 1.272 1.272l4.5-4.5a.899.899 0 0 0 0-1.272"
            />
          </svg>
          <span class="visually-hidden">Go forward a page</span>
        </span>
      </div>
    </div>
  </main>
</template>

<script>
import CollectionItem from './ShopNowCollectionItem';
import SkeletonProduct from '@/components/feedback/SkeletonProduct';

export default {
  components: {
    CollectionItem,
    SkeletonProduct,
  },
  computed: {
    store() {
      return this.$store.state.shopnow;
    },
    loading() {
      return this.store.loading;
    },
    products() {
      return this.store.products;
    },
    collection() {
      return this.store.collection;
    },
    back() {
      return this.store.prevPage || null;
    },
    forward() {
      if (!this.products.length || !this.store.page) {
        return 0;
      }

      return this.store.page;
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.shop-collection';

@keyframes product-square {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#{$block} {
  position: relative;
  flex-grow: 1;

  &__loader {
    display: grid;

    /* autoprefixer: ignore next */
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 1.5rem;
  }

  &__skeleton-title {
    @include skeleton;

    grid-column: 1 / 4;
    max-width: 360px;
    width: 100%;
    height: 3rem;
  }

  &__heading {
    text-align: left;

    @include responsive-font(2.25rem, 1.5rem);

    margin-bottom: 2.5rem;
  }

  &__empty-text {
    font-size: 1rem;
  }

  &__content {
    // Extra padding to account for floating nav
    padding-bottom: 5.75rem;
  }

  &__items {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    @supports (display: grid) {
      display: grid;

      /* autoprefixer: ignore next */
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-template-rows: 1fr;
      grid-gap: 1.5rem;
    }
  }

  &__item {
    box-sizing: border-box;
    backface-visibility: hidden;
    will-change: opacity;
    opacity: 0;
    animation: product-square 0.24s ease-in forwards;
    width: 33.3%;
    padding: 0.75rem;

    @supports (display: grid) {
      width: auto;
      padding: inherit;
    }

    &:nth-of-type(2),
    &:nth-of-type(4) {
      animation-delay: 0.08s;
    }

    &:nth-of-type(3),
    &:nth-of-type(5),
    &:nth-of-type(7) {
      animation-delay: 0.16s;
    }

    &:nth-of-type(6),
    &:nth-of-type(8) {
      animation-delay: 0.16s;
    }

    &:nth-of-type(9) {
      animation-delay: 0.24s;
    }

    &:nth-of-type(n + 9) {
      animation-delay: 0.24s;
    }
  }

  &__pagination {
    grid-column: 1 / 4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }

  &__pagination-button {
    flex-shrink: 0;
    color: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2.5rem;
    min-height: 2.5rem;
    transition: border-color 0.24s ease;
    border-radius: 5px;
    border: 1px solid #dee0e0;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 4%);

    & + & {
      margin-left: 1rem;
    }

    &:hover {
      border-color: #b3b3b3;
    }

    &.disabled {
      cursor: not-allowed;
      color: $gray-dark;

      &:hover {
        border-color: #dee0e0;
      }
    }
  }

  &__arrow {
    width: 1.25rem;
    height: 1.25rem;

    &.back {
      transform: rotate(0.5turn);
    }
  }
}

@media screen and (max-width: $break-large) {
  #{$block} {
    &__items,
    &__loader {
      /* autoprefixer: ignore next */
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: $break-small) {
  #{$block} {
    &__heading {
      margin-bottom: 1.5rem;
      text-align: center;
    }

    &__items,
    &__loader {
      /* autoprefixer: ignore next */
      grid-gap: 0.75rem;
    }
  }
}
</style>
