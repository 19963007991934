<template>
  <div
    class="return-method-container"
  >
    <base-text
      type="body-2"
      class="return-method-legal"
    >
      You can drop off at any Happy Returns® location. By selecting you are agreeing to Happy Returns’
      <a
        v-track="'happy returns privacy policy clicked'"
        target="_blank"
        class="legal-link"
        href="https://www.privacypolicy.happyreturns.com"
      >
        privacy policy
      </a>
    </base-text>

    <happy-returns-locations
      :locations="returnMethod.closest"
    />

    <base-button
      v-track="'happy returns view all locations clicked'"
      class="all-locations-btn"
      size="small"
      type="secondary"
      target="_blank"
      :to="locationsLink"
      @click.stop
    >
      <span
        class="icon-label"
      >
        {{ $content.moduleReturnMethodSelectorHappyReturns.viewAllLocationsBtn }}
        <base-icon
          class="external-icon"
          name="external-link"
        />
      </span>
    </base-button>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseText,
  BaseIcon,
} from '@loophq/design-system';
import HappyReturnsLocations from './HappyReturnsLocations';

export default {
  components: {
    BaseButton,
    BaseText,
    BaseIcon,
    HappyReturnsLocations,
  },
  props: {
    returnMethod: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    locationsLink() {
      return `https://locations.happyreturns.com/?has_qr_code=true&address=${this.returnMethod.address?.zip}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.all-locations-btn {
  color: var(--grey-800) !important;
  margin-top: 0;
}

.return-method-container {
  padding: var(--spacing-400);
}

.external-icon {
  height: 16px;
  margin-left: var(--spacing-200);
}

.icon-label {
  display: flex;
  align-items: center;
}

.return-method-legal {
  color: var(--grey-800);
  margin-bottom: 1.25rem;
}

.legal-link {
  color: var(--grey-800);
}
</style>
