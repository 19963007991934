<template>
  <div class="address-form">
    <div
      v-if="hasFirstLast"
      class="address-form__line"
    >
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="address.firstName"
        name="firstName"
        rules="required"
        slim
      >
        <base-input
          :model-value="address.firstName"
          v-bind="field"
          :label="$content.moduleAddressForm.firstName"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('firstName')"
        />
      </Field>
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="address.lastName"
        name="lastName"
        rules="required"
        slim
      >
        <base-input
          :model-value="address.lastName"
          v-bind="field"
          :label="$content.moduleAddressForm.lastName"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('lastName')"
        />
      </Field>
    </div>
    <div
      v-if="hasName"
      class="address-form__line"
    >
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="address.name"
        name="name"
        rules="required"
        slim
      >
        <base-input
          :model-value="address.name"
          v-bind="field"
          :label="$content.moduleAddressForm.name"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('name')"
        />
      </Field>
    </div>
    <div class="address-form__line">
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="address.address1"
        name="address1"
        rules="required"
        slim
      >
        <base-input
          :model-value="address.address1"
          v-bind="field"
          :label="$content.moduleAddressForm.address1"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('address1')"
          :maxlength="128"
        />
      </Field>
    </div>
    <div class="address-form__line">
      <Field
        v-slot="{ field }"
        v-model="address.address2"
        name="address2"
        slim
      >
        <base-input
          :model-value="address.address2"
          v-bind="field"
          :label="$content.moduleAddressForm.address2"
          :disabled="disabledFields.includes('address2')"
        />
      </Field>
    </div>
    <div
      v-if="isNewZealand"
      class="address-form__line"
    >
      <base-input
        v-model="suburb"
        :label="$content.moduleAddressForm.suburb"
        :disabled="disabledFields.includes('city')"
      />
    </div>
    <div class="address-form__line">
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="city"
        name="city"
        rules="required"
        slim
      >
        <base-input
          :model-value="city"
          v-bind="field"
          :label="$content.moduleAddressForm.city"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('city')"
        />
      </Field>
    </div>
    <div class="address-form__line">
      <Field
        v-if="regions"
        v-slot="{ errors: validationErrors, field }"
        v-model="address.state"
        name="state"
        rules="required"
        slim
      >
        <base-input
          :model-value="address.state"
          v-bind="field"
          :label="$content.moduleAddressForm.region"
          :values="regions"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('state')"
        />
      </Field>
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="address.zip"
        name="zip"
        rules="required"
        slim
      >
        <base-input
          :model-value="address.zip"
          v-bind="field"
          :label="$content.moduleAddressForm.zip"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('zip')"
        />
      </Field>
    </div>
    <div class="address-form__line">
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="address.country"
        name="country"
        rules="required"
        slim
      >
        <base-input
          :model-value="address.country"
          v-bind="field"
          :label="$content.moduleAddressForm.country"
          :values="countries"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('country')"
        />
      </Field>
    </div>
    <div
      v-if="address.phone || address.phone === ''"
      class="address-form__line"
    >
      <Field
        v-slot="{ errors: validationErrors, field }"
        v-model="address.phone"
        name="phone"
        :rules="rules.phone"
        mode="aggressive"
        slim
      >
        <base-input
          :model-value="address.phone"
          v-bind="field"
          :label="$content.moduleAddressForm.phone"
          :error="validationErrors[0] ?? (errors && errors[field.name]) ?? null"
          :disabled="disabledFields.includes('phone')"
        />
      </Field>
    </div>
  </div>
</template>

<script>
import {
  BaseInput
} from '@loophq/design-system';
import { getCountries } from '@/js/countries';

/**
 * @typedef Address
 * @type {object}
 * @property {string=} firstName - If this and lastName are set, shows separate fields for first and last name.
 * @property {string=} lastName - If this and firstName are set, shows separate fields for first and last name.
 * @property {string=} name - Shows a name field if set. If this is set to a falsy value, the field will not show. If firstName and lastName are set, the component will prefer them over this.
 * @property {string} address1 - Required to validate the form
 * @property {string=} address2 - Optional
 * @property {string} city - Required to validate the form
 * @property {string=} state - Optional, required to validate the form if state is available for the country
 * @property {string} zip - Required to validate the form
 * @property {string} country - Required to validate the form
 * @property {string} countryCode - Hidden property to store the country code
 * @property {string=} phone - Optional. Field is hidden if not set
 */
export default {
  name: 'AddressForm',
  components: {
    BaseInput
  },
  /**
	 * Some fields will conditionally show based on whether the prop is set.
	 * Refer to the typedef for more info
	 */
  props: {
    /** @type {Address} */
    modelValue: {
      type: Object,
      required: true
    },
    /**
		 * Pass in an array of strings that map to the props that map to the fields you want to disable.
		 * Will still show the fields, you just won't be able to edit them.
		 * Validation is skipped for disabled fields.
		 */
    disabledFields: {
      type: Array,
      required: false,
      default: () => ([])
    },
    /**
		 * Pass in an array of strings that map to the props that map to the fields you want to require.
		 * Currently only supports phone number, most address fields are required always
		 */
    required: {
      type: Array,
      required: false,
      default: () => ([])
    },
    /**
		 * Accepts an array of two or three letter country codes
		 */
    availableCountries: {
      type: Array,
      required: false,
      validator: (val) => {
        return Array.isArray(val) &&
          val.every((item) => {
            return typeof item === 'string' && item.length <= 3;
          });
      }
    },
    /** @type {Address} */
    errors: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        phone: '',
      },
      city: '',
      suburb: '',
      countryData: []
    };
  },
  computed: {
    countries() {
      return [
        '',
        ...this.countryData.map((country) => country.name)
      ];
    },
    regions() {
      if (!this.modelValue.country) {
        return [];
      }
      const regions = this.countryData.find((country) => this.modelValue.country === country.name)?.states;
      return regions ? ['', ...regions] : null;
    },
    hasFirstLast() {
      return ['firstName', 'lastName'].every((key) => {
        return !!this.address[key] || this.address[key] === '';
      });
    },
    hasName() {
      return !(this.hasFirstLast) && (this.address.name || this.address.name === '');
    },
    rules() {
      return {
        phone: this.required.includes('phone') ? 'required' : null
      };
    },
    order() {
      return this.$store.getters.order;
    },
    isNewZealand() {
      return this.address.country === 'New Zealand';
    }
  },
  watch: {
    address: {
      deep: true,
      handler() {
        this.address.countryCode = this.countryData.find((country) => country.name === this.address.country)?.code;
        this.$emit('update:modelValue', this.address);
      }
    },
    suburb(suburb) {
      this.address.city = suburb ? `${suburb}, ${this.city}` : this.city;
    },
    city(city) {
      this.address.city = this.suburb ? `${this.suburb}, ${city}` : city;
    },
  },
  created() {
    this.address = this.modelValue;

    this.countryData = getCountries(this.availableCountries);
    if (!this.address.countryCode && this.address.country) {
      this.address.countryCode = this.countryData.find((country) => country.name === this.address.country)?.code;
    }

    if (!this.address.country) {
      this.address.country = this.order?.address?.country ?? 'United States';
      this.address.countryCode = this.order?.address?.country_code ?? 'US';
    }

    if (this.address.country === 'New Zealand') {
      const match = this.modelValue.city.match(/^(?<suburb>.*), ?(?<city>.*)$/);
      const city = match?.groups?.city;
      const suburb = match?.groups?.suburb;
      if (city) {
        this.city = city;
        this.suburb = suburb;
      } else {
        this.city = this.modelValue.city;
      }
    } else {
      this.city = this.modelValue.city;
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.address-form';

#{$block} {
  &__line {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
    grid-gap: var(--spacing-200);

    & + & {
      margin-top: var(--spacing-300);
    }
  }
}

@media screen and (max-width: $break-small) {
  #{$block} {
    &__line {
      flex-direction: column;
    }
  }
}
</style>
