export default {
  key: '',
  theme: {
    primaryColor: '#000',
    cornerRadius: '4px',
    headerFont: '',
    copyFont: '',
    pageOrientation: 'bottom-right'
  },
  welcome: {
    heading: 'On store exchanges',
    items: [
      {
        heading: 'How to exchange',
        body: 'Shop the store like normal. When you find a product you like, use the product card that pops up to add it to your exchange cart.'
      },
      {
        heading: 'Finished?',
        body: `When you're done shopping, click the continue exchange button in the cart to complete your exchange.`
      },
      {
        heading: `Can't find what you're looking for?`,
        body: 'We get it! You can return to your exchange at any point by clicking the back to exchange button below or located in the cart.'
      }
    ],
    buttons: {
      continue: 'Get started',
      cancel: 'Back to exchange'
    }
  },
  product: {
    button: 'Add to exchange'
  },
  cart: {
    heading: 'Your cart',
    buttons: {
      continue: 'Continue exchange',
      cancel: 'Cancel'
    }
  },
  currency: 'USD'
};
