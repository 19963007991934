<template>
  <base-modal
    ref="baseModalElement"
    class="label-modal-layout"
    @close="closeModal"
  >
    <base-text type="caption">
      <render-content :data="{ orderName }">
        {{ content.labelOrderHeader }}
      </render-content>
    </base-text>

    <base-text
      type="display-large"
      class="label-modal-layout__heading"
    >
      <render-content :data="{ carrier }">
        <slot name="label-card-header"></slot>
        {{ content.labelCardHeader }}
      </render-content>
    </base-text>

    <base-text class="label-modal-layout__description">
      <slot name="description"></slot>
    </base-text>

    <div
      v-for="(label, index) in labels"
      :key="`${label.id}-${index}`"
      class="label-modal-layout__label"
    >
      <slot
        name="label-card"
        :count="index + 1"
        v-bind="label"
      ></slot>
    </div>

    <div
      class="label-modal-layout__addendum-wrapper"
    >
      <img
        class="label-modal-layout__addendum-email-icon"
        src="/img/icons/mail.svg"
        alt="email icon"
      />
      <base-text
        class="label-modal-layout__addendum"
        type="body-2"
      >
        <render-content>
          {{ content.addendum }}
        </render-content>
      </base-text>
    </div>
  </base-modal>
</template>

<script setup>
import { BaseModal, BaseText } from '@loophq/design-system';
import { onMounted, ref } from 'vue';
import { enablePageScroll } from 'scroll-lock';

defineProps({
  labels: Array,
  returnId: Number,
  shopId: Number,
  returnKey: String,
  returnBy: String,
  orderName: String,
  content: Object,
  carrier: String,
});

const baseModalElement = ref(null);

onMounted(() => {
  if (baseModalElement.value !== null) {
    enablePageScroll(baseModalElement.value?.target);
  }
});

const emit = defineEmits(['close-modal']);

function closeModal() {
  emit('close-modal');
}
</script>

<style lang="scss" scoped>
:deep(.base-card) {
  padding: var(--spacing-400) var(--spacing-500);
}

.label-modal-layout {
  &__heading,
  &__description {
    margin: var(--spacing-200) auto;
  }

  &__label {
    margin-bottom: var(--spacing-400);
  }

  &__addendum {
    margin: var(--spacing-400) 0;

    &-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: normal;
      align-items: center;
      gap: var(--spacing-200);
    }

    &-email-icon {
      width: 38px;
      padding: var(--spacing-200);
      border: thin solid gray;
      border-radius: 50%;
    }
  }
}
</style>
