<template>
  <div class="tracking-event-timeline-steps">
    <div class="tracking-event-timeline-steps__top-content">
      <base-text class="tracking-eyebrow">
        {{ orderEyebrow }} {{ orderName }}
      </base-text>

      <base-text
        type="display-large"
        class="tracking-event-timeline-steps__heading"
        data-testid="tracking_event_timeline_heading"
      >
        {{ heading }}
      </base-text>

      <p class="tracking-event-timeline-steps__carrier-info">
        <img
          v-if="carrierImage"
          class="tracking-event-timeline-steps__carrier-logo-img"
          :src="carrierImage"
          :alt="`${tracker.carrier} logo`"
        />

        <base-button
          v-track="'carrier tracking url clicked'"
          type="link"
          class="tracking-event-timeline-steps__tracking-url"
          data-test-id="tracking_event_timeline_tracking_url"
          :to="trackingUrl"
          target="_blank"
        >
          <span class="tracking-event-timeline-steps__tracking-code">
            {{ tracker?.tracking_code }}
          </span>
        </base-button>
      </p>
    </div>

    <div
      v-if="tracker"
      class="steps"
      data-testid="tracking_event_timeline_steps"
    >
      <div
        class="step-item"
        :class="{
          'is-active': latestShippingStatus?.step === 1
        }"
        data-testid="pre_transit_step"
      >
        <div class="step-marker">
          <div class="step-marker-indicator">
            <base-icon
              v-if="latestShippingStatus?.step > 1"
              name="check"
              aria-hidden="true"
              data-testid="pre_transit_completed"
            />
          </div>
          <base-icon
            v-if="latestShippingStatus?.step === 1 && shippingStatusHealth !== 'ok'"
            class="tracking-event-timeline-steps__warn-error-icon"
            :name="shippingStatusHealth === 'warning' ? 'warning' : 'close-outline'"
            aria-hidden="true"
          />
        </div>
        <div class="step-details">
          <p class="step-title">
            {{ orderReceivedCopy }}
          </p>
        </div>
      </div>

      <div
        class="step-item"
        :class="{
          'is-active': latestShippingStatus?.step === 2
        }"
        data-testid="in_transit_step"
      >
        <div class="step-marker">
          <div class="step-marker-indicator">
            <base-icon
              v-if="latestShippingStatus?.step > 2"
              name="check"
              aria-hidden="true"
              data-testid="in_transit_completed"
            />
          </div>
          <base-icon
            v-if="latestShippingStatus?.step === 2 && shippingStatusHealth !== 'ok'"
            class="tracking-event-timeline-steps__warn-error-icon"
            :name="shippingStatusHealth === 'warning' ? 'warning' : 'close-outline'"
            aria-hidden="true"
          />
        </div>
        <div class="step-details">
          <p class="step-title">
            {{ shippedCopy }}
          </p>
        </div>
      </div>

      <div
        class="step-item"
        :class="{
          'is-active': latestShippingStatus?.step === 3
        }"
        data-testid="out_for_delivery_step"
      >
        <div class="step-marker">
          <div class="step-marker-indicator">
            <base-icon
              v-if="latestShippingStatus?.step > 3"
              name="check"
              aria-hidden="true"
              data-testid="out_for_delivery_completed"
            />
          </div>
          <base-icon
            v-if="latestShippingStatus?.step === 3 && shippingStatusHealth !== 'ok'"
            class="tracking-event-timeline-steps__warn-error-icon"
            :name="shippingStatusHealth === 'warning' ? 'warning' : 'close-outline'"
            aria-hidden="true"
          />
        </div>
        <div class="step-details">
          <p class="step-title">
            {{ outForDeliveryCopy }}
          </p>
        </div>
      </div>

      <div
        class="step-item"
        :class="{
          'is-active': latestShippingStatus?.step === 4
        }"
        data-testid="delivered_step"
      >
        <div class="step-marker">
          <div class="step-marker-indicator">
          </div>
          <base-icon
            v-if="latestShippingStatus?.step === 4 && shippingStatusHealth !== 'ok'"
            class="tracking-event-timeline-steps__warn-error-icon"
            :name="shippingStatusHealth === 'warning' ? 'warning' : 'close-outline'"
            aria-hidden="true"
          />
        </div>
        <div class="step-details">
          <p class="step-title">
            {{ deliveredCopy }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-else
      class="tracking-event-timeline-steps__loader"
      data-testid="tracking_event_timeline_loader"
    >
      <skeleton-text
        :lines="3"
        width="90%"
      />
    </div>
  </div>
</template>

<script setup>
import { BaseButton, BaseIcon, BaseText } from '@loophq/design-system';
import SkeletonText from '@/components/feedback/SkeletonText.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { carrierImg } from '@/js/helpers/carriers';

const store = useStore();

const props = defineProps({
  orderName: {
    type: String,
    required: false
  },
  tracker: {
    type: Object,
    required: true
  },
  shippingStatusHealth: {
    type: String,
    required: true
  },
  latestShippingStatus: {
    type: Object,
    required: true
  },
  fulfillmentTrackingUrl: {
    type: String,
    required: false
  }
});

const orderEyebrow = store.getters['content']?.moduleForwardTracking.eyebrow;
const orderReceivedCopy = store.getters['content']?.moduleForwardTracking.eventTimelineStatusPreTransit;
const shippedCopy = store.getters['content']?.moduleForwardTracking.eventTimelineStatusInTransit;
const outForDeliveryCopy = store.getters['content']?.moduleForwardTracking.eventTimelineStatusOutForDelivery;
const deliveredCopy = store.getters['content']?.moduleForwardTracking.eventTimelineStatusDelivered;

const carrierImage = computed(() => {
  return carrierImg(props.tracker?.carrier);
});

const heading = computed(() => {
  switch (props.latestShippingStatus?.step) {
    case 1:
      return store.getters['content']?.moduleForwardTracking.eventTimelinePreTransitHeading;
    case 4:
      return store.getters['content']?.moduleForwardTracking.eventTimelineDeliveredHeading;
    default:
      return store.getters['content']?.moduleForwardTracking.eventTimelineHeading;
  }
});

const trackingUrl = computed(() => {
  return props.fulfillmentTrackingUrl || props.tracker?.public_url;
});

</script>

<style lang="scss" scoped>
$block: '.tracking-event-timeline-steps';

#{$block} {
  &__top-content {
    margin-left: var(--spacing-600);
  }

  &__heading {
    margin: var(--spacing-300) 0;
    font-family: var(--heading-font);
  }

  &__carrier-logo-img {
    max-width: 100px;
    height: auto;
    max-height: 30px;
    width: auto;
    margin-right: var(--spacing-200);
  }

  &__warn-error-icon {
    height: 1.75rem;
    width: 1.75rem;
    position: absolute;
    border-radius: 50%;

    &[name="warning"] {
      background: var(--yellow-500);
    }

    &[name="close-outline"] {
      background: var(--red-300);
    }
  }

  &__carrier-info {
    display: flex;
    min-height: 30px;
  }

  &__loader {
    margin-left: var(--spacing-600);
    margin-top: var(--spacing-300);
  }

  &__tracking-code {
    padding-top: var(--spacing-100);
    text-decoration: none;
  }
}

.steps {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--spacing-300);

  .step-item {
    margin-top: 0;
    position: relative;
    flex-grow: 1;
    flex-basis: 0;

    &:not(:first-child)::before {
      height: 0.2em;
      width: 100%;
      bottom: 0;
      left: -50%;
      top: 1rem;
    }

    &:not(:first-child) {
      flex: 1 1 1em;

      &::before {
        content: " ";
        display: block;
        position: absolute;
      }
    }

    &::before {
      background: linear-gradient(to left, #000 50%, #000 50%);
      background-size: 200% 100%;
      background-position: right bottom;

      .step-marker {
        color: white;
        width: 1.5rem;
        height: 1.5rem;
        background-color: #000;
      }
    }

    &.is-active {
      &::before {
        background-position: left bottom;
      }

      .step-marker {
        border-color: black;
      }

      .step-details {
        p {
          filter: brightness(60%);
        }
      }
    }

    &.is-complete {
      &::before {
        background-position: left bottom;
      }

      .step-marker {
        border-color: black;
      }

      .step-details {
        p {
          color: black;
          font-weight: 600;
        }
      }
    }

    .step-marker {
      align-items: center;
      display: flex;
      border-radius: 50%;
      font-weight: bold;
      justify-content: center;
      background: white;
      color: white;
      border: 2px solid white;
      z-index: 1;
      position: absolute;
      left: calc(50% - 16px);
      padding: 0.5rem;
    }

    .step-marker-indicator {
      width: 16px;
      height: 16px;
      background: black;
      border-radius: 50%;

      .base-icon {
        width: 0.85rem;
        height: 0.85rem;
        padding-left: 2px;
        padding-top: 2px;
      }
    }

    .step-details {
      margin-top: 2.5rem;
      margin-left: 0.5em;
      margin-right: 0.5em;
      padding-top: 0.75em;
      text-align: center;

      p {
        color: var(--body-color);
      }
    }
  }

  .steps-content {
    align-items: stretch;
    flex-basis: 100%;
    margin: 2rem 0;

    .step-content {
      display: none;

      &.is-active {
        display: block;
      }
    }
  }

  .steps-actions {
    display: flex;
    align-items: stretch;
    flex-basis: 100%;

    .steps-action {
      display: flex;
      flex-basis: 0;
      flex-grow: 1;
      margin: 0.5rem;
      justify-content: center;
      align-items: center;
    }
  }

  &.is-animated {
    .step-item {
      &::before {
        transition: all 2s ease;
      }

      .step-marker {
        transition: all 0s ease;
        transition-delay: 1.5s;
      }
    }
  }
}

@media screen and (width <= 1402px) {
  #{$block} {
    &__top-content {
      margin-left: 0;
    }

    &__heading {
      margin: var(--spacing-400) 0;
    }

    p.base-text {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    &__carrier-info {
      width: 100%;
      justify-content: center;
    }
  }
}
</style>

<style>
.tracking-event-timeline-steps__warn-error-icon svg.base-icon__icon.box {
  --icon-color: black;
}
</style>
