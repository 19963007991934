// These are the keys we're tracking for options on the variant
const names = ['option1', 'option2', 'option3'];

// Takes a list of variants and an option name and gets an array of all possibilities
const getOptionNames = (name, variants) => {
  return [...new Set(variants.map(item => item[name]))]
    .filter(Boolean);
};

// Get an array of all possible options from a list of variants
const getOptions = (variants, selected) => {
  return names.flatMap(name => {
    return selected[name] ? [] : getOptionNames(name, variants);
  });
};

/**
 *
 * Get a narrowed down bucket of variants
 * Variants is an array of variants from the api,
 * selected is an object with any selected options set on option<Number>
 * properties, mirroring the variant ids in the variants array
 *
 * @param {Object[]} variants
 * @param {Object} selected
 * @param {String} selected.option1
 * @param {String} selected.option2
 * @param {String} selected.option3
 */
const getBucket = (variants, selected) => {
  // This sequentially filters the variants array by option
  return names.reduce((accumulator, current) => {
    return accumulator
      .filter(variant => {
        if (selected[current]) {
          // If this variant's option matches the selected option
          // we want to include it in the set, otherwise we're dropping it
          return variant[current] === selected[current];
        }

        // We need to include variants by default
        return true;
      });
  }, variants);
};

/**
 * Gets an array of out of stock variants
 * @param {{ option1: string, option2: string, option3: string }[]} variants - Array of variant objects from our api
 * @param {{ option1: string, option2: string, option3: string }} selected - Object of currently selected options
 * @param {number} position - The option number (1, 2, or 3)
 * @param {function} validator - Sends a variant as the only argument, should return true if the variant is out of stock
 * @returns {string[]} - An array of out of stock variant option values
 */
export const getOutOfStock = (variants, selected = {}, position, validator) => {
  validator = validator ? validator : (variant) => variant.limit <= 0;
  const bucket = getBucket(variants, selected);

  return getOptions(variants, selected)
    .filter(item => {
      return bucket
        .filter(variant => variant[`option${position}`] === item)
        .every(validator);
    });
};

/**
 * Gets an array of unavailable variants
 * @param {{ option1: string, option2: string, option3: string }[]} variants - Array of variant objects from our api
 * @param {{ option1: string, option2: string, option3: string }} selected - Object of currently selected options
 */
export const getUnavailable = (variants, selected = {}) => {
  const bucket = getBucket(variants, selected);
  return getOptions(variants, selected)
    .filter(item => {
      return !bucket
        .find(variant => {
          return [variant.option1, variant.option2, variant.option3].includes(item);
        });
    });
};
