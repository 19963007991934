import { throttle } from 'lodash';
import Store from '@/store';

export const smoothscroll = (to = 0) => {
  const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

  // Only attempt to move if we haven't reached the goal
  if (currentScroll > to) {
    window.requestAnimationFrame(() => {
      window.scrollTo(0, currentScroll - (currentScroll / 2));
      smoothscroll();
    });
  }
};

export const setMobileVH = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;

  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // We listen to the resize event
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
};

export const observeWindowChanges = () => {
  if (window.ResizeObserver) {
    const resizeObserver = new ResizeObserver(throttle(() => {
      Store.commit('setScreen', { width: window.innerWidth, height: window.innerHeight });
    }), 100);

    resizeObserver.observe(document.documentElement);
  }
};
