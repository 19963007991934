import { getProducts } from '@/js/controllers/advancedExchange';

export default {
  namespaced: true,
  state: {
    options: {},
  },
  mutations: {
    setOption(state, payload) {
      state.options = {
        ...state.options,
        [payload.id]: payload
      };
    }
  },
  actions: {
    async getOption({ state, commit }, { id, payload }) {
      // if we already have results for a collection type exchange, we don't
      // need to re-retrieve results as they won't change. for tag<>tag types
      // we DO need to fetch new results based upon the selected product's tags.
      if (state.options[id] && state.options[id].isCollectionType) {
        return state.options[id];
      }

      // add an empty option set to a loading state.
      const option = { id, errors: null, loading: true, products: [] };
      commit('setOption', option);

      try {
        const response = await getProducts(id, payload);
        option.products = response.products;
        option.hasNextPage = response.has_next ?? false;
        option.nextPage = response.next_page;
        option.isCollectionType = response.is_collection_type ?? false;
      } catch (error) {
        option.errors = error;
      }

      option.loading = false;
      commit('setOption', option);

      return option;
    },

    async nextPage({ state, commit }, { id, payload = {} }) {
      const option = { ...state.options[id], loading: true, errors: null };
      commit('setOption', option);

      const body = {
        ...payload,
        page: option.nextPage ?? 1,
      };

      try {
        const response = await getProducts(option.id, body);
        option.products = option.products.concat(response.products);
        option.hasNextPage = response.has_next ?? false;
        option.nextPage = response.next_page;
      } catch (error) {
        option.errors = error;
      }

      option.loading = false;
      commit('setOption', option);

      return option;
    }
  }
};
