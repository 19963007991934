<template>
  <div
    v-if="normalizedReturn"
    class="tracking-header"
  >
    <estimated-delivery-date
      v-if="hasEstimatedDeliveryDate"
      class="tracking-header__delivery-date"
    />
    <event-timeline
      class="tracking-header__event-timeline"
      :class="{'tracking-header__shrink-event-timeline': hasEstimatedDeliveryDate}"
    />
  </div>
</template>

<script setup>
import EstimatedDeliveryDate from './EstimatedDeliveryDate';
import EventTimeline from './EventTimeline';
import { computed, inject } from 'vue';

const normalizedReturn = inject('normalizedReturn');

const hasEstimatedDeliveryDate = computed(() => {
  return !!normalizedReturn.value?.tracker?.estDeliveryDate;
});
</script>

<style lang="scss" scoped>
$block: '.tracking-header';

#{$block} {
  display: flex;
  justify-content: flex-start;
  padding: 0 var(--spacing-500);
  background: white;
  align-items: stretch;

  .base-text {
    width: 100%;
  }

  &__delivery-date {
    flex-shrink: 0;
  }

  &__event-timeline {
    flex-grow: 1;
  }

  &__shrink-event-timeline {
    max-width: 640px;
  }
}

@media screen and (width <= 1402px) {
  #{$block} {
    padding: var(--spacing-500);
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;

    &__delivery-date {
      width: 100%;
      order: 1;
      padding: 24px 0 0;
      margin: 24px 0 0;
      border-top: 1px solid var(--grey-200);
      border-right: none;
    }

    &__event-timeline {
      order: 0;
      padding: 0;
    }
  }
}
</style>
