export const categories = {
  LOOKUP: 'Lookup',
  GIFT_LOOKUP: 'Gift Lookup',
  GIFT_RECEIPT_LOOKUP: 'Gift Receipt Lookup',
  EXCHANGE: 'Exchange',
  CREDIT: 'Credit',
  SHOP_NOW: 'Shop Now',
  REVIEW: 'Review',
  RETURN_STATUS: 'ReturnStatus',
  DEEPLINK: 'Deeplink',
  GIFT_DEEPLINK: 'Gift Deeplink',
  CART: 'Cart',
  SURVEY: 'ReturnSurvey',
};

export const defaultCategory = 'Other';
