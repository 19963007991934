<template>
  <div
    class="stripe-input"
    :class="{ disabled, loading }"
    data-testid="stripe-input"
  >
    <form
      ref="element"
      class="stripe-input__element"
      :class="{ error: !!error, focused }"
      @submit="onSubmit"
    >
      <!-- a Stripe Element will be inserted here. -->
    </form>
    <transition name="stripe-input">
      <p
        v-if="error"
        class="stripe-input__error"
      >
        {{ error }}
      </p>
    </transition>
  </div>
</template>

<script>
import env from '@/env';

export default {
  name: 'StripeInput',
  props: {
    isInstantOutcome: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  emits: [
    'change',
    'update',
    'submit',
  ],
  data() {
    return {
      stripe: null,
      elements: null,
      input: null,
      loading: false,
      error: null,
      focused: false
    };
  },
  watch: {
    async isInstantOutcome(newVal) {
      let token = null;

      try {
        token = await this.getToken();
      } catch (error) {
        console.error(error);
      }

      this.validate(newVal, token, {});
    }
  },
  mounted() {
    this.stripe = Stripe(env('VITE_STRIPE_KEY'));
    this.elements = this.stripe.elements({
      locale: this.$store.state.language
    });
    this.input = this.elements.create('card', {
      style: {
        base: {
          fontSize: '16px'
        }
      }
    });

    this.input.on('change', async (event) => {
      this.$emit('change', event);

      let token = null;
      if (!event.error && event.complete) {
        try {
          token = await this.getToken();
        } catch (error) {
          console.error(error);
        }
      }

      this.validate(this.isInstantOutcome, token, event);

      this.$emit('update', token);
    });

    this.input.on('focus', () => {
      this.focused = true;
    });

    this.input.on('blur', () => {
      this.focused = false;
    });

    // Add an instance of the card Element into the `element` <div>
    this.input.mount(this.$refs.element);
  },
  beforeUnmount() {
    this.input.unmount();
  },
  methods: {
    validate(isInstantOutcome, token, event) {
      if (event.error?.message) {
        this.error = event.error.message;
        return;
      }

      if (isInstantOutcome) {
        if (token?.card?.funding === 'prepaid') {
          this.error = this.$content.pageReturnStatus.creditCardPrepaidFundingTypeError;
          return;
        }
      }

      this.error = null;
    },
    async getToken() {
      const res = await this.stripe.createToken(this.input);
      if (!res.error) {
        return res.token;
      }

      return Promise.reject(res.error);
    },
    onSubmit() {
      this.$emit('submit');
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.stripe-input';

#{$block}-enter, #{$block}-leave-to {
  transform: translate3d(0, 3px, 0);
}

#{$block}-enter-active, #{$block}-leave-active {
  transition: transform var(--easing-regular);
}

#{$block} {
  &__element {
    margin: 0;
    padding: var(--spacing-300);
    border: 1px solid var(--grey-300);
    border-radius: var(--corners);
    box-shadow: 0 0 0 1px transparent;
    transition: border-color var(--transition-300), box-shadow var(--transition-300);
    appearance: none;
    background: white;

    &:hover {
      border-color: var(--grey-700);
    }

    &.error {
      border-color: var(--red-300);
      box-shadow: 0 0 0 1px var(--red-300);
    }

    // We have to implement this as a class because the stripe element
    // is in an iframe and we can't target the input directly
    &.focused {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 1px var(--primary-color);
    }

    &:disabled {
      background-color: var(--grey-100) !important;
      color: var(--grey-600) !important;
      cursor: not-allowed !important;
      border-color: transparent !important;
      box-shadow: 0 0 0 1px transparent;
    }
  }

  &__error {
    color: var(--red-300);
    padding: var(--spacing-100) 0;
  }
}
</style>
