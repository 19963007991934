<template>
  <div class="usps-qr-generation-window-alert__wrapper">
    <alert-banner
      class="usps-qr-selector__alert"
      type="warning"
      heading="Access to generate your QR code ends today."
      :description="alertDescription"
      :inline="true"
      :is-closable="false"
    />
  </div>
</template>

<script setup>
import { AlertBanner } from '@loophq/design-system';
import {
  endOfDay,
  intervalToDuration,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { computed, ref } from 'vue';

const timeToEOD = ref(timeUntilEndOfDayET());

const alertDescription = computed(() => {
  return `Time remaining: ${timeToEOD.value}`;
});

function timeUntilEndOfDayET() {
  let now = zonedTimeToUtc(new Date(), 'America/New_York');

  const end = zonedTimeToUtc(endOfDay(new Date()), 'America/New_York');

  const duration = intervalToDuration({ start: now, end: end });

  return `${duration.hours} hours, ${duration.minutes} minutes`;
}
</script>
