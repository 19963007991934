import { datadogRum } from '@datadog/browser-rum';
import env from '@/env';

export function logError(error, context = {}) {
  try {
    if (env('MODE') === 'development') {
      return console.error(error, context);
    }

    switch (true) {
      case error instanceof Error:
        datadogRum.addError(error, context);
        break;
      case typeof error === 'string':
        datadogRum.addError(new Error(error), context);
        break;
      case typeof error === 'object':
        datadogRum.addError(new Error(JSON.stringify(error), context));
        break;
      default:
        console.error(error);
    }
  } catch (e) {
    console.error(e);
  }
}
