<template>
  <base-text
    class="credit-selector-callout"
    :type="loading ? 'body-2' : 'body'"
  >
    <span
      v-if="loading"
      class="credit-selector-callout__fees-loading"
    >
      <base-loader size="small" />
      <slot name="loadingMessage">
        <render-content>
          {{ $content.moduleCreditSelector.handlingFeesLoading }}
        </render-content>
      </slot>
    </span>

    <slot v-else />
  </base-text>
</template>

<script>
import {
  BaseLoader,
  BaseText
} from '@loophq/design-system';

export default {
  components: {
    BaseLoader,
    BaseText
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.credit-selector-callout';

#{$block} {
  &__fees-loading {
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-200);
  }
}
</style>
