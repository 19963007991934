import axios from 'axios';
import store from '@/store';

const getId = () => store.state.shopContents.id;

export default {
  async generateLookupQrCode(qrCodeableType, qrCodeableId) {
    const shopId = getId();

    return await axios.post(`api/v1/${shopId}/qr/generate`, {
      qr_codeable_type: qrCodeableType,
      qr_codeable_id: qrCodeableId,
    });
  },
  async getLabelQrCode(shopId, returnId, labelId) {
    return await axios.post(`api/v1/${shopId}/return/${returnId}/label/${labelId}/qr-code`);
  },
};
