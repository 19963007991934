<template>
  <transition
    name="background-appear"
    appear
    @after-enter="showContent"
  >
    <div
      v-if="showAnimation"
      class="pacer-animation"
      :style="{
        '--columns': product.length
      }"
    >
      <div class="pacer-content">
        <base-text
          class="pacer-text"
          aria-live="assertive"
          type="display-large"
        >
          <render-content>
            {{ text }}
          </render-content>
        </base-text>
        <transition name="product-leave">
          <span
            v-if="showProduct"
            class="product-wrapper"
            :style="{
              '--top': `-${animationData.top}px`,
              '--right': `${animationData.right}px`
            }"
          >
            <product-thumbnail
              ref="product"
              class="pacer-product"
              :product="products"
            />
          </span>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  BaseText,
  ProductThumbnail,
} from '@loophq/design-system';

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default {
  name: 'PacerAnimation',
  components: {
    BaseText,
    ProductThumbnail,
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    outcome: {
      type: Object,
      required: false
    }
  },
  emits: [
    'finished'
  ],
  data() {
    return {
      showProduct: true,
      showAnimation: true,
      animationData: {
        top: 0,
        right: 0
      }
    };
  },
  computed: {
    products() {
      return [
        this.product,
        ...(this.outcome?.product ? [this.outcome.product] : [])
      ]
        .map((item) => {
          return {
            image: item.image,
            title: item.title,
            variants: item.variant_title
          };
        });
    },
    text() {
      const content = this.$content.moduleLineItem;
      if (this.outcome?.product) {
        return content.exchangeSuccess;
      }

      if (this.outcome?.returnType === 'credit') {
        return content.refundSuccess;
      }

      return content.defaultSuccess;
    },
  },
  methods: {
    async showContent() {
      const rightPadding = 16;
      const productBoundingBox = this.$refs.product.$el.getBoundingClientRect();
      this.animationData.top = productBoundingBox.top;
      this.animationData.right = window.innerWidth - productBoundingBox.right - rightPadding;

      // Wait a bit so the user has time to process what's happening
      await sleep(1000);

      // Play the exchange animation if needed
      if (this.product.length > 1) {
        this.$refs.product.play('exchange');
        await sleep(600);
      }

      // Play the close animation
      this.showProduct = false;
      await sleep(100);
      this.showAnimation = false;
      await sleep(300);

      // Tell the parent that we're finished here
      this.$emit('finished');
    }
  }
};
</script>

<style lang="scss" scoped>
.background-appear-enter-active {
  transition: transform 0.5s cubic-bezier(0.7, -0.2, 0.4, 1.8);
  transform-origin: 80% 95%;
}

.background-appear-enter {
  --opacity: 0;

  transform: scale3d(0, 0, 1);
}

.background-appear-leave-active {
  transition: opacity 0.2s ease;
}

.background-appear-leave-to {
  opacity: 0;
}

.product-leave-leave-active {
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform-origin: 100% 0%;
}

.product-leave-leave-to {
  transform: translate3d(var(--right), var(--top), 0) scale3d(0, 0, 1);
}

.pacer-animation {
  height: 100%;
  width: 100%;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pacer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: var(--opacity);
  transition: opacity 0.3s ease-in 0.4s;
}

.pacer-text {
  margin-bottom: var(--spacing-600);
  color: var(--primary-text-color);
}

.pacer-product {
  max-width: calc(10rem * var(--columns));
  background: white;
}

@media screen and (width <= 680px) {
  .background-appear-enter-active {
    transform-origin: 50% 95%;
  }

  .background-appear-enter {
    transform: scale3d(0.8, 0, 1);
  }
}
</style>
