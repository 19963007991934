import { Server, RestSerializer } from 'miragejs';
import { snakeCase, mapValues } from 'lodash';
import env from '@/env';

// All mirage related things need to be inside this function, otherwise they get
// evaled at the module level and break when running in production mode
export function makeServer({ environment = 'development' } = {}) {
  const exceptions = ['address1', 'address2'];

  const ApplicationSerializer = RestSerializer.extend({
    keyForAttribute(attr) {
      if (exceptions.includes(attr)) {
        return attr;
      }

      return snakeCase(attr);
    },
    serialize() {
      const json = RestSerializer.prototype.serialize.apply(this, arguments);

      const mapIdToNumber = (res) => {
        if (typeof res !== 'object') {
          return res;
        }

        return mapValues(res, (value, key) => {
          if (key === 'id') {
            return +value;
          }

          return value;
        });
      };

      if (Array.isArray(json)) {
        return json.map((item) => mapIdToNumber(item));
      }

      if (typeof json === 'object') {
        return mapIdToNumber(json);
      }

      return json;
    }
  });

  //get all files in this directory and the returns folder and use globEager to import directly instead of lazyloading
  const files = { ...(import.meta.globEager('./returns/*.js')), ...(import.meta.globEager('./*.js')) };

  const modules = [];
  for (const key in files) {
    modules.push(files[key].default);
  }

  const getMethod = (name) => {
    return (acc, cur) => {
      if (cur[name] && typeof cur[name] === 'function') {
        return {
          ...acc,
          ...cur[name]()
        };
      }

      return acc;
    };
  };

  const getSerializer = (acc, cur) => {
    if (cur.serializers && typeof cur.serializers === 'function') {
      return {
        ...acc,
        ...cur.serializers(ApplicationSerializer)
      };
    }

    return acc;
  };

  const server = new Server({
    environment,
    serializers: {
      application: ApplicationSerializer,
      ...modules.reduce(getSerializer, {})
    },
    models: modules.reduce(getMethod('models'), {}),
    factories: modules.reduce(getMethod('factories'), {}),
    seeds(server) {
      modules
        .filter(module => module.seed)
        .forEach((module) => {
          module.seed(server);
        });

    },
    routes() {
      //there is a know bug with pretender that breaks axios calls on passthrough
      //requests made with Miragejs, this was pulled a slackOverflow post to resolve it
      const NativeXMLHttpRequest = window.XMLHttpRequest;

      window.XMLHttpRequest = function XMLHttpRequest() {
        const request = new NativeXMLHttpRequest(arguments);
        delete request.onloadend;
        return request;
      };

      this.urlPrefix = env('VITE_API_URL');
      this.namespace = 'api/v1';

      modules
        .filter(module => module.routes)
        .forEach((module) => {
          module.routes(this);
        });

      this.passthrough(`${env('VITE_API_URL')}/**`);
      this.passthrough(`https://dev.visualwebsiteoptimizer.com/**`);
    },
  });

  return server;
}
