<template>
  <div class="print-packing-slip">
    <base-button
      :type="type"
      class="print-packing-slip__print-button"
      download="packing-slip.pdf"
      target="_blank"
      rel="noopener noreferrer"
      icon="printer"
      :loading="loading"
      :activated="loading"
      @click="print"
    >
      {{ text }}
    </base-button>
  </div>
</template>

<script>
import {
  BaseButton,
} from '@loophq/design-system';
import Returns from '@/js/controllers/return';

export default {
  components: {
    BaseButton,
  },
  props: {
    returnKey: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'secondary',
      validator: (val) => ['primary', 'secondary', 'link'].includes(val)
    }
  },
  emits: [
    'packingSlipAlert',
  ],
  data() {
    return {
      loading: false,
      blob: null,
    };
  },
  computed: {
    text() {
      const content = this.$content.modulePackingInstructions;
      if (this.loading) {
        return content.packingSlipGenerating;
      } else if (this.blob) {
        return content.packingSlipButton;
      } else {
        return content.packingSlipGenerateButton;
      }
    },
  },
  methods: {
    async print() {
      // if we have the PDF link ready, open it in a new tab
      if (this.blob) {
        const windowResult = window.open(URL.createObjectURL(this.blob));

        // when window.open does not return a Window, our pdf open was 
        // likely blocked by the browser.
        if (windowResult == null) {
          this.$emit('packingSlipAlert', this.$content.modulePackingInstructions.packingSlipPopupErrorBlocked);
          console.error('PrintPackingSlip#print: tab not opened');
        }
      } else {
        this.loading = true;
        this.errorMsg = false;
        this.$emit('packingSlipAlert', null);
        this.$trackEvent('generate packing slip clicked');
        // generate the packing slip
        await Returns.printPackingSlip(this.returnKey)
          .then((response) => {
            this.blob = new Blob([response.data], { type: 'application/pdf' });
          })
          .catch((error) => {
            this.$emit('packingSlipAlert', this.$content.modulePackingInstructions.packingSlipPopupErrorGeneric);      
            console.error(`PrintPackingSlip#generate: ${error}`);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.print-packing-slip';

#{$block} {
  display: flex;
  flex-direction: column;
}
</style>
