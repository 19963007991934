<template>
  <base-panel
    type="panel"
    :heading="$content.moduleExchangeSelector.explanationHeading"
    :active="isActive"
    @close="close"
  >
    <markup-renderer
      class="explanation-copy"
      :content="$content.moduleExchangeSelector.explanation"
      :customization-data="customizationData"
      :components="components"
    />
  </base-panel>
</template>

<script>
import {
  BasePanel,
} from '@loophq/design-system';
import MarkupRenderer from '@/components/renderers/MarkupRenderer';

export default {
  name: 'InstantExchangeDialog',
  components: {
    BasePanel,
    MarkupRenderer,
  },
  props: {
    components: {
      type: Object,
      required: true,
    },
    customizationData: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['close'],
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>
