<template>
  <div
    class="return-tracking-estimated-delivery-date"
  >
    <base-text
      class="return-tracking-estimated-delivery-date__heading"
      type="display-small"
    >
      <base-icon
        class="return-tracking-estimated-delivery-date__calendar-icon"
        name="calendar"
      />
      {{ heading }}
    </base-text>
    <base-text
      type="display-xlarge"
      class="return-tracking-estimated-delivery-date__date"
    >
      {{ body }}
    </base-text>
    <base-tooltip
      class="return-tracking-estimated-delivery-date__tooltip"
      :content="tooltip"
    >
      <base-icon
        class="return-tracking-estimated-delivery-date__info-icon"
        name="info"
      />
    </base-tooltip>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { format } from 'date-fns-tz';
import { BaseIcon, BaseText, BaseTooltip } from '@loophq/design-system';
import { useStore } from 'vuex';

const store = useStore();
const heading = store.getters['content']?.moduleReturnTracking.estimatedDeliveryDateHeading;
const tooltip = store.getters['content']?.moduleReturnTracking.estimatedDeliveryDateTooltip;

const normalizedReturn = inject('normalizedReturn');

const formatDate = (date) => {
  return format(new Date(date), 'MMMM dd, yyyy');
};

const body = computed(() => {
  if (normalizedReturn.value.tracker?.estDeliveryDate) {
    return formatDate(normalizedReturn.value.tracker.estDeliveryDate);
  }

  return 'Not available';
});
</script>

<style lang="scss" scoped>
$block: '.return-tracking-estimated-delivery-date';

#{$block} {
  margin-right: var(--spacing-600);
  padding: var(--spacing-500) var(--spacing-600) var(--spacing-500) 0;
  border-right: 1px solid var(--grey-200);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .base-text {
    color: black;
    display: flex;
    justify-content: center;
  }

  &__heading {
    margin-bottom: var(--spacing-200);
    display: flex;
    align-items: center;
  }

  &__calendar-icon {
    display: block;
    margin-right: var(--spacing-200);
    margin-top: 1px;
  }

  &__tooltip {
    align-self: center;
    margin-top: var(--spacing-300);
    color: black;
  }
}

@media screen and (max-width: $break-large) {
  #{$block} {
    border-right: 0;
    margin-right: 0;
  }
}
</style>
