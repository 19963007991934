import axios from 'axios';
import Return from '@/js/controllers/return';
import { feeTypes, feeOutcomes } from '../constants/fees';

export const getFeeAmounts = async function (order, shopId) {
  try {
    const fees = Return.mapFeesPayload({ ...order });
    const totals = Return.mapTotalsPayload({ ...order });
    const res = await axios.post(`api/v1/${shopId}/fees`, {
      totals,
      order: fees
    });
    return res.data;
  } catch (e) {
    return { 
      errors: e.response?.data?.errors,
    };
  }
};

export const getHandlingFeeByOutcome = function (order, outcome) {
  const { fees } = order.return_policy;
  const handlingFee = fees.find(fee => fee.outcome === outcome);
  if (handlingFee) {
    const amount = handlingFee.type === feeTypes.FLAT_FEE ? handlingFee.amount : handlingFee.fallback_amount;
    return {
      ...handlingFee,
      amount
    };
  }
  return null;
};

export const getDefaultHandlingFeeValue = function (order) {
  return {
    fees: {
      credit: getHandlingFeeByOutcome(order, feeOutcomes.CREDIT),
      refund: getHandlingFeeByOutcome(order, feeOutcomes.REFUND)
    }
  };

};
