<template>
  <section
    class="column-layout"
    :class="[align]"
  >
    <div class="column-layout__content">
      <slot></slot>
    </div>
    <aside
      v-if="$slots.sidebar"
      class="column-layout__sidebar"
    >
      <slot name="sidebar"></slot>
    </aside>
  </section>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      required: false,
      default: () => 'left',
      validator: value => ['left', 'right'].includes(value)
    }
  }
};
</script>

<style lang="scss">
$block: '.column-layout';

#{$block} {
  display: flex;
  width: 100%;

  &.right {
    flex-direction: row-reverse;

    #{$block}__sidebar {
      margin-left: 0;
      margin-right: var(--spacing-900);
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__sidebar {
    flex-shrink: 0;
    width: 33%;
    max-width: 400px;
    margin-left: var(--spacing-900);
  }
}

@media screen and (max-width: $break-large) {
  #{$block} {
    &__sidebar {
      margin-left: var(--spacing-500);
    }
  }
}

@media screen and (max-width: $break-medium) {
  #{$block} {
    flex-direction: column;

    &.right {
      flex-direction: column-reverse;

      #{$block}__sidebar {
        margin-right: 0;
        margin-bottom: 1.25rem;
      }
    }

    &__sidebar {
      width: 100%;
      margin-left: 0;
      margin-top: var(--spacing-400);
    }
  }
}
</style>
