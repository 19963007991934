import { shuffle, uniqBy } from 'lodash';
import { getGroups } from '@/js/controllers/reasons';

export default {
  namespaced: true,
  state: {
    reasons: [],
    groups: [],
    loading: false,
  },
  mutations: {
    setReasons(state, value) {
      state.reasons = value;
    },
    setGroups(state, value) {
      state.groups = value;
    },
    setLoading(state, value) {
      state.loading = value;
    }
  },
  actions: {
    async get({ commit }) {
      commit('setLoading', true);

      const reasons = {};

      const groups = await getGroups();
      groups.flatMap(item => {
        return item.reasons;
      })
        .forEach((reason) => {
          // Check if reason already exists
          // Merge the new reason with the existing one so that we have all of the possible child reasons
          const existing = reasons[reason.id] ? reasons[reason.id] : {};
          let children = [];
          if (reason.children && reason?.children.length) {
            if (existing && existing.children?.length) {
              children = existing.children
                .concat(reason.children);
            } else {
              children = reason.children;
            }
          }
          reasons[reason.id] = {
            ...existing,
            ...reason,
            children: uniqBy(children, 'id').filter(n => n)
          };
        });

      commit('setReasons', Object.values(reasons));
      commit('setGroups', groups);
    },
    shuffle({ state, commit }) {
      const shuffled = state.groups
        .map((group) => {
          if (group.randomize) {
            return {
              ...group,
              reasons: shuffle(group.reasons)
            };
          }

          return group;
        });
      commit('setGroups', shuffled);
    },
  },
  getters: {
    getGroup: (state, getters, rootState) => (groupId) => {
      const group = state.groups.find((group) => group.id === Number(groupId));
      const reasons = group.reasons
        .map((reason) => {
          return {
            ...reason,
            commentsRequired: reason.comments_required,
            text: reason?.copy?.[rootState.language] ?? reason?.copy?.['en'] ?? reason.text,
            children: reason.children.map((child) => {
              return {
                ...child,
                commentsRequired: child.comments_required,
                text: child?.copy?.[rootState.language] ?? child?.copy?.['en'] ?? child.text,
              };
            })
          };
        });

      return {
        ...group,
        reasons
      };
    }
  }
};
