<template>
  <div
    class="banner"
  >
    <base-icon
      name="warning"
      class="banner-icon"
    />
    <div>
      <base-text
        class="banner-heading"
        type="body"
      >
        {{ heading }}
      </base-text>
      <base-text
        type="caption"
      >
        {{ description }}
      </base-text>
    </div>
  </div>
</template>

<script>
import {
  BaseIcon,
  BaseText
} from '@loophq/design-system';
export default {
  components: {
    BaseIcon,
    BaseText,
  },
  props: {
    heading: {
      type: String,
    },
    description: {
      type: String,
    }
  }
};
</script>

<style lang="scss" scoped>
.banner {
  background: #f8f3d4;
  color: #826c08;
  border-radius: 6px;
  padding: var(--spacing-300);
  display: flex;
  align-items: center;
}

.banner-icon {
  height: 22px;
  width: 22px;
  margin-right: var(--spacing-300);
}
</style>
