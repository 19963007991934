import axios from 'axios';
import changeCase from 'change-object-case';

export default {
  submit(shopId, returnKey, submission) {
    return axios
      .post(`api/v1/${shopId}/return/${returnKey}/survey`, submission)
      .then((res) => {
        return changeCase.toCamel(res.data);
      });
  }
};
