import axios from 'axios';
import store from '@/store';

/**
 * Sorts an array of object by an order key on the object
 * @param {object[]} arr - The array to sort
 * @param {number} arr[].order
 * @returns {array}
 */
const sortByOrder = (arr) => {
  return arr
    .sort((a, b) => a.order - b.order)
    .map((item) => {
      if (item.children) {
        return {
          ...item,
          children: sortByOrder(item.children)
            .map((item) => item)
        };
      }

      return item;
    });
};

export const getGroups = () => {
  const shopId = store.getters.shop.id;
  return axios.get(`api/v1/${shopId}/reason-groups`)
    .then((res) => {
      return res.data
        .map((group) => {
          const {
            id,
            product_types,
            randomize,
            ordered_reasons
          } = group;

          return {
            id,
            productTypes: product_types,
            randomize,
            reasons: sortByOrder(ordered_reasons),
            default: group.default
          };
        });
    });
};

export default {
  getGroups
};
