import env from '@/env';

export const gtag = function () {
    window.dataLayer?.push(arguments);
};

export const setUpGA = (key) => {
  //Set up GA + Tag Manager
  const loopAnalyticsKey = env('VITE_GA_KEY');
  config('loop', loopAnalyticsKey, {
    custom_map: {
      dimension1: 'shop_id'
    }
  });
  //Check to see if client has their own GA
  if (key) {
    config('merchant', key);
  }
};

export const config = (name, key) => {
  if (!window.dataLayer) {
    window.dataLayer = window.dataLayer || [];

    gtag('js', new Date());
  }

  gtag('config', key);
};
