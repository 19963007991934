/**
 * A function that builds a return payload from our existing paradigm
 * We should move away from this at some point and use this as our paradigm
 * for managing returns
 *
 * @param {Object} lineItems
 */
const constructPayload = (lineItems, credit = '') => {
  const filtered = Object.values(lineItems || {}).filter(item => item.resolution);
  const toReturn = filtered
    .map(item => {
      return {
        id: item.id,
        type: item.returnType, // exchange, credit. Should be removable eventually
        resolution: item.resolution, // exchange, return
        reason: {
          id: item.reason?.reason_id,
          comment: item.reason?.comment
        }
      };
    });
  const cart = filtered
    .filter(item => item.new_variant)
    .map(item => {
      return {
        variantId: item.new_variant.id,
        lineItemId: item.id
      };
    });

  return {
    items: toReturn,
    resolutions: {
      credit,
      cart
    }
  };
};

export {
  constructPayload
};
