<template>
  <button
    :id="id"
    class="credit-option card"
    :class="[{ 'activated': option === loadingStatus }, {'invalid': validation}]"
    @click="attemptAction"
  >
    <div class="credit-option-info">
      <div class="credit-option-info__header">
        <base-text
          class="credit-option-info__header"
          tag="span"
          type="heading"
        >
          <render-content>
            {{ data.title }}
          </render-content>
        </base-text>
        <div
          v-if="showBonus"
          class="credit-option-bonus"
        >
          <p>{{ $content.pageCredit.shopNowBonus }} <display-price :amount="data.bonusAmount" /></p>
        </div>
      </div>
      <p>
        <render-content>
          {{ data.copy }}
        </render-content>
      </p>
    </div>
    <div class="credit-option-value">
      <base-text
        v-if="showBonus && !isHandlingFeeLoading"
        class="price"
        tag="span"
        type="display-medium"
      >
        <display-price :amount="amount" />
      </base-text>

      <base-text
        v-if="!isHandlingFeeLoading"
        class="price"
        tag="span"
        type="display-medium"
        :class="{ struck: showBonus }"
      >
        <display-price :amount="displayPrice" />
      </base-text>

      <base-text
        v-if="showFee && !isHandlingFeeLoading && handlingFee > 0"
        type="caption"
        class="credit-option-fee-disclaimer"
      >
        <render-content :data="{ feeName: $content.global.handlingFee}">
          ({{ $content.pageCredit.feesDisclaimer }})
        </render-content>
      </base-text>

      <div
        v-if="isHandlingFeeLoading"
        class="credit-option-fee-loader"
      >
        <base-loader />
        <p
          class="credit-option-fee"
        >
          {{ $content.pageCredit.calculatingTotals }}
        </p>
      </div>

      <div class="credit-option-loader"></div>
    </div>
  </button>
</template>

<script>
/* eslint-disable vue/require-prop-types */
import {
  BaseText,
  BaseLoader,
} from '@loophq/design-system';
import DisplayPrice from '@/components/globals/DisplayPrice';

export default {
  components: {
    BaseText,
    BaseLoader,
    DisplayPrice
  },
  props: [
    'data',
    'totals',
    'loadingStatus',
    'option',
    'fee',
    'validation',
    'flowCheck',
    'rules',
    'amount',
    'id',
  ],
  computed: {
    showBonus: function() {
      return !!(this.data.showBonus && this.data.bonusAmount > 0);
    },
    showFee: function() {
      // do not show fees if order has return coverage
      if (this.$store.getters.hasReturnCoverage) {
        return false;
      }
      if (this.data.handlingFee?.setByWorkflows) {
        return true;
      }
      if (this.flowCheck) {
        if (this.data.showFee && !this.flowCheck.noFee) {
          return true;
        }
      }
      return false;
    },
    handlingFee: function() {
      return this.data.handlingFee?.amount;
    },
    isHandlingFeeLoading: function() {
      return this.$store.getters['fees/loading'];
    },
    refund() {
      if (this.showFee) {
        const refund = this.totals.credit.preCalculated.handlingFee;
        if (refund < 0) {
          return 0;
        } else {
          return refund;
        }
      }
      return this.totals.credit.base;
    },
    displayPrice() {
      return this.showBonus ? this.data.base : this.amount;
    }
  },
  methods: {
    attemptAction: function() {
      let shouldTakeAction = true;
      switch (this.option) {
        case 'gift':
          shouldTakeAction = !this.rules.banGiftCard;
          break;
        case 'refund':
          shouldTakeAction = !this.rules.banRefund;
          break;
        case 'SWC':
          shouldTakeAction = !this.rules.banExchange;
          break;
      }

      if (shouldTakeAction) {
        this.data.action(this.option);
      } else {
        return false;
      }
    }
  },
  render: h => h(this)
};
</script>

<style lang="scss" scoped>
.credit-option-value {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.price {
  text-transform: uppercase; // For currency display
  font-weight: 300;
  text-align: right;

  &.struck {
    text-decoration: line-through;
    opacity: 0.25;
  }
}

.credit-option-fee-disclaimer {
  text-transform: uppercase;
  font-size: 0.6rem;
}

.credit-option-loader {
  border-top-color: var(--theme-heading-color);
}

.credit-option-fee-loader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .credit-option-fee {
    margin-top: var(--spacing-200);
    text-align: center;
  }

  .base-loader {
    --loader-color: var(--theme-body-color);
  }
}
</style>
