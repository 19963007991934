const removeHashFromOrderName = (orderName) => {
  if (orderName.charAt(0) === '#') {
    return orderName.slice(1);
  }
  return orderName;
};

export const getPortalReturnUrl = (shopDomain, orderName, zip) => {
  return `${shopDomain}/#/?order=${removeHashFromOrderName(orderName)}&zip=${zip}`;
};
