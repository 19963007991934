<template>
  <div
    class="sustainable-banner fade-in-fwd"
    :class="{ rsp: isRSP }"
  >
    <div>
      <base-text
        class="sustainable-banner__heading"
        type="heading"
      >
        {{ sustainableHeading }}
      </base-text>
    </div>
    <markup-renderer
      class="sustainable-banner__body"
      type="body"
      :content="sustainableDescription"
      :customization-data="{ returnMethod: returnMethodName, impact: returnMethodImpact }"
    />
  </div>
</template>

<script>
import {
  BaseText,
  AlertBanner,
} from '@loophq/design-system';
import firstMile from '@/js/constants/firstMile';
import MarkupRenderer from '@/components/renderers/MarkupRenderer';

export default {
  components: {
    BaseText,
    AlertBanner,
    MarkupRenderer
  },
  props: {
    returnMethod: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isRSP: false,
      backgroundPath: '/img/go-green-cover.svg',
    };
  },
  computed: {
    returnMethodName() {
      return firstMile.FORMATTED_NAMES[this.returnMethod?.name];
    },
    returnMethodIsSustainable() {
      return Object.keys(firstMile.SUSTAINABLE_RETURN_METHODS).includes(this.returnMethod?.name);
    },
    returnMethodImpact() {
      return firstMile.SUSTAINABLE_RETURN_METHODS[this.returnMethod?.name];
    },
    sustainableHeading() {
      if (this.returnMethodIsSustainable) {
        return this.isRSP ? this.$content.moduleSustainableBanner.sustainableReturnMethodHeadingRsp : this.$content.moduleSustainableBanner.sustainableReturnMethodHeadingRcp;
      } else {
        // Encourage returning with original packaging for non-sustainable methods
        return this.$content.moduleSustainableBanner.sustainableDefaultHeading;
      }
    },
    sustainableDescription() {
      if (this.returnMethodIsSustainable) {
        return this.isRSP ? this.$content.moduleSustainableBanner.sustainableReturnMethodDescriptionRsp : this.$content.moduleSustainableBanner.sustainableReturnMethodDescriptionRcp;
      } else {
        // Encourage returning with original packaging for non-sustainable methods
        return this.$content.moduleSustainableBanner.sustainableDefaultDescription;
      }
    },
    backgroundUrl() {
      return `url(${window.location.origin + this.backgroundPath})`;
    }
  },
  created() {
    this.isRSP = !!this.returnMethod?.returnId;
  }
};
</script>

<style lang="scss" scoped>
$block: '.sustainable-banner';
#{$block} {
  padding: var(--spacing-300);
  background-image: v-bind(backgroundUrl);
  background-color: var(--grey-100);
  background-size: 100%;
  border-radius: var(--spacing-300);

  &__heading {
    margin-bottom: var(--spacing-200);
  }
}

.fade-in-fwd {
  animation: fade-in-fwd 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-in-fwd {
  0% {
    transform: translateZ(-80px);
    opacity: 0;
  }

  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}
</style>
