<template>
  <div class="shop-later-gift-card-status">
    <shop-later-alert>
      <img
        v-if="imgSrc"
        :src="imgSrc"
        :alt="imgAlt"
      />
      <base-text
        type="display-medium"
        class="shop-later-gift-card-status__heading"
      >
        {{ heading }}
      </base-text>

      <markup-renderer
        class="shop-later-gift-card-status__body"
        :content="body"
        :customization-data="{ formattedTotalCreditOffer }"
      />
    </shop-later-alert>
  </div>
</template>

<script>
import {
  BaseText,
} from '@loophq/design-system';
import ShopLaterAlert from '@/views/StatusPage/ShopLater/Alert';
import { formatCurrency } from '@/js/helpers/formatCurrency';
import MarkupRenderer from '@/components/renderers/MarkupRenderer';

export default {
  name: 'ShopLaterGiftCardStatus',
  components: {
    MarkupRenderer,
    BaseText,
    ShopLaterAlert
  },
  props: {
    giftCard: {
      type: Object,
      required: false
    },
    currency: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedTotalCreditOffer() {
      return formatCurrency(this.giftCard.value, this.currency);
    },
    delivered() {
      return this.giftCard.state === 'processed';
    },
    onTheWay() {
      return this.giftCard.state !== 'processed';
    },
    heading() {
      if (this.delivered) return this.$content.pageReturnStatus.shopLaterGiftCardReadyHeadingCopy;
      if (this.onTheWay) return this.$content.pageReturnStatus.shopLaterAcceptedHeadingCopy;
      return null;
    },
    body() {
      if (this.delivered) return this.$content.pageReturnStatus.shopLaterGiftCardReadyPrimaryCopy;
      if (this.onTheWay) return this.$content.pageReturnStatus.shopLaterAcceptedPrimaryCopy;
      return null;
    },
    imgSrc() {
      if (this.delivered) return '/img/shop-later-email.svg';
      if (this.onTheWay) return '/img/shop-later-box.svg';
      return null;
    },
    imgAlt() {
      if (this.delivered) return 'Shop Later Email Delivered';
      if (this.onTheWay) return 'Shop Later Gift Card on the Way';
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.shop-later-gift-card-status';

#{$block} {
  img {
    margin-bottom: var(--spacing-300);
    max-width: 25%;
  }

  &__heading {
    color: var(--grey-900);
    line-height: 2rem;
    margin-bottom: var(--spacing-200);
  }

  &__body {
    color: var(--grey-600);
    line-height: 1.25rem;
  }
}
</style>
