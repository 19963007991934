<template>
  <div 
    class="qr-code-line-items" 
    data-testid="qr-code-line-items"
  >
    <div 
      v-for="(lineItem, i) in lineItems"
      :key="i"
      class="qr-code-line-items__line-item"
    >
      <img 
        v-if="i <= 3" 
        :src="lineItem.image" 
      />
      <base-text 
        v-if="extraLineItems >= 1 && i == 3"
        tag="span"
        class="qr-code-line-items__plus-icon"
      >
        +{{ extraLineItems }}
      </base-text>
    </div>
  </div>  
</template>

<script>
import {
  BaseText,
} from '@loophq/design-system';

export default {
  name: 'QrCodeLineItems',
  components: {
    BaseText,
  },
  props: {
    lineItems: {
      type: Array,
      required: true,
    },
    extraLineItems: {
      type: Number,
      required: false,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.qr-code-line-items';

#{$block} {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-top: 2px solid #f7f8f9;
  border-bottom: 2px solid #f7f8f9;
  padding: var(--spacing-400);
  margin-top: var(--spacing-500);
  margin-bottom: var(--spacing-500);

  &__line-item {
    max-height: 57px;
    max-width: 57px;
    position: relative;

    img {
      border-radius: var(--theme-corners);
      overflow: hidden;
      height: 57px;
      width: 57px;
      object-fit: cover;
    }

    & + #{$block}__line-item {
      margin-left: var(--spacing-300);
    }
  }

  &__plus-icon {
    position: absolute;
    background: rgba(0, 0, 0, 50%);
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: white;
    border-radius: var(--corners);
    overflow: hidden;
  }
}
</style>
