import axios from 'axios';
import store from '@/store';
import changeCase from 'change-object-case';
changeCase.options = { recursive: true, arrayRecursive: true };

// We need to grab this at runtime because this is not available when the code is first run
const getId = () => store.state.shopContents.id;
const getLang = () => store.state.language;

export default {
  generate({ id }) {
    if (!id) {
      return Promise.reject('Labels.generate(): You need to send a return id.');
    }

    const shopId = getId();
    const lang = getLang();

    return axios
      .get(
        `api/v1/${shopId}/return/${id}/label`,
        {
          params: {
            lang,
            event_source: 'return_status_page',
          }
        }
      )
      .then((res) => {
        if (res.data.errors) {
          return Promise.reject(res);
        }

        return changeCase.toCamel(res);
      });
  },
  setPrepaid({ id, labelId }) {
    if (!id || !labelId) {
      return Promise.reject('Labels.setPrepaid(): You need to send a return id and a label id.');
    }

    const shopId = getId();
    const lang = getLang();
    return axios
      .get(
        `api/v1/${shopId}/return/${id}/label/set/${labelId}`,
        {
          params: {
            lang,
            event_source: 'return_status_page'
          }
        }
      )
      .then((res) => changeCase.toCamel(res.data));
  }
};
