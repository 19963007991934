import axios from 'axios';
import store from '@/store';

export default {
  async getPreview(productType) {
    const shopId = store.state.shopContents.id;
    const res = await axios.post(
            `api/v1/${shopId}/collections/preview`,
            { product_type: productType }
    );

    if (res.errors) {
      throw res.errors;
    }

    return {
      products: res.data.related_products,
      count: res.data.shop_now_count,
    };
  }
};
