import axios from 'axios';
import store from '@/store';

export default {
  async createRequest(data) {
    const shopId = store.state.shopContents.id;
    const lang = store.getters.order.language;
    const res = await axios.post(`api/v1/${shopId}/return/gift?lang=${lang}`, data);

    if (res.data.errors) {
      throw res.data.errors.join(',');
    }

    return res.data;
  }
};
