<template>
  <base-text
    v-if="normalizedReturn.tracker?.carrierTrackingUrl"
    class="tracking-carrier-link"
    type="body-2"
  >
    <span
      v-if="props.showLabelInfo"
      class="tracking-carrier-link__label-info"
    >
      {{ labelInfo }}
    </span>

    <div class="tracking-carrier-link__wrapper">
      <img
        v-if="carrierImage"
        class="tracking-carrier-link__carrier-logo-img"
        :src="carrierImage"
        :alt="`${normalizedReturn.tracker?.carrier} logo`"
      />

      <span
        v-else
        class="tracking-carrier-link__carrier-name"
      >
        {{ normalizedReturn.tracker.carrier }}
      </span>

      <a
        :href="normalizedReturn.tracker.carrierTrackingUrl"
        target="__blank"
        class="tracking-carrier-link__carrier-link"
      >
        {{ normalizedReturn.label.trackingNumber }}
      </a>
    </div>
  </base-text>
</template>

<script setup>
import { BaseText } from '@loophq/design-system';
import { useStore } from 'vuex';
import { computed, inject } from 'vue';
import { carrierImg } from '@/js/helpers/carriers';

const store = useStore();

const normalizedReturn = inject('normalizedReturn');

const props = defineProps({
  showLabelInfo: {
    type: Boolean,
    default: true
  }
});

const labelInfo = computed(() => {
  return store.getters['content'].moduleReturnTracking.labelInfo;
});

const carrierImage = computed(() => {
  return carrierImg(normalizedReturn?.value?.tracker?.carrier);
});
</script>

<style lang="scss" scoped>
$block: '.tracking-carrier-link';

#{$block} {
  display: flex;
  align-items: center;

  &__carrier-logo-img {
    max-width: 100px;
    height: auto;
    max-height: 30px;
    width: auto;
    margin-right: var(--spacing-200);
  }

  &__carrier-name {
    margin-right: var(--spacing-200);
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }
}

@media screen and (width <= 680px) {
  #{$block} {
    flex-direction: column;

    &__label-info {
      margin-bottom: var(--spacing-200);
    }
  }
}
</style>
