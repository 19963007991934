import { isObject } from 'lodash';

/**
 * Calculate actual text length from a tiptap doc object
 *
 * @param {Object} doc - a tiptap doc
 */
export const calculateWysiwygLength = (doc) => {
  // eslint-disable-next-line no-prototype-builtins
  if (isObject(doc) && doc.hasOwnProperty('type') && doc.hasOwnProperty('content')) {
    return doc.content.reduce((accumulator, current) => {
      if (current.text) {
        return accumulator + current.text.length;
      }

      return accumulator + calculateWysiwygLength(current);
    }, 0);
  }

  return 0;
};

export const hasContent = (doc) => {
  return calculateWysiwygLength(doc) > 0;
};
