/*eslint-disable no-empty-function */
const models = () => {
};

const factories = () => {
};

const seed = () => {
};

const routes = (context) => {
  context.get('/:shopId/return/:returnKey/related', (schema, request) => {
    const return_key = request.params.returnKey;
    return findOrFail(schema, return_key);
  });
};

const findOrFail = (schema, returnKey) => {
  const item = schema.returns.findBy({ return_key: returnKey });
  if (item) {
    const returns = item.returns ?? [];
    return { returns };
  }

  return new Response(
    404,
    { 'Content-Type': 'application/json' },
    {
      errors: [{
        title: 'return not found',
        description: 'return could not be found'
      }]
    }
  );
};

export default {
  factories,
  routes,
  models,
  seed
};
