import { Model } from 'miragejs';
import { products } from './mocks/products';

const serializers = (Serializer) => {
  return {
    product: Serializer.extend({
      root: false,
      embed: true
    }),
  };
};

const models = () => {
  return {
    product: Model,
  };
};

const factories = () => {
  return {};
};

const seed = (context) => {
  products.forEach(item => {
    context.create('product', item);
  });
};

export default {
  serializers,
  models,
  factories,
  seed
};
