<template>
  <span class="icon-swapper">
    <span
      class="icon-swapper__icons"
      :class="{ arrow: active }"
    >
      <svg
        class="icon-swapper__close"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
      ><path
        d="M7.414 6l4.293-4.293A.999.999 0 1 0 10.293.293L6 4.586 1.707.293A.999.999 0 1 0 .293 1.707L4.586 6 .293 10.293a.999.999 0 1 0 1.414 1.414L6 7.414l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L7.414 6z"
        fill="currentColor"
        fill-rule="evenodd"
      /></svg>
      <svg
        class="icon-swapper__arrow"
        viewBox="0 0 16 16"
      ><path
        fill="currentColor"
        transform="translate(-1 -1)"
        fill-rule="evenodd"
        d="M15.936 8.364l-4.5-4.5a.899.899 0 1 0-1.272 1.272L13.127 8.1H2.7a.9.9 0 1 0 0 1.8h10.427l-2.963 2.964a.899.899 0 1 0 1.272 1.272l4.5-4.5a.899.899 0 0 0 0-1.272"
      /></svg>
    </span>
    <span
      v-if="!active"
      class="visually-hidden"
    >Close</span>
    <span
      v-else
      class="visually-hidden"
    >Back to parent nav</span>
  </span>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.icon-swapper';
#{$block} {
  display: flex;
  align-items: center;
  justify-content: center;

  &__icons {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    &.arrow {
      border: none;
      padding: 0;

      #{$block} {
        &__close {
          opacity: 0;
        }

        &__arrow {
          opacity: 1;
          transform: translate3d(-50%, -50%, 0) rotate(180deg);
          transition: transform 0.24s ease-out;
        }
      }
    }
  }

  &__close {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 1rem;
    height: 1rem;
    transition: opacity 0.24s ease-out;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(100%, -50%, 0) rotate(180deg);
    width: 1.25rem;
    height: 1.25rem;
    opacity: 0;
    transition: opacity 0.24s ease-out;
  }
}
</style>
