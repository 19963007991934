import Order from '@/js/controllers/order';

export default {
  namespaced: true,
  state: {
    step: 'GiftReceiptLookup',
    order: null,
    orderNumber: '',
    zip: '',
    recipientName: '',
    selectedItems: [],
    lineItems: {},
    qrCodeData: {},
    giftReceipts: [],
    activeReceipt: null
  },
  mutations: {
    /**
     * @param {Object} state
     * @param {Object} receipt
     */
    setActiveReceipt(state, receipt) {
      state.activeReceipt = receipt ? { ...receipt } : null;
    },
    /**
     * @param {Object} state
     * @param {Object} qrCodeData
     */
    setQRCodeData(state, qrCodeData) {
      state.qrCodeData = qrCodeData;
    },
    /**
     * @param {Object} state
     * @param {Object} giftReceiptData
     */
    addGiftReceipt(state, giftReceiptData) {
      state.giftReceipts.push(giftReceiptData);
      // ensure line items already stored in state are associated with newly created gift receipt.
      // appropriately prevents items from being re-selected when creating additional receipts.
      giftReceiptData.line_item_ids?.forEach((id) => {
        state.lineItems[id].gift_receipt_id = giftReceiptData.id;
      });
    },
    /**
     * @param {Object} state
     * @param {Object} lineItem
     */
    updateLineItems(state, lineItem) {
      state.lineItems[lineItem.id] = { ...lineItem, loading: false };
    },
    /**
     * @param {Object} state
     * @param {Object[]} selectedItems
     */
    setSelectedItems(state, selectedItems) {
      state.selectedItems = selectedItems;
    },
    /**
     * @param {Object} state
     * @param {String} name
     */
    setRecipientName(state, name) {
      state.recipientName = name;
    },
    /**
     * @param {Object} state
     * @param {Number|String} orderNumber
     */
    setOrderNumber(state, orderNumber) {
      state.orderNumber = orderNumber;
    },
    /**
     * @param {Object} state
     * @param {Number|String} zip
     */
    setZip(state, zip) {
      state.zip = zip;
    },
    /**
     * @param {Object} state
     * @param {Object} order
     */
    setOrder(state, order) {
      state.order = order;
      if (order.line_item_ids && !Object.keys(state.lineItems).length) {
        order.line_item_ids.forEach(id => {
          state.lineItems[id] = { id, loading: true };
        });
      }

      // set giftReceipts
      if (order.gift_receipts?.length) {
        state.giftReceipts = order.gift_receipts;
      }
    },
    /**
     * @param {Object} state
     * @param {String} step
     */
    setStep(state, step) {
      state.step = step;
    },
    /**
     * Resets the state of this store, useful for when you land back on the main page
     * without the page reloading
     *
     * @param {Object} state
     */
    resetStore(state) {
      state.order = null;
      state.orderNumber = '';
      state.zip = '';
      state.recipientName = '';
      state.selectedItems = [];
      state.lineItems = {};
      state.qrCodeData = {};
      state.giftReceipts = [];
    },
    /**
     * Updates the store's giftReceipts
     *
     * @param {Object} state
     * @param {Array<Object>} giftReceipts
     */
    updateGiftReceipts(state, giftReceipts) {
      state.giftReceipts = giftReceipts;
    }
  },
  actions: {
    resetStore({ commit }) {
      commit('resetStore');
    },
    loadLineItems({ state, commit }) {
      const lineItemIds = state.order.line_item_ids;
      if (lineItemIds) {
        lineItemIds.forEach(lineItemId => {
          try {
            const getLineItem = async (lineItemId) => {
              return await Order.getLineItem(lineItemId, {
                allowUnfulfilledItems: true
              });
            };

            getLineItem(lineItemId).then(lineItem => {
              lineItem.variants = lineItem.variant_title;
              commit('updateLineItems', lineItem);
            });
          } catch (error) {
            console.error(error);
            console.error(`Product ${lineItemId} failed to load.`);
          }
        });
      }
    }
  },
  getters: {
    lineItems(state) {
      return state.lineItems;
    }
  }
};
