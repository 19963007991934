let memoize = function (factory, ctx) {
  var cache = {};
  return function (key) {
    if (!(key in cache)) {
      cache[key] = factory.call(ctx, key);
    }
    return cache[key];
  };
};

export const colorToRGBA = (function () {
  var canvas = document.createElement('canvas');
  canvas.width = canvas.height = 1;
  var ctx = canvas.getContext('2d');
    
    
  return memoize(function (col) {
    ctx.clearRect(0, 0, 1, 1);
    ctx.fillStyle = col;
    ctx.fillRect(0, 0, 1, 1);
    return Array.from(ctx.getImageData(0, 0, 1, 1).data);
  });
})();

export const isDark = color => {
  const rgb = colorToRGBA(color);

  // YIQ equation from http://24ways.org/2010/calculating-color-contrast
  var yiq = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
  return yiq < 128;
};

export const isLight = color => {
  return !isDark(color);
};
