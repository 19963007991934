<template>
  <section class="support-section">
    <base-text
      v-if="$content.moduleFooter.heading"
      class="support-section__text"
      tag="h2"
    >
      <render-content>
        {{ $content.moduleFooter.heading }}
      </render-content>
    </base-text>
    <base-text
      class="support-section__text"
      data-testid="support-email"
      @click="trackClick"
    >
      <render-content :data="{ link }">
        {{ $content.moduleFooter.copy }}
      </render-content>
    </base-text>
  </section>
</template>

<script>
import { BaseText } from '@loophq/design-system';
import { track } from '@/js/segment';
import { customerPortalEvents, products } from '@/js/constants/segment';

export default {
  name: 'SupportSection',
  components: {
    BaseText
  },
  props: {
    page: {
      type: String,
      required: false,
    }
  },
  computed: {
    link() {
      if (!this.$content.moduleFooter.link) {
        const email = this.$shop.support_email;
        return {
          type: 'link',
          attrs: {
            href: `mailto:${email}`
          },
          text: email,
          events: {
            click: () => {
              this.$trackEvent('support contacted');
            }
          }
        };
      }

      return {
        type: 'link',
        attrs: {
          href: this.$content.moduleFooter.link,
          target: '_blank',
          rel: 'noopener'
        },
        text: this.$content.moduleFooter.linkCopy
      };
    },
    analytics() {
      return this.$store.getters['analytics/getData'] ?? {};
    },
  },
  methods: {
    trackClick() {
      track(customerPortalEvents.SUPPORT_EMAIL_CLICKED, {
        ...this.analytics,
        page: this.page,
        shop: this.$shop.id,
        shopName: this.$shop.name,
        product: products.CUSTOMER_PORTAL,
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.support-section';

#{$block} {
  display: flex;
  flex-direction: column;

  a {
    color: inherit;
  }

  &__text {
    text-align: inherit;
    color: var(--theme-text-color);
  }
}
</style>
