<template>
  <div v-if="collection.products && collection.products.length">
    <div class="shop-collection__header">
      <base-text
        type="heading"
        class="shop-collection__title"
      >
        {{ collection.title }}
      </base-text>
      <base-button
        v-track="'Shop all'"
        class="shop-collection__shop-all"
        type="secondary"
        size="small"
        :to="`/shop/collection/${collection.id}`"
      >
        {{ $content.pageShopNow.shopAllButton }}
      </base-button>
    </div>
    <div class="shop-collection__items">
      <collection-item
        v-for="product in mappedProducts"
        :key="`${collection.id}-${product.id}`"
        class="shop-collection__item"
        :collection="collection"
        :product="product"
      />
    </div>
    <base-button
      class="shop-collection__shop-all-mobile"
      type="secondary"
      size="small"
      :to="`/shop/collection/${collection.id}`"
    >
      {{ $content.pageShopNow.shopAllButton }}
    </base-button>
  </div>
</template>

<script>
import {
  BaseText,
  BaseButton,
} from '@loophq/design-system';
import CollectionItem from './ShopNowCollectionItem';

export default {
  components: {
    BaseText,
    BaseButton,
    CollectionItem
  },
  props: {
    collection: {
      required: true,
      type: Object,
    },
  },
  computed: {
    mappedProducts() {
      return this.collection.products.slice(0, this.previewProductCount);
    },
    previewProductCount() {
      return this.$screen.width < 1365 ? 2 : 3;
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.shop-collection';

#{$block} {
  &__items {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--spacing-800);

    @supports (display: grid) {
      display: grid;

      /* autoprefixer: ignore next */
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-template-rows: 1fr;
      grid-gap: 1.5rem;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-300);
  }

  &__title {
    @include responsive-font(2.25rem, 1.5rem);
  }

  &__shop-all {
    margin-left: var(--spacing-300);
  }

  &__shop-all-mobile {
    display: none;
  }
}

@media screen and (max-width: $break-large) {
  #{$block} {
    &__items,
    &__loader {
      /* autoprefixer: ignore next */
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: $break-small) {
  #{$block} {
    &__items,
    &__loader {
      /* autoprefixer: ignore next */
      grid-gap: 0.75rem;
      margin-bottom: 0;
    }

    &__shop-all {
      display: none;
    }

    &__shop-all-mobile {
      display: flex;
      margin-bottom: var(--spacing-800);
    }
  }
}
</style>
