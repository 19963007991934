<template>
  <div>
    <div class="gift-receipt-list-item">
      <div class="gift-receipt-list-item__name-date-wrapper">
        <base-text
          tag="span"
          type="body"
          class="gift-receipt-list-item__recipient-name"
        >
          <render-content :data="{ recipientName: receipt.recipient_name }">
            {{ $content.moduleGiftReceiptLookup.giftReceiptListNameField }}
          </render-content>
        </base-text>
        <base-text
          tag="span"
          type="body"
          class="gift-receipt-list-item__creation-date"
        >
          {{ formatTimestamp(receipt.created_at) }}
        </base-text>
      </div>
      <base-button
        class="gift-receipt-list-item__view-button"
        size="small"
        :activated="loading"
        :type="$screen.isMobile ? 'text' : 'secondary'"
        @click="viewReceipt(receipt)"
      >
        {{ $content.moduleGiftReceiptLookup.giftReceiptListViewReceiptButtonText }}
      </base-button>
      <base-button
        type="secondary"
        class="gift-receipt-list-item__delete-button"
        @click="setModalOpen"
      >
        <base-icon name="trash-can" />
      </base-button>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseIcon,
  BaseText,
} from '@loophq/design-system';
import formatDate from '@/js/helpers/formatDate';

export default {
  name: 'GiftReceiptListItem',
  components: {
    BaseButton,
    BaseIcon,
    BaseText,
  },
  props: {
    receipt: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'set-active-receipt',
    'set-modal-open',
  ],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    giftReceipts() {
      return this.$store.state.giftReceipt.giftReceipts;
    },
  },
  methods: {
    setModalOpen(value = false) {
      this.$emit('set-active-receipt', this.receipt);
      this.$emit('set-modal-open', value);
    },
    async viewReceipt() {
      this.loading = true;
      this.$store.commit('giftReceipt/setActiveReceipt', this.receipt);
      this.loading = false;
      this.$store.commit('giftReceipt/setStep', 'SharePage');
    },
    formatTimestamp(date) {
      return formatDate(date, 'short');
    },
  },
};
</script>

<style lang="scss" scoped>
$block: '.gift-receipt-list-item';

#{$block} {
  border-top: 1px solid white;
  display: grid;
  grid-template-columns: 7fr min-content min-content;
  gap: var(--spacing-200);
  align-items: center;
  padding: var(--spacing-300) var(--spacing-200);

  @media screen and (min-width: $break-medium) {
    grid-template-columns: 7fr 4fr min-content;
  }

  &:first-of-type {
    border-top: none;
  }

  &__name-date-wrapper {
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;

    @media screen and (min-width: $break-small) {
      flex-flow: row nowrap;
      align-items: center;
    }
  }

  &__creation-date {
    margin-right: var(--spacing-400);
    font-weight: 400;

    @media screen and (min-width: $break-medium) {
      margin-right: var(--spacing-600);
    }
  }

  &__recipient-name,
  &__creation-date {
    @media screen and (min-width: $break-small) {
      font-size: 115%;
    }
  }

  &__recipient-name {
    @media screen and (max-width: $break-small) {
      font-size: 105%;
      font-weight: bold;
    }
  }

  &__creation-date {
    @media screen and (max-width: $break-small) {
      font-size: 90%;
    }
  }

  &__view-button {
    text-transform: uppercase;

    @media screen and (min-width: $break-small) {
      margin-left: auto;
      padding-left: var(--spacing-500) !important;
      padding-right: var(--spacing-500) !important;
    }
  }

  &__delete-button {
    font-weight: 700;
    width: fit-content;
    margin-left: var(--spacing-300);
    padding: 0 !important;
    border-radius: 50%;

    &::before {
      border-radius: 50%;
    }

    :deep(span.base-button__text) {
      border: 1px solid white;
      border-radius: 50%;
      padding: 8px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
    }

    :deep(svg) {
      margin-left: 3px;
      margin-top: 1px;
      width: 24px;
      height: 24px;
    }
  }
}
</style>
