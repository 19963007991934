import { get } from 'lodash';
import store from '@/store';
import { format } from 'date-fns-tz';

const formatDate = (dateString, monthLength = 'long') => {
  if (!dateString) {
    return '';
  }

  const dateArray = dateString.split(' ')?.[0].split('-');
  const lang = get(store.state, 'language', '');

  if (dateArray.length === 3) {
    if (lang === 'en') {
      return new Date(dateArray[0], dateArray[1] - 1, dateArray[2].split('T')[0]).toLocaleString(lang, {
        year: 'numeric',
        month: monthLength,
        day: 'numeric'
      });
    }
    return new Date(dateArray[0], dateArray[1] - 1, dateArray[2].split('T')[0]).toLocaleString(lang, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    });
  }
  return null;
};

export const formatTrackingDate = (date) => {
  return format(new Date(date), 'MMMM dd, yyyy');
};

export const formatDateToLocalWithTime = (dateString, monthLength = 'long') => {
  if (!dateString) return '';

  const date = new Date(dateString);
  const lang = get(store.state, 'language', '');

  const datePart = date.toLocaleDateString(lang, {
    day: 'numeric',
    month: monthLength,
    year: 'numeric'
  });

  const timePart = date.toLocaleTimeString(lang, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });

  return `${datePart} - ${timePart}`;
};

export default formatDate;
