import { availableMetrics } from '@/js/controllers/metrics';

const PRE_PAID = 'pre-paid';
const HAPPY_RETURNS = 'happy-returns';
const SHOPIFY_POS = 'shopify-pos';
const LOOP_POS = 'loop-pos';
const VEHO = 'veho';
const LABEL_CHOICE = 'label-choice';
const INPOST = 'inpost';
const ASDA = 'asda';
const EVRI = 'evri';
const SEKO = 'seko';
const DHL_SEKO = 'DHL-SEKO';
const PITNEY_BOWES_STANDARD = 'pitney-bowes-standard';
const PITNEY_BOWES_STANDARD_NEWGISTICS = 'NEWGISTICS';


export default {
  PRE_PAID,
  HAPPY_RETURNS,
  SHOPIFY_POS,
  LOOP_POS,
  VEHO,
  LABEL_CHOICE,
  INPOST,
  EVRI,
  ASDA,
  SEKO,
  DHL_SEKO,
  PITNEY_BOWES_STANDARD,

  METRICS: {
    [HAPPY_RETURNS]: availableMetrics.HAPPY_RETURNS_OFFERED,
    [VEHO]: availableMetrics.VEHO_OFFERED,
    [INPOST]: availableMetrics.INPOST_OFFERED,
    [ASDA]: availableMetrics.ASDA_OFFERED,
    [EVRI]: availableMetrics.EVRI_OFFERED,
  },

  FORMATTED_NAMES: {
    [PRE_PAID]: 'Box and ship',
    [HAPPY_RETURNS]: 'Happy Returns®',
  },

  SUSTAINABLE_RETURN_METHODS: {
    [HAPPY_RETURNS]: '16 shipments',
  },

  // Always hide the sustainable banner for certain return methods
  UNSUSTAINABLE_RETURN_METHODS: [
    VEHO
  ],

  RETURN_METHODS_SCROLLTO: [
    VEHO,
  ],

  RETURN_METHODS_NEED_VALIDATED: [
    VEHO,
    EVRI,
    SEKO,
  ],

  RETURN_METHODS_THAT_REPLACE_BOX_AND_SHIP: [
    INPOST,
    EVRI,
    ASDA,
    SEKO,
    PITNEY_BOWES_STANDARD,
  ],

  REBOUND_UK_CARRIERS: [
    INPOST,
    EVRI,
    ASDA
  ],

  REBOUND_SLUGS: {
    [INPOST]: 'inpost-drop-off-select',
    [ASDA]: 'asda-drop-off',
    evri_qr: 'evri-drop-off-qr-code',
    evri_label: 'evri-drop-off-label'
  },

  LABEL_CHOICES: {
    [EVRI]: {
      qrCode: 'evri-drop-off-qr-code',
      label: 'evri-drop-off-label'
    },
    [SEKO]: {
      qrCode: 'qr',
      label: 'label'
    },
    [PITNEY_BOWES_STANDARD]: {
      qrCode: 'qr',
      label: 'label'
    }
  },

  RETURN_METHODS_WITH_LABEL_CHOICES: [
    EVRI,
    SEKO,
    PITNEY_BOWES_STANDARD,
  ],

  SINGLE_CHOICE_RETURN_METHODS: [
    SEKO,
    DHL_SEKO,
    INPOST,
    EVRI,
    ASDA,
    PITNEY_BOWES_STANDARD_NEWGISTICS,
  ],

  DENSE_POPULATION_MILES: 2,
  REGULAR_POPULATION_MILES: 10,

  VEHO_SCHEDULABLE_DAYS: 7,
  VEHO_HOLIDAYS: {
    '1,1': 'New Years Day',
    '7,4': 'July 4th',
    '12,25': 'Christmas Day',
    // Some holidays dont have concrete dates, but instead has 'nth day of kth week'
    // For example, Thanksgiving is Thursday of the fourth week
    // month,week,day
    '10,4,4': 'Thanksgiving Day',
  },
  VEHO_PICKUP_OPTIONS: [
    'Leave at door',
    'Meet at door',
    'Meet outside'
  ],
  VEHO_SPECIAL_INSTRUCTIONS_MAX_CHAR: 256,
  VEHO_NEXT_ICONS: [
    'package-alt',
    'shipment',
    'chat',
    'circle-tick',
  ],
  RETURN_CODE_NEXT_ICONS: [
    'package-alt',
    'qr-code',
    'circle-tick',
  ],
  VEHO_STATE_NEEDS_SCHEDULED: 'needs_scheduled',
  VEHO_STATE_TIMED_OUT: 'timed_out',
};
