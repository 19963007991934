<template>
  <div class="not-found">
    <blurred-background>
      <base-text type="display-large">
        Page not found.
      </base-text>
    </blurred-background>
  </div>
</template>

<script>
import {
  BaseText
} from '@loophq/design-system';
import BlurredBackground from '@/components/layout/BlurredBackground.vue';

export default {
  name: 'NotFound',
  components: {
    BlurredBackground,
    BaseText
  }
};
</script>

<style lang="scss" scoped>
$block: '.not-found';

#{$block} {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
