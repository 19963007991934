import { getFeeAmounts, getDefaultHandlingFeeValue } from '@/js/controllers/fees';
import { logError } from '@/js/helpers/errors';
import { merge } from 'lodash';

export default {
  namespaced: true,
  state: {
    handlingFee: null,
    loading: false,
    error: null,
  },
  mutations: {
    setHandlingFee(state, handlingFee) {
      state.handlingFee = {
        ...handlingFee
      };
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setError(state, error) {
      state.error = error || null;
    },
  },
  actions: {
    async getFees({ commit, dispatch, rootGetters }, order) {
      try {
        commit('setLoading', true);
        let handlingFee = getDefaultHandlingFeeValue(order);
        const fees = await getFeeAmounts(order, rootGetters.shop.id);

        if (fees.errors) {
          // set fallback on error with getting real fee amounts
          commit('setHandlingFee', handlingFee);

          throw new Error(fees.errors);
        }

        if (fees.refund) {
          handlingFee.fees.refund = merge(handlingFee.fees.refund, fees.refund.fee);
        } else {
          handlingFee = merge(handlingFee, fees);
        }
        commit('setHandlingFee', handlingFee);

        await dispatch('totals/update', null, { root: true });
        commit('setLoading', false);
      } catch (errors) {
        logError(errors);
        if (errors) {
          commit('setError', errors.message);
        }
      }
    }
  },
  getters: {
    handlingFee: (state) => state.handlingFee,
    loading: (state) => state.loading,
    error: (state) => state.error,
  }
};
