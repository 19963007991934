<template>
  <div
    class="return-method-container"
  >
    <base-text
      v-if="shouldShowLabelChoices"
      type="body-2"
      class="return-method-prompt"
    >
      {{ content.prompt }}
    </base-text>
    <base-text
      type="body-2"
      class="return-method-legal"
    >
      {{ content.body }}
    </base-text>

    <base-button
      v-track="track"
      class="all-locations-btn"
      size="small"
      type="secondary"
      target="_blank"
      :to="locationsLink"
      @click.stop
    >
      <span
        class="icon-label"
      >
        {{ content.viewLocationsBtn }}
        <base-icon
          class="external-icon"
          name="external-link"
        />
      </span>
    </base-button>

    <div v-if="shouldShowLabelChoices">
      <hr class="label-choice__hr" />

      <div class="label-choice__radio-container">
        <div
          v-for="option in returnOptions"
          :key="option.key"
          class="label-choice__radio"
          @click="handlePickupOptionSelect(option.key)"
        >
          <radio-input
            v-model="returnOptionSelected"
            class="label-choice__radio-option"
            :class="{ disabled : !returnMethod.options.includes(option.key) }"
            :value="option.key"
            :disabled="!returnMethod.options.includes(option.key)"
          >
            <base-text
              class="label-choice__radio-option-heading"
              type="body"
              data-testid="qr-code-radio-button"
            >
              {{ option.heading }}
            </base-text>
            <base-text
              class="label-choice__radio-option-copy"
              type="body-2"
            >
              {{ option.copy }}
            </base-text>
          </radio-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseText,
  BaseIcon,
  RadioInput,
} from '@loophq/design-system';
import firstMile from '@/js/constants/firstMile';

export default {
  components: {
    BaseButton,
    BaseText,
    BaseIcon,
    RadioInput,
  },
  props: {
    returnMethod: {
      type: Object,
      required: true,
    },
  },
  emits: ['validated'],
  data() {
    return {
      expanded: false,
      returnOptions: [],
      returnOptionSelected: '',
    };
  },
  computed: {
    isInpost() {
      return this.returnMethod.name === firstMile.INPOST;
    },
    isAsda() {
      return this.returnMethod.name === firstMile.ASDA;
    },
    isEvri() {
      return this.returnMethod.name === firstMile.EVRI;
    },
    isSeko() {
      return this.returnMethod.name === firstMile.SEKO;
    },
    isPitneyBowes() {
      return this.returnMethod.name === firstMile.PITNEY_BOWES_STANDARD;
    },
    shouldShowLabelChoices() {
      return firstMile.RETURN_METHODS_WITH_LABEL_CHOICES.includes(this.returnMethod.name);
    },
    locationsLink() {
      if (this.isInpost) return `https://inpost.co.uk/lockers/?utm_source=Loop&utm_medium=returns_portal&utm_campaign=instant_returns`;
      else if (this.isAsda) return `https://storelocator.asda.com`;
      else if (this.isEvri) return `https://www.evri.com/find-a-parcelshop`;
      else if (this.isSeko) return `https://tools.usps.com/find-location.htm?locationType=po&serviceType=lbroretail`;
      else if (this.isPitneyBowes) return `https://tools.usps.com/find-location.htm?locationType=po&serviceType=lbroretail`;
      else return ``;
    },
    track() {
      if (this.isInpost) return 'inpost view locations clicked';
      else if (this.isAsda) return `asda view locations clicked`;
      else if (this.isEvri) return `evri view locations clicked`;
      else if (this.isSeko) return `seko usps view locations clicked`;
      else return '';
    },
    content() {
      if (this.isInpost) return this.$content.moduleReturnMethodSelectorInpost;
      else if (this.isAsda) return this.$content.moduleReturnMethodSelectorAsda;
      else if (this.isEvri) return this.$content.moduleReturnMethodSelectorEvri;
      else if (this.isSeko) return this.$content.moduleReturnMethodSelectorSeko;
      else if (this.isPitneyBowes) return this.$content.moduleReturnMethodSelectorPitneyBowesStandard;
      else return {};
    }
  },
  created() {
    // Evri and SEKO require a customer selection between QR code and Label
    if (this.shouldShowLabelChoices) {
      let qrCodeKey, labelKey;
      if (this.isEvri) {
        qrCodeKey = firstMile.LABEL_CHOICES[firstMile.EVRI].qrCode;
        labelKey = firstMile.LABEL_CHOICES[firstMile.EVRI].label;
      } else if (this.isSeko) {
        qrCodeKey = firstMile.LABEL_CHOICES[firstMile.SEKO].qrCode;
        labelKey = firstMile.LABEL_CHOICES[firstMile.SEKO].label;
      } else if (this.isPitneyBowes) {
        qrCodeKey = firstMile.LABEL_CHOICES[firstMile.PITNEY_BOWES_STANDARD].qrCode;
        labelKey = firstMile.LABEL_CHOICES[firstMile.PITNEY_BOWES_STANDARD].label;
      }

      this.returnOptions = [
        {
          key: qrCodeKey,
          heading: this.content.qrCodeHeading,
          copy: this.content.qrCodeCopy
        },
        {
          key: labelKey,
          heading: this.content.labelHeading,
          copy: this.content.labelCopy
        }
      ];

      // If only one return option is available, preselect the option
      if (this.returnMethod?.options?.length === 1) {
        const key = this.returnMethod.options[0];
        this.returnOptionSelected = key;
        this.handlePickupOptionSelect(key);
        this.$emit('validated', true);
      }
    }
  },
  methods: {
    handlePickupOptionSelect(key) {
      if (this.returnMethod.options.includes(key)) {
        this.$store.dispatch('firstMile/setReturnMethod', {
          ...this.returnMethod,
          returnOption: key,
        });
        this.$emit('validated', true);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.all-locations-btn {
  margin-top: 0;
}

.distance {
  margin-bottom: 12px;
}

.heading {
  display: inline-block;
  color: var(--grey-800);
  font-weight: 500;
  padding-right: var(--spacing-200);
}

.link {
  color: inherit !important;
  font-weight: normal;
  display: inline-block !important;
}

.location-info {
  margin: var(--spacing-300) 0;
}

.show-more {
  margin: var(--spacing-400) 0 0;
  display: flex !important;
}

.show-more-label {
  display: flex;
  color: var(--primary-color);
}

.location-address {
  text-decoration: underline;
  text-align: left;
}

.return-method-container {
  padding: var(--spacing-400);
}

.external-icon {
  height: 16px;
  margin-left: var(--spacing-200);
}

.icon-label {
  display: flex;
  align-items: center;
  color: #41454a;
}

.link .icon-label {
  text-decoration: underline;
}

.link-icon {
  height: 12px;
}

.return-method-legal {
  color: var(--grey-800);
  margin-bottom: 12px;
}

.label-choice__hr {
  border: 1px solid var(--grey-200);
  margin: var(--spacing-400) 0;
}

.label-choice__radio {
  & + & {
    margin-top: var(--spacing-200);
  }

  &-option {
    display: flex;
    align-items: center;
    padding: var(--spacing-200) 0;

    &-heading {
      color: var(--heading-color);
      font-weight: 600;
    }

    &.disabled {
      &:deep(.radio-input__input) {
        border: none;
        background-color: var(--grey-300);
      }

      &:deep(.radio-input__label) > p {
        color: var(--grey-600);
      }
    }
  }

  &:deep(.radio-input__label) {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 var(--spacing-300);
  }
}

@media screen and (max-width: $break-small) {
  .distance {
    display: block;
  }
}
</style>
