import axios from 'axios';
import store from '@/store';

export const getProducts = async (optionId, payload = {}) => {
  const shopId = store.state.shopContents.id;
  const response = await axios.post(`api/v1/${shopId}/advanced_exchange/option/${optionId}/products`, payload);

  if (response.data.errors) {
    throw response.data.errors;
  }

  return response.data;
};
