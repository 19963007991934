<template>
  <base-card class="portal-inactive">
    <base-text class="inactive-copy">
      <render-content>
        {{ $content.moduleDisabled.copy }}
      </render-content>
    </base-text>
  </base-card>
</template>

<script>
import {
  BaseCard,
  BaseText,
} from '@loophq/design-system';

export default {
  components: {
    BaseCard,
    BaseText,
  }
};
</script>

<style lang="scss" scoped>
.portal-inactive {
  max-width: 500px;
  margin: auto;

  * + * {
    margin-top: var(--spacing-400);
  }
}

.inactive-copy {
  text-align: center;
  color: var(--grey-700);
}
</style>
