<template>
  <div>
    <div
      class="instant-return-details-header"
    >
      <div
        class="instant-return-header"
      >
        <base-icon
          class="instant-exchange-icon"
          name="instant-exchange"
        />
        <base-text
          class="instant-return-header-text"
        >
          Instant return
        </base-text>
      </div>
      <base-button
        class="more-button"
        type="link"
        @click.prevent="toggleExplanation"
      >
        <render-content>
          {{ $content.moduleReturnSelector.explanationButton }}
        </render-content>
      </base-button>
    </div>
    <base-text class="instant-return-body">
      <render-content :data="{ instantReturnWindow }">
        {{ $content.moduleReturnSelector.quickCopy }}
      </render-content>
    </base-text>
  </div>
</template>

<script>
import {
  BaseText,
  BaseButton,
  BaseIcon,
} from '@loophq/design-system';

export default {
  name: 'InstantReturnDetails',
  components: {
    BaseText,
    BaseButton,
    BaseIcon
  },
  props: {
    instantReturnWindow: {
      type: Number,
      required: true,
    },
  },
  emits: [
    'show-instant-return-explanation',
  ],
  methods: {
    toggleExplanation() {
      this.$emit('show-instant-return-explanation');
    }
  }
};
</script>

<style lang="scss" scoped>
.instant-return-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.instant-return-header {
  display: flex;
  align-items: center;
}

.instant-return-header-text {
  font-weight: 700;
  margin-left: -6px;
}

.instant-return-body {
  margin-top: var(--spacing-200);
}

.instant-exchange-icon {
  color: var(--primary-color) !important;
  height: 1.15rem;
  margin-top: 4px;
}
</style>
