<template>
  <div>
    <div
      class="instant-exchange-details-header"
    >
      <div
        class="instant-exchange-header"
      >
        <base-icon
          class="instant-exchange-icon"
          name="instant-exchange"
        />
        <base-text
          class="instant-exchange-header-text"
        >
          Instant exchange
        </base-text>
      </div>
      <base-button
        class="more-button"
        type="link"
        @click.prevent="toggleExplanation"
      >
        <render-content>
          {{ $content.moduleExchangeSelector.explanationButton }}
        </render-content>
      </base-button>
    </div>
    <base-text class="instant-exchange-body">
      <render-content :data="{ instantExchangeWindow }">
        {{ $content.moduleExchangeSelector.quickCopy }}
      </render-content>
    </base-text>
  </div>
</template>

<script>
import {
  BaseText,
  BaseButton,
  BaseIcon,
} from '@loophq/design-system';

export default {
  components: {
    BaseText,
    BaseButton,
    BaseIcon
  },
  props: {
    instantExchangeWindow: {
      type: Number,
      required: true,
    }
  },
  emits: ['show-instant-exchange-explanation'],
  methods: {
    toggleExplanation() {
      this.$emit('show-instant-exchange-explanation');
    }
  }
};
</script>

<style lang="scss" scoped>
.instant-exchange-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.instant-exchange-header {
  display: flex;
  align-items: center;
}

.instant-exchange-header-text {
  font-weight: 700;
  margin-left: -6px;
}

.instant-exchange-body {
  margin-top: var(--spacing-200);
}

.instant-exchange-icon {
  color: var(--primary-color) !important;
  height: 1.15rem;
  margin-top: 4px;
}
</style>
