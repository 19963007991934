import { merge } from 'lodash';
export const createLocation = (id, data = {}) => {
  return merge(
    {},
    {
      id,
      name: 'Loop HQ',
      distance: 2.34333,
      address: {
        company: null,
        address1: '797 N Wall St',
        address2: 'Suite 201',
        city: 'Columbus',
        state: 'Ohio',
        zip: '43215',
        country: 'United States',
        country_code: 'US',
        phone: '(555) 555-5555',
        latitude: '40.75816881729377',
        longitude: '-73.98479290229305'
      }
    },
    data
  );
};

export const locations = [
  createLocation(1),
  createLocation(
    2,
    {
      address: {
        address1: '201 E Randolph St',
        address2: '',
        city: 'Chicago',
        state: 'Illinois',
        zip: '60601'
      },
      name: 'The Bean',
      distance: 7.96123,
    },
  ),
  createLocation(
    3,
    {
      address: {
        address1: '1528 Broadway',
        address2: '',
        city: 'New York',
        state: 'New York',
        zip: '10036'
      },
      name: 'Times Square',
      distance: 12.345883,
    },
  ),
  createLocation(
    4,
    {
      name: 'Farther than normal',
      distance: 24.12384,
    },
  )
];
