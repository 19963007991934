<template>
  <div class="shop-cash-info">
    <base-panel
      class="shop-cash-info__panel"
      type="panel"
      :active="hasShopCashExplanation"
      @close="$emit('handle-info-toggle')"
    >
      <template #footer>
        <base-button
          class="panel-go-back-button"
          type="primary"
          @click="$emit('handle-info-toggle')"
        >
          {{ $content.pageCredit.gotIt }}
        </base-button>
      </template>
      <div class="shop-cash-info__header">
        <h2>Store credit +</h2>
        <img
          alt="Shopify Shop logo"
          src="../../assets/ShopCash/shopLogoBoxed.svg"
          class="shop-logo"
        />
      </div>
      <markup-renderer
        class="shop-cash-info__copy"
        :content="$content.pageCredit.shopCashExplanation"
        :customization-data="{
          shopName: displayShopName,
          shopCashBonusAmount: $filters.currency(bonusAmount, displayCurrency),
        }"
      />
    </base-panel>
  </div>
</template>

<script setup>
import MarkupRenderer from '@/components/renderers/MarkupRenderer.vue';
import { defineProps, defineEmits, computed } from 'vue';
import { useStore } from 'vuex';
import { BasePanel, BaseButton } from '@loophq/design-system';

defineProps({
  hasShopCashExplanation: {
    type: Boolean,
    required: true,
  },
  bonusAmount: {
    type: Number,
    required: true,
    default: 0,
  },
});

defineEmits(['handle-info-toggle']);

const store = useStore();
const displayShopName = computed(() => store.getters.shop.name);
const displayCurrency = computed(() => store.getters['currencies/displayCurrency']);
</script>

<style lang="scss" scoped>
$block: '.shop-cash-info';

#{$block} {
  .panel-go-back-button {
    width: 100%;
  }

  :deep(.shop-cash-info__copy) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: left !important;
    }

    li {
      margin-bottom: var(--spacing-300);
    }

    li p {
      color: $gray-darker;
    }

    a,
    p {
      color: $gray-medium-dark;
    }

    h4 {
      font-weight: 500;
      color: $gray-darker;
    }

    em {
      color: $gray-medium-dark;
      font-size: 12px;
    }
  }

  :deep(.shop-cash-info__header) {
    display: flex;
    margin-bottom: var(--spacing-300);
    align-items: center;

    h2 {
      color: $gray-darker;
      font-weight: 400;
    }
  }

  .shop-logo {
    width: 2.75rem;
    margin-left: 0.25rem;
  }
}
</style>
