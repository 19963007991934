import { Model, Response } from 'miragejs';
import { carts } from './mocks/carts';

const serializers = (Serializer) => {
  return {
    cart: Serializer.extend({
      root: false,
      embed: true
    }),
  };
};

const models = () => {
  return {
    cart: Model
  };
};

const factories = () => {
  return {};
};

const seed = (context) => {
  carts.forEach((item) => {
    context.create('cart', item);
  });
};

const routes = (context) => {
  context.get('/:shopId/cart/:cartId', (schema, request) => {
    const name = request.params.cartId;

    const item = schema.carts.findBy({ name });

    if (item) {
      return item.attrs.items;
    }

    return new Response(
      200,
      { 'Content-Type': 'application/json' },
      {
        'errors': [
          'Could not find specified cart.'
        ]
      }
    );
  });

  context.post('/:shopId/cart/:cartId', (schema, request) => {
    const name = request.params.cartId;
    const body = JSON.parse(request.requestBody);
    const cart = body.cart;
    const item = schema.carts.findBy({ name });

    if (item) {
      item.update({ items: cart });

      return {
        token: name,
        data: { cart }
      };
    }

    return new Response(
      200,
      { 'Content-Type': 'application/json' },
      {
        'errors': [
          'Could not find specified cart.'
        ]
      }
    );
  });
};

export default {
  serializers,
  models,
  factories,
  seed,
  routes
};
