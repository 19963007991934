export const refundEvents = {
  DELIVERED: 'delivered',
  DISABLED: 'disabled',
  IMMEDIATE: 'immediate',
  IN_TRANSIT: 'in_transit',
  MANUAL: 'manual',
  OUT_FOR_DELIVERY: 'out_for_delivery',
  PRE_TRANSIT: 'pre_transit',
};

export const exchangeEvents = {
  DELIVERED: 'delivered',
  DISABLED: 'disabled',
  IMMEDIATE: 'immediate',
  IN_TRANSIT: 'in_transit',
  MANUAL: 'manual',
  OUT_FOR_DELIVERY: 'out_for_delivery',
  PRE_TRANSIT: 'pre_transit',
};

export const giftCardEvents = {
  DELIVERED: 'delivered',
  DISABLED: 'disabled',
  IMMEDIATE: 'immediate',
  IN_TRANSIT: 'in_transit',
  MANUAL: 'manual',
  OUT_FOR_DELIVERY: 'out_for_delivery',
  PRE_TRANSIT: 'pre_transit',
};
