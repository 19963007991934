<template>
  <div
    class="page-container"
    :class="size"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      required: false,
    }
  },
  render: h => h(this),
};
</script>

<style lang="scss" scoped>
.page-container {
  $parent: &;

  max-width: 480px;
  width: 100%;
  margin: 0 auto;

  &.large {
    max-width: 784px;
  }
}
</style>
