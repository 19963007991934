<template>
  <div class="pickup-faq-item__row">
    <base-accordion
      class="pickup-faq-item"
      @toggle="handleToggle"
    >
      <template v-slot:toggle>
        <base-button
          class="pickup-faq-item__heading"
          type="text"
          :icon="icon"
        >
          {{ item.question }}
        </base-button>
      </template>
      <base-text
        class="pickup-faq-item__body"
        type="body-2"
      >
        {{ item.answer }}
      </base-text>
    </base-accordion>
  </div>
</template>

<script>
import { BaseText, BaseButton, BaseAccordion } from '@loophq/design-system';

export default {
  components: {
    BaseAccordion,
    BaseText,
    BaseButton,
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      icon: 'caret-right'
    };
  },
  methods: {
    handleToggle(isActive) {
      if (isActive) {
        this.icon = 'down';
      } else {
        this.icon = 'caret-right';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.pickup-faq-item';

#{$block} {
  &__row {
    & + & {
      border-top: 1px solid var(--grey-200);
    }

    :deep(.base-accordion__toggle) {
      display: flex;

      .base-button {
        width: 100%;
        padding: var(--spacing-300) 0;
        display: flex;
        flex-direction: row;
      }

      .base-button__text {
        text-align: start;

        /* Body */
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      .base-button__icon {
        margin-right: var(--spacing-100);
      }
    }
  }

  &__body {
    padding: 0 var(--spacing-400) var(--spacing-400) var(--spacing-300);
  }
}

@media screen and (max-width: $break-small) {
  #{$block} {
    &__body {
      padding: 0 var(--spacing-400) var(--spacing-200);
    }

    &__row {
      padding: var(--spacing-200) var(--spacing-400) !important;

      & + & {
        margin-top: 0;
      }
    }
  }
}
</style>
