<script>
import { h } from 'vue';
import { render } from './renderers';
import { formatCustomizationString } from '@/js/helpers/formatCustomizationString';

const formatText = (content, data) => {
  if (Array.isArray(content)) {
    return content.map((item) => formatText(item, data));
  }

  if (content?.content) {
    return {
      ...content,
      content: formatText(content.content, data)
    };
  }

  if (content?.text) {
    return {
      ...content,
      text: formatCustomizationString(content.text, data)
    };
  }

  return content;
};

export default {
  props: {
    content: {
      type: Object,
      required: false,
      default: () => ({}),
      validator: value => typeof value === 'object' && 'type' in value && value.type === 'doc'
    },
    customizationData: {
      type: Object,
      required: false
    },
    components: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    markup() {
      if (!this.content || !this.content.content) {
        return [];
      }

      if (this.customizationData) {
        return formatText(this.content.content, this.customizationData);
      }

      return this.content.content;
    }
  },
  render() {
    return h('div', {
      'class': {
        'markup-renderer': true
      }
    }, render(this.markup, h, this.components));
  }
};
</script>

<style lang="scss" scoped>
$block: '.markup-renderer';

#{$block} {
  @include body;

  text-align: left;

  > * + * {
    margin-top: var(--spacing-300);
  }

  /*     h2 {
		@include display-small;
		margin: 1rem 0;
	}

	h3 {
		@include heading;
		margin: 1rem 0;
	}

	h4 {
		@include subheading;
		margin: 1rem 0;
	} */

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  ul {
    list-style-type: disc;
    padding: 0 0 0 2em;

    li {
      list-style-type: disc;
    }
  }

  ol {
    list-style-type: decimal;
    padding: 0 0 0 2em;
  }

  li {
    + li {
      margin-top: 0.25rem;
    }
  }

  blockquote {
    background: var(--grey-200);
    padding: 1.25rem;
  }

  pre {
    background: var(--grey-900);
    color: var(--grey-300);
    padding: 0.25rem 0.5rem 0.375rem;
  }

  code {
    background: var(--grey-900);
    color: var(--grey-300);
    border-radius: 3px;
    padding: 0.25rem 0.5rem 0.375rem;
  }

  img {
    width: auto;
    max-width: 100%;
  }
}
</style>
