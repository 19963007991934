<template>
  <div class="outcomes-header">
    <expandable-list
      v-if="lineItemProps.length"
      class="outcomes-header__list"
      :items="lineItemProps"
    />
  </div>
</template>

<script>
import ExpandableList from '@/components/lists/ExpandableList';

export default {
  components: {
    ExpandableList,
  },
  props: {
    lineItem: {
      type: Object,
      required: true
    },
  },
  computed: {
    lineItemProps() {
      if (this.lineItem && this.lineItem.visible_properties) {
        return this.lineItem.visible_properties.map(({ name, value }) => {
          return {
            key: name,
            value
          };
        });
      }

      return [];
    },
  }
};
</script>

<style lang="scss" scoped>
$block: '.outcomes-header';

#{$block} {
  color: var(--grey-800);
  opacity: 0.675;
}
</style>
