<template>
  <button
    class="policy-conditions"
    @click="handleAcceptPolicy"
  >
    <checkbox-input
      v-model="acceptsPolicyConditions"
      :checked="acceptsPolicyConditions"
      class="policy-conditions__checkbox-input"
      @click="handleAcceptPolicy"
    />
    <markup-renderer
      :content="$content.pageReview.policyAcceptanceMessage"
    />
  </button>
</template>

<script setup>
import {  ref } from 'vue';
import { useStore } from 'vuex';
import {
  CheckboxInput,
} from '@loophq/design-system';
import MarkupRenderer from '@/components/renderers/MarkupRenderer';

const store = useStore();
const acceptsPolicyConditions = ref(store.state.return.acceptsPolicyConditions);

const handleAcceptPolicy = () => {
  acceptsPolicyConditions.value = !acceptsPolicyConditions.value;
  store.commit('return/setAcceptsPolicyConditions', acceptsPolicyConditions.value);
};
</script>

<style lang="scss" scoped>
.policy-conditions {
  display: flex;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;

  &__checkbox-input {
    margin-top: var(--spacing-100);
  }
}
</style>
