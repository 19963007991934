<template>
  <div
    class="veho-return-method"
  >
    <base-text
      class="veho-return-method__heading"
      type="display-medium"
    >
      <render-content>
        {{ $content.moduleReturnMethodSelectorVeho.scheduleCopy }}
      </render-content>
    </base-text>
    <base-text
      class="veho-return-method__step"
      type="body"
    >
      {{ $content.moduleReturnMethodSelectorVeho.pickupDateStep }}
    </base-text>
    <base-text
      class="veho-return-method__body"
      type="body-2"
    >
      <render-content>
        {{ $content.moduleReturnMethodSelectorVeho.vehoDescriptionCopy1 }}
      </render-content>
    </base-text>
    <base-text
      class="veho-return-method__body"
      type="body-2"
    >
      <render-content>
        {{ $content.moduleReturnMethodSelectorVeho.vehoDescriptionCopy2 }}
      </render-content>
    </base-text>
    <veho-date-picker
      @selected="handleDateSelect"
    />
    <base-text
      class="veho-return-method__step"
      type="body"
    >
      {{ $content.moduleReturnMethodSelectorVeho.pickupDetailsStep }}
    </base-text>
    <base-input
      v-model="custPhoneNum"
      v-maska="'+1 (###) ###-####'"
      class="veho-return-method__input-phone"
      type="tel"
      label="Cell phone number"
      :error="custPhoneNumError"
      @maska="handlePhoneInput"
      @input="debouncedCheckForm"
    />
    <base-text
      class="veho-return-method__input-label"
      type="body"
    >
      Pickup option:
    </base-text>
    <div class="veho-return-method__radio-container">
      <div
        v-for="option in pickupOptions"
        :key="option"
        class="veho-return-method__radio"
        @click="handlePickupOptionSelect(option)"
      >
        <radio-input
          v-model="pickupOptionSelected"
          class="veho-return-method__radio-option"
          :value="option"
        >
          {{ option }}
        </radio-input>
      </div>
    </div>
    <base-input
      v-if="pickupOptionSelected.length > 0"
      v-model="specialInstructions"
      class="veho-return-method__input-instructions"
      type="textarea"
      :label="$content.moduleReturnMethodSelectorVeho.specialInstructionsLabel"
      :help-text="$content.moduleReturnMethodSelectorVeho.specialInstructionsHelpText"
      :max="specialInstructionsMax"
      @input="debouncedCheckForm"
    />
  </div>
</template>

<script>
import {
  BaseInput,
  BaseText,
  RadioInput,
} from '@loophq/design-system';
import VehoDatePicker from './VehoDatePicker';
import { maska } from 'maska';
import { debounce } from 'lodash';
import firstMile from '@/js/constants/firstMile';

export default {
  components: {
    BaseInput,
    BaseText,
    RadioInput,
    VehoDatePicker,
  },
  directives: { maska },
  props: {
    returnMethod: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'validated',
  ],
  data() {
    return {
      date: null,
      custPhoneNum: '',
      rawCustPhoneNum: '',
      custPhoneNumError: '',
      pickupOptions: firstMile.VEHO_PICKUP_OPTIONS,
      pickupOptionSelected: '',
      specialInstructionsMax: firstMile.VEHO_SPECIAL_INSTRUCTIONS_MAX_CHAR,
      specialInstructions: ''
    };
  },
  created() {
    this.debouncedCheckForm = debounce(() => this.checkFormCompletion(), 500);
    // Prefill form with customer phone number, if it exists
    this.custPhoneNum = this.$store.getters['return/address'].phone ?? '';
  },
  methods: {
    handleDateSelect(selectedDate) {
      this.date = selectedDate;
      this.debouncedCheckForm();
    },
    handlePickupOptionSelect(option) {
      this.pickupOptionSelected = option;
      this.debouncedCheckForm();
    },
    handlePhoneInput(event) {
      this.rawCustPhoneNum = event.target.dataset.maskRawValue;
    },
    checkFormCompletion() {
      const pickupOption = this.pickupOptionSelected.length > 0;
      const phoneNumIsValid = this.rawCustPhoneNum.length === 10;

      // Show phone error prompt
      if (!phoneNumIsValid && this.rawCustPhoneNum.length > 0) {
        this.custPhoneNumError = this.$content.moduleReturnMethodSelectorVeho.phoneErrorCopy;
      } else {
        this.custPhoneNumError = '';
      }

      if (this.date && phoneNumIsValid && pickupOption) {
        this.$store.dispatch('firstMile/setReturnMethod', {
          ...this.returnMethod,
          pickupDate: this.date.toISOString().split('T')[0],
          phoneNumber: this.rawCustPhoneNum,
          instructions: `${this.pickupOptionSelected}: ${this.specialInstructions || 'no special instructions'}`,
        });
        this.$emit('validated', true);
      } else {
        this.$emit('validated', false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.veho-return-method';

#{$block} {
  padding: var(--spacing-400);

  &__heading {
    color: var(--heading-color);
    font-weight: 500;
    display: block;
    margin-bottom: var(--spacing-400);
  }

  &__step {
    margin-bottom: var(--spacing-400);
    font-weight: 600;
    color: var(--heading-color);
  }

  &__body {
    margin-bottom: var(--spacing-300);
  }

  &__input {
    &-phone,
    &-instructions {
      margin-top: var(--spacing-300);

      .input-wrapper__bottom {
        padding: 0;
      }

      .input-wrapper__helper {
        font-size: 13px;
      }
    }

    &-label {
      margin: var(--spacing-300) 0 var(--spacing-200);
      color: var(--heading-color);
    }

    &-label::after {
      content: '*';
      display: inline-block;
      color: var(--red-300);
    }
  }

  &__radio {
    display: flex;
    justify-content: flex-start;
    flex: 1;

    &-container {
      display: flex;
      justify-content: space-around;
      border: 1px solid var(--grey-200);
      border-radius: var(--spacing-200);
    }

    &-option {
      display: flex;
      padding: 19px 16px;

      &:deep(.radio-input__input) {
        margin-right: var(--spacing-300) !important;
      }
    }
  }

  &__radio + &__radio {
    border-left: 1px solid var(--grey-200);
  }
}

@media screen and (max-width: $break-small) {
  .veho-return-method__radio-container {
    flex-direction: column;
  }

  .veho-return-method__radio + .veho-return-method__radio {
    border-left: none;
    border-top: 1px solid var(--grey-200);
  }
}
</style>

<style lang="scss">
$block: '.veho-return-method';

#{$block} {
  &__input {
    &-phone,
    &-instructions {
      .input-wrapper__bottom {
        padding: 0;
      }

      .input-wrapper__helper {
        font-size: 13px;
      }

      .base-input__input.textarea {
        min-width: auto;
        min-height: 96px !important;
      }
    }

    &-phone {
      .input-wrapper__label::after {
        content: '*';
        display: inline-block;
        color: var(--red-300);
      }
    }
  }

  &__radio {
    &-option {
      clip: auto;

      .radio-input__input {
        position: relative !important;
        height: var(--spacing-400);
        width: var(--spacing-400);
        margin: 0 var(--spacing-200) 0 0;
        clip: auto;
      }

      .radio-input__label {
        color: var(--grey-800);
      }
    }
  }
}
</style>
