<template>
  <div
    class="skeleton-product"
    aria-hidden="true"
  >
    <span class="skeleton-product__image"></span>
    <span
      v-if="showInfo"
      class="skeleton-product__title"
    ></span>
    <span
      v-if="showInfo"
      class="skeleton-product__price"
    ></span>
  </div>
</template>

<script>
export default {
  props: {
    showInfo: {
      type: Boolean,
      required: false,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.skeleton-product';

#{$block} {
  display: flex;
  flex-direction: column;

  &__image {
    @include skeleton;

    width: 100%;
    padding-bottom: 100%;
  }

  &__title {
    @include skeleton;

    width: 80%;
    height: 1.25rem;
    margin-top: 0.75rem;
  }

  &__price {
    @include skeleton;

    width: 40%;
    height: 1.25rem;
    margin-top: 0.5rem;
  }
}
</style>
