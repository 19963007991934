<template>
  <blurred-background
    v-if="$content.pageLookup.shopLinkCopy && !inIframe"
    class="back-to-shop"
  >
    <a
      class="shop-link"
      :href="shopLink"
    >
      <base-icon
        class="shop-icon"
        name="arrow-left"
        aria-hidden="true"
      />
      <render-content>
        {{ $content.pageLookup.shopLinkCopy }}
      </render-content>
    </a>
  </blurred-background>
</template>

<script>
import {
  BaseIcon,
} from '@loophq/design-system';
import BlurredBackground from '@/components/layout/BlurredBackground';
import { inIframe } from '@/js/messages';

export default {
  components: {
    BaseIcon,
    BlurredBackground,
  },
  data() {
    return {
      inIframe: inIframe()
    };
  },
  computed: {
    shopLink() {
      if (this.$content.pageLookup.shopLink) {
        return this.$content.pageLookup.shopLink;
      }

      return `https://${this.$shop.shop_domain}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.back-to-shop {
  display: block;
}

.shop-link {
  color: inherit;
  display: flex;
  align-self: start;
  align-items: center;
  text-decoration: none;
  background-color: transparent;
  background-image: linear-gradient(120deg, var(--theme-text-color), var(--theme-text-color));
  background-repeat: no-repeat;
  background-size: 0 1px;
  background-position: 0% 100%;
  box-shadow: 0 0 0 0 transparent;
  border-radius: calc(var(--corners) / 2);
  transition: background-size var(--easing-300), box-shadow var(--easing-300);

  &:hover {
    background-size: 100% 1px;

    .shop-icon {
      transform: translate3d(-1px, 0, 0);
    }
  }

  &.focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px currentColor;
  }
}

.shop-icon {
  width: 1.25rem;
  height: 1rem;
  margin-right: var(--spacing-200);
  transition: transform var(--transition-300);
}

@media screen and (max-width: $break-small) {
  .back-to-shop {
    width: fit-content;
  }
}
</style>
