import { feeTypes, feeOutcomes } from '../../js/constants/fees';

export const percentageFee = {
  'amount': 53,
  'fallback_amount': 2550,
  'id': 27,
  'type': feeTypes.PERCENTAGE_OF_LABEL,
  'outcome': feeOutcomes.REFUND,
};

export const flatFee = {
  'amount': 53,
  'fallback_amount': 2550,
  'id': 27,
  'type': feeTypes.FLAT_FEE,
  'outcome': feeOutcomes.REFUND,
};

export const feeScenarios = [
  {
    name: 'fee on store credit',
    fees: {
      credit: {
        amount: 250,
        fallback_amount: 0,
        id: 27,
        max_amount: 0,
        max_amount_enabled: false,
        set_by_workflows: false,
        type: feeTypes.FLAT_FEE,
        outcome: feeOutcomes.CREDIT
      }
    }
  },
  {
    name: 'fee on original payment',
    fees: {
      refund: {
        amount: 500,
        fallback_amount: 0,
        id: 27,
        max_amount: 0,
        max_amount_enabled: false,
        set_by_workflows: false,
        type: feeTypes.FLAT_FEE,
        outcome: feeOutcomes.REFUND
      }
    }
  }
];
