<template>
  <div
    class="label-error__message"
    data-testid="label_error_message"
  >
    <slot name="errorDetails" />
  </div>
  <div :class="computedClass.buttonParent">
    <base-button
      :class="computedClass.button"
      icon="printer"
      :disabled="true"
    >
      <render-content>
        {{ text }}
      </render-content>
    </base-button>
    <div
      class="label-error__fix"
      data-testid="label_error_fix"
    >
      <slot name="fixAction" />
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
} from '@loophq/design-system';

export default {
  components: {
    BaseButton,
  },
  props: {
    label: {
      type: Object,
      required: false
    },
    packingSlipsEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hasCombinedLabelsWithPackingSlipsFlag: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {
    text() {
      const content = (this.packingSlipsEnabled && this.hasCombinedLabelsWithPackingSlipsFlag)
        ? this.$content.moduleLabelWithPackingSlip
        : this.$content.moduleLabel;

      return content.cta;
    },
    computedClass() {
      return {
        button: (this.packingSlipsEnabled && this.hasCombinedLabelsWithPackingSlipsFlag)
          ? 'label-error__print-button-full'
          : 'label-error__print-button',
        buttonParent: (this.packingSlipsEnabled && this.hasCombinedLabelsWithPackingSlipsFlag)
          ? 'label-error__actions-full'
          : 'label-error__actions'
      };
    }
  },
};
</script>

<style lang="scss" scoped>
$block: '.label-error';

#{$block} {
  align-self: flex-start;
  width: auto;
  display: flex;
  gap: var(--spacing-300);

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--spacing-300);
    gap: var(--spacing-500);
  }

  &__actions-full {
    align-items: center;
    margin-top: var(--spacing-300);
  }

  &__print-button,
  &__fix {
    width: 50%;
  }

  &__print-button-full {
    width: 100%;

    :deep(.base-button__text) {
      width: auto;
    }
  }

  &__fix > :slotted(*) {
    width: 100%;
  }

  @media screen and (max-width: $break-small) {
    &__print-button {
      width: 100%;
    }

    &__fix {
      width: 100%;
    }

    &__actions {
      flex-direction: column;
      gap: var(--spacing-300);
    }
  }
}
</style>
