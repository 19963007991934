import axios from 'axios';

const loadTrackingData = async (trackingHash) => {
  const response = await axios.get(`/tracking/trackers/${trackingHash}`);
  return response.data;
};

const searchOrderByTrackingNumber = async (searchCriteria) => {
  const { shopId, email, trackingNumber } = searchCriteria;
  const encodedEmail = encodeURIComponent(email);

  const response = await axios.get(`/tracking/${shopId}/lookup?trackingNumber=${trackingNumber}&email=${encodedEmail}`);
  return response.data;
};

const loadTrackingRecommendations = async (recommendationsPayload) => {
  const response = await axios.post(`/tracking/recommendations`, recommendationsPayload);
  return response.data;
};

export default {
  loadTrackingData,
  searchOrderByTrackingNumber,
  loadTrackingRecommendations
};
