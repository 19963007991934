<template>
  <div class="packing-instructions">
    <base-text
      v-if="copy"
      class="packing-instructions__copy"
    >
      <render-content>
        {{ copy }}
      </render-content>
    </base-text>
    <div class="packing-instructions__grid">
      <product-card
        v-for="product in products.primary"
        :key="product.id"
        :product="product"
        :size="primarySize"
      />
    </div>
    <template v-if="products.secondary.length">
      <hr class="packing-instructions__divider" />
      <base-text
        class="packing-instructions__secondary-heading"
        tag="h2"
      >
        <render-content>
          {{ $content.modulePackingInstructions.keepHeading }}
        </render-content>
      </base-text>
      <base-text
        class="packing-instructions__secondary-copy"
        type="body-2"
      >
        <render-content>
          {{ $content.modulePackingInstructions.keepCopy }}
        </render-content>
      </base-text>
      <div class="packing-instructions__list">
        <product-card
          v-for="product in products.secondary"
          :key="product.id"
          size="small"
          :product="product"
        />
      </div>
    </template>
  </div>
</template>

<script>
import {
  ProductCard,
  BaseText,
} from '@loophq/design-system';
import formatProduct from '@/js/helpers/formatProduct';
import PrintPackingSlip from './PrintPackingSlip';
import { replacePlaceholderUsingObject } from '@/js/utility/replacePlaceholderUsingObject';

export default {
  components: {
    ProductCard,
    BaseText,
    PrintPackingSlip
  },
  props: {
    toReturn: {
      type: Array,
      required: true
    },
    toKeep: {
      type: Array,
      required: false
    },
    returnMethod: {
      type: Object,
      required: false,
    },
    returnKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasToReturn() {
      return this.toReturn.length;
    },
    hasToKeep() {
      return this.toKeep.length;
    },
    copy() {
      // only show keep item copy if there is something to keep
      if (!this.hasToReturn && this.hasToKeep) {
        return this.$content.modulePackingInstructions.keepCopy ?? null;
      }

      return this.$content.modulePackingInstructions.copy ?? null;
    },
    products() {
      if (!this.hasToReturn) {
        return {
          primary: this.toKeep.map(product => {
            const updatedProduct = {
              ...product,
              title: replacePlaceholderUsingObject(this.$content.global.productTitle, product)
            };
            return formatProduct(updatedProduct);
          }),
          secondary: []
        };
      }

      return {
        primary: this.toReturn.map(product => {
          const updatedProduct = {
            ...product,
            title: replacePlaceholderUsingObject(this.$content.global.productTitle, product)
          };
          return formatProduct(updatedProduct);
        }),
        secondary: this.toKeep.map(formatProduct)
      };
    },
    primarySize() {
      return this.$screen.width <= 680 ? 'medium' : 'large';
    },
  }
};
</script>

<style lang="scss" scoped>
$block: '.packing-instructions';

#{$block} {
  &__copy {
    color: var(--grey-700);
    margin-top: var(--spacing-200);
  }

  &__grid {
    display: grid;
    grid-template-columns: #{'repeat(auto-fill, minmax(min(10rem, 100%), 1fr))'};
    grid-gap: var(--spacing-300);
  }

  &__divider {
    width: 100%;
    border: 0;
    border-top: 2px solid var(--grey-200);
    margin: var(--spacing-500) 0 var(--spacing-300) 0;
  }

  &__secondary-heading {
    font-weight: 600;
  }

  &__secondary-copy {
    color: var(--grey-700);
    margin-top: var(--spacing-200);
  }

  &__list {
    margin-top: var(--spacing-400);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    * {
      max-width: 350px;
    }

    * + * {
      margin-top: var(--spacing-200);
    }
  }
}

@media screen and (max-width: $break-small) {
  #{$block} {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
</style>
