<template>
  <div>
    <base-text
      class="locations-heading"
      type="body"
    >
      {{ $content.moduleReturnMethodSelectorHappyReturns.locationsHeading }}
    </base-text>
    <div class="locations">
      <div
        v-for="location in locations"
        :key="location.location.id"
        class="location"
      >
        <base-button
          v-track="'happy returns get directions clicked'"
          type="link"
          class="link"
          :to="googleMapsLink(location.address.latitude, location.address.longitude)"
          target="_blank"
        >
          <base-text 
            class="icon-label"
            type="body-2"
            tag="span"
          >
            {{ location.address.name }}
            <base-icon
              class="link-icon"
              name="external-link"
            />
          </base-text>
        </base-button>
        <div class="location-address">
          <base-text
            class="location-address-street"
            type="body-2"
          >
            {{ street(location.address) }}
          </base-text>
          <base-text
            type="body-2"
          >
            {{ cityStateZip(location.address) }}
          </base-text>
        </div>
        <base-text
          class="location-distance"
          type="body-2"
        >
          {{ distanceCopy(location.distance) }}
        </base-text>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  BaseButton,
  BaseText,
  BaseIcon,
} from '@loophq/design-system';

defineProps({
  locations: {
    type: Array,
    required: true,
  },
});

const googleMapsLink = (latitude, longitude) => {
  return `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
};

const street = (addr) => {
  const street = addr.address1 + (addr.address2 ? ` ${addr.address2}` : '');
  return `${street},`;
};

const cityStateZip = (addr) => {
  return `${addr.city}, ${addr.state} ${addr.zip}`;
};

const distanceCopy = (dist) => {
  if (!dist.value || !dist.unit) {
    return '';
  }
  return `${dist.value.toFixed(1)} ${dist.unit === 'm' ? 'mi' : dist.unit}`;
};

</script>

<style lang="scss" scoped>
.locations {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0.75rem;

  &-heading {
    font-weight: 600;
    color: var(--heading-color);
    margin-bottom: 0.75rem;
  }
}

.location {
  .icon-label {
    color: var(--grey-800);
  }

  & + & {
    margin-top: var(--spacing-300);
  }

  &-address {
    display: flex;
    flex-direction: row;
    color: var(--color-slate-500);

    &-street {
      margin-right: var(--spacing-100);
    }
  }

  &-distance {
    color: var(--color-slate-500);
  }
}

.link-icon {
  height: 12px;
}

.link .icon-label {
  text-decoration: underline;
}

.icon-label {
  display: flex;
  align-items: center;
}

@media screen and (max-width: $break-small) {
  .distance {
    display: block;
  }

  .location {
    & + & {
      margin-top: var(--spacing-300);
    }

    &-address {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
