<template>
  <div>
    <cobranded-header :center="true" />
    <div class="gift-lookup-page__form">
      <base-text
        tag="h1"
        type="display-xlarge"
        class="gift-lookup-page__h1"
      >
        {{ $content.moduleGiftReceiptLookup.heading }} <span class="italic">{{ $content.moduleGiftReceiptLookup.headingItalicized }}</span>
      </base-text>
      <base-text
        tag="span"
        type="display-medium"
        class="gift-lookup-page__form-text"
      >
        <render-content :data="{ shopName: $shop.name }">
          {{ $content.moduleGiftReceiptLookup.lookupCopyLine }}
        </render-content>
      </base-text>
      <form
        class="gift-lookup-page__form-inputs"
        @submit.prevent="fetchOrder"
      >
        <base-input
          v-model="orderNumber"
          :label="$content.moduleOrderLookup.orderPlaceholder"
          class="gift-lookup-page__order-number-input"
        />
        <base-input
          v-model="zip"
          :label="zipLabel"
          class="gift-lookup-page__zip-input"
        />
        <base-button
          class="gift-lookup-page__submit-button"
          type="primary"
          :activated="loading"
        >
          {{ $content.moduleGiftReceiptLookup.buttonText }}
        </base-button>
      </form>
      <div
        v-if="error && error.length > 0"
        class="gift-lookup-page__errors"
      >
        <base-text
          tag="span"
          class="gift-lookup-page__error"
        >
          {{ error }}
        </base-text>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseInput,
  BaseText,
} from '@loophq/design-system';
import Order from '@/js/controllers/order';
import CobrandedHeader from './CobrandedHeader.vue';
import RenderContent from '@/components/renderers/RenderContent.vue';

export default {
  name: 'GiftReceiptLookup',
  components: {
    BaseButton,
    BaseInput,
    BaseText,
    CobrandedHeader,
    RenderContent
  },
  data() {
    return {
      error: null,
      loading: false
    };
  },
  computed: {
    orderNumber: {
      get() {
        return this.$store.state.giftReceipt.orderNumber;
      },
      set(orderNum) {
        this.$store.commit('giftReceipt/setOrderNumber', orderNum);
      }
    },
    zip: {
      get() {
        return this.$store.state.giftReceipt.zip;
      },
      set(zipCode) {
        this.$store.commit('giftReceipt/setZip', zipCode);
      }
    },
    customZipPlaceholder() {
      return this.$store.state.customizations.content?.[this.language]?.moduleOrderLookup.buildZipPlaceholder || null;
    },
    zipLabel() {
      return this.customZipPlaceholder ?? this.buildZipPlaceholder();
    }
  },
  created() {
    const { query } = this.$route;

    if (!query.zip && !query.order) return;

    // update vuex store
    this.orderNumber = query.order;
    this.zip = query.zip;

    this.fetchOrder();
  },
  mounted() {
    this.$store.dispatch('giftReceipt/resetStore');
  },
  methods: {
    buildZipPlaceholder() {
      const labels = {
        email: this.$content.moduleOrderLookup.emailPlaceholder || 'Email',
        phone: this.$content.moduleOrderLookup.phonePlaceholder || 'Phone Number',
        zip: this.$content.moduleOrderLookup.zipPlaceholder || 'Zip Code'
      };

      // build placeholder based on available lookup fields in shop settings (e.g: zip,email,phone)
      return (this.$settings.availableLookupFields || 'zip')
        .split(',')
        .map(field => labels[field])
        .join(' or ');
    },
    async fetchOrder() {
      this.loading = true;

      if (!this.orderNumber || !this.zip) {
        this.error = this.$content.moduleGiftReceiptLookup.firstStepErrorText;
        this.loading = false;
        return;
      }

      const payload = {
        name: this.orderNumber,
        zip: this.zip
      };

      // fetch order from api
      const orderLookupResponse = await Order.giftReceiptLookup(payload);
      if (orderLookupResponse?.data?.errors?.length) {
        // set error and display to user if api sends one
        this.error = this.$content.moduleGiftReceiptLookup.firstStepErrorText;
        this.loading = false;
      } else {
        // set the order in vuex store
        this.$store.commit('giftReceipt/setOrder', orderLookupResponse.data);
        this.$store.commit('giftReceipt/setOrderNumber', orderLookupResponse.data.name);

        // load all of the line items in the background
        this.$store.dispatch('giftReceipt/loadLineItems');

        if (orderLookupResponse.data?.gift_receipts?.length) {
          // Load the gift receipt list due to existing gift receipts
          this.$store.commit('giftReceipt/setStep', 'GiftReceiptList');
        } else {
          // navigate to the name input screen
          this.$store.commit('giftReceipt/setStep', 'NameInput');
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.gift-lookup-page {
  &__form {
    padding-top: var(--spacing-400);
    z-index: 10;
    position: relative;
  }

  &__h1 {
    color: var(--heading-color);
    font-size: 7rem;
    overflow: visible;
    margin: auto;
    text-align: left;
    font-weight: 400;
    letter-spacing: -4px;

    @media ((min-width: $break-tiny) and (max-width: $break-small)) {
      font-size: 5rem;
    }

    @media (min-width: $break-small) {
      padding: 0 var(--spacing-400);
    }

    @media (max-width: $break-tiny) {
      font-size: 3rem;
    }

    @media screen and (min-width: $break-small) {
      text-align: center;
      letter-spacing: -6px;
      width: fit-content;
    }

    span.italic {
      font-style: italic;
      font-family: 'untitled serif', sans-serif;
      letter-spacing: -1px;
      overflow: visible;
      display: inline-block;

      @media screen and (min-width: $break-medium) {
        background-image: url('/img/easy-ellipse.svg');
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 95%;
        padding: 0.5rem 2rem;
        margin-left: -1.5rem;
        letter-spacing: -5px;
      }
    }
  }

  &__form-text {
    margin-bottom: var(--spacing-300);
    display: block;
    text-align: left;
    font-weight: 400;
    letter-spacing: -1px;
    margin-top: var(--spacing-400);

    @media screen and (min-width: $break-large) {
      margin-top: var(--spacing-600);
    }

    @media screen and (min-width: $break-small) {
      text-align: center;
      padding: 0 var(--spacing-400);
      margin-bottom: var(--spacing-400);
    }
  }

  &__form-inputs {
    margin: auto;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: $break-small) {
      flex-wrap: nowrap;
      padding: 0 var(--spacing-300);
    }

    @media screen and (min-width: $break-large) {
      width: 90%;
    }
  }

  &__submit-button {
    display: block;
    text-align: center;
    width: 100%;
    white-space: nowrap;

    @media screen and (min-width: $break-small) {
      max-width: max-content;
    }

    @media screen and (min-width: $break-large) {
      width: fit-content;
    }

    :deep(span) {
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  &__submit-button,
  &__order-number-input,
  &__zip-input {
    margin: var(--spacing-200) 0;

    @media (min-width: $break-small) {
      margin: 0 var(--spacing-200);
    }

    @media (min-width: $break-large) {
      margin: 0 var(--spacing-200);
    }
  }

  &__order-number-input,
  &__zip-input {
    width: 100%;

    @media screen and (min-width: $break-large) {
      width: 30%;
    }
  }

  &__bottom-image {
    // Purpose of media queries is to adjust the bottom image to avoid overlap with form fields
    // in the case of desktop and mobile viewport widths and heights
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;

    @media screen and (height >= 700px) and (height <= 919px) and (min-width: $break-medium) {
      bottom: -10%;
    }

    @media screen and (height >= 920px) and (min-width: $break-medium) {
      bottom: 0;
    }

    img {
      max-width: 90%;
      display: block;
      margin: auto;
    }
  }

  &__errors {
    margin-top: var(--spacing-300);
    text-align: center;
    padding: 0 var(--spacing-400);
  }
}
</style>
