<template>
  <column-layout>
    <base-card>
      <skeleton-text
        :lines="1"
        width="20%"
      />
      <skeleton-text
        :lines="1"
        type="heading"
      />
      <skeleton-text :lines="10" />
    </base-card>
    <template #sidebar>
      <div>
        <skeleton-text
          :lines="1"
          type="heading"
        />
        <skeleton-text />
      </div>
    </template>
  </column-layout>
</template>

<script>
import {
  BaseCard
} from '@loophq/design-system';
import ColumnLayout from '@/components/layout/ColumnLayout';
import SkeletonText from '@/components/feedback/SkeletonText';

export default {
  components: {
    BaseCard,
    ColumnLayout,
    SkeletonText
  }
};
</script>
