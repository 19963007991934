<template>
  <transition name="oos-banner">
    <base-card
      v-if="visible"
      class="oos-banner"
    >
      <base-button
        class="oos-banner__close"
        type="icon"
        icon="close"
        @click.prevent="hide"
      >
        Hide warning
      </base-button>
      <div class="oos-banner__icon">
        <base-icon
          name="warning"
        />
      </div>
      <div class="oos-banner__messages">
        <base-text
          class="oos-banner__heading"
          tag="h2"
          type="display-medium"
        >
          <render-content>
            {{ $content.moduleOosBanner.heading }}
          </render-content>
        </base-text>
        <base-text
          v-if="content"
          class="oos-banner__message"
        >
          <render-content :data="{ customerEmail: customer.email }">
            {{ content }}
          </render-content>
        </base-text>
      </div>
      <div class="oos-banner__products">
        <product-card
          v-for="product in formattedExchanges"
          :key="product.id"
          size="small"
          :product="product"
        >
          <template #utility>
            <span class="oos-banner__oos">
              <render-content>
                {{ $content.global.noStock }}
              </render-content>
            </span>
          </template>
        </product-card>
      </div>
    </base-card>
  </transition>
</template>

<script>
import {
  BaseCard,
  BaseIcon,
  BaseText,
  BaseButton,
  ProductCard,
} from '@loophq/design-system';
import formatProduct from '@/js/helpers/formatProduct';
import useStorageMethods from '@/js/mixins/store';
const { getStore, setStore } = useStorageMethods();

export default {
  name: 'OutOfStockBanner',
  components: {
    BaseCard,
    BaseIcon,
    BaseText,
    BaseButton,
    ProductCard,
  },
  props: {
    returnKey: {
      type: String,
      required: false
    },
    exchanges: {
      type: Array,
      required: false
    },
    customer: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      hidden: false
    };
  },
  computed: {
    oosExchanges() {
      return this.exchanges.filter((item) => {
        return ['refunded', 'credited'].includes(item.status?.status);
      });
    },
    formattedExchanges() {
      return this.oosExchanges.map((product) => {
        // We want to show this price struck out, so we're assigning it over to that instead
        const { price, ...rest } = formatProduct(product);

        return {
          ...rest,
          struckPrice: price
        };
      });
    },
    visible() {
      return this.oosExchanges.length && !this.hidden;
    },
    content() {
      const firstStatus = this.oosExchanges?.[0]?.status?.status;
      return firstStatus ? this.$content.moduleOosBanner[firstStatus] : null;
    }
  },
  created() {
    this.hidden = getStore(`${this.returnKey}-oos-hidden`);
  },
  methods: {
    hide() {
      this.hidden = true;
      setStore(`${this.returnKey}-oos-hidden`, true);
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.oos-banner';

#{$block}-enter, #{$block}-leave-to {
  transform: translate3d(0, 0.5rem, 0);
  opacity: 0;
}

#{$block}-enter-active, #{$block}-leave-active {
  transition: transform var(--easing-regular), opacity var(--easing-regular);
}

#{$block} {
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  border: 4px solid var(--yellow-500);

  &__close {
    position: absolute;
    top: var(--spacing-400);
    right: var(--spacing-400);
  }

  &__icon {
    flex-shrink: 0;
    padding: 0.75rem;
    margin-right: var(--spacing-400);
    border: 2px solid var(--yellow-500);
    border-radius: 50%;
  }

  &__messages {
    flex-basis: 50%;
    flex-grow: 1;
    margin-right: var(--spacing-600);
  }

  &__message {
    margin-top: var(--spacing-200);
    color: var(--grey-700);
  }

  &__products {
    flex-basis: 40%;
    flex-grow: 1;
  }

  &__oos {
    text-align: right;
    color: var(--red-300);
  }
}

@media screen and (max-width: $break-medium) {
  #{$block} {
    flex-direction: column;

    &__icon {
      margin-bottom: var(--spacing-400);
      margin-right: 0;
    }

    &__messages {
      margin-bottom: var(--spacing-400);
      margin-right: 0;
    }

    &__products {
      width: 100%;
    }
  }
}

@media screen and (max-width: $break-tiny) {
  #{$block} {
    border-radius: 0;
    border-right: none;
    border-left: none;
    padding: var(--spacing-500) var(--spacing-400);
  }
}
</style>
