//These zip codes are defined to be densly populated in the HR contract, do not change without knowing the contract values
export const HappyReturnsDenseZipCodes =
  [
    //Boston
    '02155',
    '02360',
    '01960',
    //Chicago
    '60657',
    '60618',
    '60614',
    '60640',
    '60647',
    '60629',
    '60625',
    '60617',
    '60613',
    '60619',
    //Dallas
    '75034',
    '75070',
    '75052',
    '75287',
    '75243',
    '75067',
    '76028',
    '76244',
    '76063',
    '75002',
    '75150',
    '76116',
    //Houston
    '77449',
    '77494',
    '77584',
    '77429',
    '77077',
    '77379',
    '77450',
    '77095',
    '77433',
    '77082',
    //Los Angeles
    '90250',
    '90046',
    '90044',
    '92683',
    '90650',
    '90805',
    '90034',
    '90026',
    //Miami
    '33012',
    '33186',
    '33027',
    '33458',
    '33139',
    //New york City
    '11385',
    '10002',
    '10023',
    '11375',
    '11234',
    '10128',
    '10025',
    '11211',
    '11226',
    '10467',
    '11236',
    '11235',
    '11207',
    '11214',
    '10029',
    //Philadelphia
    '19446',
    '19711',
    //San Fransisco
    '94109',
    '94501',
    '94538',
    '94541',
    '94587',
    '94015',
    //Washington DC
    '20009',
    '20874',
    '20904',
    '20852',
  ];
