<template>
  <div>
    <cobranded-header
      :order-number="orderNumber"
      :center="false"
    />
    <div class="gift-name-page__name-wrapper">
      <div class="gift-name-page__name-content">
        <base-text
          type="display-large"
          tag="h2"
          class="gift-name-page__name-copy"
        >
          {{ $content.moduleGiftReceiptLookup.nameCopy }}
        </base-text>

        <div class="gift-name-page__form">
          <base-input
            v-model="firstName"
            v-model.trim="firstName"
            label="First Name"
            class="gift-name-page__name-input"
          />
          <div class="gift-name-page__controls">
            <base-button
              v-if="shouldShowBack"
              class="gift-name-page__back-button"
              type="secondary"
              @click="handleBackClick"
            >
              {{ $content.moduleGiftReceiptLookup.nameBackButtonLabel }}
            </base-button>
            <base-button
              class="gift-name-page__next-button"
              type="primary"
              @click="handleNextClick"
            >
              {{ $content.moduleGiftReceiptLookup.nameNextButtonLabel }}
            </base-button>
          </div>
          <div
            v-if="error && error.length > 0"
            class="gift-name-page__errors"
          >
            <base-text
              tag="span"
              class="gift-name-page__error"
            >
              {{ error }}
            </base-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseInput,
  BaseText
} from '@loophq/design-system';
import CobrandedHeader from './CobrandedHeader';

export default {
  name: 'NameInput',
  components: {
    BaseButton,
    BaseInput,
    BaseText,
    CobrandedHeader
  },
  data() {
    return {
      error: null
    };
  },
  computed: {
    orderNumber: {
      get() {
        return this.$store.state.giftReceipt.orderNumber;
      },
      set(orderNum) {
        this.$store.commit('giftReceipt/setOrderNumber', orderNum);
      }
    },
    firstName: {
      get() {
        return this.$store.state.giftReceipt.recipientName;
      },
      set(recipientName) {
        this.error = null;
        this.$store.commit('giftReceipt/setRecipientName', recipientName);
      }
    },
    shouldShowBack() {
      // only show back button when we have gift receipts to go back to manage
      return this.$store.state.giftReceipt?.giftReceipts?.length > 0;
    }
  },
  methods: {
    handleBackClick() {
      this.$store.commit('giftReceipt/setRecipientName', '');
      this.$store.commit('giftReceipt/setStep', 'GiftReceiptList');
    },
    handleNextClick() {
      if (!this.firstName) {
        this.error = this.$content.moduleGiftReceiptLookup.nameErrorText;
        return;
      }

      this.$store.commit('giftReceipt/setStep', 'LineItemSelection');
    },
  },
};
</script>

<style lang="scss" scoped>
.gift-name-page {
  height: 100%;

  &__name-wrapper {
    margin-top: var(--spacing-500);
    display: flex;
    height: 100%;
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  &__name-copy {
    margin-bottom: var(--spacing-400);
  }

  &__controls {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: var(--spacing-300);
    margin-top: var(--spacing-500);
  }

  &__back-button,
  &__next-button {
    flex-basis: 50%;
    text-transform: uppercase;
  }

  &__errors {
    margin-top: var(--spacing-300);
  }
}
</style>
