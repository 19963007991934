<template>
  <div class="product-options">
    <product-option
      v-for="(option, index) in shownOptions"
      :key="option.name"
      :value="option.name"
      class="product-options__option"
      :index="index"
      :option="option"
      :selected="selected"
      :variants="variants"
      @input="update"
    />
  </div>
</template>

<script>
import ProductOption from './ProductOption';

export default {
  components: {
    ProductOption,
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    variants: {
      type: [Array, Object],
      required: true
    },
    selectedOptions: {
      type: Array,
      required: false
    }
  },
  emits: [
    'updated'
  ],
  data() {
    return {
      selected: []
    };
  },
  computed: {
    shownOptions() {
      // Only show options that have more than one value
      return this.options.filter(option => {
        return option.values.length > 1;
      });
    }
  },
  created() {
    if (this.selectedOptions) {
      this.selected = this.selectedOptions;
    }

    // Grab all options that have a single value
    const singleValueOptions = this.options.filter(option => option.values.length === 1);

    // If available, go ahead and update our selected array immediately to preselect these
    if (singleValueOptions) {
      // Mold the data into what the component needs
      const selected = singleValueOptions
        .map(({ name, position, values }) => {
          return {
            name,
            option: `option${position}`,
            value: values[0]
          };
        });
      // Get a list of the currently selected options
      const selectedNames = selected.map(option => option.name);
      // Remove any options that we're going to overwrite, and then add the new ones
      this.selected = [
        ...this.selected.filter(option => !selectedNames.includes(option.name)),
        ...selected
      ];
    }
  },
  methods: {
    update(option) {
      this.selected = [
        ...this.selected.filter(item => item.name !== option.name),
        option
      ];

      // Send changes to parent
      this.$emit('updated', this.selected);
    }
  }
};
</script>
