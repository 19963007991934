import { trackEvent } from '@/js/helpers/trackEvent';

export default {
  'click-outside': {
  // bind
    beforeMount(el, binding) {
      el.clickOutsideEvent = function (event) {
        if (!(el == event.target || el.contains(event.target))) {
          binding.value(event);
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent);
    },

    // unbind
    unmounted(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent);
    },
  },
  'track': {
    beforeMount(el, binding) {
      el.trackingHandler = () => {
        trackEvent(binding.value);
      };
      el.addEventListener(binding.arg ?? 'click', el.trackingHandler);
    },
    unmount(el, binding) {
      el.removeEventListener(binding.arg ?? 'click', el.trackingHandler);
    }
  }
};
