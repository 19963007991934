<template>
  <base-button
    class="label-processing"
    icon="printer"
    :activated="true"
  >
    <render-content>
      {{ labelText }}
    </render-content>
  </base-button>
</template>

<script>
import {
  BaseButton,
} from '@loophq/design-system';

export default {
  components: {
    BaseButton,
  },
  props: {
    packingSlipsEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hasCombinedLabelsWithPackingSlipsFlag: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {
    labelText() {
      if (this.packingSlipsEnabled && this.hasCombinedLabelsWithPackingSlipsFlag) {
        return this.$content.moduleLabelProcessing.generatingWithPackingSlip;
      }

      return this.$content.moduleLabelProcessing.generating;
    },
  }
};
</script>

<style lang="scss" scoped>
$block: '.label-processing';

#{$block} {
  align-self: flex-start;
  width: auto;
  margin: var(--spacing-300) 0 !important;
}
</style>
