<template>
  <div
    v-if="status != null"
    class="modal-container error"
  >
    <div
      class="modal-screen"
      @click="restart()"
    ></div>

    <div class="modal error">
      <base-text
        v-if="$content.moduleErrorModal.heading"
        tag="h2"
        type="display-large"
        class="errorModule-heading"
      >
        <render-content>
          {{ $content.moduleErrorModal.heading }}
        </render-content>
      </base-text>
      <p
        v-if="status.copy != undefined"
        class="errorModule-error"
      >
        {{ status.copy }}
      </p>
      <p
        v-if="$content.moduleErrorModal.copy"
        class="errorModule-message"
      >
        <render-content>
          {{ $content.moduleErrorModal.copy }}
        </render-content>
      </p>
      <button
        class="legacy-button"
        @click="restart()"
      >
        <render-content>
          {{ $content.moduleErrorModal.submit }}
        </render-content>
      </button>
      <p class="errorModule-note">
        <render-content>
          {{ $content.moduleErrorModal.contactPrompt }}
        </render-content>
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-prop-types */
import {
  BaseText,
} from '@loophq/design-system';
export default {
  components: {
    BaseText,
  },
  props: [
    'status',
    'closeErrorModal'
  ],
  computed: {
    order: function() {
      return this.$store.getters.order;
    },
    errorModuleData: function() {
      return this.$store.state.errorModule;
    }
  },
  methods: {
    restart: function() {
      window.location.reload();
    },

    backToLookup: function() {
      this.$router.push({ name: 'lookup' });
    }
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &.error {
    padding: 2rem !important;
  }
}

.errorModule {
  width: 50%;
  border-radius: 6px;
  z-index: 10000;

  &-heading {
    text-align: center;
    margin-bottom: var(--spacing-300);
  }

  &-error {
    padding: 1rem;
    margin: 1rem;
    border: 1px solid black;
  }

  &-message {
    margin: 1rem;
    text-align: center;
  }

  &-note {
    padding-top: 1rem;
    text-align: center;
  }
}
</style>
