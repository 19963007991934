<template>
  <ul class="slide-nav">
    <li
      v-for="item in items"
      :key="item.id"
      class="slide-nav__item"
    >
      <router-link
        v-if="!item.children"
        class="slide-nav__link"
        :class="{ active: item.active }"
        :to="item.link"
        @click="update(item)"
      >
        {{ item.title }}
      </router-link>
      <button
        v-else
        type="button"
        class="slide-nav__link slide-nav__button ui-font legacy-button"
        :class="{ active: item.active }"
        @click.prevent="update(item)"
      >
        {{ item.title }}
      </button>
      <transition name="slideleft">
        <slide-nav
          v-if="item.children && item.active"
          class="slide-nav__children"
          :items="item.children"
          @update="children => updateChildren(item, children)"
        />
      </transition>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SlideNav',
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  emits:
    [
      'update',
    ],
  methods: {
    update({ id }) {
      // Set this item to active
      const updated = this.items.map(item => {
        const active = item.id === id;
        return {
          ...item,
          active
        };
      });

      // Send an updated items array
      this.$emit('update', updated);
    },
    updateChildren({ id }, children) {
      const updated = this.items.map(item => {
        if (item.id === id) {
          return {
            ...item,
            children
          };
        }

        return item;
      });

      // Send an updated items array
      this.$emit('update', updated);
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.slide-nav';

#{$block} {
  list-style-type: none;
  padding: 0;
  margin: 0;

  &__item {
    display: flex;
    flex-direction: column;

    & + & {
      margin-top: 0.75rem;
      padding-top: 0.75rem;
      border-top: 1px solid $gray-medium;
    }
  }

  &__link {
    background: inherit;
    border: 0;
    text-decoration: none;
    color: $black;
    font-weight: 400;
    font-size: 1.125rem;
    text-align: left;
    padding: 0.5rem 0;

    &:hover {
      box-shadow: none;
    }

    &.active {
      outline: none;
      font-weight: 700;
    }

    &[data-focus-visible-added] {
      outline: 1px solid $gray-dark;
      outline-offset: 4px;
    }
  }

  &__button {
    &::after {
      content: '';
      position: absolute;
      right: 0.25rem;
      top: 50%;
      width: 8px;
      height: 8px;
      border-top: 2px solid $gray-medium;
      border-right: 2px solid $gray-medium;
      transform: rotate(45deg) translateX(-50%);
    }
  }

  &__children {
    box-sizing: border-box;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    overflow: auto;
    padding: 1.25rem;
    border-left: 1px solid $gray-light;
  }
}
</style>
