<template>
  <section
    class="order-tracking-support-section"
    data-testid="order_tracking_support_section"
  >
    <base-text
      class="order-tracking-support-section__text"
      tag="h2"
    >
      {{ $content.moduleTrackingLookup.supportHeading }}
    </base-text>
    <base-text
      class="order-tracking-support-section__text"
    >
      {{ $content.moduleTrackingLookup.supportCopy }}
      <a :href="'mailto:' + supportEmail">{{ supportEmail }}</a>
    </base-text>
  </section>
</template>

<script setup>
import { BaseText } from '@loophq/design-system';
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();

const supportEmail = computed(() => {
  return store.getters.shop.support_email ??  '';
});

</script>

<style lang="scss" scoped>
$block: '.order-tracking-support-section';

#{$block} {
  display: flex;
  flex-direction: column;

  &__text {
    text-align: inherit;
    color: var(--theme-text-color);
  }
}
</style>
