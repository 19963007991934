<template>
  <base-card class="customer-survey">
    <survey-form
      v-if="!surveyReceived"
      :return-key="returnKey"
      :source="source"
      @survey-received="receivedSurvey"
    />

    <div
      v-if="surveyReceived"
    >
      {{ $content.moduleSurvey.thanks }}
    </div>
  </base-card>
</template>

<script>
import { BaseCard } from '@loophq/design-system';
import SurveyForm from '@/views/StatusPage/CustomerSurvey/SurveyForm';

export default {
  name: 'CustomerSurvey',
  components: { BaseCard, SurveyForm },
  props: {
    returnKey: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      surveyReceived: false,
    };
  },
  methods: {
    receivedSurvey() {
      this.surveyReceived = true;
    },
  }
};
</script>

<style lang="scss" scoped>
$block: '.customer-survey';

#{$block} {
  box-shadow: none;
  background: var(--grey-100);
}
</style>
