import { formatCurrency } from '@/js/helpers/formatCurrency';
import store from '@/store';

/**
 * Takes an API product and maps it into values that the ProductCard understands.
 * @param {{id, image: string, title: string, variantTitle: string, price: number}} item - The product
 * @returns {{id, image: string, title: string, variants: string, price: string}}
 */
export default (item) => {
  const exchangeRate = store.getters['currencies/exchangeRate'];
  const displayCurrency = store.getters['currencies/displayCurrency'];
  const variantTitle = item.variantTitle || item.variant_title;
  const struckPrice = item.struckPrice ? { struckPrice: formatCurrency(item.struckPrice * exchangeRate, displayCurrency) } : {};

  let displayPrice = undefined;
  if (Number.isInteger(item.price)) {
    displayPrice = item.price * exchangeRate;
  }

  return {
    ...item,
    id: item.id,
    image: item.image,
    title: item.title,
    variants: variantTitle !== 'Default Title' ? variantTitle : null,
    price: formatCurrency(displayPrice, displayCurrency),
    ...struckPrice
  };
};
