import { Model } from 'miragejs';
import { collections } from './mocks/collections';

const serializers = (Serializer) => {
  return {
    collection: Serializer.extend({
      root: false,
      embed: true
    }),
  };
};

const models = () => {
  return {
    collection: Model
  };
};

const factories = () => {
  return {};
};

const seed = (context) => {
  collections.forEach(item => {
    context.create('collection', item);
  });
};

export default {
  serializers,
  models,
  factories,
  seed
};
