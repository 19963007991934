import useStoreMethods from '@/js/mixins/store';

const {
  getStore,
  setStore,
} = useStoreMethods();

export default {
  namespaced: true,
  state: {
    shopCurrency: 'USD',
    orderCurrency: 'USD',
    orderCurrencyExchangeRate: 1,
    useOrderCurrency: false,
  },
  mutations: {
    setData(state, payload) {
      const { shop_currency, order_currency, order_currency_exchange_rate, use_order_currency } = payload;
      state.useOrderCurrency = use_order_currency;
      state.shopCurrency = shop_currency;
      state.orderCurrency = order_currency;
      state.orderCurrencyExchangeRate = order_currency_exchange_rate;
      setStore('currencies', state);
    },
    hydrate(state, data) {
      Object.entries(data).forEach(([key, value]) => {
        state[key] = value;
      });
    },
  },
  actions: {
    hydrate({ commit }) {
      const saved = getStore('currencies');
      if (saved) {
        commit('hydrate', saved);
      }
    },
    setData({ commit }, data) {
      commit('setData', data);
    },
  },
  getters: {
    exchangeRate(state) {
      if (state.useOrderCurrency) {
        return state.orderCurrencyExchangeRate;
      }
      return 1;
    },
    orderCurrencyExchangeRate(state) {
      return state.orderCurrencyExchangeRate;
    },
    displayCurrency(state) {
      if (state.useOrderCurrency) {
        return state.orderCurrency;
      }
      return state.shopCurrency;
    },
    shopCurrency(state) {
      return state.shopCurrency;
    },
    orderCurrency(state) {
      return state.orderCurrency;
    },
    useOrderCurrencyAsPresentment(state) {
      return state.useOrderCurrency;
    },
    isMultiCurrency(state) {
      return state.shopCurrency !== state.orderCurrency;
    }
  }
};
