<template>
  <div
    class="default-layout app-background"
    :class="appStyling"
  >
    <div class="app-underlay"></div>
    <div class="app-gradient"></div>
    <slot></slot>
    <powered-by-loop-badge
      v-if="showPoweredByLoop"
      class="default-layout-badge"
    />
  </div>
</template>

<script>
import PoweredByLoopBadge from '@/components/badges/PoweredByLoopBadge';

export default {
  name: 'DefaultLayout',
  components: {
    PoweredByLoopBadge,
  },
  computed: {
    appStyling() {
      return [
        'route-' + this.$route.name
      ];
    },
    showPoweredByLoop() {
      return this.$settings.poweredByLoop;
    },
  }
};
</script>

<style lang="scss" scoped>
.default-layout.default-layout {
  overflow: visible;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media screen and (width <= 680px) {
  .default-layout-badge {
    margin-bottom: var(--spacing-400);
  }
}
</style>
