<template>
  <component
    :is="componentName"
    :show="isActive"
    :active="isActive"
    class="shop-later-modal"
    :show-close-button="false"
    @close="close"
  >
    <img
      class="shop-later-modal__header-img"
      :src="headerImg"
      alt="Shop later gift card image"
    />
    <base-text
      class="shop-later-modal__copy-heading"
      tag="h2"
      type="display-large"
    >
      <render-content :data="{ formattedTotalCreditOffer }">
        {{ headerCopy }}
      </render-content>
    </base-text>

    <markup-renderer
      class="shop-later-modal__copy-primary"
      :content="primaryCopy"
      :customization-data="{ formattedTotalCreditOffer, formattedRefundAmount }"
    />

    <template
      v-if="showFooter"
      v-slot:footer
    >
      <div class="shop-later-modal__actions">
        <base-button
          v-if="showCloseBtn"
          type="secondary"
          class="shop-later-modal__close-btn"
          @click="close"
        >
          <render-content>
            {{ closeBtnCopy }}
          </render-content>
        </base-button>

        <base-button
          v-if="!shopLaterAccepted"
          :activated="isLoading"
          class="shop-later-modal__submit-btn"
          @click="claim"
        >
          <div class="shop-later-modal__actions-submit-btn-wrapper">
            {{ $content.pageReturnStatus.shopLaterClaimButton }}
            <base-icon
              class="icon"
              name="check"
            />
          </div>
        </base-button>
      </div>
    </template>
  </component>
</template>

<script>
import { formatCurrency } from '@/js/helpers/formatCurrency';
import {
  BaseButton,
  BaseIcon,
  BaseModal,
  BasePanel,
  BaseText,
} from '@loophq/design-system';
import Returns from '@/js/controllers/return';
import MarkupRenderer from '@/components/renderers/MarkupRenderer';
import { enablePageScroll } from 'scroll-lock';
import { track } from '@/js/segment';

export default {
  name: 'ShopLaterOfferModal',
  components: {
    MarkupRenderer,
    BaseButton,
    BaseIcon,
    BaseModal,
    BasePanel,
    BaseText,
  },
  props: {
    item: {
      type: Object,
      required: false,
    },
    inline: {
      type: Boolean,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'refresh',
    'close-modal',
    'set-is-active'
  ],
  data() {
    return {
      components: {
        paragraph: {
          component: BaseText,
          options: {
            props: {
              tag: 'p',
              type: 'display-small',
            }
          }
        }
      },
      isLoading: false,
      shopLaterAccepted: false,
      componentName: null
    };
  },
  computed: {
    formattedRefundAmount() {
      let currency = this.item.orderCurrency || this.item.currency;
      return formatCurrency(this.item.total, currency);
    },
    formattedTotalCreditOffer() {
      if (this.item.shopLater) {
        let currency = this.item.orderCurrency || this.item.currency;
        return formatCurrency(this.item.shopLater.totalCreditOffer, currency);
      }
      return null;
    },
    headerCopy() {
      if (!this.shopLaterAccepted) {
        return this.$content.pageReturnStatus.shopLaterActiveOfferHeadingCopy;
      }
      return this.$content.pageReturnStatus.shopLaterAcceptedHeadingCopy;
    },
    primaryCopy() {
      if (this.shopLaterAccepted) {
        return this.$content.pageReturnStatus.shopLaterAcceptedPrimaryCopy;
      }
      return this.$content.pageReturnStatus.shopLaterActiveOfferPrimaryCopy;
    },
    closeBtnCopy() {
      if (this.$screen.width <= 960) {
        return this.$content.pageReturnStatus.shopLaterCloseMobileButton;
      }

      return this.$content.pageReturnStatus.shopLaterCloseButton;
    },
    shopLaterState() {
      return this.item.shopLater?.state;
    },
    headerImg() {
      return this.shopLaterAccepted ? '/img/shop-later-box.svg' : '/img/shop-later-gift-card.svg';
    },
    showCloseBtn() {
      return (this.$screen.width >= 960 && !this.shopLaterAccepted) || (this.$screen.width <= 960 && this.shopLaterAccepted);
    },
    showFooter() {
      return this.$screen.width <= 960 || !this.shopLaterAccepted;
    }
  },
  created() {
    if (
      !this.inline && // not Shop Later V2
      this.$route.query.shoplater === 'true' &&
      this.item.shopLater?.state === 'active' &&
      this.item.state === 'open'
    ) {
      this.$emit('set-is-active');
    }

    if (this.$screen.width <= 960) {
      this.componentName = 'base-panel';
    } else {
      this.componentName = 'base-modal';
    }
  },
  methods: {
    async claim() {
      this.isLoading = true;

      const res = await Returns.acceptShopLater(this.item.returnKey, this.item.shopLater.uuid);
      if (res.shopLaterOffer.state === 'accepted') {
        this.shopLaterAccepted = true;
        this.isLoading = false;

        const { returnKey, returnOutcome, shopLaterOffer: { totalCreditOffer, bonus, fee } } = res;

        track('shopLaterOfferAccepted', {
          returnKey,
          returnOutcome,
          totalCreditOffer,
          bonus,
          fee
        });
      }
    },
    close() {
      this.$emit('close-modal');

      if (this.shopLaterAccepted === true) {
        this.$emit('refresh');
      }

      enablePageScroll();
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.shop-later-modal';

#{$block} {
  &__copy-heading {
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    margin: var(--spacing-300) 0 var(--spacing-300) 0;
  }

  &__copy-primary {
    text-align: center;
    font-weight: normal;
  }

  &__copy-secondary {
    text-align: center;
    font-weight: normal;
    margin-top: var(--spacing-300);
  }

  &__header-img {
    width: 100px;
    display: block;
    margin: var(--spacing-300) auto 0;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__actions-submit-btn-wrapper {
    display: flex;
  }

  &__submit-btn.base-button {
    background-color: black;
    color: white;

    span {
      margin-left: var(--spacing-200);
    }
  }
}

@media screen and (min-width: $break-medium) {
  $block: '.shop-later-modal';

  #{$block} {
    &__close-btn {
      border: none;
    }
  }
}

@media screen and (max-width: $break-medium) {
  $block: '.shop-later-modal';

  :deep(p) {
    color: var(--grey-800);
  }

  #{$block} {
    &__copy-heading {
      text-align: left;
      font-weight: bold;
      font-size: 26px;
      line-height: 32px;
      margin: var(--spacing-300) 0 var(--spacing-300) 0;
    }

    &__copy-primary {
      text-align: left;
      font-weight: normal;
    }

    &__copy-secondary {
      text-align: left;
      font-weight: normal;
      margin-top: var(--spacing-300);
    }

    &__header-img {
      width: 65px;
      display: block;
      margin: inherit;
    }

    &__submit-btn,
    &__close-btn {
      width: 100%;
    }

    &__actions-submit-btn-wrapper {
      justify-content: center;
    }
  }
}
</style>
