<template>
  <ul class="accordion-nav">
    <li
      v-for="item in items"
      :key="item.id"
      class="accordion-nav__item"
    >
      <router-link
        v-if="!item.children || item.children && !item.children.length"
        class="accordion-nav__link"
        :class="{ active: item.active }"
        :to="item.link"
        @click="update(item)"
      >
        {{ item.title }}
      </router-link>
      <button
        v-else
        type="button"
        class="accordion-nav__link ui-font legacy-button"
        @click.prevent="update(item)"
      >
        {{ item.title }}
      </button>
      <transition-expand>
        <accordion-nav
          v-if="item.children && item.children.length && item.active"
          class="accordion-nav__children"
          :items="item.children"
          @update="children => updateChildren(item, children)"
        />
      </transition-expand>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AccordionNav',
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  emits: [
    'update',
    'track'
  ],
  methods: {
    update({ id, children = [], title }) {
      this.$emit('track', title);
      // Set this item to active
      const updated = this.items.map(item => {
        const active = item.id === id;

        // Select the first child if active
        if (item.id === id && active && children?.length) {
          // Pull out the first item so we can activate it
          const [first, ...rest] = children;

          // Update the route
          this.$router.push(first.link);

          // Return with the first item active
          return {
            ...item,
            active,
            children: [
              // Activate the first child
              {
                ...first,
                active: true
              },
              ...rest
                .map(child => {
                  // Deactivate the rest
                  return {
                    ...child,
                    active: false
                  };
                })
            ]
          };
        }

        // No children, return the item with the toggled active state
        return {
          ...item,
          active
        };
      });

      // Send an updated items array
      this.$emit('update', updated);
    },
    updateChildren({ id }, children) {
      const updated = this.items.map(item => {
        if (item.id === id) {
          return {
            ...item,
            children
          };
        }

        return item;
      });

      // Send an updated items array
      this.$emit('update', updated);
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.accordion-nav';

#{$block} {
  list-style-type: none;
  padding: 0;
  margin: 0;

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__link#{$block}__link {
    position: relative;
    z-index: 0;
    background: white;
    border: 0;
    text-decoration: none;
    color: var(--theme-heading-color);
    line-height: 1;
    font-weight: 400;
    font-size: 1rem;
    text-align: left;
    padding: 0.75rem 0;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: -0.75rem;
      right: auto;
      width: calc(100% + 1.5rem);
      height: 100%;
      border-radius: 5px;
      border: 0;
      background-color: $gray-lighter;
      opacity: 0;
      transition: opacity var(--easing-quick);
    }

    &:hover {
      box-shadow: none;

      &::before {
        opacity: 0.5;
      }
    }

    &:active,
    &:focus,
    &:hover {
      filter: none;
      overflow: visible;
      -webkit-tap-highlight-color: transparent;
    }

    &.active {
      &::before {
        opacity: 1;
      }
    }

    &[data-focus-visible-added] {
      outline: 1px solid var(--grey-600);
      outline-offset: 4px;
    }
  }

  &__children {
    padding: 0.5rem 0;

    #{$block} {
      // Specificity hack
      &__item#{$block}__item {
        margin-top: 0;
      }

      &__link#{$block}__link {
        padding: 0.75rem;

        &::before {
          left: 0;
          width: 100%;
        }
      }
    }
  }
}
</style>
