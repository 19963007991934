export const labelStatuses = {
  AVAILABLE_FOR_PICKUP: 'available_for_pickup',
  CANCELLED: 'cancelled',
  DELIVERED: 'delivered',
  FAILED: 'failed',
  FAILURE: 'failure',
  IN_TRANSIT: 'in_transit',
  NEW: 'new',
  OUT_FOR_DELIVERY: 'out_for_delivery',
  PENDING: 'pending',
  PRE_TRANSIT: 'pre_transit',
  REFUND_REQUESTED: 'refund_requested',
  RETURN_TO_SENDER: 'return_to_sender',
  UNKNOWN: 'unknown',
  QUEUED: 'queued',
  LABEL_CREATED: 'label_created',
  ARRIVED_AT_FACILITY: 'arrived_at_facility',
  DEPARTED_FACILITY: 'departed_facility',
  ARRIVED_AT_PICKUP_LOCATION: 'arrived_at_pickup_location',
  AWAITING_INFORMATION: 'awaiting_information',
  DELAYED: 'delayed',
  WEATHER_DELAY: 'weather_delay'
};

export const shippedStatuses = [
  labelStatuses.IN_TRANSIT,
  labelStatuses.ARRIVED_AT_FACILITY,
  labelStatuses.DEPARTED_FACILITY
];

export const outForDeliveryStatuses = [
  labelStatuses.OUT_FOR_DELIVERY
];

export const deliveredStatuses = [
  labelStatuses.DELIVERED,
  labelStatuses.ARRIVED_AT_PICKUP_LOCATION
];

export const orderReceivedStatuses = [
  labelStatuses.PRE_TRANSIT,
  labelStatuses.LABEL_CREATED,
];

export const shippingWarningStatuses = [
  labelStatuses.AWAITING_INFORMATION,
  labelStatuses.DELAYED,
  labelStatuses.WEATHER_DELAY
];

export const expectedShippingStatuses = [
  ...orderReceivedStatuses,
  ...shippedStatuses,
  ...outForDeliveryStatuses,
  ...deliveredStatuses
];
