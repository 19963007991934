<template>
  <div
    class="card"
    :class="[type]"
  >
    <header
      v-if="$slots.header || heading"
      class="card__header"
    >
      <slot name="header">
        <h2 class="card__heading">
          <render-content>
            {{ heading }}
          </render-content>
        </h2>
      </slot>
    </header>
    <div class="card__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: () => 'default',
      validator: value => ['default', 'unstyled'].includes(value)
    },
    heading: {
      type: String,
      required: false
    }
  },
  render: h => h(this)
};
</script>

<style lang="scss" scoped>
.card {
  $parent: &;

  width: 100%;
  border-radius: var(--theme-corners);

  &__header {
    width: 100%;
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid rgba(45, 49, 56, 8%);
  }

  &__heading {
    width: 100%;
    font-size: 0.75rem;
    color: var(--grey-900);
    text-transform: uppercase;
  }

  &__content {
    padding: 1.25rem;
  }

  &.default {
    background: white;
    border: 1px solid rgba(45, 49, 56, 4%); // var(--rgb-blue-900)
    box-shadow: 0 4px 8px 0 rgba(45, 49, 56, 3%); // var(--rgb-blue-900)
  }

  &.unstyled {
    background: white;
    border: none;
    box-shadow: none;
  }
}
</style>
