<template>
  <base-card class="return-items">
    <base-text
      class="return-items__heading"
      tag="h2"
      type="display-medium"
    >
      {{ $content.moduleReturnItems.sending }}
    </base-text>
    <div
      class="return-items__grid"
    >
      <product-card
        v-for="product in sending"
        :key="product.id"
        :product="product"
        :size="size"
      />
    </div>
    <template v-if="receiving && receiving.length">
      <base-text
        class="return-items__heading"
        tag="h2"
        type="display-medium"
      >
        {{ $content.moduleReturnItems.receiving }}
      </base-text>
      <div class="return-items__grid">
        <product-card
          v-for="(product, index) in receiving"
          :key="product.id"
          :product="product"
          :size="size"
        >
          <template
            v-if="product.inCart && !product.is_script_discount_affected"
            #utility
          >
            <base-button
              type="link"
              :activated="removing === index"
              @click.prevent="remove(index)"
            >
              {{ $content.moduleReturnItems.remove }}
            </base-button>
          </template>
        </product-card>
      </div>
    </template>
  </base-card>
</template>

<script>
import {
  BaseText,
  BaseCard,
  BaseButton,
  ProductCard,
} from '@loophq/design-system';
import Cart from '@/js/controllers/cart';

export default {
  components: {
    BaseText,
    BaseCard,
    BaseButton,
    ProductCard,
  },
  props: {
    sending: {
      type: Array,
      required: true
    },
    receiving: {
      type: Array,
      required: false,
    },
    order: {
      type: Object,
      required: false
    }
  },
  emits: [
    'checkCreditType'
  ],
  data() {
    return {
      removing: null
    };
  },
  computed: {
    size() {
      return this.$screen.width <= 680 ? 'medium' : 'large';
    },
  },
  methods: {
    async remove(index) {
      this.removing = index;
      const cart = this.order.cart ?? [];
      const updatedCart = cart
        .filter((item, cartIndex) => cartIndex !== index);

      try {
        // If they have a cart token they're coming from on-store and we need
        // to update that cart. In app carts are purely client side and do not need this
        if (this.$store.state.cartToken) {
          await Cart.update(updatedCart.map(item => `${item.variant_id}`));
        }

        this.$store.commit('updateOrder', {
          ...this.order,
          cart: updatedCart
        });
        const variant = cart.find((_, cartIndex) => cartIndex === index);
        this.$store.commit('return/removeNewItem', { variantId: variant.variant_id ?? variant.id });

        const { errors } = await this.$store.dispatch('totals/get');
        if (errors) {
          throw new Error(errors);
        }

        this.$emit('checkCreditType');

        this.$toast(this.$content.moduleReturnItems.removalSuccess);
        this.removing = null;
      } catch (error) {
        this.removing = null;
        this.$error(this.$content.moduleReturnItems.removalError);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.return-items';

#{$block} {
  &__grid {
    margin-top: var(--spacing-500);
    display: grid;
    grid-template-columns: #{'repeat(auto-fill, minmax(min(9rem, 100%), 1fr))'};
    grid-gap: var(--spacing-300);
  }

  &__heading {
    &:not(:first-of-type) {
      margin-top: var(--spacing-600);
    }
  }
}

@media screen and (max-width: $break-small) {
  #{$block} {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
</style>
