<template>
  <div
    v-if="(showNewReturnButton || order.returns.length) && !order.isGiftReturn"
    class="associated-returns"
  >
    <base-button
      v-if="showNewReturnButton"
      class="new-return"
      type="secondary"
      :activated="loading"
      @click.prevent="createNewReturn"
    >
      <render-content>
        {{ $content.moduleAssociatedReturns.newReturn }}
      </render-content>
    </base-button>
    <div
      v-if="errors.length"
      class="errors"
    >
      <template v-for="error in errors">
        <base-text
          v-if="$content.moduleLookupErrors[error]"
          :key="error"
        >
          <render-content>
            {{ $content.moduleLookupErrors[error] }}
          </render-content>
        </base-text>
      </template>
    </div>
    <button
      v-if="order.returns.length"
      class="toggle"
      @click.prevent="active = !active"
    >
      <base-text
        type="body"
        class="heading"
      >
        <render-content>
          {{ $content.moduleAssociatedReturns.otherReturns }}
        </render-content>
      </base-text>
      <base-icon
        class="icon"
        :class="{ active }"
        name="down"
      />
    </button>
    <transition-expand>
      <div
        v-if="active"
        class="expanded"
      >
        <div
          v-for="(product, index) in returns"
          :key="`product-${index}`"
          class="item"
        >
          <product-card
            class="product"
            size="medium"
            :product="product"
          />
          <base-button
            v-if="product.url"
            class="view-button"
            type="link"
            :to="product.url"
          >
            <render-content>
              {{ $content.moduleAssociatedReturns.returnCta }}
            </render-content>
          </base-button>
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseText,
  BaseIcon,
  ProductCard,
} from '@loophq/design-system';
import Order from '@/js/controllers/order';
import formatDate from '@/js/helpers/formatDate';
import useStorageMethods from '@/js/mixins/store';
const { getStore } = useStorageMethods();

export default {
  components: {
    BaseButton,
    BaseText,
    BaseIcon,
    ProductCard,
  },
  props: {
    order: {
      type: Object,
      required: true,
      validator: (val) => {
        const keys = Object.keys(val);
        return ['address', 'orderName', 'returnKey'].every((item) => keys.includes(item));
      }
    }
  },
  data() {
    return {
      showNewReturnButton: false,
      loading: false,
      active: false,
      errors: []
    };
  },
  computed: {
    returns() {
      const content = this.$content.moduleAssociatedReturns;
      return this.order.returns.map((item) => {
        const amount = item.orderLineItems.length;
        const firstItemWithImage = item.orderLineItems.find((line) => {
          return line.image;
        });

        return {
          image: firstItemWithImage ? firstItemWithImage.image : null,
          title: content.returnAmount.replace(/{{\s*returnAmount\s*}}/, amount),
          variants: formatDate(item.createdAt),
          url: `/return/${item.returnKey}`
        };
      });
    }
  },
  created() {
    const returnKey = getStore('showNewReturnButton');
    // Show the button if the order has either disabled OR removed line items
    this.showNewReturnButton = returnKey === this.order.returnKey || this.order.removedLineItems?.length > 0;
  },
  methods: {
    async createNewReturn() {
      this.loading = true;

      try {
        const payload = {
          name: this.order.orderName,
          zip: this.order.address.zip,
        };

        const res = await Order.lookup(payload);

        if (res.data.errors?.length) {
          throw res.data.errors;
        }

        this.$store.dispatch('initOrder', res.data);

        this.$trackEvent('successful lookup from status page');

        this.$router.push({ name: 'exchange' });
      } catch (error) {
        console.error(error);
        this.errors = error;
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.new-return {
  width: 100%;

  ~ .toggle {
    margin-top: var(--spacing-500);
  }
}

.errors {
  margin-top: var(--spacing-200);
}

.toggle {
  @include reset-button;

  width: 100%;
  display: flex;
  align-items: flex-end;
  cursor: pointer;

  &.focus-visible {
    outline: none;
    background-color: var(--grey-200);
    border-radius: var(--corners);
  }
}

.heading {
  flex-grow: 1;
  text-decoration: underline;
}

.icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-left: var(--spacing-200);
  color: var(--grey-600);
  transition: transform var(--transition-500);

  &.active {
    transform: rotate(0.5turn);
  }
}

.expanded {
  padding-top: var(--spacing-300);
}

.item {
  display: flex;
  align-items: center;
}

.product {
  flex-grow: 1;
  // We need to shift over the left side in this case, usually these products
  // are in a container that needs padding but not in this case
  margin-left: calc(var(--spacing-200) * -1);
}

.view-button {
  flex-shrink: 0;
  margin: var(--spacing-200);
}
</style>
