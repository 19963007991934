<template>
  <footer class="ls-cart-footer">
    <span class="ls-cart-footer__total-line">
      <div class="ls-cart-footer__label-wrapper">
        <span
          class="ls-cart-footer__total-label"
          :style="{ 'font-family': theme.copyFont }"
          data-testid="cart-footer-total-label"
        >
          {{ credit.message }}
        </span>
        <base-loader
          v-if="loading"
          class="ls-cart-footer__total-loader"
          size="small"
        />
      </div>
      <span
        class="ls-cart-footer__total-amount"
        :style="{ 'font-family': theme.copyFont }"
        data-testid="cart-footer-total-amount"
      >
        <display-price
          :amount="credit.amount"
        />
      </span>
    </span>
    <div class="ls-cart-footer__buttons">
      <base-button
        v-if="view !== returnsList"
        class="ls-cart-footer__continue"
        :disabled="loading"
        data-testid="cart-footer-continue-button"
        @click.prevent="next"
      >
        {{ $content.pageShopNow.cartButton }}
      </base-button>
      <base-button
        v-if="showReturnMoreItemsButton"
        class="ls-cart-footer__add-more"
        :disabled="loading"
        type="secondary"
        data-testid="cart-footer-return-list-button"
        @click.prevent="handleBack"
      >
        {{ $content.pageShopNow.returnMoreItemsButton }}
      </base-button>
      <base-button
        v-if="showBaseContinueShoppingButton"
        class="ls-cart-footer__add-more"
        :disabled="loading"
        type="secondary"
        data-testid="cart-footer-continue-shopping-button"
        @click.prevent="closeModal"
      >
        {{ $content.pageShopNow.continueShoppingButton }}
      </base-button>
      <base-button
        v-if="showReturnListContinueShoppingButton"
        class="ls-cart-footer__add-more"
        :disabled="loading"
        type="primary"
        data-testid="cart-footer-returns-list-continue-shopping-button"
        @click.prevent="toShop"
      >
        {{ $content.pageShopNow.continueShoppingButton }}
      </base-button>
    </div>
  </footer>
</template>

<script>
import {
  BaseButton,
  BaseLoader
} from '@loophq/design-system';
import DisplayPrice from '@/components/globals/DisplayPrice.vue';
import { experiments } from '@/js/constants/experiments';
import { views } from '../../../js/constants/views';

export default {
  name: 'CartFooter',
  components: {
    BaseButton,
    DisplayPrice,
    BaseLoader
  },
  props: {
    theme: {
      type: Object
    },
    text: {
      type: Object
    },
    cart: {
      type: Array
    },
    total: {
      type: Number
    },
    loading: {
      type: Boolean,
      required: false
    },
    view: {
      type: String,
      required: false
    }
  },
  emits: ['next', 'back', 'toggle', 'toShop'],
  computed: {
    credit() {
      return {
        message: this.total >= 0 ? this.$content.pageShopNow.creditMessage : this.$content.pageShopNow.owedMessage,
        amount: Math.abs(this.total)
      };
    },
    returnsList() {
      return views.RETURNS_LIST;
    },
    flowBExperimentEligibleCustomer() {
      return this.$store.getters.experimentVariation(experiments.RETURNS_PORTAL_FLOW_B_EXPERIMENT) === experiments.FLOW_B;
    },
    flowBExperimentEligibleCustomerOneEligibleItem() {
      return this.flowBExperimentEligibleCustomer && Boolean(this.$store.state.singleItemEligible);
    },
    showReturnMoreItemsButton() {
      return this.flowBExperimentEligibleCustomer && !this.flowBExperimentEligibleCustomerOneEligibleItem && (this.view !== views.RETURNS_LIST);
    },
    showBaseContinueShoppingButton() {
      return this.flowBExperimentEligibleCustomerOneEligibleItem && (this.view !== views.RETURNS_LIST);
    },
    showReturnListContinueShoppingButton() {
      return this.flowBExperimentEligibleCustomer && (this.view === views.RETURNS_LIST);
    }
  },
  methods: {
    next() {
      this.$trackEvent('continue to review');
      this.$emit('next');
    },
    handleBack() {
      this.$trackEvent('back to return list');
      this.$emit('back');
    },
    closeModal() {
      this.$trackEvent('cart closed');
      this.$emit('toggle');
    },
    toShop() {
      this.$trackEvent('continue shopping');
      this.$emit('toShop');
    }
  }
};
</script>

<style lang="scss" scoped>
.ls-cart-footer {
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid $light-grey;

  &__total-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    color: var(--theme-body-color);
  }

  &__label-wrapper {
    display: flex;
    flex-direction: row;
  }

  &__total-label {
    font-family: $font-stack;
    font-size: 0.9375rem;
    align-self: center;
  }

  &__total-loader {
    margin-left: 8px;
    align-self: center;
  }

  &__total-amount {
    font-family: $font-stack;
    font-size: 1.25rem;
    font-weight: 500;
  }

  &__visually-hidden {
    @include visually-hidden;
  }

  &__buttons {
    width: 100%;
    display: flex;
    padding: 0 1rem 1rem;
    align-items: center;
    flex-direction: column;
  }

  &__shop,
  &__continue {
    appearance: none;
    box-sizing: border-box;
    padding: 0.75rem 1.25rem 0.875rem;
    border-radius: $small-radius;
    font-family: $font-stack;
    font-size: 0.9375rem;
    font-weight: 500;
    color: $base-background;
    text-align: center;
    line-height: 1.1;
    background-color: $base-text;
    transition: background-color 0.24s ease, color 0.24s ease;
    flex-grow: 1;
    flex-shrink: 0;
    width: calc(100% - 0.375rem);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
  }

  &__add-more {
    appearance: none;
    box-sizing: border-box;
    padding: 0.75rem 1.25rem 0.875rem;
    border-radius: $small-radius;
    font-family: $font-stack;
    font-size: 0.9375rem;
    font-weight: 500;
    color: $base-background;
    text-align: center;
    line-height: 1.1;
    background-color: $base-text;
    transition: background-color 0.24s ease, color 0.24s ease;
    flex-grow: 1;
    flex-shrink: 0;
    width: calc(100% - 0.375rem);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    margin-top: 0.75rem;
  }

  &__shop {
    background-color: $base-background;
    color: $base-text;
    border: 1px solid $light-grey;
    margin-right: 0.375rem;
  }
}
</style>
