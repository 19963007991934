/*eslint-disable no-empty-function */
import { Model } from 'miragejs';
import { lineItems } from './mocks/lineItems';

const serializers = (Serializer) => {
  return {
    lineItem: Serializer.extend({
      root: false,
      embed: true
    })
  };
};

const models = () => {
  return {
    lineItem: Model
  };
};

const factories = () => {
  return {};
};

const seed = (context) => {
  lineItems.forEach(item => {
    context.create('lineItem', item);
  });
};

const routes = (/* context */) => {
};

export default {
  serializers,
  models,
  factories,
  seed,
  routes
};
