export default {
  computed: {
    warrantyAllowedOutcomes() {
      const warrantyItem = Object.values(this.order.line_items).find(
        (item) => item.warrantyAllowedOutcomes !== undefined
      );
      return warrantyItem ? warrantyItem.warrantyAllowedOutcomes : {};
    }
  }
};
