async function sha256(str) {
  const buf = await crypto.subtle.digest('SHA-256', new TextEncoder('utf-8').encode(str));
  return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('').toString();
}

export default async function customerInPercentage(customerEmail, shopId, percentage) {
  if (percentage === 0) {
    return false;
  }

  if (percentage === 100) {
    return true;
  }

  const hash = await sha256(`${customerEmail}.${shopId}`);

  const cleanHash = hash.replace(/[^a-zA-Z]/g, '');

  // first 4 letters of the hash
  const buckets = [
    cleanHash.toLowerCase().charAt(0),
    cleanHash.toLowerCase().charAt(1),
    cleanHash.toLowerCase().charAt(2),
    cleanHash.toLowerCase().charAt(3),
  ];

  // four numbers between 0 and 25
  const bucketNumbers = [
    buckets[0].charCodeAt(0) - 97,
    buckets[1].charCodeAt(0) - 97,
    buckets[2].charCodeAt(0) - 97,
    buckets[3].charCodeAt(0) - 97,
  ];

  // add up the numbers to get a percentile
  const customerPercentile = bucketNumbers.reduce((previousValue, currentValue) => {
    return previousValue + currentValue;
  });

  return customerPercentile <= percentage;
}
