<template>
  <span
    class="blurred-background"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.blurred-background {
  position: relative;
  z-index: 0;
  padding: var(--spacing-200) var(--spacing-300);
  color: var(--theme-text-color);
  backdrop-filter: blur(8px);

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    border-radius: var(--corners);
    background-color: var(--theme-text-background-color);
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }
}

@media screen and (max-width: $break-small) {
  .blurred-background {
    padding: var(--spacing-200);
  }
}
</style>
