<template>
  <div
    class="skeleton-nav"
    aria-hidden="true"
  >
    <span class="skeleton-nav__line"></span>
    <span class="skeleton-nav__line"></span>
    <span class="skeleton-nav__line"></span>
    <span class="skeleton-nav__line"></span>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
$block: '.skeleton-nav';

#{$block} {
  display: flex;
  flex-direction: column;

  &__line {
    @include skeleton;

    width: 75%;
    height: 1.5rem;

    & + & {
      margin-top: 1rem;
    }
  }
}
</style>
