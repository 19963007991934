import { getCollectionProducts } from '@/js/controllers/shop';
import Collections from '@/js/controllers/collections';
import { resolveOrReject } from './helpers';

const defaultPageSize = 18;

export default {
  namespaced: true,
  state: {
    items: {},
    previews: {}
  },
  mutations: {
    set(state, value) {
      state.items = {
        ...state.items,
        [value.id]: value
      };
    },
    setPreview(state, data) {
      state.previews = {
        ...state.previews,
        ...data
      };
    },
  },
  actions: {
    get({ state, commit }, { id, params }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const existing = state.items[id];
        // We already have this collection, or have requested it
        if (existing && !existing.failed) {
          // This collection is still loading but we don't have a promise for it
          // so we're just polling for it to finish
          if (existing.loading) {
            const interval = setInterval(() => {
              // Finished loading, bail out and send the data
              if (!state.items[id].loading) {
                clearInterval(interval);
                resolveOrReject(resolve, reject, state.items[id]);
              }
            }, 100);
          } else {
            // We have a collection with proper data
            resolve(existing);
          }
        } else {
          // Collection does not exist, create it and then fetch the products
          commit('set', { id, loading: true, page: 1, fullyLoaded: true });
          try {
            const { errors, products, next_page } = await getCollectionProducts(id, params);
            const collection = {
              id,
              loading: false,
              errors: errors || null,
              products,
              page: next_page,
              fullyLoaded: !!(products.length % defaultPageSize) || !products.length
            };

            commit('set', collection);
            resolve(collection);
          } catch ({ response, message }) {
            console.error(`Error requesting variants for ${id}: ${message}`);
            if (response) {
              console.error(response);
            }
            const updatedProduct = { id, loading: false, failed: true };
            commit('set', updatedProduct);
            reject(updatedProduct);
          }
        }
      });
    },
    nextPage({ state, commit, dispatch }, { id }) {
      const existing = state.items[id];
      if (!existing) {
        return dispatch('get', { id });
      }

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        //get current page id
        const page = existing.page;
        const { errors, products, next_page } = await getCollectionProducts(id, { page, per_page: defaultPageSize });
        const updatedProducts = [...existing.products, ...products];
        const collection = {
          id,
          loading: false,
          errors,
          products: updatedProducts,
          page: next_page,
          fullyLoaded: !!(updatedProducts.length % defaultPageSize) || !products.length
        };
        commit('set', collection);
        resolve(collection);
      });
    },
    async getPreview({ state, commit }, { productType }) {
      const existing = state.previews[productType];

      // We already have this preview, or have requested it
      if (existing && !existing.failed) {
        // This preview is still loading but we don't have a promise for it
        // so we're just polling for it to finish
        if (existing.loading) {
          const interval = setInterval(() => {
            // Finished loading, bail out and send the data
            if (!state.previews[productType]?.loading) {
              clearInterval(interval);
              resolveOrReject(Promise.resolve, Promise.reject, state.previews[productType]);
            }
          }, 100);
        } else {
          // We have a collection with proper data
          Promise.resolve(existing);
        }
      } else {
        // Preview does not exist, create it and then fetch the products
        commit('setPreview', {
          [productType]: {
            loading: true,
            products: [],
            count: null
          }
        });

        try {
          const { products, count } = await Collections.getPreview(productType);
          const data = {
            loading: false,
            products,
            count
          };

          commit('setPreview', {
            [productType]: data
          });
          return data;
        } catch (error) {
          console.error(error);

          commit('setPreview', {
            [productType]: { loading: false, failed: true }
          });

          Promise.reject({ loading: false, failed: true });
        }
      }
    },
  },
};
