<template>
  <loading-card
    v-if="product.loading"
    class="loading-card"
    :size="cardSize"
  />
  <product-card
    v-else
    class="product-card"
    tag="button"
    :size="cardSize"
    :product="product"
    :selected="selectedItems.includes(product.id)"
    :disabled="lineItemDisabled"
    @click.prevent="handleLineItemClick(product.id)"
  />
</template>

<script>
import {
  LoadingCard,
  ProductCard
} from '@loophq/design-system';
import { debounce } from 'lodash';

export default {
  name: 'GiftProductCardWrapper',
  components: {
    LoadingCard,
    ProductCard,
  },
  props: {
    lineItemId: {
      type: Number,
      required: true,
    }
  },
  emits: ['reset-error'],
  data() {
    return {
      cardSize: 'large',
    };
  },
  computed: {
    product() {
      const lineItem = this.$store.getters['giftReceipt/lineItems'][this.lineItemId];
      if (lineItem) {
        delete lineItem.price;
      }

      return lineItem;
    },
    lineItemDisabled() {
      return !this.lineItemValid(this.product);
    },
    selectedItems: {
      get() {
        return this.$store.state.giftReceipt.selectedItems;
      },
      set(selectedItems) {
        this.$store.commit('giftReceipt/setSelectedItems', selectedItems);
      }
    },
  },
  mounted() {
    window.addEventListener('resize', debounce(this.resizeCardSizeHandler, 100));

    this.resizeCardSizeHandler();
  },
  methods: {
    lineItemValid(item) {
      if (!item) {
        return false;
      }

      if (item.gift_receipt_id) {
        return false;
      }

      if (item.loading) {
        return true;
      }

      if (item.failed) {
        return false;
      }

      if (item.allowed?.returned) {
        return false;
      }

      const exchangeBlocked = !item.allowed?.exchange;
      const returnBlocked = !item.allowed?.return || (!item.allowed?.gift && !item.allowed?.refund);
      if (exchangeBlocked && returnBlocked) {
        return false;
      }

      return true;
    },
    resizeCardSizeHandler() {
      // 680 = $break-small. this is when we flip product card to horizontal/mobile layout
      if (window.innerWidth > 680) {
        this.cardSize = 'large';
        return;
      }

      this.cardSize = 'medium';
    },
    handleLineItemClick(lineItemId) {
      this.$emit('reset-error');

      const selected = this.selectedItems;
      const alreadySelected = selected.find(id => id === lineItemId);

      if (alreadySelected) {
        selected.splice(selected.indexOf(lineItemId), 1);
      } else {
        selected.push(lineItemId);
      }

      this.selectedItems = selected;
    }
  },
};
</script>

<style lang="scss" scoped>
$gap: var(--spacing-300);

// this breakpoint corresponds with the resize handler for card size and the
// wrapper styles located in LineItemSelection.vue
@media screen and (min-width: $break-small) {
  button.product-card,
  .loading-card {
    margin: $gap 0 0 $gap;
    max-width: 30%;
  }
}

.loading-card,
.product-card {
  width: 100%;
  border-radius: 8px;

  & + .loading-card,
  & + .product-card {
    margin-top: var(--spacing-300);
  }
}

</style>
