export const createDestination = (id, data = {}) => {
  return {
    id,
    name: 'Default Destination',
    display_name_in_portal: false,
    ...data
  };
};

export const destinations = [
  createDestination(1),
  createDestination(2,  {
    name: 'Displayed destination',
    display_name_in_portal: true
  }),
  createDestination(3, {
    name: 'Alternate destination',
    display_name_in_portal: true
  })
];
