<template>
  <svg
    v-if="html"
    class="default-icon"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-html="html"
  />
</template>

<script>
/**
 * Outputs an inline svg from the set of default order lookup icons
 */
import defs, { names } from './icons.js';

export default {
  name: 'DefaultIcon',
  props: {
    name: {
      type: String,
      required: true,
      validator: (val) => names.includes(val)
    }
  },
  computed: {
    html() {
      return defs[this.name] ?? '';
    }
  }
};
</script>

<style lang="scss" scoped>
.default-icon {
  --secondary-color: var(--grey-300);
}
</style>
