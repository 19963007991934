<template>
  <div class="tracking-error">
    <base-text type="display-large">
      Something went wrong.
    </base-text>
  </div>
</template>

<script>
import {
  BaseText
} from '@loophq/design-system';

export default {
  name: 'BadRequest',
  components: {
    BaseText
  }
};
</script>

<style lang="scss" scoped>
$block: '.tracking-error';

#{$block} {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  color: white;
}
</style>
