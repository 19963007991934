import Store from '@/store';
import env from '@/env';
import Router from '@/router';
import { gtag } from '@/js/googleAnalytics';
import { defaultCategory } from '@/js/constants/eventCategories';
import { ref } from 'vue';
/**
 * Track an event to google analytics
 * @param {string} event
 * @param {object} fields
 */
export const trackEvent = (event, fields = {}) => {
  const Route = ref(Router);
  const category = Route.value.currentRoute.meta.trackingCategory ?? defaultCategory;
  const label = Store.getters.shop.name;
  const shopId = Store.getters.shop.id;
  const source = Store.state.source;
  gtag('event', event, {
    event_category: category,
    event_label: label,
    shop_id: shopId,
    ...source && { source },
    ...fields
  });

  if (env('VITE_LOG_TRACKING_EVENTS')) {
    console.groupCollapsed(`Event tracked: ${event}`);
    console.log(`Category: ${category}`);
    console.log(`Label: ${label}`);
    console.log(`Shop id: ${shopId}`);
    console.groupEnd();
  }
};

export const trackRefund = (props = {}, items = {}) => {
  const shopName = Store.getters.shop.name;
  const shopId = Store.getters.shop.id;
  const source = Store.state.source;
  gtag('event', 'refund', {
    shop_id: shopId,
    shop_name: shopName,
    ...source && { source },
    items,
    ...props,
  });

  if (env('VITE_LOG_TRACKING_EVENTS')) {
    console.groupCollapsed(`Refund event tracked`);
    console.log(`Shop: ${shopName} (${shopId})`);
    console.dir(props);
    console.dir(items);
    console.groupEnd();
  }
};
