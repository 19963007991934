<template>
  <popover
    v-click-outside="updateActive"
    v-bind="{ active, alignArrow, placement, name }"
  >
    <slot></slot>
    <template #content>
      <div class="pop">
        <div class="pop-wrapper">
          <div class="pop-container">
            <button
              type="button"
              class="legacy-button pop-close"
              @click.prevent="updateActive"
            >
              <img
                src="/img/icons/close.svg"
                alt="Close"
              />
            </button>

            <p>
              <render-content>
                {{ data.copy }}
              </render-content>
            </p>
            <p v-if="data.link__header != ''">
              <render-content>
                {{ data.link__header }}
              </render-content>
            </p>
          </div>
        </div>
      </div>
    </template>
  </popover>
</template>

<script>
import Popover from '@/components/Popover.vue';

export default {
  components: {
    Popover
  },
  props: {
    data: {
      type: Object
    },
    active: {
      type: Boolean,
      required: true,
      default: () => false
    },
    alignArrow: {
      type: String,
      required: false,
      validator: value => ['left', 'right'].includes(value)
    },
    placement: {
      type: String,
      required: false,
      default: () => 'top',
      validator: value => ['top', 'bottom'].includes(value)
    },
    name: {
      type: String,
      required: true
    }
  },
  emits: ['update'],
  methods: {
    updateActive() {
      this.$emit('update', { active: false });
    }
  }
};
</script>

<style lang="scss">
.pop {
  &-wrapper {
    position: relative;
    background-color: white;
    animation: slideIn 0.2s forwards;
    border-radius: 6px;
  }

  &-container {
    padding: 0.5rem 1rem;
    width: 20rem;
    border-radius: 6px;
    border-bottom: none;
    overflow: hidden;

    p {
      text-align: left;
      padding: 0.75rem 0;
    }

    a {
      font-size: 0.9rem;
    }
  }

  &-close {
    padding: 0;
    position: absolute;
    top: -0.85rem;
    right: -0.85rem;
    width: 1.9rem;
    height: 1.9rem;
    background-color: var(--theme-primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.24s;
    pointer-events: all;

    &:hover,
    &:focus {
      cursor: pointer;
      filter: brightness(1.05);

      img {
        opacity: 1;
      }
    }

    &:focus {
      outline: none;
      transform: scale3d(1.2, 1.2, 1);
    }

    img {
      filter: invert(1);
      width: 40%;
      transition: all 0.24s;
      opacity: 0.85;
    }
  }
}
</style>
