<template>
  <base-card
    class="return-method-selector"
    :class="{ 'return-method-selector--disabled': !!pending }"
  >
    <base-text
      tag="h2"
      type="display-medium"
      class="return-method-selector__heading"
    >
      <render-content>
        {{ heading }}
      </render-content>
    </base-text>
    <div class="return-method-selector__wrapper">
      <return-method-item
        v-for="returnMethod in options"
        :key="returnMethod.name"
        :ref="returnMethod.name"
        v-track="`return method ${returnMethod.name} selected`"
        class="return-method-selector__item"
        :option="returnMethod"
        :selected="selected"
        :disabled="!!pending"
        :is-single="isSingleReturnMethod"
        name="return-method-type"
        @change="handleReturnMethodChange"
      />
    </div>
  </base-card>
</template>

<script>
import {
  BaseCard,
  BaseText,
} from '@loophq/design-system';
import ReturnMethodItem from './ReturnMethods/ReturnMethodItem';
import firstMile from '@/js/constants/firstMile';
import { submitMetric } from '@/js/controllers/metrics';
import { mapState } from 'vuex';

export default {
  components: {
    BaseCard,
    BaseText,
    ReturnMethodItem,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: '',
    };
  },
  computed: {
    ...mapState('totals', ['pending']),
    heading() {
      return this.isSingleReturnMethod
        ? this.$content.moduleSingleReturnMethodSelector.heading
        : this.$content.moduleReturnMethodSelector.heading;
    },
    isSingleReturnMethod() {
      return this.options.length === 1;
    }
  },
  mounted() {
    if (this.options.length === 1 || this.options.length && this.$settings.preselectReturnMethodEnabled) {
      this.handleReturnMethodChange(this.options[0]);
    }
    this.options.forEach((returnMethod) => {
      submitMetric(firstMile.METRICS[returnMethod.name], {
        order: {
          id: this.$store.getters.order.id,
          providerOrderId: this.$store.getters.order.provider_order_id,
        },
      });
    });
  },
  methods: {
    async handleReturnMethodChange(option) {
      if (this.selected !== option.name) {
        this.selected = option.name;
        // If current return method doesn't need validation, set as validated
        if (!firstMile.RETURN_METHODS_NEED_VALIDATED.includes(option.name)) {
          this.$store.commit('firstMile/setReturnMethodIsValid', true);
        } else {
          this.$store.commit('firstMile/setReturnMethodIsValid', false);
        }
        await this.$store.dispatch('firstMile/setReturnMethod', option);
        this.$trackEvent(`changed to ${option.name} return method`);
        if (firstMile.RETURN_METHODS_SCROLLTO.includes(option.name)) {
          // Scroll to top of return method selection
          window.scrollTo(0, this.$refs[option.name][0].$el.offsetTop - 10, { behavior: 'smooth' });
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
$block: '.return-method-selector';

#{$block} {
  padding: 40px;

  &__wrapper {
    margin-top: var(--spacing-500);
  }

  &--disabled {
    cursor: not-allowed;

    #{$block}__wrapper {
      pointer-events: none;
    }
  }

  &__item {
    border: 1px solid var(--grey-200);
    border-bottom: none;
  }

  &__item:first-child {
    border-radius: var(--spacing-200) var(--spacing-200) 0 0;
  }

  &__item:last-child {
    border-radius: 0 0 var(--spacing-200) var(--spacing-200);
    border-bottom: 1px solid var(--grey-200);

    &:deep(.return-method__option) {
      border-radius: 0 0 var(--spacing-200) var(--spacing-200);
    }
  }

  &__item:only-child {
    border: 0;
  }
}

@media screen and (max-width: $break-small) {
  #{$block} {
    padding: var(--spacing-500) var(--spacing-300) !important;
  }
}
</style>
