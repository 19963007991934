<script>
/*eslint no-unused-vars: "off" */
import { get } from 'lodash';
import { h } from 'vue';
import store from '@/store';

let loaded = false;

export default {
  name: 'ChatWidget',
  props: {
  },
  created() {
    const configs = get(store.state, 'portal_services_configs', '{}');

    if (configs['zendesk-chat']) {
      const zendeskConfigs = configs['zendesk-chat'];
      if (zendeskConfigs.key) {
        const id = 'ze-snippet';
        const url = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskConfigs.key}`;
        
        if (zendeskConfigs.helpCenterSearchConfig) {
          this.configureCustomizedZendeskChatWidget(id, url, zendeskConfigs.helpCenterSearchConfig);
        } else {
          this.configureBasicWidget(id, url);
        }
      }
    }

    if (configs['gorgias-chat']) {
      const gorgiasConfigs = configs['gorgias-chat'];

      if (gorgiasConfigs.base_url && gorgiasConfigs.api_url) {
        this.configureLegacyGorgiasWidget(window || {}, gorgiasConfigs);
      }

      if (gorgiasConfigs.app_id) {
        const id = 'gorgias-chat-widget-install-v2';
        const url = `https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=${gorgiasConfigs.app_id}`;
        this.configureBasicWidget(id, url);
      }
    }

  },
  methods: {
    configureBasicWidget(scriptId, url) {
      if (loaded) return;
      this.loaded = true;

      let script = document.createElement('script');
      script.setAttribute('id', scriptId);
      script.setAttribute('src', url);
      document.head.appendChild(script);
    },
    configureCustomizedZendeskChatWidget(scriptId, url, helpCenterSearchConfig) {
      if (loaded) return;
      this.loaded = true;

      let script = document.createElement('script');
      script.setAttribute('id', scriptId);
      script.setAttribute('src', url);
      script.setAttribute('type', 'text/javascript');
      script.onload = function() {
        /* eslint-disable-next-line no-undef */
        zE(function() {
          /* eslint-disable-next-line no-undef */
          zE.setHelpCenterSuggestions({ search: helpCenterSearchConfig });
        });
      };
      document.head.appendChild(script);
    },
    configureLegacyGorgiasWidget(_, config) {
      if (loaded) return;
      loaded = true;

      if (config.base_url && config.api_url) {
        _.GORGIAS_CHAT_APP_ID = config.app_id,
        _.GORGIAS_CHAT_BASE_URL = config.base_url,
        _.GORGIAS_API_BASE_URL = config.api_url;
        var e = new XMLHttpRequest;
        e.open('GET', `https://${_.GORGIAS_API_BASE_URL}/applications/${_.GORGIAS_CHAT_APP_ID}`, !0), e.onload = function (t) {
          if (4 === e.readyState) if (200 === e.status) {
            var n = JSON.parse(e.responseText);
            if (!n.application || !n.bundleVersion) throw new Error(`Missing fields in the response body - https://${_.GORGIAS_API_BASE_URL}/applications/${_.GORGIAS_CHAT_APP_ID}`);
            if (_.GORGIAS_CHAT_APP = n.application, _.GORGIAS_CHAT_BUNDLE_VERSION = n.bundleVersion, n && n.texts && (_.GORGIAS_CHAT_TEXTS = n.texts), n && n.sspTexts && (_.GORGIAS_CHAT_SELF_SERVICE_PORTAL_TEXTS = n.sspTexts), !document.getElementById('gorgias-chat-container')) {
              var o = document.createElement('div');
              o.id = 'gorgias-chat-container', document.body.appendChild(o);
              var r = document.createElement('script');
              r.setAttribute('defer', !0), r.src = 'https://client-builds.production.gorgias.chat/{bundleVersion}/static/js/main.js'.replace('{bundleVersion}', n.bundleVersion), document.body.appendChild(r);
            }
          } else console.error(`Failed request GET - https://${_.GORGIAS_API_BASE_URL}/applications/${_.GORGIAS_CHAT_APP_ID}`);
        }, e.onerror = function (_) {
          console.error(_);
        }, e.send();
      }
    }
  },
  render() {
    return h('div');
  }
};
</script>

<style>
/* stylelint-disable selector-id-pattern */

/* Gorgias */
#gorgias-chat-container iframe#chat-button {
  left: 22px;
}

#gorgias-chat-container iframe#chat-window {
  left: 0;
}

#gorgias-chat-container iframe#chat-campaigns {
  left: 0;
}

/* Zendesk */
iframe#launcher,
iframe#webWidget {
  left: 0 !important;
}
</style>
