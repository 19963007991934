<template>
  <div class="prepaid-labels">
    <div
      class="prepaid-labels__selection"
    >
      <radio-input
        v-for="item in prepaid"
        :key="item.id"
        v-model="tracking"
        class="prepaid-labels__radio"
        :value="item.trackingCode"
        :disabled="loading === 'setting' || !!label"
      >
        {{ item.trackingCode }}
      </radio-input>
      <div
        class="prepaid-labels__buttons"
        data-testid="prepaid_labels_buttons"
      >
        <base-button
          class="prepaid-labels__button"
          :activated="loading === 'setting'"
          :disabled="!!label"
          icon="package"
          data-testid="prepaid_labels_button"
          @click="useLabel"
        >
          <render-content>
            {{ ctaLabel }}
          </render-content>
        </base-button>
        <slot
          name="packingSlip"
          classes="prepaid_labels__button"
        />
      </div>
    </div>
    <div
      v-if="!label && labelsEnabled"
      class="prepaid-labels__new"
    >
      <render-content>
        {{ $content.modulePrepaidLabels.noLabel }}
      </render-content>
      <base-button
        class="prepaid-labels__generate"
        type="link"
        @click="generateLabel"
      >
        <render-content>
          {{ $content.modulePrepaidLabels.noLabelCta }}
        </render-content>
      </base-button>
    </div>
  </div>
</template>

<script>
import {
  RadioInput,
  BaseButton,
} from '@loophq/design-system';
import Labels from '@/js/controllers/labels';
import { logError } from '@/js/helpers/errors';

export default {
  components: {
    RadioInput,
    BaseButton,
  },
  props: {
    returnId: {
      type: [String, Number],
      required: true
    },
    prepaid: {
      type: Array,
      required: true,
    },
    label: {
      type: Object,
      required: false
    },
    labelsEnabled: {
      type: Boolean,
      required: false
    },
  },
  emits: [
    'label',
    'generate'
  ],
  data() {
    return {
      tracking: null,
      loading: null,
      error: null
    };
  },
  computed: {
    selected() {
      return this.prepaid.find((item) => item.trackingCode === this.label?.trackingNumber) ?? null;
    },
    ctaLabel() {
      return this.label ? this.$content.modulePrepaidLabels.successCta : this.$content.modulePrepaidLabels.cta;
    }
  },
  created() {
    // if a prepaid label has already been associated with the return, it will no longer
    // be included in the prepaid label list, so we need to explicitly include it for future
    // visits to this page
    if (this.label && !this.selected) {
      // eslint-disable-next-line vue/no-mutating-props
      this.prepaid.push({
        id: 'selected-label',
        trackingCode: this.label.trackingNumber
      });
    }

    if (!this.selected) {
      this.tracking = this.prepaid?.[0].trackingCode;
    } else {
      this.tracking = this.selected.trackingCode;
    }
  },
  methods: {
    async useLabel() {
      this.loading = 'setting';

      try {
        const prepaid = this.prepaid.find((item) => item.trackingCode === this.tracking);
        const label = await Labels.setPrepaid({ id: this.returnId, labelId: prepaid.id });

        // the server response is a boolean
        if (label) {
          prepaid.shippingProviderId = 2;
          this.$emit('label', prepaid);
        }
        this.loading = null;
      } catch (error) {
        logError(error);
        this.error = this.$content.modulePrepaidLabels.error;
        this.loading = null;
      }

      this.$trackEvent('use prepaid label clicked');
    },
    generateLabel() {
      this.$emit('generate');
      this.$trackEvent('requesting new label instead of prepaid label');
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.prepaid-labels';

#{$block} {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &__selection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--grey-800);
  }

  &__radio {
    margin-bottom: var(--spacing-300);
  }

  &__buttons {
    display: flex;
    margin-top: var(--spacing-200);
    width: inherit;
    gap: var(--spacing-300);
  }

  &__button,
  :slotted(.prepaid_labels__button) {
    width: 50%;
  }

  :deep(.base-button__text) {
    width: auto;
  }

  &__new {
    margin-top: var(--spacing-400);
    color: var(--grey-700);
  }

  &__generate {
    margin-left: var(--spacing-100);
  }
}
</style>
