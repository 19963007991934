<template>
  <component
    :is="addressFormComponent"
    v-if="modelValue"
    v-model="internalValue"
    v-bind="{ disabledFields, availableCountries, required, errors }"
    v-on="$attrs"
  />
</template>

<script>
import { featureFlags } from '@/js/constants/featureFlags';
import AddressForm from './AddressForm.vue';
import AddressFormWithOceania from './AddressFormWithOceania.vue';

export default {
  name: 'AddressFormWrapper',
  components: {
    AddressForm,
    AddressFormWithOceania,
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    disabledFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    availableCountries: {
      type: Array,
      required: false,
      default: () => null,
    },
    required: {
      type: Array,
      required: false,
      default: () => [],
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      internalValue: {},
    };
  },
  computed: {
    addressFormComponent() {
      return this.$store.getters.hasFeature(featureFlags.OCEANIA_ADDRESS_IMPROVEMENTS_ENABLED)
        ? AddressFormWithOceania
        : AddressForm;
    },
  },
  watch: {
    modelValue: {
      handler(newValue) {
        if (newValue !== this.internalValue) {
          this.internalValue = newValue; // Direct assignment
        }
      },
      deep: true,
      immediate: true, // Trigger immediately on component creation
    },
    internalValue: {
      handler(newValue) {
        if (newValue !== this.modelValue) {
          this.$emit('update:modelValue', newValue); // Emit updates to parent
        }
      },
      deep: true,
    },
  }
};
</script>
