<template>
  <div class="tracking-event-timeline">
    <div v-if="normalizedReturn.tracker?.trackingEvents?.length">
      <base-text
        class="tracking-event-timeline__heading"
        type="display-medium"
      >
        {{ $content?.moduleReturnTracking.eventTrackingHeading }}
      </base-text>

      <alert-banner
        v-for="event in normalizedReturn.tracker.recentTrackingEvents"
        :key="event.occurredAt"
        class="tracking-event-timeline__item"
        :heading="formatDate(event.occurredAt)"
        :description="event.description"
        :has-icon="event.status === 'failure'"
        :inline="true"
        :type="event.status === 'failure' ? 'warning' : 'info'"
      />
    </div>

    <carrier-link />
  </div>
</template>

<script setup>
import { inject } from 'vue';
import { format } from 'date-fns-tz';
import { BaseText, AlertBanner } from '@loophq/design-system';
import CarrierLink from '@/views/StatusPage/Tracking/CarrierLink';

const formatDate = (date) => {
  return format(new Date(date), 'MM/dd/yyyy - h:mma');
};

const normalizedReturn = inject('normalizedReturn');
</script>

<style lang="scss" scoped>
$block: '.tracking-event-timeline';

#{$block} {
  margin-top: var(--spacing-300);

  .base-text {
    color: black;
  }

  &__heading {
    margin-bottom: var(--spacing-300);
  }

  &__item {
    padding: var(--spacing-300);
    background: white;
    margin-bottom: var(--spacing-300);
  }

  &__item-date {
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: var(--spacing-200);
  }

  &__label-info {
    a {
      color: black;
    }
  }
}
</style>
