<template>
  <span
    class="base-loader"
    :class="[size]"
  >
    <span class="visually-hidden">Loading...</span>
  </span>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      required: false,
      default: 'regular',
      validator: value => ['small', 'regular', 'large'].includes(value)
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.base-loader';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#{$block} {
  --loader-color: currentColor;
  --loader-width: 2px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity var(--transition-300);
  user-select: none;

  &.small {
    width: 1rem;
    height: 1rem;
  }

  &.regular {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.large {
    --loader-width: 4px;

    width: 3rem;
    height: 3rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: var(--loader-width) solid transparent;
    border-top-color: var(--loader-color);
    transform: translate3d(0, 0, 0);
    animation: spin 0.8s infinite linear;
  }
}
</style>
