import { computed } from 'vue';
import { labelStatuses } from '@/js/constants/labels';
import { exchangeStates } from '@/js/constants/exchanges';
import { exchangeEvents, giftCardEvents, refundEvents } from '@/js/constants/returnPolicies';

export default function useTracking(item) {

  const hasReturnsTrackingAvailableShopSettingOn = computed(() => {
    return item.value?.returnTrackingAvailable;
  });

  const hasReturnsTrackingEnabledShopSettingOn = computed(() => {
    return item.value?.returnTrackingEnabled;
  });

  const hasExchangeTrackingEnabledShopSettingOn = computed(() => {
    return item.value?.exchangeTrackingEnabled;
  });

  const hasDeliveredRefundProcessingEvent = computed(() => {
    return item.value?.policy.refundEvent === refundEvents.DELIVERED;
  });

  const hasDeliveredGiftCardProcessingEvent = computed(() => {
    return item.value?.policy.giftCardEvent === giftCardEvents.DELIVERED;
  });

  const hasManualGiftCardProcessingEvent = computed(() => {
    return item.value?.policy.giftCardEvent === giftCardEvents.DISABLED;
  });

  const hasManualRefundProcessingEvent = computed(() => {
    return item.value?.policy.refundEvent === refundEvents.DISABLED;
  });

  const hasDeliveredExchangeProcessingEvent = computed(() => {
    return item.value?.policy.exchangeEvent === exchangeEvents.DELIVERED;
  });

  const showReturnsTracking = computed(() => {
    if (!hasReturnsTrackingAvailableShopSettingOn.value || !hasReturnsTrackingEnabledShopSettingOn.value) {
      return false;
    }

    if (!item.value?.label) {
      return false;
    }

    const labelStatusesArr = [labelStatuses.IN_TRANSIT, labelStatuses.DELIVERED, labelStatuses.OUT_FOR_DELIVERY, labelStatuses.FAILURE];
    if (!labelStatusesArr.includes(item.value.label.status)) {
      return false;
    }

    const processedExchanges = item.value.exchanges.filter(e => e.state === 'processed');
    if (processedExchanges.length) {
      return false;
    }

    if (hasDeliveredExchangeProcessingEvent.value && item.value.exchanges.length) {
      return true;
    }

    if ((hasManualGiftCardProcessingEvent.value || hasDeliveredGiftCardProcessingEvent.value) && item.value.hasGiftCard) {
      return true;
    }

    return (hasManualRefundProcessingEvent.value || hasDeliveredRefundProcessingEvent.value) && !item.value.hasGiftCard;
  });

  const showExchangeTracking = computed(() => {
    if (!hasReturnsTrackingAvailableShopSettingOn.value || !hasExchangeTrackingEnabledShopSettingOn.value) {
      return false;
    }

    if (!item.value?.exchanges.length) {
      return false;
    }

    const labelStatusesArr = [labelStatuses.IN_TRANSIT, labelStatuses.DELIVERED, labelStatuses.OUT_FOR_DELIVERY, labelStatuses.FAILURE];
    if (item.value?.instantExchangeReceipt?.status && !labelStatusesArr.includes(item.value?.label?.status)) {
      return false;
    }

    const processedExchanges = item.value.exchanges.filter(i => i.state === exchangeStates.PROCESSED);

    return !!processedExchanges.length;
  });

  return {
    hasReturnsTrackingAvailableShopSettingOn,
    hasReturnsTrackingEnabledShopSettingOn,
    hasExchangeTrackingEnabledShopSettingOn,
    showExchangeTracking,
    showReturnsTracking,
  };
}
