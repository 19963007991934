<template>
  <div
    class="survey-face"
    @click="clickFace()"
  >
    <img
      :src="imgSrc"
      :alt="label"
      height="48"
      width="48"
    />
  </div>
</template>

<script>
const faces = {
  1: 'very-sad',
  2: 'sad',
  3: 'neutral',
  4: 'happy',
  5: 'very-happy',
};
const labels = {
  1: 'Very Unhappy',
  2: 'Unhappy',
  3: 'Ok',
  4: 'Happy',
  5: 'Very Happy',
};
export default {
  name: 'SurveyFace',
  props: {
    score: {
      type: Number,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    }
  },
  emits: [
    'clickFace'
  ],
  computed: {
    imgSrc() {
      let face = faces[this.score];
      if (this.selected) {
        face += '-filled';
      }
      return `/img/faces/${face}.svg`;
    },
    label() {
      return labels[this.score];
    }
  },
  methods: {
    clickFace() {
      this.$emit('clickFace', this.score);
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.survey-face';
#{$block} {
  margin: 20px;
  display: inline;
  cursor: pointer;
}
</style>
