<template>
  <div class="share-page">
    <cobranded-header 
      :order-number="orderNumber"
      :center="false"
    />
    <div class="share-page__wrapper">
      <div
        v-if="isMobile"
        class="share-page__copy-heading-wrapper"
      >
        <base-text
          class="share-page__share-heading"
          type="display-large"
          tag="h2"
        >
          <render-content :data="{ recipientName }">
            {{ $content.moduleGiftReceiptLookup.shareHeading }}
          </render-content>
        </base-text>
        <base-text
          class="share-page__share-copy"
          type="body"
          tag="p"
        >
          <render-content :data="{ recipientName }">
            {{ $content.moduleGiftReceiptLookup.shareCopy }}
          </render-content>
        </base-text>
      </div>

      <div class="share-page__qr-wrapper">
        <qr-code-card />
      </div>

      <div class="share-page__share-wrapper">
        <div
          v-if="!isMobile"
          class="share-page__copy-heading-wrapper"
        >
          <base-text
            class="share-page__share-heading"
            type="display-large"
            tag="h2"
          >
            <render-content :data="{ recipientName }">
              {{ $content.moduleGiftReceiptLookup.shareHeading }}
            </render-content>
          </base-text>
          <base-text
            class="share-page__share-copy"
            type="body"
            tag="p"
          >
            <render-content :data="{ recipientName }">
              {{ $content.moduleGiftReceiptLookup.shareCopy }}
            </render-content>
          </base-text>
        </div>
        <div class="share-page__receipt-controls">
          <base-button
            class="share-page__share-button"
            type="secondary"
            @click="toggleModalActive(true)"
          >
            {{ $content.moduleGiftReceiptLookup.shareButtonText }}
          </base-button>
          <base-button
            class="share-page__print-button"
            @click="print"
          >
            {{ $content.moduleGiftReceiptLookup.printButtonText }}
          </base-button>
          <base-button
            class="share-page__delete-create-button"
            type="text"
            @click="resetFlow"
          >
            {{ $content.moduleGiftReceiptLookup.deleteCreateButtonText }}
          </base-button>
        </div>
      </div>
    </div>
    <modal
      :show="modalActive"
      @close="toggleModalActive(false)"
    >
      <gift-share-modal
        v-if="!receiptSent"
        :receipt-sent="receiptSent"
        :recipient-name="recipientName"
        @set-receipt-sent="setReceiptSent"
      />
      <div
        v-else
        class="share-page__receipt-sent-screen"
      >
        <img src="/img/icons/check-circle.svg" />
        <base-text type="display-small">
          {{ $content.moduleGiftReceiptLookup.shareReceiptSentSuccessMessage }}
        </base-text>
      </div>
    </modal>
    <div class="share-page__print-container">
      <div class="share-page__print-qr-wrapper">
        <qr-code-card />
      </div>
      <div class="share-page__print-info-wrapper">
        <div class="share-page__info-header">
          <img
            v-if="logo"
            :src="logo"
            alt="logo"
            class="share-page__print-logo"
          />
          <base-text
            v-else
            type="display-medium"
            tag="h3"
            class="share-page__print-brand-name"
          >
            {{ brandName }}
          </base-text>
        </div>
        <base-text
          class="share-page__print-info-name"
          type="body"
        >
          <render-content :data="{ recipientName }">
            {{ $content.moduleGiftReceiptLookup.printNameLine }}
          </render-content>
        </base-text>
        <base-text
          class="share-page__print-info-copy"
          type="body"
        >
          <render-content :data="{ customerName }">
            {{ $content.moduleGiftReceiptLookup.printViewCopy }}
          </render-content>
        </base-text>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseText,
} from '@loophq/design-system';
import Modal from '@/components/layout/Modal';
import { debounce } from 'lodash';
import QrCodeCard from './QrCodeCard';
import CobrandedHeader from './CobrandedHeader';
import GiftShareModal from './GiftShareModal.vue';

export default {
  name: 'SharePage',
  components: {
    BaseButton,
    BaseText,
    CobrandedHeader,
    Modal,
    QrCodeCard,
    GiftShareModal,
  },
  data() {
    return {
      isMobile: false,
      modalActive: false,
      receiptSent: false,
    };
  },
  computed: {
    recipientName() {
      return this.store.activeReceipt?.recipient_name;
    },
    orderNumber() {
      return this.store.orderNumber;
    },
    brandName() {
      return this.$shop.name;
    },
    store() {
      return this.$store.state.giftReceipt;
    },
    logo() {
      if (this.$theme) {
        const { navLogo, logo } = this.$theme.branding;
        return navLogo || logo || null;
      }

      return null;
    },
    customerName() {
      return this.store.order.customer.first_name;
    },
  },
  mounted() {
    window.addEventListener('resize', debounce(this.resizeHandler, 100));

    this.resizeHandler();
  },
  methods: {
    setReceiptSent(value) {
      this.receiptSent = value;

      // reset email sent state in modal after two seconds
      setTimeout(() => {
        this.receiptSent = false;
      }, 2000);
    },
    resetFlow() {
      this.$store.commit('giftReceipt/setActiveReceipt', null);
      this.$store.commit('giftReceipt/setStep', 'GiftReceiptList');
    },
    print() {
      window.print();
    },
    toggleModalActive(active) {
      this.modalActive = active;
    },
    resizeHandler() {
      // 680 = $break-small
      if (window.innerWidth <= 680) {
        this.isMobile = true;
        return;
      }

      this.isMobile = false;
    }
  },
};
</script>

<style lang="scss" scoped>
$block: '.share-page';

#{$block} {
  display: block;

  @media screen and (max-width: $break-small) {
    max-height: 86vh;
    overflow-y: auto;
    // moves the scroll bar a bit away from inner content needed
    // to battle against a global parent's padding.
    margin-left: calc(-1 * var(--spacing-300));
    margin-right: calc(-1 * var(--spacing-300));
    padding-left: var(--spacing-300);
    padding-right: var(--spacing-300);
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--spacing-600);

    @media screen and (max-width: $break-small) {
      flex-direction: column;
      margin-top: var(--spacing-300);
    }
  }

  &__qr-wrapper {
    width: 100%;
    max-width: 340px;

    @media screen and (min-width: $break-large) {
      padding: 0;
      width: 45%;
    }
  }

  &__share-wrapper {
    width: 100%;
    margin-top: var(--spacing-400);

    @media screen and (min-width: $break-small) {
      margin-left: var(--spacing-600);
    }

    @media screen and (min-width: $break-large) {
      width: 55%;
      margin-top: 0;
    }
  }

  &__share-copy {
    margin-bottom: var(--spacing-400);

    @media screen and (min-width: $break-large) {
      margin-bottom: var(--spacing-600);
    }
  }

  &__share-heading {
    margin-bottom: var(--spacing-300);
  }

  &__share-button,
  &__print-button {
    display: block;
    width: 100%;
    text-transform: uppercase;
    margin-bottom: var(--spacing-300);
  }

  &__delete-create-button {
    display: block;
    margin: var(--spacing-400) auto 0 auto;
    text-transform: uppercase;
    text-decoration: underline;
  }

  &__copy-heading-wrapper {
    @media screen and (min-width: $break-small) {
      padding: 0;
    }
  }

  &__receipt-sent-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 250px;

    img {
      width: 50px;
      height: 50px;
      display: block;
      margin: var(--spacing-400) auto;
    }
  }

  &__print-logo {
    max-height: 80px !important;
    width: auto;
  }

  &__print-container {
    padding: var(--spacing-400);
    background: white;
    display: none;

    @media print {
      display: block;
      position: absolute;
      inset: 0;
      z-index: 100000;
      max-height: 99%;
      overflow: hidden;
    }
  }

  &__print-qr-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: var(--spacing-600);
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--spacing-600);
  }

  &__print-info-wrapper {
    padding-top: var(--spacing-500);
    border-top: 2px solid #e2e4e6;
  }

  &__print-info-name {
    margin-top: var(--spacing-300);
    margin-bottom: var(--spacing-300);
  }

  :deep(.modal-dialog__content) {
    margin-bottom: 0 !important;
  }
}
</style>
