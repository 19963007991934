<template>
  <div class="veho-privacy-policy">
    <base-text
      class="veho-privacy-policy__copy"
      type="body-2"
    >
      By submitting your return, you agree to Veho's 
      <a
        v-track="'veho privacy policy clicked'"
        class="veho-privacy-policy__link"
        target="_blank"
        href="https://shipveho.com/privacy"
      >
        Privacy Policy.
      </a>
    </base-text>
  </div>
</template>

<script>
import { BaseText } from '@loophq/design-system';

export default {
  name: 'VehoPrivacyPolicy',
  components: {
    BaseText,
  }
};
</script>

<style lang="scss" scoped>
  .veho-privacy-policy {
    &__link {
      color: var(--grey-600);
    }
  }
</style>
