<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <copy-renderer
    :renderData="renderData"
  >
    <slot />
  </copy-renderer>
</template>

<script>
import { get } from 'lodash';
import store from '@/store';
import CopyRenderer from '@/components/renderers/CopyRenderer';
import { trackEvent } from '@/js/helpers/trackEvent';

const formatDate = (dateString) => {
  if (!dateString) return null;
  const dateArray = dateString.split('-');
  const lang = get(store.state, 'language', '');
  if (dateArray.length === 3) {
    if (lang === 'en') {
      return new Date(dateArray[0], dateArray[1] - 1, dateArray[2]).toLocaleString(lang,{
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    }
    return new Date(dateArray[0], dateArray[1] - 1, dateArray[2]).toLocaleString(lang,{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    });
  }
  return null;
};

export default {
  name: 'RenderContent',
  components: {
    CopyRenderer
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    const email = get(store.state, 'shopContents.support_email', '');
    const supportEmail = {
      type: 'link',
      attrs: {
        href: `mailto:${email}`
      },
      text: email,
      events: {
        click: () => {
          trackEvent('support contacted');
        }
      }
    };
    return {
      renderData: {
        shopUrl: get(store.state, 'shopContents.shop_domain', ''),
        shopName: get(store.state, 'shopContents.name', ''),
        shopCurrency: get(store.state, 'currency', ''),
        returnWindow: formatDate(get(store.state, 'order.eligible', '')),
        parsedReturnWindow: formatDate(get(store.state, 'order.eligible', '')),
        warrantyReturnWindow: formatDate(get(store.state, 'order.eligible_warranty', '')),
        supportEmail,
        ...this.data
      }
    };
  }
};
</script>
