export default {
  namespaced: true,
  state: {
    customerIdentifier: null,
    session: null,
    order: null,
    shop: null,
    shopName: null,
    product: null,
  },
  mutations: {
    setData(state, payload) {
      state.customerIdentifier = payload.customerIdentifier;
      state.order = payload.order;
      state.session = payload.session;
      state.shop = payload.shop;
      state.shopName = payload.shopName;
      state.product = payload.product;
    },
    clearData(state) {
      state.customerIdentifier = null;
      state.order = null;
      state.session = null;
      state.shop = null;
      state.shopName = null;
      state.product = null;
    }
  },
  actions: {
    setData({ commit }, data) {
      commit('setData', data);
    },
    clearData({ commit }, data) {
      commit('clearData', data);
    }
  },
  getters: {
    getData: (state) => { return { ...state }; },
  }
};
