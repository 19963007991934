<template>
  <div class="exchange-selector">
    <header class="selector-header">
      <base-text
        class="selector-heading"
        tag="h2"
      >
        <render-content>
          {{ $content.moduleExchangeSelector.heading }}
        </render-content>
      </base-text>
      <base-button
        class="more-button"
        type="link"
        @click.prevent="toggleMore"
      >
        <render-content>
          {{ $content.moduleExchangeSelector.explanationButton }}
        </render-content>
      </base-button>
    </header>
    <base-card class="exchange-card">
      <radio-input
        v-model="selected"
        class="exchange-input"
        value="instant"
        data-value="instant"
      >
        <span class="exchange-label">
          <base-text class="exchange-heading">
            <render-content>
              {{ $content.moduleExchangeSelector.quickHeading }}
            </render-content>
          </base-text>
          <base-text class="exchange-body">
            <render-content :data="{ instantExchangeWindow }">
              {{ $content.moduleExchangeSelector.quickCopy }}
            </render-content>
          </base-text>
        </span>
      </radio-input>
      <radio-input
        v-model="selected"
        class="exchange-input"
        value="regular"
        data-value="regular"
      >
        <span class="exchange-label">
          <base-text class="exchange-heading">
            <render-content>
              {{ $content.moduleExchangeSelector.regularHeading }}
            </render-content>
          </base-text>
          <base-text class="exchange-body">
            <render-content>
              {{ $content.moduleExchangeSelector.regularCopy }}
            </render-content>
          </base-text>
        </span>
      </radio-input>
    </base-card>
  </div>
</template>

<script>
import {
  RadioInput,
  BaseCard,
  BaseText,
  BaseButton,
} from '@loophq/design-system';

export default {
  components: {
    RadioInput,
    BaseCard,
    BaseText,
    BaseButton,
  },
  props: {
    initialValue: {
      type: String,
      required: false
    },
    exchanges: {
      type: Object,
      required: false
    }
  },
  emits: ['input', 'show-instant-exchange-explanation'],
  data() {
    return {
      selected: '',
    };
  },
  computed: {
    instantExchangeWindow() {
      return this.exchanges.instantExchangeWindow;
    },
  },
  watch: {
    selected(val) {
      this.$trackEvent(`${val} exchange clicked`);

      this.$emit('input', {
        exchangeType: val
      });
    }
  },
  created() {
    if (this.initialValue) {
      this.selected = this.initialValue;
    }

    this.$trackEvent('user shown exchange options');
  },
  methods: {
    toggleMore() {
      this.$emit('show-instant-exchange-explanation');

      this.$trackEvent('more ix information button clicked');
    }
  }
};
</script>

<style lang="scss" scoped>
.exchange-selector {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-300);
}

.selector-header {
  display: flex;
}

.selector-heading {
  font-weight: 700;
}

.more-button {
  margin-left: var(--spacing-200);
}

.exchange-input {
  & + & {
    margin-top: var(--spacing-300);
    padding-top: var(--spacing-300);
    border-top: 1px solid var(--grey-200);
  }
}

.exchange-card {
  border-radius: var(--corners);
  box-shadow: none;
  padding: var(--spacing-300) var(--spacing-300);
}

.exchange-label {
  display: flex;
  flex-direction: column;
}

.exchange-heading {
  font-weight: 700;
}

.exchange-body {
  margin-top: var(--spacing-100);
}

.explanation-copy {
  margin: -1rem 0;
}
</style>
